import FrontLayout from "../layout-new-theme/FrontLayout";
import Discover from "./Discover";

const  DiscoverPage = () => {
    return <>
        <FrontLayout>
            <Discover/>
        </FrontLayout>
    </>
}
export default DiscoverPage;