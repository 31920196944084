import React, { useState, useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import LocationAirportIcon from "../../images/location-airport-icon.svg";
import TrainPin from "../../images/train_pin.svg";
import alphard from "../../images/alphard-new23.png";
import hiace_grand_cabin from "../../images/hiace-grand-new23.png";
import hiace_luxury_cabin from "../../images/hiace-luxury-new23.png";
import toyota_granace from "../../images/granace-new23.png";
import ByTheHourForm from "../layout-new-theme/form-card/ByTheHourForm";
import SpecifiedDestinationForm from "../layout-new-theme/form-card/SpecifiedDestinationForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import suitCaseIcon from "../../images/suitcase.svg";
import passengerIcon from "../../images/passenger.svg";
import chargerIcon from "../../images/charger.svg";
import wifiIcon from "../../images/wifi.svg";
import tvIcon from "../../images/tv.svg";
import dvdIcon from "../../images/dvd.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";
const Index = () => {
  const dispatch = useDispatch();
  const page_name = "cms-index";
  const { t } = useTranslation();
  const history = useHistory();
  const settings = useSelector((state) => state.setting);
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const { lang } = settings;

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  const [tabName, setTabName] = useState("byTheHour");
  const [cutomerChangedDropOffTimeOnly, setcutomerChangedDropOffTimeOnly] =
    useState("");
  const availabilityValueReset = () => {};
  const [formdatavalidation, setFormdatavalidation] = useState({
    pickuplocation: true,
    dropofflocations: true,
    traveldate: true,
    pickuptime: true,
    pax: true,
    flightNumber: true,
    duration: true,
    isAirportTransfer: null,
  });

  useEffect(() => {
    setFormdatavalidation({
      pickuplocation: true,
      dropofflocations: true,
      traveldate: true,
      pickuptime: true,
      pax: true,
      flightNumber: true,
      duration: true,
      isAirportTransfer: null,
    });
  }, [tabName]);

  /**
   * * Set the selected tab (Airport Transfer, Single Destination, Multiple Destinations, By The Hour)
   ** Check tabName parameter value
   */
  const getTab = () => {
    if (tabName === "byTheHour")
      return (
        <ByTheHourForm
          cutomerChangedDropOffTimeOnly={cutomerChangedDropOffTimeOnly}
          setcutomerChangedDropOffTimeOnly={(val) => {
            setcutomerChangedDropOffTimeOnly(val);
          }}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
        />
      );
    if (tabName === "specifiedDestination")
      return (
        <SpecifiedDestinationForm
          cutomerChangedDropOffTimeOnly={cutomerChangedDropOffTimeOnly}
          setcutomerChangedDropOffTimeOnly={(val) => {
            setcutomerChangedDropOffTimeOnly(val);
          }}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
        />
      );
  };
  return (
      <FrontLayout>
        <div className={`container-fluid mx-0 px-0 ${tabName === "byTheHour" ? "hero-image-home-3-byTheHour" : "hero-image-home-3-specifiedDestination"}`}> 
        <div className="container px-md-3 custom-pt-7 d-flex flex-column justify-content-center">
          <div className="row">
            <div className="col-lg-6  d-flex align-items-start home-title" >
              <h1
                className="fw-900 text-start mb-3 mb-lg-5 mt-4 mt-md-0"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content1
                      : cmspage?.page_content_jp.content1,
                }}
              >
                {/* {t("Index.Hero.Heading")} */}
              </h1>
            </div>
            <div className="col-lg-6">
              {/* <!-- select tab btns --> */}
              <div className="col">
                <ul className="nav nav-custom flex-md-row  text-center">
                  <li className={`nav-item py-md-0 col-6`}>
                    <div
                      className={`nav-link nav-link-form px-3 px-lg-5  d-flex justify-content-center align-items-center  ${
                        tabName === "byTheHour" ? "active" : ""
                      }`}
                      onClick={() => {
                        setTabName("byTheHour");
                      }}
                      role={"button"}
                    >
                      <span className="d-none d-md-block text-center">
                        
                      {t("ByTheHour.Destinations.Mobile")}
                      </span>
                      <span className="d-block d-md-none text-center">
                        {t("ByTheHour.Destinations.Mobile")}
                      </span>
                    </div>
                  </li>
                  <li className={`nav-item py-md-0 col-6`}>
                    <div
                      className={`nav-link nav-link-form px-3 px-lg-5 d-flex justify-content-center align-items-center   ${
                        tabName === "specifiedDestination" ? "active" : ""
                      }`}
                      onClick={() => {
                        setTabName("specifiedDestination");
                      }}
                      role={"button"}
                    >
                      {t("Specified.Destinations")}
                    </div>
                  </li>
                </ul>
              </div>
              {/* <!-- hero form part --> */}
              {getTab()}
              {/* <!-- hero form part end --> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- section 1 --> */}

      <div className={`container-fluid mx-0 px-0 px-0 mb-5 pt-lg-5 pb-5 `}>
        <div className={`row mx-0 mb-5 pb-lg-3 d-flex align-items-center ${tabName === "byTheHour" ? `mt-0` : `mt-5 mt-md-0`}`}>
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center">
            <h2
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Index.Heading.One")} */}
            </h2>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>

        <div className="container px-md-3">
          <div className="row gy-4 gx-2 mb-n1 align-items-stretch">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  // src={chauffeur_driving}
                  src={
                    lang === "EN"
                      ? cmspage?.page_content_en.image1
                      : cmspage?.page_content_jp.image1
                  }
                  className="card-img img-cover"
                  alt="Highly-trained chauffeurs"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height">
                  <hr className="card-line" />
                  <h5
                    className="card-title mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content3
                          : cmspage?.page_content_jp.content3,
                    }}
                  >
                    {/* {t("Index.Card.One")} */}
                  </h5>

                  <p
                    className="card-text-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content4
                          : cmspage?.page_content_jp.content4,
                    }}
                  >
                    {/* {t("Index.Card.One.Content")} */}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  // src={vehicle_on_road_small}
                  src={
                    lang === "EN"
                      ? cmspage?.page_content_en.image2
                      : cmspage?.page_content_jp.image2
                  }
                  className="card-img img-cover"
                  alt="Luxurious vehicles with high-end amenities"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height">
                  <hr className="card-line" />
                  {/* <h5 className="card-title mb-3">{t("Index.Card.Two")}</h5> */}
                  <h5
                    className="card-title mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content5
                          : cmspage?.page_content_jp.content5,
                    }}
                  ></h5>
                  {/* <p className="card-text-light">{t("Index.Card.Two.Content")}</p> */}
                  <p
                    className="card-text-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content6
                          : cmspage?.page_content_jp.content6,
                    }}
                  ></p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  // src={customer_service}
                  src={
                    lang === "EN"
                      ? cmspage?.page_content_en.image3
                      : cmspage?.page_content_jp.image3
                  }
                  className="card-img img-cover"
                  alt="Exceptional customer service"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height">
                  <hr className="card-line" />
                  {/* <h5 className="card-title mb-3">{t("Index.Card.Three")}</h5> */}
                  <h5
                    className="card-title mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content7
                          : cmspage?.page_content_jp.content7,
                    }}
                  ></h5>
                  {/* <p className="card-text-light">{t("Index.Card.Three.Content")}</p> */}
                  <p
                    className="card-text-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content8
                          : cmspage?.page_content_jp.content8,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  // src={vehicle_interior_small}
                  src={
                    lang === "EN"
                      ? cmspage?.page_content_en.image4
                      : cmspage?.page_content_jp.image4
                  }
                  className="card-img img-cover"
                  alt="Comfortable interior with plenty of space and amenities"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height">
                  <hr className="card-line" />
                  {/* <h5 className="card-title mb-3">{t("Index.Card.Four")}</h5> */}
                  <h5
                    className="card-title mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content9
                          : cmspage?.page_content_jp.content9,
                    }}
                  ></h5>
                  {/* <p className="card-text-light">{t("Index.Card.Four.Content")}</p> */}
                  <p
                    className="card-text-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content10
                          : cmspage?.page_content_jp.content10,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}

      {/* <!-- section 2 packages--> */}
      <div className="container-fluid mx-0 px-0 custom-mt-7 py-3 px-0 ">
        <div className="row mx-0 my-5 pb-5 d-flex align-items-center">
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center nowrap pt-lg-2 pb-lg-3">
            {/* <h3>{t("Index.Heading.Two")}</h3> */}
            <h2
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content11
                    : cmspage?.page_content_jp.content11,
              }}
            ></h2>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>

        <div className="container-fluid mx-0 px-0 bg-light-grey pb-3">
          <div className="container px-md-5 mt-5 py-5 bg-light-grey">
            <div className="row gy-4 mt-n2">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    // src={AirportTransfer}
                    src={
                      lang === "EN"
                        ? cmspage?.page_content_en.image5
                        : cmspage?.page_content_jp.image5
                    }
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Airport transfer service"
                  />

                  <div
                    className="card-header ps-3 pt-3 pb-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content12
                          : cmspage?.page_content_jp.content12,
                    }}
                  >
                    {/* {t("Index.Card.Five.Tag")} */}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5
                      className="card-title mb-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content13
                            : cmspage?.page_content_jp.content13,
                      }}
                    >
                      {/* {t("Index.Card.Five.Heading")} */}
                    </h5>

                    <div className="row mt-auto">
                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./airport-transfer")}
                            type="button"
                            className="btn btn-outline-primary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {/* {t("Book.Now")} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    lang === "EN"
                                      ? cmspage?.page_content_en.content14
                                      : cmspage?.page_content_jp.content14,
                                }}
                              ></div>
                              <i className="ps-2">
                                <img  alt="img" src={arrow_right_icon}  />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    // src={events}
                    src={
                      lang === "EN"
                        ? cmspage?.page_content_en.image6
                        : cmspage?.page_content_jp.image6
                    }
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Events, such as weddings and anniversaries"
                  />

                  <div
                    className="card-header ps-3 pt-3 pb-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content15
                          : cmspage?.page_content_jp.content15,
                    }}
                  >
                    {/* {t("Index.Card.Six.Tag")} */}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5
                      className="card-title mb-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content16
                            : cmspage?.page_content_jp.content16,
                      }}
                    >
                      {/* {t("Index.Card.Six.Heading")} */}
                    </h5>

                    <div className="row mt-auto">
                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() =>
                              history.push("./weddings-anniversaries")
                            }
                            type="button"
                            className="btn btn-outline-primary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {/* {t("Book.Now")} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    lang === "EN"
                                      ? cmspage?.page_content_en.content17
                                      : cmspage?.page_content_jp.content17,
                                }}
                              ></div>
                              <i className="ps-2">
                               
                                <img  alt="img" src={arrow_right_icon}  />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    // src={business_transfer}
                    src={
                      lang === "EN"
                        ? cmspage?.page_content_en.image7
                        : cmspage?.page_content_jp.image7
                    }
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Business transfer"
                  />

                  <div
                    className="card-header ps-3 pt-3 pb-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content18
                          : cmspage?.page_content_jp.content18,
                    }}
                  >
                    {/* {t("Index.Card.Seven.Tag")} */}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5
                      className="card-title mb-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content19
                            : cmspage?.page_content_jp.content19,
                      }}
                    >
                      {/* {t("Index.Card.Seven.Heading")} */}
                    </h5>

                    <div className="row mt-auto">
                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./executive-transfer")}
                            type="button"
                            className="btn btn-outline-primary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {/* {t("Book.Now")} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    lang === "EN"
                                      ? cmspage?.page_content_en.content20
                                      : cmspage?.page_content_jp.content20,
                                }}
                              ></div>
                              <i className="ps-2">
                               
                                <img  alt="img" src={arrow_right_icon}  />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    // src={sightseeing}
                    src={
                      lang === "EN"
                        ? cmspage?.page_content_en.image8
                        : cmspage?.page_content_jp.image8
                    }
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Sightseeing: private tours and packages"
                  />

                  <div
                    className="card-header ps-3 pt-3 pb-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content21
                          : cmspage?.page_content_jp.content21,
                    }}
                  >
                    {/* {t("Index.Card.Eight.Tag")} */}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5
                      className="card-title mb-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content22
                            : cmspage?.page_content_jp.content22,
                      }}
                    >
                      {/* {t("Index.Card.Eight.Heading")} */}
                    </h5>

                    <div className="row mt-auto">
                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./private-tour")}
                            type="button"
                            className="btn btn-outline-primary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {/* {t("Book.Now")} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    lang === "EN"
                                      ? cmspage?.page_content_en.content23
                                      : cmspage?.page_content_jp.content23,
                                }}
                              ></div>
                              <i className="ps-2">
                               
                                <img  alt="img" src={arrow_right_icon}  />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2, 2 card grid highlighted packages --> */}
      <div className="container-fluid mx-0 px-0 mt-2 mt-md-4">
        <div className="container px-md-3 pb-4">
          <div className="row mx-0 mb-3 mb-md-5 align-items-center">
            {/* <div className="col-auto px-0"></div> */}
            <div className="col-auto ml-5">
              <span className="h6 noto nowrap m-0 text-start fw-bolder d-md-none">
                {t("Seamless Airport Transfer Solutions")}
              </span>
              <h3 className=" noto nowrap m-0 text-center fw-bolder d-none d-md-block">
                {t("Seamless Airport Transfer Solutions")}
              </h3>
            </div>
            <div className="col px-0">
              <hr />
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="hnd-banner">
                  <div className="card-body ms-3 py-0">
                    <div className="d-flex flex-row py-3">
                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">
                        <div className="col-12 pt-3">
                          <img
                            src={LocationAirportIcon}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                        <div className="col-12 text-center pt-3 pb-3 mb-0">
                          <p className="mb-0">HND</p>
                        </div>
                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between">
                        <div className="col-12">
                          <div
                            className="card-header pt-3 ps-0 pb-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content24
                                  : cmspage?.page_content_jp.content24,
                            }}
                          >
                            {/* {t("Index.Card.Five.Tag")} */}
                          </div>
                          <h5
                            className="card-title card-title-banner"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content25
                                  : cmspage?.page_content_jp.content25,
                            }}
                          >
                            {/* {t("Haneda.Airport")} */}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() =>
                                history.push("./haneda-airport-transfer")
                              }
                              type="button"
                              className="btn btn-outline-primary btn-sm text-dark px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline ">
                                {/* {t("Book.Now")} */}

                                {t("Learn.More")}
                                <i className="ps-2">
                                  <img  alt="img" src={arrow_right_icon} />
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="nrt-banner">
                  <div className="card-body ms-3 py-0">
                    <div className="d-flex flex-row py-3">
                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">
                        <div className="col-12 pt-3">
                          <img
                            src={LocationAirportIcon}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                        <div className="col-12 text-center pt-3 pb-3 mb-0">
                          <p className="mb-0">NRT</p>
                        </div>
                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between">
                        <div className="col-12">
                          <div
                            className="card-header pt-3 ps-0 pb-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content27
                                  : cmspage?.page_content_jp.content27,
                            }}
                          >
                            {/* {t("Index.Card.Five.Tag")} */}
                          </div>
                          <h5
                            className="card-title card-title-banner"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content28
                                  : cmspage?.page_content_jp.content28,
                            }}
                          >
                            {/* {t("Narita.Airport")} */}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() =>
                                history.push("./narita-airport-transfer")
                              }
                              type="button"
                              className="btn btn-outline-primary btn-sm text-dark px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                                {/* {t("Book.Now")} */}

                                {t("Learn.More")}
                                <i className="ps-2">
                                  <img  alt="img" src={arrow_right_icon} />
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2, 2 card grid end -->
    <!-- section 2 end -->

    {/* <!-- section 2, 2 card grid highlighted packages --> */}
      <div className="container-fluid mx-0 px-0 mt-2 mt-md-4">
        <div className="container px-md-3 pb-4">
          <div className="row mx-0 mb-3 mb-md-5 align-items-center">
            <div className="col-auto ml-5">
              <span className="h6 noto nowrap m-0 text-start fw-bolder d-md-none">
                {t("Smooth Shinkansen Transfer Options")}
              </span>
              <h3 className=" noto nowrap m-0 text-center fw-bolder d-none d-md-block">
                {t("Smooth Shinkansen Transfer Options")}
              </h3>
            </div>
            <div className="col px-0">
              <hr />
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="tky-st-banner">
                  <div className="card-body ms-3 py-0">
                    <div className="d-flex flex-row py-3">
                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">
                        <div className="col-12 pt-4">
                          <img
                            src={TrainPin}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between text-white">
                        <div className="col-12 mb-3">
                          <div className="card-header pt-3 ps-0 pb-2">
                            {t("SHINKANSEN TRANSFER")}
                          </div>
                          <h5 className="card-title card-title-banner">
                            {t("Tokyo Station")}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() =>
                                // history.push("./haneda-airport-transfer")
                                history.push("./book")
                              }
                              type="button"
                              className="btn btn-outline-primary btn-sm px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline text-white">
                                {/* {t("Book.Now")} */}
                                {t("Learn.More")}
                                <i className="ps-2">
                                 <img  alt="img" src={arrow_right_icon}/>
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="shng-st-banner">
                  <div className="card-body ms-3 py-0">
                    <div className="d-flex flex-row py-3">
                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">
                        <div className="col-12 pt-4">
                          <img
                            src={TrainPin}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between text-white">
                        <div className="col-12 mb-3">
                          <div className="card-header pt-3 ps-0 pb-2">
                            {t("SHINKANSEN TRANSFER")}
                          </div>
                          <h5 className="card-title card-title-banner">
                            {t("Shinagawa Station")}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() =>
                                // history.push("./haneda-airport-transfer")
                                history.push("./book")
                              }
                              type="button"
                              className="btn btn-outline-primary btn-sm text-dark px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline text-white">
                                {/* {t("Book.Now")} */}

                                {t("Learn.More")}
                                <i className="ps-2">
                                 <img  alt="img" src={arrow_right_icon}/>
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2, 2 card grid end -->
    <!-- section 2 end -->

    
    <!-- section 4, vehicles --> */}
      <div className="container-fluid mx-0 px-0 mt-lg-3 py-5 px-0">
        <div className="row mx-0 d-flex align-items-center">
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center">
            {/* <h3>{t("Fleet.Heading")}</h3> */}
            <h2
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content30
                    : cmspage?.page_content_jp.content30,
              }}
            ></h2>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>

        <div className="container px-md-3 mt-4 mt-lg-3 pt-lg-5 pb-3">
          <div className="row gy-4 gx-2">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={alphard}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota Alphard"
                  onClick={() => history.push("./alphard")}
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Alphard.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h4 className="card-title m-0 p-0">{t("Toyota.Alphard")}</h4>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column ">
                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={passengerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={suitCaseIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={chargerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={wifiIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">{t("Toyota.Alphard.Wifi")}</p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={tvIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={dvdIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">
                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./alphard")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark rounded-3 py-2"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={toyota_granace}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota GranAce"
                  onClick={() => history.push("./granace")}
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Granace.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h4 className="card-title m-0 p-0">{t("Toyota.Granace")}</h4>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column ">
                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={passengerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={suitCaseIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={chargerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={wifiIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">{t("Toyota.Granace.Wifi")}</p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={tvIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={dvdIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">
                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./granace")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark rounded-3 py-2"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={hiace_grand_cabin}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota HiAce Grand Cabin"
                  onClick={() => history.push("./hiace-grand-cabin")}
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Hiace.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h4 className="card-title m-0 p-0">{t("Toyota.Hiace")}</h4>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column ">
                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={passengerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={suitCaseIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={chargerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={wifiIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">{t("Toyota.Hiace.Wifi")}</p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={tvIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={dvdIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">
                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./hiace-grand-cabin")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark rounded-3 py-2"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card border-grey h-100 shadow-sm-2">
                <img
                  src={hiace_luxury_cabin}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota HiAce Luxury Cabin"
                  onClick={() => history.push("./hiace-luxury-cabin")}
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Hiace.Luxury.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h4 className="card-title m-0 p-0">
                    {t("Toyota.Hiace.Luxury")}
                  </h4>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column ">
                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={passengerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={suitCaseIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={chargerIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={wifiIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Wifi")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={tvIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-1 text-center align-item-center me-2">
                        <img src={dvdIcon} alt="" srcSet="" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">
                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./hiace-luxury-cabin")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark rounded-3 py-2"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- section 4 end --> */}
    </FrontLayout>
  );
};

export default Index;
