import moment from "moment";
import { getAllPackages } from "../actions/admin/package";
import { checkDistance } from "../actions/navitimeAPI";
import Alphard from "../images/Alphard.jpeg";
import GC from "../images/Grand_Cabin.png";
import LC from "../images/Luxury_Cabin.png";
import Granace from "../images/Granace.png";

/**
 * * Get image file name
 */
export const getFileName = (file) => {
  const imageName = new Date().getTime();
  const fileExtArr = file.target.files[0].name.split(".");
  const name = fileExtArr[0];
  const ext = fileExtArr[fileExtArr.length - 1];
  const fileName = name + "_" + imageName + "." + ext;
  return fileName;
};

/**
 * * Get cart drop-off location
 */
export const getCartDropOffLocations = (formdata) => {
  const fromAir = formdata.isFromAirport;
  var drop = "";
  if (fromAir === true || fromAir === undefined) {
    drop = formdata.destinations
      ? formdata?.destinations
        .map((d) => {
          return d.formatted_address;
        })
        .join(" / ")
      : "[drop off location]";
  } else if (fromAir === false) {
    drop = formdata.pickuplocation
      ? formdata?.pickuplocation.label
      : "[drop off location]";
  }
  return drop;
};

/**
 * * Get cart pickup location
 */
export const getCartPickupLocation = (formdata) => {
  const fromAir = formdata.isFromAirport;

  var pic = "";
  if (fromAir === true || fromAir === undefined) {
    pic = formdata.pickuplocation
      ? formdata?.pickuplocation.label
      : "[pick up location]";
  } else if (fromAir === false) {
    pic = formdata.destinations
      ? formdata?.destinations[0].label
      : "[pick up location]";
  }
  return pic;
};

/**
 * * Get pickup location
 */
export const getPickupLocation = (formdata) => {
  const fromAir = formdata.isFromAirport;
  var pic = "";
  if (fromAir === true || fromAir === undefined) {
    pic = formdata.pickuplocation
      ? formdata?.pickuplocation.label
      : "[pick up location]";
  } else if (fromAir === false) {
    pic = formdata.dropofflocations
      ? formdata?.dropofflocations[0].label
      : "[pick up location]";
  }
  return pic;
};

/**
 * * Get drop off locations
 */
export const getDropOffLocations = (formdata) => {
  const fromAir = formdata.isFromAirport;
  var drop = "";
  if (fromAir === true || fromAir === undefined) {
    drop = formdata.dropofflocations
      ? formdata?.dropofflocations
        .map((d) => {
          return d.formatted_address;
        })
        .join(" / ")
      : "[drop off location]";
  } else if (fromAir === false) {
    drop = formdata.pickuplocation
      ? formdata?.pickuplocation.label
      : "[drop off location]";
  }
  return drop;
};

/**
 * * Generate random value
 */
export const generateRandom = (min = 0, max = 9) => {
  // find diff
  let difference = max - min;
  // generate random number
  let rand = Math.random();
  // multiply with difference
  rand = Math.floor(rand * difference);
  // add with min value
  rand = rand + min;
  return rand;
};

/**
 * * Get vehicle type image
 */
export const getImage = (vtype) => {
  if (vtype === null) return;
  var imageName = null;
  switch (vtype.toLowerCase()) {
    case "Toyota Alphard".toLowerCase():
      imageName = Alphard;
      break;
    case "HiAce Luxury Cabin".toLowerCase():
      imageName = LC;
      break;
    case "HiAce Grand Cabin".toLowerCase():
      imageName = GC;
      break;
    case "Toyota GranAce".toLowerCase():
      imageName = Granace;
      break;
    default:
      break;
  }
  return imageName;
};

/**
 * * Travel date and pickup time have two different input controls
 * * This function will merge data from both input controls to a single date format
 */
export const getTravelDateWithPickupTime = (formData) => {
  if (formData === null) return;
  const dateString = formData.traveldate + " " + formData.pickuptime;
  const travel_date_with_pickup_time = moment.utc(dateString, "YYYY-MM-DD HH:mm");
  var p_arr = formData.pickuptime.split(":");
  var p_h = Number(p_arr[0]);
  var p_m = Number(p_arr[1]);

  return { travel_date_with_pickup_time, p_h, p_m };
};

export const getTravelDateWithDropoffTime = (formData) => {
  if (formData === null) return;
  const dateString = formData.traveldate + " " + formData.dropofftime;
  const travel_date_with_dropoff_time_initial = moment(
    dateString,
    "YYYY-MM-DD HH:mm"
  );
  return { travel_date_with_dropoff_time_initial };
};

export const setSpecialPackage = async (pickup_location, destinations) => {
  const pkg_list = await getAllPackages();
  // Check airport package in pickup location
  var activated_pkg = [];
  var pickup_location_has_airport = false;
  for (var i = 0; i < pkg_list.length; i++) {
    const airport_tags = pkg_list[i].airporttags.trim().split(",");
    airport_tags.map((at) => {
      if (
        pickup_location.formatted_address
          .toLowerCase()
          .includes(at.toLowerCase().trim())
      ) {
        //Hanedakuko, Haneda Airport, Haneda International Airport, 羽田空港, 羽田空港国際線
        pickup_location_has_airport =
          at.toLowerCase().trim() == "羽田空港" ||
            at.toLowerCase().trim() == "Hanedakuko" ||
            at.toLowerCase().trim() == "Haneda Airport" ||
            at.toLowerCase().trim() == "Haneda International Airport" ||
            at.toLowerCase().trim() == "羽田空港国際線"
            ? false
            : true;

        var locations_tags_list = pkg_list[i].locationstags.trim().split(",");
        if (
          locations_tags_list.some((item) =>
            destinations[0].formatted_address
              .toLowerCase()
              .trim()
              .includes(item.toLowerCase().trim())
          )
        ) {
          // Has destination. Package activated.
          activated_pkg.push(pkg_list[i]);
        }
      }
    });
  }
  // // if pickup point doesn't contain the airport, then check it in destination point
  if (!pickup_location_has_airport) {
    for (var j = 0; j < pkg_list.length; j++) {
      const airport_tags = pkg_list[j].airporttags.trim().split(",");
      airport_tags.map((at) => {
        if (
          destinations[0].formatted_address
            .trim()
            .toLowerCase()
            .includes(at.trim().toLowerCase())
        ) {
          // destination_has_airport = true;
          // search for the matching destination
          var airport_tags_list = pkg_list[j].locationstags.trim().split(",");
          if (
            airport_tags_list.some((item) =>
              pickup_location.formatted_address
                .trim()
                .toLowerCase()
                .includes(item.trim().toLowerCase())
            )
          ) {
            // Has destination. Package activated.
            activated_pkg.push(pkg_list[j]);
          }
        }
      });
    }
  }
  return activated_pkg;
};

/**
 * * Pickup location or drop-off location must contain Tokyo
 * * Validate the presence of Tokyo here
 * @param pickuplocation
 * @param destination
 */
export const checkTokyoAvailability = (pickuplocation, destinations) => {
  var has_tokyo = false;
  if (
    pickuplocation.city === "Tokyo" ||
    pickuplocation.city === "東京" ||
    pickuplocation.city === "東京中" ||
    pickuplocation.city === "東京都" ||
    destinations.find((item) => item.city === "Tokyo") ||
    destinations.find((item) => item.city === "東京") ||
    destinations.find((item) => item.city === "東京中") ||
    destinations.find((item) => item.city === "東京都")
  ) {
    has_tokyo = true;
  }
  return has_tokyo;
};

/**
 * * Validate the presence of Tokyo in the drop-off / pick-up address
 */
export const checkTokyoAvailabilitySingleForm = (
  pickuplocation,
  dropofflocation
) => {
  var has_tokyo = false;
  if (
    pickuplocation.city === "Tokyo" ||
    pickuplocation.city === "東京" ||
    pickuplocation.city === "東京中" ||
    pickuplocation.city === "東京都" ||
    dropofflocation.city === "Tokyo" ||
    dropofflocation.city === "東京" ||
    dropofflocation.city === "東京中" ||
    dropofflocation.city === "東京都"
  ) {
    has_tokyo = true;
  }
  return has_tokyo;
};

/**
 * * Get total amount
 * @param vehicle_type - vehicle type
 * @param totHoursComplete - complete total hours
 * @param tollFare - toll fee
 * @param zoneIncludedHoursRounded - any hours of the booking that are within the night zone (22:00-05:00)
 * @param activePackages - activated airport transfer package
 * @param customerAddedAditionalMinutes - addaed additional minuted
 * @param total - return total amount
 * @param nightPrice - return night time price
 */
export const getTotal = (
  vehicle_type,
  totHoursComplete,
  tollFare,
  travelAreaPrice,
  zoneIncludedHoursRounded,
  activePackages,
  customerAddedAditionalMinutes,
  longDistanceSurcharge = 0,
  totalTimeWihtoutPackage = 0,
  dropoffLocations
) => {
  var status = false;
  try {
    status = activePackages[0].vehicles.some(
      (e) => e.vtype === vehicle_type.typename
    );
  } catch { }
  if (!status) {
    const tot =
      vehicle_type.baseprice + //jika 2 jam pakai baseprice
      ((totHoursComplete > 2 ? totHoursComplete - 2 : 0) * 2 * vehicle_type.hourlyprice) +  //jika lebih dari 2 jam
      tollFare +
      travelAreaPrice +
      longDistanceSurcharge +
      (zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25); //night hours calculation
    return {
      total: Math.ceil(tot),
      nightPrice:
        (zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25),
    };
  } else {
    const pkg = activePackages[0].vehicles.find(
      (e) => e.vtype === vehicle_type.typename
    );

    var inHrsRate = 0;

    if (customerAddedAditionalMinutes <= 960) {
      inHrsRate =
        pkg.vehicleCost + //package baseprice
        ((customerAddedAditionalMinutes / 60) * vehicle_type.hourlyprice) + //hours outside calculation navitime
        (zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25); //night hours calculation
    } else {
      inHrsRate =
        pkg.vehicleCost + //package baseprice
        (zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25); //night hours calculation
    }

    if(dropoffLocations.length > 1 && totalTimeWihtoutPackage > 0){
      inHrsRate += Math.ceil(totalTimeWihtoutPackage/30) * vehicle_type.airportTimeExtension;
    }

    if (pkg)
      return {
        total: Math.ceil(inHrsRate),
        nightPrice:
          (zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25),
      };
  }
};

/**
 * * Get total amount
 * @param vehicle_type - vehicle type
 * @param totHoursComplete - complete total hours
 * @param tollFare - toll fee
 * @param zoneIncludedHoursRounded - any hours of the booking that are within the night zone (22:00-05:00)
 * @param activePackages - activated airport transfer package
 * @param customerAddedAditionalMinutes - addaed additional minuted
 * @param total - return total amount
 * @param nightPrice - return night time price
 */
export const getTotalEditBooking = async (
  bookingInfo,
  availableVehicleTypes
) => {
  var total = 0;
  var nightPrice = 0;
  var pricePerVehicle = [];

  bookingInfo.map((val, ind) => {
    const vehicleType = availableVehicleTypes.find(
      (e) => e._id === val.vehicleTypeID._id
    );

    pricePerVehicle.push({
      total: vehicleType.price,
      tollFare: vehicleType.tollFare,
      longDistanceSurcharge: vehicleType.longDistanceSurcharge,
      vehicleTypeId: vehicleType._id,
      childSeatPrice: val.childSeatPrice
    });
    total += vehicleType.price;
  })

  return {
    total: total,
    pricePerVehicle: pricePerVehicle
  }
};

/**
 * * Get total amount without the package included
 * @param vehicle_type - vehicle type
 * @param totHoursComplete - complete total hours
 * @param tollFare - toll fee
 * @param zoneIncludedHoursRounded - rounded zone hours
 */
export const getTotalWithoutPackage = (
  vehicle_type,
  totHoursComplete,
  tollFare,
  zoneIncludedHoursRounded
) => {
  const tot =
    vehicle_type.baseprice +
    (totHoursComplete > 2 ? totHoursComplete - 2 : 0) *
    2 *
    vehicle_type.hourlyprice +
    tollFare +
    zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25;
  return parseInt(tot);
};

/**
 * * Filter the night zone hour range
 */
export const isDateInMultiplierZone = (pd_date) => {
  if (pd_date) {
    const mm_date = moment(pd_date.toString());
    const hour = Number(mm_date.hour());
    var isInZone = (0 <= hour && hour < 6) || (22 <= hour && hour < 24) ? true : false;
  }
  return isInZone;
};

/**
 * * Get total pax (passenger) count when the pax object is passed here. There are 4 pax types (adult, baby, child, toddler).
 * @param obj
 */
export const getTotalPaxCount = (obj) => {
  if (obj === null) return 0;
  var sum = obj.adult + obj.baby + obj.child + obj.toddler;
  return sum;
};

export const getTotalLuggageCount = (obj) => {
  if (obj === null) return 0;
  var sum = obj.cabinSize + obj.checkinSize;
  return sum;
};

export const getTotalPaxCountArray = (arr) => {
  if (arr.length === 0) return 0;

  var sum = 0;

  for (let i = 0; i < arr.length; i++) {
    for (let ind = 0; ind < arr[i].length; ind++) {
      sum += arr[i][ind].paxcount;
    }

  }

  return sum;
};

export const getTotalLuggageCountArray = (arr) => {
  if (arr.length === 0) return 0;

  var sum = 0;

  for (let i = 0; i < arr.length; i++) {
    for (let ind = 0; ind < arr[i].length; ind++) {
      sum += arr[i][ind].luggagecount;
    }
  }

  return sum;
};

export const getObjectElementSum = (obj) => {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
};

export const sum = (prev, next) => {
  return prev + next;
};

export const getKey = () => {
  return Math.floor(Math.random() * 10000000000 + 1);
};

/**
 * * Validate the presence of Tokyo in the drop-off / pick-up address
 */

function checkLowerThanKm(m) {
  return (m / 1000) < 200;
}

export const checkIsAroundGarage = async (
  location, isDropoff = false
) => {
  var has_tokyo = false;
  if (isDropoff) {
    await Promise.all(
      location.map(async (itm, ind) => {
        const distance_route = await checkDistance(
          "35.6235029,139.7410879",
          itm.lat.toString() + "," + itm.lon.toString()
        );
        if (checkLowerThanKm(distance_route.distance)) {
          has_tokyo = true;
        }
      })
    )
  } else {
    const distance_route = await checkDistance(
      "35.6235029,139.7410879",
      location.lat.toString() + "," + location.lon.toString()
    );
    if (checkLowerThanKm(distance_route.distance)) {
      has_tokyo = true;
    }
  }
  return has_tokyo;
};
