import React, { useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import alphard from "../../images/alphard-bg-cropped.png";
import alphard1 from "../../images/alphard-sm-1-new.jpeg";
import alphard2 from "../../images/alphard-sm-2-new.jpeg";
import alphard3 from "../../images/alphard-sm-3-new.jpeg";
import alphard4 from "../../images/alphard-sm-4-new.jpeg";
import alphard5 from "../../images/alphard-sm-5-new.jpeg";
import alphard6 from "../../images/alphard-sm-6-new.jpeg";
import alphard7 from "../../images/alphard-sm-7-new.jpg";
import alphard8 from "../../images/alphard-sm-8-new.jpg";
import alphard9 from "../../images/alphard-sm-9-new.jpg";
import video_icon from "../../icons/car-icons/360-icon.svg";
import alphard_seatplan from "../../images/alphard_seatplan.png";
import people_icon from "../../icons/people.svg";
import luggage_icon from "../../icons/luggage.svg";
import charger_icon from "../../icons/charger.svg";
import wifi_icon from "../../icons/wifi.svg";
import tv_icon from "../../icons/tv.svg";
import dvd_icon from "../../icons/dvd.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";

const AlphardCarPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState(null);

  return (
    <FrontLayout>
      <div className="hero-image-alphard d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p className="card-header ps-0 text-gold">{t("Fleet")}</p>
            <h1 className="fw-bold mb-4 mt-2">{t("Toyota.Alphard")}</h1>
            <p className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0">
              {t("Toyota.Alphard.Description")}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- vehicle type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                aria-current="page"
                to="./alphard"
              >
                {t("Toyota.Alphard")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./granace"
              >
                {t("Toyota.Granace")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./hiace-grand-cabin"
              >
                {t("Hiace")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./hiace-luxury-cabin"
              >
                {t("Hiace.Luxury")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- vehicle type nav --> */}
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-md-3 mt-lg-5 pt-5 pb-3">
        <div className="row mx-0 mb-3 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column mx-auto flex-md-row justify-content-md-between border-bottom-gold">
            <div className="col-12 col-md-6 col-lg-7 d-flex flex-column mt-auto pb-3 pb-md-0 order-2 order-md-1">
              <div className="card-header ps-0 pt-3 pb-2">
                {t("Toyota.Alphard.Tag")}
              </div>
              <div className="card-body ps-0 pt-1">
                <h2 className="card-title fs-7 mb-3">{t("Toyota.Alphard")}</h2>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-5 pb-3 order-1 order-md-2">
              <img
                src={alphard}
                className="card-img-top"
                alt="Toyota Alphard"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto pb-3">
        <div className="row mx-0 mb-3 mb-lg-5 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 col-md-6 d-flex flex-column mt-auto">
              <div className="row mb-3">
                <h3 className="fs-6">{t("Vehicle.Details")}</h3>
              </div>
              <p className="text-dark">
                {t("Toyota.Alphard.Description.Longer")}
              </p>
            </div>

            <div className="col-12 col-md-5 mt-4 mt-md-0">
              <div className="row mb-3">
                <h3 className="fs-6">{t("Features")}</h3>
              </div>
              <div className="row row-cols-2 gy-2 gy-md-0 gx-5 gx-md-0 my-3">
                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <img src={people_icon} alt=""  className="vehicle-specs-icons" />
                  </div>
                  <div className="col ps-0">
                    <p className="card-text d-flex align-self-center">
                      {t("Toyota.Alphard.Passengers")}
                    </p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">

                  <img src={luggage_icon} alt=""  className="vehicle-specs-icons" />
                    
                  </div>
                  <div className="col ps-0">
                    <p className="card-text d-flex align-self-center">
                      {t("Toyota.Alphard.Suitcases")}
                    </p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                   
                  <img src={charger_icon} alt=""  className="vehicle-specs-icons" />
                  </div>
                  <div className="col ps-0">
                    <p className="card-text d-flex align-self-center">
                      {t("Toyota.Alphard.Mobile.Device.Charger")}
                    </p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    
                  <img src={wifi_icon} alt=""  className="vehicle-specs-icons" />
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Alphard.Wifi")}</p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                  <img src={tv_icon} alt=""  className="vehicle-specs-icons" />
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">
                      {t("Toyota.Alphard.Television")}
                    </p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                   
                  <img src={dvd_icon} alt=""  className="vehicle-specs-icons" />
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">
                      {t("Toyota.Alphard.DVD.Player")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2 end --> */}
      {/* <!-- section 3, image slider/ carousel --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mb-lg-0 pb-lg-3">
        <div className="row d-flex justify-content-center align-items-center mx-0 mb-3 mb-lg-0 px-3 px-md-0">
          <div className="col col-lg-10 pb-2">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="carousel-indicators-item active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  className="carousel-indicators-item"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  className="carousel-indicators-item"
                  aria-label="Slide 3"
                ></button>
              </div>

              <div className="d-flex align-items-center">
                <div className="carousel-button-container-left">
                  <button
                    className="carousel-button-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <i className="bi bi-arrow-left icon-next-prev"></i>
                  </button>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row row-cols-3 row-cols-md-4 px-2">
                      <div className="col p-1">
                        <img
                          src={alphard1}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(1)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={alphard2}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(2)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={alphard3}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(3)}
                        />
                      </div>
                      <div className="col p-1 d-none d-md-block">
                        <img
                          src={alphard4}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(4)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 row-cols-md-4 px-2">
                      <div className="col p-1 d-block d-md-none">
                        <img
                          src={alphard4}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(4)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={alphard5}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(5)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={alphard6}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(6)}
                        />
                      </div>
                      <div className="col p-1 d-none d-md-block">
                        <img
                          src={alphard7}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(7)}
                        />
                      </div>
                      <div className="col p-1 d-none d-md-block">
                        <img
                          src={alphard8}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(8)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 row-cols-md-4 px-2">
                      <div className="col p-1 d-block d-md-none">
                        <img
                          src={alphard7}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(7)}
                        />
                      </div>
                      <div className="col p-1 d-block d-md-none">
                        <img
                          src={alphard8}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(8)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={alphard9}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Alphard"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(9)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-button-container-right">
                  <button
                    className="carousel-button-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <i className="bi bi-arrow-right icon-next-prev"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3, image slider end --> */}
      {/* <!-- section 4 video --> */}
      <div className="container col-md-11 col-lg-9 mx-auto">
        <hr className="col-12 col-lg-10 mx-auto card-line-light-grey mt-5"></hr>
        <div className="row mx-0 mb-3 px-3 px-md-0 pt-3">
          <div className="col col-lg-10 offset-lg-1 pb-5 px-0">
            <div className="row row-cols-1 mb-3">
              <div className="card-body d-flex flex-column flex-md-row pb-0 align-items-md-center justify-content-between">
                <div className="col-sm-12 col-md-7 d-flex flex-column justify-content-center order-2 order-md-1">
                  <h3 className="fs-6 col-lg-9 mb-3">
                    {t("Toyota.Alphard.Video.Intro")}
                  </h3>
                  <p>{t("Toyota.Alphard.Video.Intro.Additional")}</p>
                </div>
                <div className="d-flex align-items-center order-1 order-md-2 pb-4 pb-md-0">
                  <div className="col-auto d-flex align-items-center">
                    <img
                      src={video_icon}
                      className="card-img"
                      alt="360-degree"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mx-0 px-0 bg-light-grey">
        <div className="container col-md-11 col-lg-9 mx-auto px-md-4 mt-5 pt-5 bg-light-grey">
          <div className="row mx-2 mx-sm-0 mt-n2 mb-5">
            <div className="col col-lg-10 offset-lg-1 px-0">
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/y5mfQMNH-xA"
                  title="Toyota Alphard VR 360 Video by THE LUXURY FLEET Co., Ltd."
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 4 end --> */}
      {/* <!-- section 5 specifications, heading --> */}
      <div className="row mx-0 mb-4 mb-lg-5 pb-md-4 pt-3 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3>{t("Specifications")}</h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs, mob --> */}
      <div className="d-block d-md-none container col-md-11 col-lg-9 mx-auto">
        <div className="row mx-0 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 px-0">
            <div className="row row-cols-2">
              <div className="col-12 col-md-5 col-lg-5 d-flex justify-content-center">
                <img
                  src={alphard_seatplan}
                  className="img-fluid"
                  alt="Toyota Alphard seat map"
                />
              </div>

              <div className="col-12 col-md-7 col-lg-7 d-flex flex-column mb-auto">
                <div className="card-header ps-0 pt-5 pb-2">
                  {t("Toyota.Alphard.Tag")}
                </div>
                <div className="card-body ps-0 pt-1">
                  <h3 className="card-title mb-3">{t("Toyota.Alphard")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none container col-md-11 col-lg-9 mx-auto px-0">
        <div className="row mx-0 mb-3 mb-md-5 px-md-0">
          <div className="col col-lg-10 offset-lg-1 pb-5 px-0">
            <ul
              className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable border-bottom border-top pt-3"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 active"
                  id="sizeSpecifications-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sizeSpecifications-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="sizeSpecifications-tab-pane"
                  aria-selected="true"
                >
                  {t("Size.Specifications")}
                </button>
              </li>
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4"
                  id="interiorSpace-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#interiorSpace-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="interiorSpace-tab-pane"
                  aria-selected="false"
                >
                  {t("Interior.Space")}
                </button>
              </li>
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4"
                  id="capacity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#capacity-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="capacity-tab-pane"
                  aria-selected="false"
                >
                  {t("Capacity")}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="sizeSpecifications-tab-pane"
                role="tabpanel"
                aria-labelledby="sizeSpecifications-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Size.Dimensions")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <p>{t("Length")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Alphard.L")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("Width")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Alphard.W")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("Height")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Alphard.H")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="interiorSpace-tab-pane"
                role="tabpanel"
                aria-labelledby="interiorSpace-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Interior.Space")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <p>{t("No1")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Drivers.Seat")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("No2")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Passenger.Area")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("No3")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Luggage.Area")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="capacity-tab-pane"
                role="tabpanel"
                aria-labelledby="capacity-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Passengers.Luggage")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <i>
                              <img src={people_icon} alt="" srcSet="" className="vehicle-specs-icons" />
                            </i>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Alphard.Passengers")}</p>
                          </div>
                          <div className="col-6">
                            <i>
                            <img src={luggage_icon} alt="" srcSet="" className="vehicle-specs-icons" />
                            </i>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Alphard.Suitcases")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs, mob end --> */}
      {/* <!-- section 5 specifications, image and info tabs --> */}

      <div className="d-none d-md-block container col-md-11 col-lg-9 mx-auto pb-3">
        <div className="row mx-0 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 pb-lg-5 px-0">
            <div className="row row-cols-2 mb-lg-5">
              <div className="col-12 col-md-5 col-lg-5 d-flex justify-content-center">
                <img
                  src={alphard_seatplan}
                  className="img-fluid"
                  alt="Toyota Alphard seat map"
                />
              </div>

              <div className="col-12 col-md-7 col-lg-7 d-flex flex-column mb-auto">
                <div className="card-header ps-0 pt-3 pb-2">
                  {t("Toyota.Alphard.Tag")}
                </div>
                <div className="card-body ps-0 pt-1">
                  <h3 className="card-title mb-3">{t("Toyota.Alphard")}</h3>
                </div>
                <div className="card">
                  <ul
                    className="nav nav-tabs nav-justified"
                    id="myTabMd"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 tab-link-light-bg-alt active py-4 tab-link-light-bg-radius-left border-end-0"
                        id="sizeSpecifications-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#sizeSpecifications-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="sizeSpecifications-tab-pane-md"
                        aria-selected="true"
                      >
                        {t("Size.Specifications")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 nav-link tab-link-light-bg-alt py-4 rounded-0 border-top-0 border-start border-end"
                        id="interiorSpace-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#interiorSpace-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="interiorSpace-tab-pane-md"
                        aria-selected="false"
                      >
                        {t("Interior.Space")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 tab-link-light-bg-alt py-4 tab-link-light-bg-radius-right border-start-0"
                        id="capacity-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#capacity-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="capacity-tab-pane-md"
                        aria-selected="false"
                      >
                        {t("Capacity")}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContentMd">
                    <div
                      className="tab-pane fade show active"
                      id="sizeSpecifications-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="sizeSpecifications-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Size.Dimensions")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <p>{t("Length")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Alphard.L")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("Width")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Alphard.W")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("Height")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Alphard.H")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="interiorSpace-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="interiorSpace-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Interior.Space")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <p>{t("No1")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Drivers.Seat")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("No2")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Passenger.Area")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("No3")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Luggage.Area")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="capacity-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="capacity-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Passengers.Luggage")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <i>
                                    <img src={people_icon} alt="" srcSet="" className="vehicle-specs-icons" />
                                   
                                  </i>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Alphard.Passengers")}</p>
                                </div>
                                <div className="col-6">
                                  <i>
                                  <img src={luggage_icon} alt="" srcSet="" className="vehicle-specs-icons" />
                                  </i>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Alphard.Suitcases")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs end --> */}
      {/* <!-- banner --> */}
      <div className="container-fluid pt-5 mt-3 mt-sm-5 mt-lg-0 pt-md-0 cta-banner-alphard">
        <div className="container px-md-5 pt-5 pt-md-0">
          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">
              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4 className="card-title-banner">
                  {t("CTA.Banner.Vehicle.Heading")}
                </h4>
              </div>

              <p className="col-12 col-md-11 col-lg-8 intro mb-4">
                {t("CTA.Banner.Vehicle.Intro")}
              </p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {t("Book.Your.Journey")}
                    <i className="ps-2">

                    <img src={arrow_right_icon} alt="" srcSet="" className="vehicle-specs-icons" />
                    </i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Preview Image */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered ">
          <div className="modal-content custom-modal-preview-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="button-close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x icon-close"></i>
              </button>
            </div>
            <div className="card bg-transparent border-0">
              <div className="card-body ">
                <div className="row align-items-center ">
                  <div className="col-auto p-1">
                    <button
                      type="button"
                      className="custom-modal-preview-next-prev"
                      onClick={() =>
                        setPreviewImage(
                          previewImage === 1 ? 9 : previewImage - 1
                        )
                      }
                    >
                      <i className="bi bi-arrow-left icon-next-prev"></i>
                    </button>
                  </div>
                  <div className="col px-2">
                    <img
                      src={
                        previewImage === 1
                          ? alphard1
                          : previewImage === 2
                            ? alphard2
                            : previewImage === 3
                              ? alphard3
                              : previewImage === 4
                                ? alphard4
                                : previewImage === 5
                                  ? alphard5
                                  : previewImage === 6
                                    ? alphard6
                                    : previewImage === 7
                                      ? alphard7
                                      : previewImage === 8
                                        ? alphard8
                                        : previewImage === 9
                                          ? alphard9
                                          : ""
                      }
                      className="img-fluid img-modal-carousel"
                      alt="Preview"
                    />
                  </div>
                  <div className="col-auto p-1">
                    <button
                      type="button"
                      className="custom-modal-preview-next-prev"
                      onClick={() =>
                        setPreviewImage(
                          previewImage === 9 ? 1 : previewImage + 1
                        )
                      }
                    >
                      <i className="bi bi-arrow-right icon-next-prev"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default AlphardCarPage;
