import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBookingsByCartID } from "../../actions/admin/booking";
import { useEffect } from "react";
import { useState } from "react";
import BookingItem from "./myPage/bookings/BookingItem";
import visa_icon from "../../icons/visa.svg";
import master_card_icon from "../../icons/master-card.svg";
import jcb_icon from "../../icons/jcb.svg";
import american_ex_icon from "../../icons/american_ex.svg";
import email_icon from "../../icons/email.svg";
import LanguageCard from "../layout-new-theme/LanguageCard";
import { getLocationByAddress } from "../../actions/googleAPI";
import { useHistory } from "react-router-dom";
import { flywireUpdatePayment } from "../../actions/admin/flywire"

const ChangePaymentMethod = () => {
  const { cartId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [bookings, setBookings] = useState([]);
  const history = useHistory();

  const callbackFlywire = async (result, ref) => {
    result.ref = ref

    if (result.status === "success") {
      const updatePayment = await flywireUpdatePayment(result);

      setLoading(false);
      if (updatePayment.status === 200 && updatePayment.data.status === "SUCCESS") {
        history.push(`/`);
      }
    }
  }

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(' ');

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  }

  const changePaymentMethodHandler = async () => {
    setLoading(true);
    console.log(bookings);

    const { firstName, lastName } = await extractFirstAndLastName(bookings[0].customer.name);
    const zipCodeCheck = await getLocationByAddress(bookings[0].customer.postal_code, bookings[0].customer.country_code);

    const config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
      amount: 0,
      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
      recurringType: "tokenization",
      recurringTypeRecipientCodes: [
        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
      ],

      // Specify the returnUrl or an onCompleteCallback handler
      // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
      firstName: firstName,
      lastName: lastName,
      email: bookings[0].customer.email,
      phone: bookings[0].customer.prefix_number + " " + bookings[0].customer.phone,
      address: bookings[0].customer.address,
      city: zipCodeCheck.address_components.filter(item => item.types.includes("administrative_area_level_2") || item.types.includes("locality"))[0].long_name,
      zip: bookings[0].customer.postal_code,
      country: bookings[0].customer.country_code,

      recipientFields: {
        booking_reference: `CB-${cartId}`,
        additional_information: null,
      },

      requestPayerInfo: false,
      requestRecipientInfo: false,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card"]
        }
      },
      onCompleteCallback: function (args) {
        callbackFlywire(args, `CB-${cartId}`);
      },
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          console.log(error.msg);
        });
      }
    };

    console.log("CONFIG ", config);

    setLoading(false);
    const modal = window.FlywirePayment.initiate(config);
    modal.render();
  }

  const getCardTypeByBrand = (type) => {
    if (type === "VISA") {
      return visa_icon;
    } else if (type === "MASTERCARD") {
      return master_card_icon;
    } else if (type === "AMERICAN EXPRESS") {
      return american_ex_icon;
    } else if (type === "JCB") {
      return jcb_icon;
    }
    return "Unknown";
  }

  const handleGetBookingsByCartID = async (cartId) => {
    try {
      setLoading(true);
      const response = await getBookingsByCartID(cartId);
      console.log(response);
      if (response.length === 0) {
        setError("No booking found for this cart");
      } else if (!response[0].hasOwnProperty("flywireResponse")) {
        setError("No flywire payment method found for this");
      } else if (response[0].paymentStatus !== "CHARGE_PENDING") {
        setError("Page unavailable");
      } else {
        setBookings(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsByCartID(cartId);
  }, [cartId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <h1>{error}</h1>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="col-md-8 offset-md-2 mt-4">
          <div className="card  mb-3">
            <div className="card-body border-top-gold">
              <div className="d-flex align-items-center justify-content-between">
                <h5>Payment Detail</h5>
                <LanguageCard />
              </div>
              <hr />
              <div className="d-flex align-items-center gap-5">
                <div className="col-auto">
                  <img src={getCardTypeByBrand(bookings[0].flywireResponse.brand)} alt="" srcset="" height={20} />
                </div>
                <div className="col-auto">
                  <p className="fw-bold m-0">Card Number</p>
                  <p className="m-0">******{bookings[0].flywireResponse.digits}</p>
                </div>

                <div className="col">
                  <p className="fw-bold m-0">Expiry Date</p>
                  <p className="m-0">{bookings[0].flywireResponse.expirationMonth}/{bookings[0].flywireResponse.expirationYear}</p>
                </div>

                <div className="col-auto">
                  <button
                    className="btn btn-primary rounded py-2"
                    onClick={changePaymentMethodHandler}
                  >
                    Change Payment Method
                  </button>
                </div>
              </div>
            </div>
          </div>
          {bookings.map((booking, i = 0) => (
            <div key={i}>
              <BookingItem cardId={cartId} bookingDetail={booking} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChangePaymentMethod;
