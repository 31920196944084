import React from "react";
const ModalConfirmation = ({
  text = "",
  handleClose,
  handleContinue,
  show,
}) => {
  return (
    <>
      {show && <div className="modal-backdrop show"></div>}
      <div
        className={show ? "modal fade show" : "modal fade"}
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content border-top-gold">
            <div className="text-end me-4 mt-3">
              <button
                onClick={handleClose}
                type="button"
                className="text-end btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body my-1 text-center"
            >
              <h4 className="mb-4">{text}</h4>
              <div className="d-flex gap-3 justify-content-center">
              <button className="btn btn-primary px-4" onClick={handleClose}>
                No
              </button>
              <button className="btn btn-primary px-4" onClick={handleContinue}>
                Yes
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalConfirmation;
