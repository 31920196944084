import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const DashBookingItem = ({
  key,
  count,
  booking
}) => {
  const [style, setStyle] = useState("null");

  useEffect(() => {
    setBookingStyle();
  }, []);

  const setBookingStyle = () => {
    switch (booking?.bookingStatus) {
      case "CONFIRMED":
        setStyle("badge bg-success py-2");
        break;
      case "PAYMENT_PENDING":
        setStyle("badge bg-primary py-2");
        break;
      case "INQUIRY":
        setStyle("badge bg-warning py-2");
        break;
      case "CANCELLED":
        setStyle("badge bg-danger py-2");
        break;
      default:
        setStyle("badge bg-dark py-2");
        break;
    }
  };

  // Extract vehicle type names from bookingInfo array
  const vehicleTypeNames = booking?.bookingInfo.map((info) => info.vehicleTypeName);

  // Combine vehicle type names with commas
  const combinedVehicleTypeNames = vehicleTypeNames.join(', ');

  return (
    <tr>
      <td>{count + 1}</td>
      <td>{booking?.customer?.name}</td>
      <td>
        <span
          className="badge text-dark py-2"
          style={{
            border: "1px solid #" + booking?.cartID.substring(0, 6),
          }}
        >
          {booking?.cartID}
        </span>
      </td>
      <td>{combinedVehicleTypeNames}</td>
      <td>
        <div className={style}>{booking?.bookingStatus}</div>
      </td>
      <td>
        <Moment format="YYYY/MM/DD">{booking?.date}</Moment>
      </td>
      <td>
        <Moment format="YYYY/MM/DD hh:mm A">{booking?.travelDate}</Moment>
      </td>

      <td>
        <Moment format="YYYY/MM/DD hh:mm A">
          {booking?.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime}
        </Moment>
      </td>
      <td>{booking?.pickupLocation?.label}</td>
    </tr>
  );
};

export default DashBookingItem;
