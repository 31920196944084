import {
  ADD_BOOKING,
  BOOKING_ERROR,
  GET_BOOKINGS,
  GET_BOOKING, ON_GET_BOOKING,
  UPDATE_BOOKING_STATUS,
  UPDATE_BOOKING_DRIVER,
  CHANGE_BOOKING_VEHICLE,
  ADD_SINGLE_FORM_BOOKING,
  ADD_MULTI_FORM_BOOKING,
  ADD_AIRPORT_FORM_BOOKING,
  ADD_BY_THE_HOUR_FORM_BOOKING,
  ADD_SPECIFIED_DESTINATION_FORM_BOOKING,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_CLOCK_REF,
  GET_CALANDER_BOOKINGS,
  GET_CART,
} from "../types";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const getCart = (cartid) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/bookings/cart/${cartid}`);
    dispatch({
      type: GET_CART,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getBookingsByCartID = async (cartID) => {
  try {
    const res = await customAxios.get(
      `/api/admin/bookings/getbookingbycart/${cartID}`
    );
    return res.data;
  } catch (err) {
    return [];
  }
};

export const addClockRefState = (clockRef) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CLOCK_REF,
      payload: clockRef,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const removeFromCartReduxStore = (cart) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: cart,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const addToCartReduxStore = (cart) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TO_CART,
      payload: cart,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const addMultipleDestinationFormToState = (form) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MULTI_FORM_BOOKING,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const addByTheHourFormToState = (form) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_BY_THE_HOUR_FORM_BOOKING,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const addSpecifiedDestinationFormToState = (form) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SPECIFIED_DESTINATION_FORM_BOOKING,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};


export const addAirportFormToState = (form) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_AIRPORT_FORM_BOOKING,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const addSingleDestinationFormToState = (form) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SINGLE_FORM_BOOKING,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "Err", status: 200 },
    });
  }
};

export const removeAllCartItem = async (cartID) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/emptyCart",
      { cartID },
      config
    );

    return res;
  } catch (err) {
    return [];
  }
};

export const deleteCartItem = async (key) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/removeCartItem",
      {
        key,
      },
      config
    );

    return res;
  } catch (err) {
    return [];
  }
};

export const updateCart = async (cart, paymentIntent, paymentIntentStatus, customerInfo, statusBooking) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/updatecart",
      {
        cart,
        paymentIntent,
        paymentIntentStatus,
        customerInfo,
        statusBooking
      },
      config
    );
    return res;
  } catch (err) {
    return [];
  }
};

export const flywireSuccessPayment = async (flywireResponse) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/flywire/success-payment",
      flywireResponse,
      config
    );
    return res;
  } catch (err) {
    return [];
  }
};

export const checkBookingPayment = async (cart_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/check-payment",
      {cartId: cart_id},
      config
    );
    return res.data;
  } catch (err) {
    return [];
  }
};

export const updateCartCustomer = async (cart, customerInfo) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/update-cart-customer",
      {
        cart,
        customerInfo,
      },
      config
    );
    return res;
  } catch (err) {
    return [];
  }
};

export const updateStatusAuthenticatedUserBooking = async (reqBody) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/checkout-authenticated-user-booking",
      reqBody,
      config
    );
    return res;
  } catch (err) {
    return [];
  }
};

export const addCart = async (cart, customerInfo, bookingStatus) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/addtocart",
      {
        cart,
        customerInfo,
        bookingStatus,
      },
      config
    );

    return res;
  } catch (err) {
    return [];
  }
};

export const updateCartCustomerInfo = async (cart, customerInfo) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/cartCustomerInfo",
      {
        cart,
        customerInfo,
      },
      config
    );

    return res;
  } catch (err) {
    return [];
  }
};

export const getBookingsForCalander = async (from, to) => {
  try {
    const res = await customAxios.get(`/api/admin/bookings/${from}/${to}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

/**
 * * Get available vehicles based on the provided to the parameters
 * @param pickup  - pickup time
 * @param dropoff - drop off time
 * @param v_types - vehicle type
 */
export const getAvailableVehicles = async (pickup, dropoff, v_types) => {
  try {
    const res = await customAxios.get(
      `/api/admin/bookings/availablevehicles/${pickup}/${dropoff}/${v_types}`
    );

    return res.data;
  } catch (err) {
    return [];
  }
};

/**
 * * Get available vehicle types list - api call
 * @param pickup -
 * @param dropoff -
 * @param passengers -
 * @param luggages -
 */
export const getAvailableVehicleTypes = async (
  pickup,
  dropoff,
  passengers,
  luggages
) => {
  try {
    const res = await customAxios.get(
      `/api/admin/bookings/avtype/${pickup}/${dropoff}/${passengers}/${luggages}`
    );
    
    return res.data;
  } catch (err) {
    return [];
  }
};

/**
 * * Get the relevant booking by id
 */
export const getBooking = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ON_GET_BOOKING
    });
    const res = await customAxios.get(`/api/admin/bookings/${id}`);
    dispatch({
      type: GET_BOOKING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.statusText ? err.response.statusText : "",
        status: err.response.status ? err.response.status : "",
      },
    });
  }
};

/**
 * * Get all bookings using the filters
 */
export const getCalanderBookingsFilter =
  (text, startDate, endDate, isAbandoned) => async (dispatch) => {
    
    try {
      const res = await customAxios.get(
        `/api/admin/bookings/abandoned/${startDate}/${endDate}/${isAbandoned}`
      );
      dispatch({
        type: GET_CALANDER_BOOKINGS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BOOKING_ERROR,
        payload: {
          msg: err.response?.statusText,
          status: err.response?.status,
        },
      });
    }
  };

/**
 * * Get all bookings using the filters
 */
export const getBookingsFilter =
  (
    text,
    startDate,
    endDate,
    isAbandoned,
    bookingStatus,
    driver,
    vehicleType,
    vehicle,
    bookingType,
    isAllDrivers,
    isAllVehicles,
  ) =>
    async (dispatch) => {
      const bookingStatusLabels = bookingStatus.map(item => item.label).join(',');
      const driverLabels = driver.map(item => item.label).join(',');
      const vehicleLabels = vehicle.map(item => item.label).join(',');
      const bookingTypeLabels = bookingType.map(item => item.label).join(',');
      try {
        const res = await customAxios.get(
          `/api/admin/bookings/${startDate}/${endDate}/${text}/${isAbandoned}/${bookingStatusLabels}/${isAllDrivers ? "all" : driverLabels}/${isAllVehicles ? "all" : vehicleLabels}/${bookingTypeLabels}`
        );
        dispatch({
          type: GET_BOOKINGS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: BOOKING_ERROR,
          payload: {
            msg: err.response?.statusText,
            status: err.response?.status,
          },
        });
      }
    };

export const getAbandenedBookingsFilter =
  (startDate, endDate, isAbandoned) => async (dispatch) => {
  
    try {
      const res = await customAxios.get(
        `/api/admin/bookings/abandoned/${startDate}/${endDate}/${isAbandoned}`
      );
      dispatch({
        type: GET_BOOKINGS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BOOKING_ERROR,
        payload: {
          msg: err.response?.statusText,
          status: err.response?.status,
        },
      });
    }
  };

/**
 * * Get all bookings
 */
export const getBookings = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/bookings");
    dispatch({
      type: GET_BOOKINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * * Add a driver
 */
export const addBooking =
  (
    requestBody
  ) =>
    async (dispatch) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await customAxios.post(
          "/api/admin/bookings",
          requestBody,
          config
        );
        dispatch({
          type: ADD_BOOKING,
          payload: res.data,
        });
        dispatch(setAlert("Booking created", "success"));
      } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
          dispatch(
            setAlert(
              "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
              "danger"
            )
          );
        }
        dispatch({
          type: BOOKING_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status },
        });
      }
    };

export const updateBooking =
  (
    key,
    travelDate,
    pickuptime,
    pickuplocation,
    destinations,
    pax,
    luggage,
    pickupFlightNo,
    dropoffFlightNo,
    isFromAirport,
    travelDateWithDropOffTime,
    dropoffDatetime,
    garageArivalTime,
    dropofftime,
    name,
    email,
    phone,
    address,
    country,
    countryCodeCust,
    prefixNumber,
    postCode,
    estimate,
    vehicleid,
    bookingAmountInfo,
    bookingInfo,
    bookingStatus,
    totalDistance,
    startTime,
    endTime,
    noteList,
    cartID,
    activePackage,
    passenger_name,
    specialRequest,
    meetAssist,
    byTheHourBook,
    duration,
    isManyPeople,
    totalPrice,
    childSeat,
    isAirportTransfer,
    userId,
    withCart,
    pronounced,
    passengerPronounced,
    countryCode,
    duplicateFromBookingKey,
    flightTracking,
    travelArea
  ) =>
    async (dispatch) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const res = await customAxios.post(
          "/api/admin/bookings/update",
          {
            key,
            travelDate,
            pickuptime,
            pickuplocation,
            destinations,
            pax,
            luggage,
            pickupFlightNo,
            dropoffFlightNo,
            isFromAirport,
            travelDateWithDropOffTime,
            dropoffDatetime,
            garageArivalTime,
            dropofftime,
            name,
            email,
            phone,
            address,
            country,
            countryCodeCust,
            prefixNumber,
            postCode,
            estimate,
            vehicleid,
            bookingAmountInfo,
            bookingInfo,
            bookingStatus,
            totalDistance,
            startTime,
            endTime,
            noteList,
            cartID,
            activePackage,
            passenger_name,
            specialRequest,
            meetAssist,
            byTheHourBook,
            duration,
            isManyPeople,
            totalPrice,
            childSeat,
            isAirportTransfer,
            userId,
            withCart,
            pronounced,
            passengerPronounced,
            countryCode,
            duplicateFromBookingKey,
            flightTracking,
            travelArea
          },
          config
        );
        dispatch({
          type: ADD_BOOKING,
          payload: res.data,
        });
        dispatch(setAlert("Booking created", "success"));
      } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
          dispatch(
            setAlert(
              "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
              "danger"
            )
          );
        }
        dispatch({
          type: BOOKING_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status },
        });
      }
    };

export const updateBookingStatus =
  (_id, bookingStatus, available_vehicle_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await customAxios.post(
        "/api/admin/bookings/updatebookingstatus",
        {
          _id,
          bookingStatus,
          vehicleid: available_vehicle_id,
        },
        config
      );
      dispatch({
        type: UPDATE_BOOKING_STATUS,
        payload: res.data,
      });
      dispatch(setAlert("Booking updated.", "success"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        dispatch(
          setAlert(
            "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
            "danger"
          )
        );
      }
      dispatch({
        type: BOOKING_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

export const updateDriver = (_id, driver_id, index) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/updatedriver",
      {
        _id,
        driverid: driver_id,
        bookingInfoIndex: index,
      },
      config
    );
    dispatch({
      type: UPDATE_BOOKING_DRIVER,
      payload: res.data,
    });
    dispatch(setAlert("Driver updated.", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(
        setAlert(
          "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
          "danger"
        )
      );
    }
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const changeVehicle = (_id, vehicleid, index) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/changevehicle",
      {
        _id,
        vehicleid,
        bookingInfoIndex: index,
      },
      config
    );
    dispatch({
      type: CHANGE_BOOKING_VEHICLE,
      payload: res.data,
    });
    dispatch(setAlert("Vehicle changed.", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(
        setAlert(
          "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
          "danger"
        )
      );
    }
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const addNote = (_id, note) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/addnote",
      {
        _id,
        note,
      },
      config
    );
    dispatch({
      type: CHANGE_BOOKING_VEHICLE,
      payload: res.data,
    });
    dispatch(setAlert("Vehicle changed.", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(
        setAlert(
          "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
          "danger"
        )
      );
    }
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

/**
 * * Get the check updated user before payment
 */
export const checkCustomerIsUpdated = async (cart_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/checkUpdatedUserFromChatId",
      {
        cartId: cart_id
      },
      config
    );

    return res.data;
  } catch (err) {
    console.log(err)
    return false;
  }
};

export const setTimeoutCart = async (keys) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/update-timeout-cart",
      {
        keys: keys
      },
      config
    );
    // return res.data;
  } catch (err) {
    return [];
  }
}

export const getAvailableVehicle = async (reqParams) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/bookings/search-available-vehicles",
      reqParams,
      config
    );
    return res.data;
  } catch (err) {
    return [];
  } 
}