import React, { useState, useEffect } from "react";
import CustomGoogleAutoComplete from "../google-input/CustomGoogleAutoComplete";
import { useTranslation } from "react-i18next";
import { generateRandom } from "../../../utils/Calculator";
import location_pin_icon from "../../../icons/form-icons/location-pin.svg"

const MultipleDropOff = ({
  setDestinations,
  dropofflocations,
  isNotValidFirstLocation,
  changeValidation,
  isUseAddDestination,
}) => {
  const { t } = useTranslation();
  const [inputFields, setInputFields] = useState([{}]);


  useEffect(() => {
    setInputFields(dropofflocations);
  }, [dropofflocations]);

  const addInputField = () => {
    const hasData = inputFields[inputFields.length - 1]?.label;
    if (inputFields.length === 0) {
      setInputFields([...inputFields, {}]);
      changeValidation(true);
    } else if (hasData !== undefined) {
      setInputFields([...inputFields, {}]);
      changeValidation(true);
    } else {
      changeValidation(false);
    }
  };

  const removeInputFields = (index, value) => {
    const newValues = inputFields.filter((i) => i.value !== value);
    setInputFields(newValues);
    setDestinations(newValues);
  };
  const locationSelected = (index, place) => {
    if (place === undefined) return;
    var newPLace = { ...place };
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    newPLace.lat = place.geometry.location.lat;
    newPLace.lon = place.geometry.location.lng;
    newPLace.label = place.name + ", " + place.formatted_address;
    newPLace.formatted_address = place.name + ", " + place.formatted_address;
    newPLace.value = generateRandom(1000, 200000);
    newPLace.city = city;
    newPLace.place_id = place.place_id;
    newPLace.types = place.types;
    newPLace.prefecture = city;

    let list = inputFields.slice();

    list.splice(index, 1, newPLace);

    setInputFields(list);
    setDestinations(list);

    changeValidation(true);
  };

  return (
    <div>
      {inputFields.map((location, index) => {
        return (
          <div className="row  " key={"loc" + (Math.random() + 1).toString(36).substring(7)}>
            <div
              className={`col  ml-2 input-box`}
            >
              <div className={`form-floating position-relative ${inputFields.length !== 1 ? `mb-3` : ``}`}>

                <CustomGoogleAutoComplete
                resetButton ={ index > 0 ? false : true}
                  id={"multi goog" + (Math.random() + 1).toString(36).substring(7)}
                  placeholder="Drop-off address"


                  onPlaceSelected={(location) => {
                    locationSelected(index, location)
                  }}
                  defaultValue={location?.label === undefined ? null : location?.label}
                  invalidText={"SpecifiedDestination.Pickup.Specific.Validation"}
                />
                {inputFields.length !== 1 ? <i className="fa fa-close icon" onClick={() => removeInputFields(index, location.value)}></i> : ``}

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img  alt="img" src={location_pin_icon}/>
                  </div>
                </span>
                <label htmlFor="dropOffAddress" className="label-icon">
                  {t("Drop.Off.Address")}
                </label>
              </div>
            </div>
            {index === inputFields.length - 1 && (
              <div className="col-lg-auto col-sm-12 col-md-12 d-grid d-md-block ps-md-0 mt-3 mt-md-0">
                <button
                  disabled={!isUseAddDestination}
                  type="button"
                  className="btn btn-add-destination btn-lg rounded-3 d-flex justify-content-center w-full px-3 py-3"
                  onClick={addInputField}
                >
                  <div className="d-flex align-items-center px-2 d-inline">
                    <div className="pe-2 d-inline">
                      <i className="bi bi-plus"></i>
                    </div>
                    {t("Add.Destination")}
                  </div>
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleDropOff;
