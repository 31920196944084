/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Countdown from "react-countdown";
import Setting from "../../utils/settings.json";
import moment from "moment";

const Clock = ({ refCallback, onCompleteTimer, cart, start, stop, lastAddedBookToCart }) => {
  // const dispatch = useDispatch();
  // const { clockRefState } = useSelector((state) => state.booking);

  const [timerDate, setTimerDate] = useState(
    Date.now() + Setting.CART_TIMEOUT * 60000
  );

  // useEffect(() => {
  //   setClockRef(clockRefState);
  // }, [clockRefState]);

  const SettingClockRef = (ref) => {
    if (ref !== null) {
      // localStorage.setItem("clockRef", JSON.stringify(ref));
      // dispatch(addClockRefState(ref));
    }
  };

  useEffect(() => {
    if (cart === null) return;
    try {
      if (cart.length > 0) {
        const createdDatetime = moment(lastAddedBookToCart);
        const currentDatetime = moment();
        const duration = moment.duration(currentDatetime.diff(createdDatetime));
        const minutes = duration.asMinutes();
        const remainTime = Setting.CART_TIMEOUT - minutes;
        setTimerDate(Date.now() + remainTime * 60000);
        start();
        // clockRef.start();
      } else {
        stop();
        // clockRef.stop();
        setTimerDate(Date.now() + Setting.CART_TIMEOUT * 60000);
      }
      console.log("SET TIME")
    } catch {}
  }, []);

  //   const onCompleteTimer = () => {
  //     setTimerDate(Date.now() + Setting.CART_TIMEOUT * 60000);
  //   };

  // Random component
  const Completionist = () => <span>01:00:00</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="noto">
          {0 <= hours && hours < 10 ? "0" + hours : hours}:
          {0 <= minutes && minutes < 10 ? "0" + minutes : minutes}:
          {0 <= seconds && seconds < 10 ? "0" + seconds.toString() : seconds}
        </span>
      );
    }
  };

  return (
    <Fragment>
      <Countdown
        // When the component mounts, this will
        // call `refCallback` in the parent component,
        // passing a reference to this `Countdown` component
        ref={SettingClockRef}
        date={timerDate}
        intervalDelay={3}
        zeroPadTime={2}
        autoStart={cart !== null && cart.length > 0 ? true : false}
        daysInHours
        renderer={renderer}
        onComplete={() => {
          onCompleteTimer();
          setTimerDate(Date.now() + Setting.CART_TIMEOUT * 60000);
          // setTimerDate(Date.now() + Setting.CART_TIMEOUT * 60000);
        }}
      />
    </Fragment>
  );
};

export default Clock;
