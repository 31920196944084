/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import {
  removeFromCartReduxStore,
  addToCartReduxStore,
  updateCartCustomer,
  updateStatusAuthenticatedUserBooking,
  checkCustomerIsUpdated,
  checkBookingPayment
} from "../../actions/admin/booking";
import { getUserDetail, updatePaymentInfo } from "../../actions/auth";
import { flywireSuccessPayment } from "../../actions/admin/flywire"
import { getLocationByAddress } from "../../actions/googleAPI";
import { useLoading } from "./LoadingContext";
import ReactTooltip from "react-tooltip";
import arrow_right_icon from "../../icons/arrow_right.svg";

import visa_icon from "../../images/visa-icon.svg";
import jcb_icon from "../../images/jcb-icon.svg";
import diners_club_icon from "../../images/diners-club-icon.svg";
import amex_icon from "../../images/amex-icon.svg";
import discover_icon from "../../images/discover-icon.svg";
import maestro_icon from "../../images/maestro-icon.svg";

import CheckoutConfirmationModal from "../front-pages/CheckoutConfirmationModal";

const NewCheckout = (props, isAuthenticated) => {
  const itemsToCheckoutRaw = props.location.state?.items_to_checkout || [];
  const fromCart = props.location.state?.fromCart || false;
  const { showLoading, closeLoading } = useLoading();

  const dispatch = useDispatch();
  const [itemsToCheckout,] = useState(
    itemsToCheckoutRaw.length > 0
      ? JSON.parse(itemsToCheckoutRaw)
      : itemsToCheckoutRaw
  );

  const validationErrors = [];

  const [namev, setnamev] = useState("");
  const [emailv, setemailv] = useState("");
  const [phonev, setphonev] = useState("");
  const [addressv, setaddressv] = useState("");
  const [postcodev, setpostcodev] = useState("");
  const [countryv, setcountryv] = useState("");
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    postCode: "",
    country: "Japan",
    userId: "",
    country_code: "JP",
    prefix_number: "+81",
  });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [countriesData, setCountriesData] = useState([]);
  const [countryCode, setCountryCode] = useState("JP");
  const [prefixPhoneNumber, setPrefixPhoneNumber] = useState("81");
  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const history = useHistory();

  const handleTermsCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked); // Toggle the checkbox status
  };

  const handleLiClickCountryList = (dialCode, countryCode, name) => {
    setPrefixPhoneNumber(dialCode.replace("+", ""));
    setCountryCode(countryCode);
    setCustomerData({
      ...customerData,
      country: name,
      country_code: countryCode,
      prefix_number: dialCode,
    });
  };

  const onChangeForm = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "name") setnamev("");
    if (e.target.name === "email") setemailv("");
    if (e.target.name === "phone") setphonev("");
    if (e.target.name === "address") setaddressv("");
    if (e.target.name === "postCode") setpostcodev("");
    if (e.target.name === "country") setcountryv("");
    if (e.target.name === "country_code") setcountryv("");
    if (e.target.name === "prefix_number") setcountryv("");
  };

  const validateForms = () => {
    if (
      customerData.name === "" ||
      customerData.address === "" ||
      customerData.email === "" ||
      customerData.phone === "" ||
      customerData.postCode === "" ||
      customerData.country === "" ||
      customerData.country_code === "" ||
      customerData.prefix_number === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const callbackFlywire = async (result, ref) => {

    result.ref = ref

    if (result.status === "success") {
      const cart_id = itemsToCheckout[0].randomId === undefined ? itemsToCheckout[0].cartID : itemsToCheckout[0].randomId;

      const updateSuccessBooking = await flywireSuccessPayment(result);

      closeLoading(true);
      if (updateSuccessBooking.status === 200 && updateSuccessBooking.data.status === "SUCCESS") {
        history.push(`/PaymentSuccess/${cart_id}`);
      }
    }
  }

  const callbackFlywireAuthenticatedUser = async (result, ref) => {

    result.ref = ref

    if (result.status === "success") {
      const cart_id = itemsToCheckout[0].randomId === undefined ? itemsToCheckout[0].cartID : itemsToCheckout[0].randomId;

      const addPaymentMethodUser = await updatePaymentInfo(result);

      closeLoading(true);
      if (addPaymentMethodUser.status === "SUCCESS") {
        const updateSuccessBooking = await flywireSuccessPayment(result);

        if (updateSuccessBooking.status === 200 && updateSuccessBooking.data.status === "SUCCESS") {
          history.push(`/PaymentSuccess/${cart_id}`);
        }
      }
    }
  }

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(' ');

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  }

  const [checkoutConfirmationShow, setCheckoutConfirmationShow] = useState(false);

  const handleCheckoutConfirmationShow = () => setCheckoutConfirmationShow(true);
  const handleCheckoutConfirmationClose = () => setCheckoutConfirmationShow(false);
  const handleCheckoutConfirmationNext = () => {
    setCheckoutConfirmationShow(false);
    clickProceedToPayment();
  };

  const clickProceedToPayment = async () => {
    showLoading(true);
    var config;
    var modal;
    if (isTermsChecked) {
      if (validationErrors.length === 0) {
        const cart_id = itemsToCheckout[0].randomId === undefined ? itemsToCheckout[0].cartID : itemsToCheckout[0].randomId;

        const checkBookingPaymentResult = await checkBookingPayment(cart_id);

        if (checkBookingPaymentResult.status === "ELIGIBLE") {

          await fetchUpdateBookingCustomer(
            itemsToCheckout,
            customerData
          );

          const customer_status = await checkCustomerIsUpdated(cart_id);

          console.log(customerData.postCode, customerData.country_code);
          const zipCodeCheck = await getLocationByAddress(customerData.postCode, customerData.country_code);

          console.log(zipCodeCheck);
          console.log(zipCodeCheck.address_components.filter(item => item.types.includes("administrative_area_level_2") || item.types.includes("locality"))[0].long_name);

          if (zipCodeCheck) {
            const { firstName, lastName } = await extractFirstAndLastName(customerData.name);

            if (customer_status) {
              if (itemsToCheckout) {
                if (fromCart) {
                  updateCartItem(itemsToCheckout);
                }

                if (customerData.role === "individual" || customerData.role === "business") {

                  // open modal

                  const userDetail = await getUserDetail(customerData.userId);

                  if (userDetail.individualDetail.paymentMethod.length > 0 || userDetail.individualDetail.paymentMethod.length > 0) {
                    const responseUpdate = await fetchUpdateBookingAuthenticatedUser({
                      cart: itemsToCheckout,
                      customerInfo: customerData,
                      statusBooking: "CONFIRMED",
                      statusPayment: "CHARGE_PENDING",
                    });

                    itemsToCheckout.map((val) => {
                      const items = [];
                      let totalPrice = 0;

                      val.bookingInfo.map((bi, ind) => {
                        const item = {
                          index: ind,
                          item_id: bi.vehicleTypeID,
                          item_name: bi.vehicleTypeName,
                          item_brand: "Toyota",
                          affiliation: "Stripe",
                          coupon: "",
                          discount: 0,
                          item_category: val.isAirportTransfer ? 'Airport Transfer' : val.byTheHourBook ? 'By The Hour' : 'Specified Destination',
                          item_category2: val.destinations.length === 1 ? val.destinations[0].place_id : val.destinations[val.destinations.length - 1].place_id,
                          item_category3: (bi.childSeat[0].childSeatCount + bi.childSeat[1].childSeatCount + bi.childSeat[2].childSeatCount) > 0 ? "Child Seats" : "",
                          location_id: "",
                          price: bi.total,
                          quantity: 1,
                        };

                        items.push(item);
                      })

                      totalPrice = val.totalPrice

                      const ecommerceData = {
                        currency: "JPY",
                        value: totalPrice,
                        items: items
                      }

                      const dataToPush = {
                        event: 'add_payment_info',
                        ecommerce: ecommerceData
                      };

                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push(dataToPush);
                    })

                    closeLoading(true);
                    if (responseUpdate.status === 200 && responseUpdate.statusText === "OK") {
                      history.push(`/PaymentSuccess/${itemsToCheckout[0].randomId}`);
                    }
                  } else {

                    config = {
                      env: process.env.REACT_APP_FLYWIRE_ENV,
                      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

                      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
                      amount: 0,
                      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
                      recurringType: "tokenization",
                      recurringTypeRecipientCodes: [
                        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
                        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
                      ],

                      // Specify the returnUrl or an onCompleteCallback handler
                      // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

                      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
                      firstName: firstName,
                      lastName: lastName,
                      email: customerData.email,
                      phone: userDetail.individualDetail.billingInformation.prefix_number + " " + userDetail.individualDetail.billingInformation.phoneNumber,
                      address: userDetail.individualDetail.billingInformation.address,
                      city: zipCodeCheck.address_components.filter(item => item.types.includes("administrative_area_level_2") || item.types.includes("locality"))[0].long_name,
                      zip: customerData.postCode,
                      country: userDetail.individualDetail.billingInformation.country_code,

                      recipientFields: {
                        booking_reference: `CB-${cart_id}`,
                        additional_information: null,
                      },

                      requestPayerInfo: false,
                      requestRecipientInfo: false,

                      // Control which tokenization options are available
                      paymentOptionsConfig: {
                        filters: {
                          type: ["credit_card"]
                        }
                      },
                      onCompleteCallback: function (args) {
                        callbackFlywireAuthenticatedUser(args, `CB-${cart_id}`);
                      },
                      onInvalidInput: function (errors) {
                        errors.forEach(function (error) {
                          console.log(error.msg);
                        });
                      }
                    };

                    closeLoading(true);
                    modal = window.FlywirePayment.initiate(config);
                    modal.render();
                  }
                } else {
                  config = {
                    env: process.env.REACT_APP_FLYWIRE_ENV,
                    recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

                    // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
                    amount: 0,
                    //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
                    recurringType: "tokenization",
                    recurringTypeRecipientCodes: [
                      process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
                      process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
                    ],

                    // Specify the returnUrl or an onCompleteCallback handler
                    // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

                    // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
                    firstName: firstName,
                    lastName: lastName,
                    email: customerData.email,
                    phone: customerData.prefix_number + " " + customerData.phone,
                    address: customerData.address,
                    city: zipCodeCheck.address_components.filter(item => item.types.includes("administrative_area_level_2") || item.types.includes("locality"))[0].long_name,
                    zip: customerData.postCode,
                    country: customerData.country_code,

                    recipientFields: {
                      booking_reference: `CB-${cart_id}`,
                      additional_information: null,
                    },

                    requestPayerInfo: false,
                    requestRecipientInfo: false,

                    // Control which tokenization options are available
                    paymentOptionsConfig: {
                      filters: {
                        type: ["credit_card"]
                      }
                    },
                    onCompleteCallback: function (args) {
                      callbackFlywire(args, `CB-${cart_id}`);
                    },
                    onInvalidInput: function (errors) {
                      errors.forEach(function (error) {
                        console.log(error.msg);
                      });
                    }
                  };

                  console.log("CONFIG ", config);

                  closeLoading(true);
                  modal = window.FlywirePayment.initiate(config);
                  modal.render();
                }
              }
            } else {
              closeLoading(true);
              console.log("An unexpected error occured. -> User not updated yet");
            }
          } else {
            setpostcodev("invalid");
            closeLoading(true);
          }
        } else {
          closeLoading(true);
          console.log("NOT ELIGIBLE")
        }
      } else {
        closeLoading(true);
        console.log("An unexpected error occured.");
      }
    }
  };


  const loadCountriesData = () => {
    fetch("/json/countries.json") // Replace with the actual path to your JSON file
      .then((response) => response.json())
      .then((data) => setCountriesData(data))
      .catch((error) => console.error("Error loading JSON data:", error));
  };

  useEffect(() => {
    try {
      if (itemsToCheckout.length === 0) {
        history.push("../Cart");
      }
      loadCountriesData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedJwt = jwtDecode(token);

        if (decodedJwt.user.role === "individual") {

          setCustomerData({
            name: decodedJwt.user.id.individualDetail.billingInformation
              .fullName,
            email: decodedJwt.user.id.email,
            phone:
              decodedJwt.user.id.individualDetail.billingInformation
                .phoneNumber,
            address:
              decodedJwt.user.id.individualDetail.billingInformation.address,
            postCode:
              decodedJwt.user.id.individualDetail.billingInformation.postCode,
            country:
              decodedJwt.user.id.individualDetail.billingInformation.country,
            country_code:
              decodedJwt.user.id.individualDetail.billingInformation.country_code,
            userId: decodedJwt.user.id._id,
            role: "individual"
          });
          setPrefixPhoneNumber(
            decodedJwt.user.id.individualDetail.billingInformation.prefix_number
          );
          setCountryCode(
            decodedJwt.user.id.individualDetail.billingInformation.country_code
          );
        } else if (decodedJwt.user.role === "business") {


          setCustomerData({
            name: decodedJwt.user.id.businessDetail.billingInformation
              .contactName,
            email: decodedJwt.user.id.email,
            phone:
              decodedJwt.user.id.businessDetail.billingInformation
                .phoneNumber,
            address:
              decodedJwt.user.id.businessDetail.billingInformation
                .billingAddress,
            postCode:
              decodedJwt.user.id.businessDetail.billingInformation.postCode,
            country: decodedJwt.user.id.businessDetail.billingInformation.country,
            country_code:
              decodedJwt.user.id.individualDetail.billingInformation.country_code,
            userId: decodedJwt.user.id._id,
            role: "business"
          });

          setPrefixPhoneNumber(
            decodedJwt.user.id.businessDetail.billingInformation.prefix_number
          );
          setCountryCode(
            decodedJwt.user.id.businessDetail.billingInformation.country_code
          );
        }
      }
    }
  }, [countriesData]);

  const getTotalAmount = () => {
    var tot = 0;
    itemsToCheckout.map((i) => {
      tot += i.totalPrice;
    });

    return tot;
  };

  const fetchUpdateBookingCustomer = async (
    s_cart,
    customerInfo
  ) => {
    await updateCartCustomer(
      s_cart,
      customerInfo
    );
  };

  const fetchUpdateBookingAuthenticatedUser = async (reqBody) => {
    const resp = await updateStatusAuthenticatedUserBooking(reqBody);

    return resp;
  };

  const updateCartItem = async (item) => {
    if (localStorage.getItem("cart") !== null) {
      dispatch(addToCartReduxStore(itemsToCheckout));
      localStorage.setItem("cart", JSON.stringify(itemsToCheckout));
      dispatch(removeFromCartReduxStore(itemsToCheckout));
    } else {
      window.location.reload();
    }
  };

  const contentRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const contentDiv = contentRef.current;

    function handleScroll() {
      // Check if the user has scrolled to the bottom
      if (
        contentDiv.scrollTop + contentDiv.clientHeight >=
        contentDiv.scrollHeight - 10
      ) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }

    contentDiv.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      contentDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <FrontLayout>

      <div className="container-fluid bg-page-cart mt-5 mt-lg-1 pt-1">
        <div className="custom-mx">
          <div className="container pb-5 custom-mt-8 col-lg-12">
            <h3 className="mt-3 mt-lg-0 mb-4">{t("Billing.Information")}</h3>
            <div className="row">
              <div className="col-md-8">
                <div className="card shadow-sm mb-2 px-3 py-3">
                  <div className="card-body">
                    <div className="row">
                      <h5>
                        {t("NewCheckout.PersonalInformation.Title")}{" "}
                        <span className="text-danger">*</span>
                      </h5>

                      <form className="mt-2">
                        <div className="row g-3 mb-3">
                          <div className="col-12 col-md ">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className={`form-control contact-form ${namev}`}
                                id="fullName"
                                placeholder={t("NewCheckout.PersonalInformation.FullName.Placeholder")}
                                name="name"
                                value={customerData.name}
                                onChange={(e) => onChangeForm(e)}
                                autoComplete={"new-password"}
                                style={{
                                  color: customerData.name ? "#282828" : "",
                                  fontWeight: customerData.name ? "bold" : "",
                                }}
                              />
                              <label htmlFor="fullName">{t("Full.Name")}</label>
                              {namev !== "" ? (
                                <p className="text-danger">
                                  {t("Please.enter.your.name")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col input-group  ">
                            <button
                              className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={`https://flagsapi.com/${countryCode}/flat/24.png`} alt=""
                              />{" "}
                              ({prefixPhoneNumber})
                            </button>
                            <ul className="dropdown-menu">
                              <div className="px-1 align-items-center mb-3 dropdown-search">
                                <input
                                  type="text"
                                  className={`form-control p-3 mb-2`}
                                  defaultValue={searchPhoneCode}
                                  onChange={(e) =>
                                    setSearchPhoneCode(e.target.value)
                                  }
                                  autoComplete={"new-password"}
                                />
                              </div>

                              {countriesData
                                ?.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(
                                      searchPhoneCode !== null
                                        ? searchPhoneCode.toLowerCase()
                                        : null
                                    )
                                )
                                ?.map((country, index) => (
                                  <Fragment key={country.code}>
                                    <li
                                      className="dropdown-item m-0"
                                      onClick={() =>
                                        handleLiClickCountryList(
                                          country.dial_code,
                                          country.code,
                                          country.name
                                        )
                                      }
                                    >
                                      <div className="row px-1 align-items-center">
                                        <div className="col-auto">
                                          <div className="bg-secondary bg-opacity-25 px-1">
                                            <img
                                              src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                              alt={country.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          ({country.dial_code}) {country.name}
                                        </div>
                                      </div>
                                    </li>
                                    <hr className="my-1" />
                                  </Fragment>
                                ))}
                            </ul>
                            <input
                              type="number"
                              className={`form-control contact-form p-3 ${phonev}`}
                              id="phoneNumber"
                              placeholder={t(
                                "NewCheckout.PersonalInformation.PhoneNumber.Placeholder"
                              )}
                              name="phone"
                              value={customerData.phone}
                              onChange={(e) => onChangeForm(e)}
                              autoComplete={"new-password"}
                              style={{
                                color: customerData.phone ? "#282828" : "",
                                fontWeight: customerData.phone ? "bold" : "",
                              }}
                            />
                            {/* <label htmlFor="phoneNumber">{t("Phone.Number")}</label> */}
                            {phonev !== "" ? (
                              <p className="text-danger">
                                {t("Please.enter.your.phone.number")}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col  ">
                            <div className="form-floating">
                              <input
                                type="text"
                                className={`form-control contact-form ${emailv}`}
                                id="emailAddress"
                                placeholder={t("NewCheckout.PersonalInformation.EmailAddress.Placeholder")}
                                name="email"
                                value={customerData.email}
                                onChange={(e) => onChangeForm(e)}
                                autoComplete={"new-password"}
                                style={{
                                  color: customerData.email ? "#282828" : "",
                                  fontWeight: customerData.email ? "bold" : "",
                                }}
                              />
                              <label htmlFor="emailAddress">
                                {t("Email.Address")}
                              </label>
                              {emailv ? (
                                <p className="text-danger">
                                  {t("Please.enter.a.valid.email.address")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col  ">
                            <div className="form-floating">
                              <input
                                type="text"
                                className={`form-control contact-form ${addressv}`}
                                id="streetAddress"
                                placeholder={t("NewCheckout.PersonalInformation.StreetAddress.Placeholder")}
                                name="address"
                                value={customerData.address}
                                onChange={(e) => onChangeForm(e)}
                                autoComplete={"new-password"}
                                style={{
                                  color: customerData.address ? "#282828" : "",
                                  fontWeight: customerData.address ? "bold" : "",
                                }}
                              />
                              <label htmlFor="streetAddress">
                                {t("Street.Address")}
                              </label>
                              {addressv !== "" ? (
                                <p className="text-danger">
                                  {t("Please.enter.your.address")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col col-lg-6 ">
                            <div className="form-floating">
                              <input
                                min={1}
                                type="text"
                                pattern="[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d"
                                className={`form-control contact-form ${postcodev}`}
                                id="postCode"
                                placeholder={t("NewCheckout.PersonalInformation.PostCode.Placeholder")}
                                name="postCode"
                                value={customerData.postCode}
                                onChange={(e) => onChangeForm(e)}
                                autoComplete={"new-password"}
                                style={{
                                  color: customerData.postCode ? "#282828" : "",
                                  fontWeight: customerData.postCode ? "bold" : "",
                                }}
                              />
                              <label htmlFor="postCode">{t("Post.Code")}</label>

                              {postcodev !== "" ? (
                                <p className="text-danger">
                                  {t("Please.enter.your.post.code")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col col-lg-6  ">
                            <div className="form-floating">
                              <input
                                disabled={true}
                                type="text"
                                className={`form-control contact-form ${countryv}`}
                                id="country"
                                placeholder={t("NewCheckout.PersonalInformation.Country.Placeholder")}
                                name="country"
                                value={customerData.country}
                                onChange={(e) => onChangeForm(e)}
                                autoComplete={"new-password"}
                                style={{
                                  background: "#FBFBFB",
                                  color: "#282828",
                                  borderBottom: "1px solid #EFE2C2",
                                  fontWeight: customerData.country
                                    ? "bold"
                                    : "",
                                }}
                                data-tip
                                data-for="countryTooltip"
                              />
                              <ReactTooltip
                                id="countryTooltip"
                                backgroundColor="#F6EEDC"
                                textColor="#4E4E4E"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: t("registration.country_tooltip"),
                                  }}
                                ></span>
                              </ReactTooltip>
                              <label htmlFor="country">{t("Country")}</label>
                              {countryv !== "" ? (
                                <p className="text-danger">
                                  {t("Please.enter.your.country")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <h5>
                            {t("NewCheckout.TermsAndCondition.Title")}{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="bg-gold-light-50 p-4 mt-3">
                            <div
                              className=" overflow-auto custom-scrollbar"
                              ref={contentRef}
                              style={{ height: "381px" }}
                              dangerouslySetInnerHTML={{
                                __html: `${t("termConditionContent")}`,
                              }}
                            ></div>
                          </div>
                          <div class="form-check mt-4 "
                            data-tip
                            data-for="tncTooltip">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="tnc"
                              checked={isTermsChecked}
                              onChange={handleTermsCheckboxChange}
                              disabled={isScrolledToBottom ? false : true}
                            />
                            <label
                              className={`{form-check-label noto-sans ${isTermsChecked && `fw-700`}`} for="tnc"
                              dangerouslySetInnerHTML={{
                                __html: `${t("NewCheckout.TermsAndCondition.Checkbox")}`,
                              }}
                            >
                            </label>
                            {!isTermsChecked && <ReactTooltip
                              id="tncTooltip"
                              backgroundColor="#4A4A4A"
                              textColor="#FFFFFF"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t("NewCheckout.TncTooltip"),
                                }}
                              ></span>
                            </ReactTooltip>}
                          </div>
                          {/* <div className="form-group mt-4 p-0 mb-0">
                            <div className="row row-cols-auto">
                              <div className="col">
                                <p className="noto p-0 mb-0">
                                  <input
                                    id="tnc"
                                    className="check-gold"
                                    type="checkbox"
                                    checked={isTermsChecked}
                                    onChange={handleTermsCheckboxChange}
                                    disabled={isScrolledToBottom ? false : true}
                                  />
                                  <span className="checkbox-container"></span>
                                  &nbsp;{" "}
                                  <label for="tnc" style={{ color: isScrolledToBottom ? "black" : "grey" }}
                                    dangerouslySetInnerHTML={{
                                      __html: `${t("NewCheckout.TermsAndCondition.Checkbox")}`,
                                    }}
                                  ></label>
                                </p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="d-grid mt-3">
                  <button
                    onClick={customerData.role === "individual" ? handleCheckoutConfirmationShow : clickProceedToPayment}
                    type="button"
                    className="btn btn-primary rounded py-2 d-none d-md-block"
                    disabled={!(isTermsChecked && validateForms())}
                  >
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <span>{t("NewCheckout.CheckoutButton")}</span>
                      <img alt="img" src={arrow_right_icon} />
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="card shadow-sm ms-0 ms-md-3">
                  <div className="card-body">
                    <h3 className="fw-bold text-start text-dark my-1 px-3">
                      {t("NewCheckout.Total")}
                    </h3>
                  </div>
                  <div className="card-body bg-medium-grey">
                    <div className="px-3">
                      {/* BASIC FARE */}
                      {itemsToCheckout !== null
                        ? itemsToCheckout.map((item, index) => {
                          return (
                            <div>
                              <div className="row">
                                <div className="col">
                                  <p className="fw-bold text-dark">
                                    {(item.isAirportTransfer
                                      ? t("TypeOfTransfer.AirportTransfer")
                                      : item.byTheHourBook
                                        ? t("TypeOfTransfer.ByTheHour") +
                                        ` (${lang === "EN"
                                          ? item.bookingInfo[0]
                                            .totalHoursUsage + " Hours"
                                          : item.bookingInfo[0]
                                            .totalHoursUsage + "時間"
                                        })`
                                        : t(
                                          "TypeOfTransfer.SpecifiedDestination"
                                        )) +
                                      (item.activePackage
                                        ? " - " +
                                        item.activePackage.packagename
                                        : " ")}
                                  </p>
                                </div>
                              </div>
                              {item.bookingInfo.map((itm, ind) => {
                                return (
                                  <div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="ms-3 text-dark">
                                          {(item.bookingInfo.length > 1
                                            ? ind + 1 + ". "
                                            : "") +
                                            t(
                                              itm.vehicleTypeName.replace(
                                                / /g,
                                                "."
                                              )
                                            ) +
                                            " - " +
                                            t("Vehicle.Usage.Fee")}
                                        </p>
                                      </div>
                                      <div className="col-auto align-items-end justify-content-end">
                                        <p className="text-end noto">
                                          {t("JPY")}
                                          {item.activePackage
                                            ? itm.total.toLocaleString()
                                            : (
                                              itm.total -
                                              itm.longDistanceSurcharge
                                            ).toLocaleString()}
                                          {t("JPY.JP")}
                                        </p>
                                      </div>
                                    </div>
                                    {!item.activePackage &&
                                      itm.longDistanceSurcharge > 0 ? (
                                      <div className="row">
                                        <div
                                          className="col"
                                          style={{ paddingLeft: "30px" }}
                                        >
                                          <p className="text-dark">
                                            {t(
                                              "NewCheckout.LongDistanceSurcharge"
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-auto align-items-end justify-content-end">
                                          <p className="text-end noto">
                                            {t("JPY")}
                                            {(
                                              itm.earlyFare + itm.lateFare
                                            ).toLocaleString()}
                                            {t("JPY.JP")}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {itm.childSeat.map((i) => {
                                      if (i.childSeatCount > 0) {
                                        return (
                                          <div className="row">
                                            <div
                                              className="col"
                                              style={{ paddingLeft: "30px" }}
                                            >
                                              <p className="text-dark">
                                                {`Child Seats (${t(
                                                  "NewCart.AddOnServices.ChildSeat." +
                                                  i.childSeatType
                                                )} x${i.childSeatCount})`}
                                              </p>
                                            </div>
                                            <div className="col-auto align-items-end justify-content-end">
                                              <p className="text-end noto">
                                                {t("JPY")}
                                                {(
                                                  i.childSeatCount * 1230
                                                ).toLocaleString()}
                                                {t("JPY.JP")}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                );
                              })}
                              {(item.meetAssist[0].meetAssistName !==
                                "none" ||
                                item.meetAssist[1].meetAssistName !==
                                "none") &&
                                item.meetAssist.map((m) => {
                                  if (m.meetAssistName !== "none") {
                                    return (
                                      <div className="row">
                                        <div className="col">
                                          <p className="fw-bold text-dark">
                                            {`${t(
                                              "NewCart.AddOnServices." +
                                              m.meetAssistName
                                            )} (${t(
                                              "NewCart.AddOnServices." +
                                              m.meetAssistType
                                            )})`}
                                          </p>
                                        </div>
                                        <div className="col-auto align-items-end justify-content-end">
                                          <p className="text-end noto">
                                            {t("JPY") +
                                              m.meetAssistPrice.toLocaleString() +
                                              t("JPY.JP")}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              {index < itemsToCheckout.length - 1 && (
                                <hr className="grey" />
                              )}
                            </div>
                          );
                        })
                        : ""}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row px-3 align-items-center ">
                      <div className="col">
                        <h5 className="fw-bold text-dark">
                          {t("Booking.Confirmation.Total.Price")}
                        </h5>
                      </div>
                      <div className="col-auto align-items-end justify-content-end">
                        <h3 className="text-end fw-bold">
                          {t("JPY")} {getTotalAmount().toLocaleString()}{" "}
                          {t("JPY.JP")}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 ms-0 ms-md-3 ">
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3 ">
                    <p className="text-center fs-12">
                      {t("Footer.Payment.Methods")}
                    </p>
                  </div>
                  <div className="col-12 px-3 d-md-flex justify-content-md-center d-lg-block mb-3">
                    <div className="border-0 mb-3 d-flex flex-row align-items-center gap-2">

                      <img alt="img" src={visa_icon} className="img-fluid" />
                      <img alt="img" src={jcb_icon} className="img-fluid" />
                      <img alt="img" src={diners_club_icon} className="img-fluid" />
                      <img alt="img" src={amex_icon} className="img-fluid" />
                      <img alt="img" src={discover_icon} className="img-fluid" />
                      <img alt="img" src={maestro_icon} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="d-grid ">
                  <button
                    type="button"
                    className="btn btn-primary rounded py-2 d-block d-md-none"
                    onClick={customerData.role === "individual" ? handleCheckoutConfirmationShow : clickProceedToPayment}
                    disabled={!(isTermsChecked && validateForms())}
                  >
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <span>{t("NewCheckout.CheckoutButton")}</span>
                      <img alt="img" src={arrow_right_icon} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CheckoutConfirmationModal show={checkoutConfirmationShow} onClose={handleCheckoutConfirmationClose} onNext={handleCheckoutConfirmationNext} />
    </FrontLayout>
  );
};

NewCheckout.propType = {
  isAuthenticated: PropTypes.bool,
};

const mapsStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapsStateToProps)(NewCheckout);
