import React from "react";
import { useTranslation } from "react-i18next";

function CheckoutConfirmationModal({ show, onClose, onNext }) {
  const { t } = useTranslation();
  if (!show) return null;

  return (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog  modal-dialog-scrollable modal-dialog-centered "
          role="document"
        >
          <div className="modal-content border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={onClose}
                ></button>
              </div>
              <div className="noto text-center mt-2">
              {t("NewCheckout.ModalConfirmationTitle")}
              </div>
              <p className="text-center mt-2">
                {t("NewCheckout.ModalConfirmationBody")}
              </p>
              <div className="row d-flex justify-content-center mt-4">
                <div className="col-md-5">
                  <div className="d-grid ">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClose}
                    >
                      {t("NewCheckout.ModalConfirmationBack")}
                    </button>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="d-grid ">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onNext}
                    >
                      {t("NewCheckout.ModalConfirmationProceed")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}

export default CheckoutConfirmationModal;
