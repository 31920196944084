import React from "react";
import image_svg from "../../../icons/image.svg"

const CMSImageUploaderCard = ({
  title,
  onChangeEvent,
  arrayImageName,
  formImage,
  awsImageUrl,
}) => {
  return (
    <div className="mb-3">
      <div className="col">
        <div className="mb-3">
          <label className="form-label">
            <span className="badge bg-warning text-wrap">
              <img src={image_svg} alt=""  />
            </span>
            {title}
          </label>
          <input
            className="form-control form-control-admin"
            type="file"
            id="formFile1"
            onChange={(event) => {
              onChangeEvent(event, arrayImageName, formImage);
            }}
          />
          <label className="badge bg-light text-wrap">{awsImageUrl}</label>
        </div>
      </div>
    </div>
  );
};

export default CMSImageUploaderCard;
