import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import googleIcon from "../../images/google-icon.svg";
import { useTranslation } from "react-i18next";
import { useLoading } from "../front-pages-new-theme/LoadingContext";
import email_icon from "../../icons/email.svg"
import lock_icon from "../../icons/lock.svg"
import arrow_right_icon from "../../icons/arrow_right.svg"
import profile_gold_icon from "../../icons/profile_gold.svg"
const LoginModal = ({
  login,
  show,
  handleAfterLogin,
  isAuthenticated,
  handleCloseModal,
  vehicle_type = null,
}) => {
  const { t } = useTranslation();
  const [loginFailed, setLoginFailed] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formDataRegister, setFormDataRegister] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    sign_up_with_google: false,
    access_token_google: null,
    avatar: null,
  });

  const history = useHistory();
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const { showLoading, closeLoading } = useLoading();

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    // console.log(vehicle_type)
    e.preventDefault();
    const res = await login(email, password);

    if (res) {
      if (vehicle_type !== null) {
        handleAfterLogin(vehicle_type);
      } else {
        handleAfterLogin();
      }
    } else {
      setLoginFailed(true);
    }
  };

  const handleSignInGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();

      // Handle the signed-in user
      const user = await auth2.signIn({ prompt: "consent" });
      const profile = user.getBasicProfile();
      const authResponse = user.getAuthResponse();
      const accessToken = authResponse.access_token;
      // console.log('User signed in:', profile);
      const userId = profile.getId();
      const userName = profile.getName();
      const userEmail = profile.getEmail();
      const userImageURL = profile.getImageUrl();

      showLoading(true);
      const checkLoginWithGoogle = await login(
        userEmail,
        accessToken,
        "google"
      );

      // Log user details
      // console.log("User ID: ", userId);
      // console.log("User Name: ", userName);
      // console.log("User Email: ", userEmail);
      // console.log("User Image URL: ", userImageURL);
      // console.log("Access Token: ", accessToken);

      await handleCloseModal();
      closeLoading(true);
      if (!checkLoginWithGoogle) {
        setFormDataRegister({
          ...formDataRegister,
          sign_up_with_google: true,
          email: userEmail,
          access_token_google: accessToken,
          avatar: userImageURL,
        });
        setIsGoogleSignUp(true);
      } else {
        if (vehicle_type !== null) {
          handleAfterLogin(vehicle_type);
        } else {
          handleAfterLogin();
        }
      }
    } catch (error) {
      console.error("Error signing in:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
    }
  };
  return (
    <>
      {show && (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog  modal-dialog-centered modal-login-as-guest "
              role="document"
            >
              <div className="modal-content border-top-gold">
                <div className="modal-body px-4 py-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-end d-block d-md-none">
                        <div className="row d-flex justify-content-end">
                          <button
                            onClick={handleCloseModal}
                            type="button"
                            className="btn-close p-2"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center my-4">
                        <div className="col-auto">
                          <div className="card-header m-0 p-0">
                            {t("login.retruning_customer")}
                          </div>
                          <h3 className="fw-900 text-secondary my-2">
                            {t("login.login_signup")}
                          </h3>
                        </div>
                      </div>
                      {/* START FORM */}
                      {loginFailed ? (
                        <>
                          {" "}
                          <div className="alert alert-danger">
                            {t("login.error")}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <form onSubmit={(e) => onSubmit(e)} action="#">
                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="text"
                              className={`form-control contact-form `}
                              id="emailAddress"
                              placeholder="Email address"
                              name="email"
                              onChange={(e) => onChange(e)}
                            />
                            <label htmlFor="emailAddress">
                              <div className="d-flex align-items-center">
                                <img src={email_icon} alt=""  />
                                &nbsp; {t("login.email_address")}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="password"
                              className={`form-control contact-form `}
                              id="password"
                              placeholder="Password"
                              name="password"
                              onChange={(e) => onChange(e)}
                            />
                            <label htmlFor="password">
                              <div className="d-flex align-items-center">
                                <img src={lock_icon} alt=""  />
                                &nbsp; {t("login.password")}
                              </div>
                            </label>
                          </div>
                        </div>
                        <a
                          href="/forgot-password"
                          className="text-muted noto-sans"
                          style={{ fontSize: "14px" }}
                        >
                          {t("login.forgot_password")}
                        </a>
                        <div className="row mt-4 justify-content-center">
                          <div className="d-grid text-center">
                            <button
                              name=""
                              id=""
                              type="submit"
                              className="btn btn-primary rounded py-2 px-4"
                              href="#"
                              role="button"
                            >
                              {t("login.login")}
                              <span className="ps-2">
                                <img src={arrow_right_icon} alt=""  />
                              </span>
                            </button>
                            <a
                              // href="/register"
                              className="text-muted mt-3 noto-sans d-none d-md-block"
                              data-bs-dismiss="modal"
                              data-bs-toggle="modal"
                              data-bs-target="#register"
                              onClick={handleCloseModal}
                            >
                              <span
                                style={{ fontSize: "14px" }}
                                dangerouslySetInnerHTML={{
                                  __html: t("login.sign_up_link"),
                                }}
                              ></span>
                            </a>

                            <a
                              // href="/register"
                              className="text-muted mt-3 noto-sans d-block d-md-none"
                              onClick={() => {
                                handleCloseModal();
                                history.push("/login?is_register=true");
                              }}
                            >
                              <span
                                style={{ fontSize: "14px" }}
                                dangerouslySetInnerHTML={{
                                  __html: t("login.sign_up_link"),
                                }}
                              ></span>
                            </a>
                          </div>
                        </div>
                      </form>

                      <div className="row align-items-center mt-4">
                        <div className="col">
                          <hr className="divider-grey" />
                        </div>
                        <div className="col-auto">
                          <p className="p-0 m-0 noto">{t("login.or")}</p>
                        </div>
                        <div className="col">
                          <hr className="divider-grey" />
                        </div>
                      </div>
                      <div className="row my-4 justify-content-center ">
                        <div className="col-12   d-grid text-center ">
                          <a
                            name=""
                            id=""
                            className="btn btn-secondary rounded py-2 px-4 m-0"
                            href="#"
                            role="button"
                            onClick={handleSignInGoogle}
                          >
                            <img
                              src={googleIcon}
                              className="img-fluid"
                              alt="Efficiency"
                            />
                            &nbsp; {t("login.continue_w_google")}
                          </a>
                        </div>
                      </div>
                      {/* END FORM */}
                    </div>
                    <div className="col col-md-auto mt-3 mt-md-0 px-md-4 ">
                      <div className="line-separator-section">
                        <div className="circle noto">{t("login.or")}</div>
                        <div className="horizontal-line"></div>
                        <div className="vertical-line"></div>
                      </div>
                    </div>
                    <div className="col-12 col-md">
                      <div className="text-end d-none d-md-block mt-2">
                        <div className="row d-flex justify-content-end">
                          <button
                            onClick={handleCloseModal}
                            type="button"
                            className="btn-close p-2"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center flex-column mt-2 mt-md-0 mb-5 mb-0"
                        style={{ height: "100%" }}
                      >
                        <div className="card-header m-0 p-0">
                          {t("login.guest_booking")}
                        </div>
                        <h3 className="fw-900 text-secondary my-2">
                          {t("login.continue_as_guest")}
                        </h3>
                        <div className="col-12 col-md-auto d-grid text-center mt-4 ">
                          <button
                            type="button"
                            name=""
                            id=""
                            className="btn bg-gold-light-50 border-gold rounded py-2 m-0"
                            href="#"
                            role="button"
                            onClick={() => {
                              if (vehicle_type !== null) {
                                handleAfterLogin(vehicle_type);
                              } else {
                                handleAfterLogin();
                              }
                            }}
                            data-bs-dismiss="modal"
                          >
                            <img src={profile_gold_icon} alt=""  />
                            &nbsp; {t("login.continue_as_guest")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

LoginModal.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginModal);
