import React from "react";
import danger_red_icon from "../../icons/danger_red.svg";
const AlertModal = ({
  text = "Please input all required fields (highlighted  in red)",
  handleClose,
  show,
}) => {
  return (
    <>
      {show && <div className="modal-backdrop show"></div>}
      <div
        className={show ? "modal fade show" : "modal fade"}
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content border-top-danger">
            <div className="text-end me-4 mt-3">
              <button
                onClick={handleClose}
                type="button"
                className="text-end btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body my-1 text-center"
              style={{ color: "#ED553B" }}
            >
              <h5 className="mb-4">
                <span className="me-2">
                  <img src={danger_red_icon} alt=""  />
                </span>
                {text}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
