import React, { useEffect,  } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";
import download_icon from  "../../images/download.svg";
import visa_icon from "../../images/visa-icon.svg";
import jcb_icon from "../../images/jcb-icon.svg";
import diners_club_icon from "../../images/diners-club-icon.svg";
import amex_icon from "../../images/amex-icon.svg";
import discover_icon from "../../images/discover-icon.svg";
import maestro_icon from "../../images/maestro-icon.svg";

const Pricing = () => {
  const dispatch = useDispatch();
  const page_name = "cms-pricing";
  const { t, } = useTranslation();
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();

  // useEffect(() => {
  //   if (lang === null) return;
  //   i18n.changeLanguage(lang.toLowerCase());
  // }, []);

  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-pricing d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-center">
            {/* <p className="card-header ps-0 text-gold">{t("Pricing")}</p> */}
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            ></p>
            {/* <h1 className="fw-bold mb-4 mt-2">{t("Pricing")}</h1> */}
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            ></h1>
          </div>

          <div className="row mb-3 mb-lg-5 d-flex justify-content-start mb-5">
            <div className="col-12 col-md-4">
              <div className="d-grid gap-2 d-block mt-auto">
                <a
                  className="btn btn-primary btn-lg text-dark px-1 mt-3 mb-0"
                  target="_blank"
                  href="https://drive.google.com/file/d/1oUh4k2k0hGDnVIx7xwwtZb2Qt-9fPURs/view" rel="noreferrer"
                >
                  <div className="d-flex flex-row align-items-center justify-content-center px-2 d-inline gap-3">
                  <i className="d-flex align-self-center ps-2 ">
                    <img  alt="img" src={download_icon} />
                    </i>
                    {t("Download Price List EN")}
                    
                  </div>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="d-grid gap-2 d-block mt-auto">
                <a
                  className="btn btn-primary btn-lg text-dark px-1 mt-3 mb-0"
                  target="_blank"
                  href="https://drive.google.com/file/d/14w7TUKh2iFMwZ_p5xqBLMrQrdu5z0iWt/view" rel="noreferrer"
                >
                  <div className="d-flex flex-row align-items-center justify-content-center px-2 d-inline gap-3">
                  <i className="d-flex align-self-center ps-2">
                  <img  alt="img" src={download_icon} />
                    </i>
                    {t("Download Price List JP")}
                    
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of hero image--> */}
      {/* <!-- section 1 --> */}
      <div className="container px-md-5 pt-5 mt-lg-5 col-lg-8 offset-lg-2">
        <div className="row mb-md-3 mb-lg-2 d-flex justify-content-center">
        </div>
        {/* <!-- section 1, download btns --> */}
      </div>
      {/* <!-- section 1, download btns, end --> */}
      {/* <!-- section 2, payment methods --> */}
      <div className="container px-md-5 pt-5 col-lg-6 offset-lg-3">
        <div className="row mb-3 d-flex justify-content-center">
          <h4 className="text-center mb-4">{t("Pricing.Heading.Two")}</h4>
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content6
                  : cmspage?.page_content_jp.content6,
            }}
          >
            {/* {t("Pricing.Heading.Two.Paragraph.One")} */}
          </p>
        </div>
        {/* <!-- section 2, payment methods icons --> */}
        <div className="row px-3 px-md-0">
          <div className="col col-lg-12 flex-column mx-auto p-0">
            <div className="border-0 mb-2 d-flex flex-row align-items-center justify-content-center gap-2">
              <img  alt="img" src={visa_icon} className="img-fluid" />
              <img  alt="img" src={jcb_icon} className="img-fluid" />
              <img  alt="img" src={diners_club_icon} className="img-fluid" />
              <img  alt="img" src={amex_icon} className="img-fluid" />
              <img  alt="img" src={discover_icon} className="img-fluid" />
              <img  alt="img" src={maestro_icon} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3, terms --> */}
      <div className="container-fluid px-0 pt-3 pt-lg-5 mt-5 mb-3">
        <div className="row mx-0 mb-5 d-flex align-items-center">
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center">
            <h3
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content15
                    : cmspage?.page_content_jp.content15,
              }}
            >
              {/* {t("Pricing.Heading.Three")} */}
            </h3>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>
      </div>
      <div className="container col-md-11 col-lg-7 mx-auto pb-3 px-3 px-md-0">
        <div className="row mb-3 mb-lg-5">
          <div className="col-12 col-md-6">
            <h5
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content11
                    : cmspage?.page_content_jp.content11,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.One")} */}
            </h5>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content12
                    : cmspage?.page_content_jp.content12,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.One.Paragraph.One")} */}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h5
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content13
                    : cmspage?.page_content_jp.content13,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.One")} */}
            </h5>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content14
                    : cmspage?.page_content_jp.content14,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.One.Paragraph.One")} */}
            </p>
          </div>
        </div>
        {/* <!-- section 1, airport transfer terms --> */}
        {/* <!-- section 1, airport transfer terms --> */}
      </div>
    </FrontLayout>
  );
};

export default Pricing;
