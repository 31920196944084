import React, { useRef, useState, useEffect, Fragment } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import payment_method from "../../images/payment_method.svg";
import { postBusinessLicense, businessRegister } from "../../actions/auth";
import { withAlert, types } from "react-alert";
import back_icon from "../../images/back_icon.svg";
import add_icon_gold from "../../images/add_icon_gold.svg";
import { useLoading } from "./LoadingContext";
import { getLocationByAddress } from "../../actions/googleAPI";
import visa_icon from "../../icons/visa.svg";
import master_card_icon from "../../icons/master-card.svg";
import jcb_icon from "../../icons/jcb.svg";
import american_ex_icon from "../../icons/american_ex.svg";
import email_icon from "../../icons/email.svg";
import lock_icon from "../../icons/lock.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";
import Select from "react-select";

const CustomerRegisterPage = ({ alert }) => {
  const { t } = useTranslation();
  const [countriesData, setCountriesData] = useState([]);
  const [isRegister, setisRegister] = useState(false);
  const contentRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [step, setStep] = useState(1);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { showLoading, closeLoading } = useLoading();
  const [profileData, setProfileData] = useState({
    //SECTION 1
    email: "",
    password: "",
    confirmPassword: "",
    //SECTION 2
    businessName: "",
    businessPhoneNumber: "",
    businessAddress: "",
    businessIndustry: "",
    businessType: "",
    businessRegistrationNumber: "",
    website: "",
    //SECTION 3
    pronounced: "",
    firstName: "",
    lastName: "",
    contactPhoneNumber: "",
    contactEmail: "",
    // SECTION 4
    billingPostCode: "",
    billingCountry: "Japan",
    billingName: "",
    billingPhoneNumber: "",
    billingAddress: "",
    //SECTION 5 (Payment Method)
    //SECTION 6 (Business License)
    //SECTION 7 (TNC)
  });
  const isValidEmail = (email) => {
    // Ekspresi reguler untuk memeriksa format alamat email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [selectedCategory, setSelectedCategory] = useState(
    profileData.businessIndustry
  );
  const [selectedType, setSelectedType] = useState(profileData.businessType);
  const [typeOptions, setTypeOptions] = useState([]);
  // useEffect(() => {
  //   // Update type options when selectedCategory changes
  //   const category = businessData.find(
  //     (item) => item.category === selectedCategory
  //   );

  //   setTypeOptions(category ? category.types : []);
  //   // Reset selectedType when selectedCategory changes
  //   setSelectedType("");
  // }, [selectedCategory]);

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [countryOrRegion, setCountryOrRegion] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [emailRecipients, setEmailRecipients] = useState([{ email: "" }]);
  const [recipientErrors, setRecipientErrors] = useState([]);

  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  const addRecipient = () => {
    const newErrors = [...recipientErrors];

    // Periksa apakah ada input kosong
    if (emailRecipients.some((recipient) => recipient.email === "")) {
      newErrors.push({
        index: emailRecipients.length,
        message: "Please enter a valid email",
      });
      setRecipientErrors(newErrors);
      return;
    }

    // Periksa apakah input memiliki format alamat email yang valid
    const invalidIndex = emailRecipients.findIndex(
      (recipient) => !isValidEmail(recipient.email)
    );
    if (invalidIndex !== -1) {
      newErrors.push({
        index: invalidIndex,
        message: "Please enter a valid email",
      });
      setRecipientErrors(newErrors);
      return;
    }

    setEmailRecipients([...emailRecipients, { email: "" }]);
    setRecipientErrors([]);
  };

  const handleRecipientChange = (index, value) => {
    const updatedRecipients = [...emailRecipients];
    updatedRecipients[index].email = value;
    setEmailRecipients(updatedRecipients);
  };

  const removeRecipient = (index) => {
    const updatedRecipients = [...emailRecipients];
    updatedRecipients.splice(index, 1);
    setEmailRecipients(updatedRecipients);
    // Hapus pesan kesalahan jika input dihapus
    setRecipientErrors(
      recipientErrors.filter((error) => error.index !== index)
    );
  };

  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [cardholderNameError, setCardholderNameError] = useState("");
  const [countryOrRegionError, setCountryOrRegionError] = useState("");

  const [businessLicenseFile, setBusinessLicenseFile] = useState(null);

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  // Function to handle the checkbox change
  const handleAgreeToTermsChange = (e) => {
    setAgreeToTerms(e.target.checked);
  };

  const [errorsProfileData, setErrorsProfileData] = useState({});
  const [countryCode1, setCountryCode1] = useState("JP");
  const [prefixPhoneNumber1, setPrefixPhoneNumber1] = useState("81");
  const [countryCode2, setCountryCode2] = useState("JP");
  const [prefixPhoneNumber2, setPrefixPhoneNumber2] = useState("81");
  const [countryCode3, setCountryCode3] = useState("JP");
  const [prefixPhoneNumber3, setPrefixPhoneNumber3] = useState("81");
  const [pronounced, setPronounced] = useState("Mr.");
  const [errorsEmailRecipients, setErrorsEmailRecipients] = useState({});

  const handleButtonChooseFile = (event) => {
    businessLicenseFileRef.current.click();
  };
  const handleChangeBusinessLicense = (event) => {
    const fileUploaded = event.target.files[0];
    setBusinessLicenseFile(fileUploaded);
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value;

    setCardNumber(value);
    if (value === "") {
      setCardNumberError("Card Number Required");
    } else {
      if (!checkCreditCardNumber(value)) {
        setCardNumberError("Card Number Format Invalid");
      } else {
        setCardNumberError("");
      }
    }
  };

  function checkCreditCardNumber(cardNumber) {
    return (
      visaRegex.test(cardNumber) ||
      mastercardRegex.test(cardNumber) ||
      jcbRegex.test(cardNumber) ||
      amexRegex.test(cardNumber)
    );
  }

  const handleCardholderNameChange = (e) => {
    const value = e.target.value;
    setCardholderName(value);

    if (value === "") {
      setCardholderNameError("Card Holder Name Required");
    } else {
      setCardholderNameError(""); // Clear the error if it's valid
    }
  };

  const handleCVVChange = (e) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setCVV(value);
      // if (value === "") {
      //   setCVVError("CVV Required");
      // } else {
      //   setCVVError("");
      // }
    }
  };

  const handleCountryOrRegionChange = (e) => {
    const value = e.target.value;
    setCountryOrRegion(value);
    if (value === "") {
      setCountryOrRegionError("Country Region Required");
    } else {
      setCountryOrRegionError(""); // Clear the error if it's valid
    }
  };

  useEffect(() => {
    loadCountriesData();
  }, []);

  const loadCountriesData = () => {
    fetch("/json/countries.json") // Replace with the actual path to your JSON file
      .then((response) => response.json())
      .then((data) => setCountriesData(data))
      .catch((error) => console.error("Error loading JSON data:", error));
  };

  const handleLiClickCountryList1 = (dialCode, countryCode) => {
    setPrefixPhoneNumber1(dialCode);
    setCountryCode1(countryCode);
  };
  const handleLiClickCountryList2 = (dialCode, countryCode) => {
    setPrefixPhoneNumber2(dialCode);
    setCountryCode2(countryCode);
  };
  const handleLiClickCountryList3 = (dialCode, countryCode, name) => {
    setPrefixPhoneNumber3(dialCode);
    setCountryCode3(countryCode);
    setProfileData({
      ...profileData,
      billingCountry: name,
    });
  };
  const handleExpiryDateChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.substring(0, 2) + "/" + inputValue.substring(2);
    }
    setExpiryDate(inputValue);
  };

  const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegex = /^5[1-5][0-9]{14}$/;
  const jcbRegex = /^(352[8-9]|35[3-8][0-9])[0-9]{12}$/;
  const amexRegex = /^3[47][0-9]{13}$/;

  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const businessNameRef = useRef();
  const businessPhoneNumberRef = useRef();
  const businessAddressRef = useRef();
  const businessIndustryRef = useRef();
  const businessTypeRef = useRef();
  const businessRegistrationNumberRef = useRef();
  const contactWebsite = useRef();
  const contactFirstNameRef = useRef();
  const contactLastNameRef = useRef();
  const contactPhoneNumberRef = useRef();
  const contactEmailRef = useRef();
  const billingPostCodeRef = useRef();
  const billingCountryRef = useRef();
  const billingNameRef = useRef();
  const billingPhoneNumberRef = useRef();
  const billingAddressRef = useRef();
  const businessLicenseFileRef = useRef(null);
  const agreeToTermsRef = useRef();
  const firstErrorRef = useRef(null);

  const validateProfileForm = () => {
    const validationErrors = {};

    firstErrorRef.current = null;
    console.log(firstErrorRef.current);
    if (!profileData.email) {
      validationErrors.email = "Email is required";
      if (!firstErrorRef.current) firstErrorRef.current = emailRef.current;
    } else {
      if (!validateEmail(profileData.contactEmail)) {
        validationErrors.email = "Email format invalid";
        if (!firstErrorRef.current) firstErrorRef.current = emailRef.current;
      }
    }
    if (!profileData.password) {
      validationErrors.password = "Password is required";
      if (!firstErrorRef.current) firstErrorRef.current = passwordRef.current;
    } else if (profileData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
      if (!firstErrorRef.current) firstErrorRef.current = passwordRef.current;
    }
    if (profileData.password !== profileData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
      if (!firstErrorRef.current)
        firstErrorRef.current = confirmPasswordRef.current;
    }
    // Section 2
    if (!profileData.businessName) {
      validationErrors.businessName = "Business Name is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessNameRef.current;
    }
    if (!profileData.businessPhoneNumber) {
      validationErrors.businessPhoneNumber =
        "Business Phone Number is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessPhoneNumberRef.current;
    }
    if (!profileData.businessAddress) {
      validationErrors.businessAddress = "Business Address is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessAddressRef.current;
    }
    if (!profileData.businessIndustry) {
      validationErrors.businessIndustry = "Business Industry is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessIndustryRef.current;
    }
    if (!profileData.businessType) {
      validationErrors.businessType = "Business Type is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessTypeRef.current;
    }
    if (!profileData.businessRegistrationNumber) {
      validationErrors.businessRegistrationNumber =
        "Business Registration Number is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessRegistrationNumberRef.current;
    }
    // Section 3
    if (!profileData.firstName) {
      validationErrors.firstName = "First Name is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = contactFirstNameRef.current;
    }

    if (!profileData.lastName) {
      validationErrors.lastName = "Last Name is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = contactLastNameRef.current;
    }

    if (!profileData.contactPhoneNumber) {
      validationErrors.contactPhoneNumber = "Contact Phone Number is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = contactPhoneNumberRef.current;
    }
    if (!profileData.contactEmail) {
      validationErrors.contactEmail = "Contact Email is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = contactEmailRef.current;
    } else {
      if (!validateEmail(profileData.contactEmail)) {
        validationErrors.contactEmail = "Email format invalid";
        if (!firstErrorRef.current)
          firstErrorRef.current = contactEmailRef.current;
      }
    }
    // Section 4
    if (!profileData.billingPostCode) {
      validationErrors.billingPostCode = "Billing post code is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = billingPostCodeRef.current;
    }
    if (!profileData.billingCountry) {
      validationErrors.billingCountry = "Billing country is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = billingCountryRef.current;
    }
    if (!profileData.billingName) {
      validationErrors.billingName = "Billing name is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = billingNameRef.current;
    }
    if (!profileData.billingPhoneNumber) {
      validationErrors.billingPhoneNumber = "Billing phone number is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = billingPhoneNumberRef.current;
    }
    if (!profileData.billingAddress) {
      validationErrors.billingAddress = "Billing address is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = billingAddressRef.current;
    }

    // Section 5 Payment Method

    // Section 6 (Business License)
    if (!businessLicenseFile) {
      validationErrors.businessLicenseFile =
        "Business license file is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = businessLicenseFileRef.current;
    }
    // Section 7 (TNC)
    if (!agreeToTerms) {
      validationErrors.agreeToTerms = "Agree to term and condition is required";
      if (!firstErrorRef.current)
        firstErrorRef.current = agreeToTermsRef.current;
    }

    if (firstErrorRef.current) {
      console.log(firstErrorRef);
      console.log(firstErrorRef.current.scrollHeight);
      firstErrorRef.current.scrollIntoView({ block: "center" });
    }
    setErrorsProfileData(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateProfileForm()) {
      if (step == 1) {
        const zipCodeCheck = await getLocationByAddress(
          profileData.billingPostCode,
          `${profileData.billingCountry[0].toUpperCase()}${profileData.billingCountry.slice(
            1
          )}`
        );
        if (zipCodeCheck != undefined) {
          setStep(2);
          scrollToTop();
        } else {
          setErrorsProfileData({ billingPostCode: "Post code invalid" });
          billingPostCodeRef.current.scrollIntoView({ block: "center" });
        }
      } else {
        if (paymentMethods.length < 1) {
          alert.show(t("Please add atleast one payment method"), {
            type: types.ERROR,
          });
        } else {
          const uploadBusinessLicenseFileResponse = await postBusinessLicense(
            businessLicenseFile
          );
          if (uploadBusinessLicenseFileResponse.status == 200) {
            // console.log(uploadBusinessLicenseFileResponse.data.fileName);
            console.log(emailRecipients);
            const businessRegisterResponse = await businessRegister({
              name: profileData.businessName,
              email: profileData.email,
              password: profileData.password,
              businessInformation: {
                businessName: profileData.businessName,
                businessPhoneNumber: `${profileData.billingPhoneNumber}`,
                businessAddress: profileData.businessAddress,
                businessType: profileData.businessType,
                businessIndustry: profileData.businessIndustry,
                businessRegistrationNumber:
                  profileData.businessRegistrationNumber,
                website: profileData.website,
                prefix_number: prefixPhoneNumber1,
                country_code: countryCode1,
              },
              authorizedRepresentativeContact: {
                pronounced: pronounced,
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                phoneNumber: `${profileData.contactPhoneNumber}`,
                prefix_number: prefixPhoneNumber2,
                country_code: countryCode2,
                emailAddress: profileData.contactEmail,
              },
              billingInformation: {
                contactName: profileData.billingName,
                phoneNumber: `${profileData.billingPhoneNumber}`,
                billingAddress: profileData.billingAddress,
                postCode: profileData.billingPostCode,
                country: profileData.billingCountry,
                prefix_number: prefixPhoneNumber3,
                country_code: countryCode3,
              },
              paymentMethod: paymentMethods,
              //  [
              //   {
              //     method: "card",
              //     cardNumber: cardNumber,
              //     mmyy: expiryDate,
              //     cvv: cvv,
              //     name: cardholderName,
              //     country: countryOrRegion,
              //   },
              // ]
              license: uploadBusinessLicenseFileResponse.data.fileName,
              emailRecipients: emailRecipients,
            });
            // console.log(businessRegisterResponse);
            if (businessRegisterResponse.status == 200) {
              setisRegister(true);
              window.scrollTo(0, 0);
            } else {
              alert.show(t("Register failed"), { type: types.ERROR });
            }
          } else {
            alert.show(t("Upload file failed"), { type: types.ERROR });
          }
        }
      }
    } else {
      // alert.show(
      //   t("Please fill in all the required fields (highlighted in red)."),
      //   { type: types.ERROR }
      // );
    }
  };
  const optionsPronounce = [
    { value: 'Mr.', label: 'Mr.' },
    { value: 'Ms.', label: 'Ms.' },
    { value: 'Mrs.', label: 'Mrs.' }
  ]

  const businessData = [
    {
      value: 'Technology', 
      label: 'Technology',
      types: [
        { value: 'Software Development', label: 'Software Development' },
        { value: 'Hardware Manufacturing', label: 'Hardware Manufacturing' },
        { value: 'Cloud Computing Services', label: 'Cloud Computing Services' },
        { value: 'Artificial Intelligence and Machine Learning', label: 'Artificial Intelligence and Machine Learning' },
        { value: 'Information Security Services', label: 'Information Security Services' }
      ]
    },
    {
      value: 'Healthcare',
      label: 'Healthcare',
      types: [
        { value: 'Pharmaceutical Manufacturing', label: 'Pharmaceutical Manufacturing' },
        { value: 'Medical Device Manufacturing', label: 'Medical Device Manufacturing' },
        { value: 'Healthcare Facilities', label: 'Healthcare Facilities' },
        { value: 'Biotechnology', label: 'Biotechnology' },
        { value: 'Health Insurance and Managed Care', label: 'Health Insurance and Managed Care' }
      ]
    },
    {
      value: 'Financial Services',
      label: 'Financial Services',
      types: [
        { value: 'Banking', label: 'Banking' },
        { value: 'Investment Brokerage', label: 'Investment Brokerage' },
        { value: 'Asset Management', label: 'Asset Management' },
        { value: 'Insurance', label: 'Insurance' },
        { value: 'Fintech and Mobile Payments', label: 'Fintech and Mobile Payments' }
      ]
    },
    {
      value: 'Energy',
      label: 'Energy',
      types: [
        { value: 'Oil and Gas Exploration and Production', label: 'Oil and Gas Exploration and Production' },
        { value: 'Renewable Energy', label: 'Renewable Energy' },
        { value: 'Utilities', label: 'Utilities' },
        { value: 'Energy Trading', label: 'Energy Trading' },
        { value: 'Energy Efficiency Solutions', label: 'Energy Efficiency Solutions' }
      ]
    },
    {
      value: 'Consumer Goods and Retail',
      label: 'Consumer Goods and Retail',
      types: [
        { value: 'Food and Beverage Manufacturing', label: 'Food and Beverage Manufacturing' },
        { value: 'Apparel and Footwear', label: 'Apparel and Footwear' },
        { value: 'Consumer Electronics', label: 'Consumer Electronics' },
        { value: 'E-commerce and Online Retailing', label: 'E-commerce and Online Retailing' },
        { value: 'Home and Personal Care Products', label: 'Home and Personal Care Products' }
      ]
    },
    {
      value: 'Hospitality',
      label: 'Hospitality',
      types: [
        { value: 'Hotel and Resort Operations', label: 'Hotel and Resort Operations' },
        { value: 'Tour Operators', label: 'Tour Operators' },
        { value: 'Food Service and Catering', label: 'Food Service and Catering' },
        { value: 'Transportation', label: 'Transportation' },
        { value: 'Event Planning and Management', label: 'Event Planning and Management' }
      ]
    },
    {
      value: 'Others',
      label: 'Others',
      types: [
        { value: 'Others', label: 'Others' }
      ]
    }
  ];
  

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(" ");

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  };

  const openModalFlywire = async () => {
    showLoading(true);
    const zipCodeCheck = await getLocationByAddress(
      profileData.billingPostCode,
      `${profileData.billingCountry[0].toUpperCase()}${profileData.billingCountry.slice(
        1
      )}`
    );
    const { firstName, lastName } = await extractFirstAndLastName(
      profileData.billingName
    );
    closeLoading(true);
    console.log(zipCodeCheck);
    // Add an event handler
    var config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
      amount: 0,
      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
      recurringType: "tokenization",

      // Specify the returnUrl or an onCompleteCallback handler
      returnUrl: "https://httpbin.org/get",

      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
      firstName: firstName,
      lastName: lastName,
      email: profileData.email,
      phone: prefixPhoneNumber3 + " " + profileData.billingPhoneNumber,
      address: profileData.billingAddress,
      city: zipCodeCheck.address_components.filter((item) =>
        item.types.includes("administrative_area_level_2")
      )[0].long_name,
      zip: profileData.billingPostCode,
      country: countryCode3,

      // requestPayerInfo: true,
      // requestRecipientInfo: true,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card", "direct_debit"],
        },
      },
      onCompleteCallback: function (args) {
        callbackFlywire(args);
      },
    };

    console.log(config);

    var modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  const callbackFlywire = (result) => {
    console.log(result);
    // if (addPaymentMethodForm) {
    const newPaymentMethod = {
      method: "card",
      cardNumber: result.digits,
      mmyy:
        result.expirationMonth.padStart(2, "0") +
        "" +
        result.expirationYear.substr(-2),
      cvv: "cvv",
      name: "cardholderName",
      country: "countryOrRegion",
      isActive: paymentMethods.length > 0 ? false : true,
      token: result.token,
      type: result.type,
      paymentMethod: result.paymentMethod,
      brand: result.brand,
      expirationMonth: result.expirationMonth,
      expirationYear: result.expirationYear,
    };

    setPaymentMethods((prevPaymentMethods) => [
      ...prevPaymentMethods,
      newPaymentMethod,
    ]);

    console.log(paymentMethods);
    //   setAddPaymentMethodForm(false);
    // } else {
    //   await registerUser();
    // }
  };
  const handlePaymentMethodActivation = (index) => {
    const updatedPaymentMethods = paymentMethods.map((method, i) => ({
      ...method,
      isActive: i === index ? true : false,
    }));
    // Assuming you're using useState to manage paymentMethods
    // Update the state with the modified array
    setPaymentMethods(updatedPaymentMethods);
    console.log(paymentMethods);
  };
  function getCardTypeByBrand(type) {
    if (type === "VISA") {
      return visa_icon;
    } else if (type === "MASTERCARD") {
      return master_card_icon;
    } else if (type === "AMERICAN EXPRESS") {
      return american_ex_icon;
    } else if (type === "JCB") {
      return jcb_icon;
    }

    return "Unknown"; // If none of the patterns match
  }
  const deletePaymentMethod = (index) => {
    const confirmDelete = window.confirm(t("registration.delete_confirmation"));
    if (confirmDelete) {
      const updatedPaymentMethods = paymentMethods.filter(
        (_, i) => i !== index
      );
      setPaymentMethods(updatedPaymentMethods);
    }
  };

  useEffect(() => {
    const contentDiv = contentRef.current;

    function handleScroll() {
      // Check if the user has scrolled to the bottom
      if (
        contentDiv.scrollTop + contentDiv.clientHeight >=
        contentDiv.scrollHeight - 10
      ) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }

    contentDiv.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      contentDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'red' }),
  };
  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container mt-1 pt-1">
          <div className="custom-mx">
            <div className="container pb-5 custom-mt-9 col-lg-12">
              <div className="row align-items-center">
                {!isRegister ? (
                  <div className="col-12">
                    {step == 2 && (
                      <div className="d-flex mb-4" onClick={() => setStep(1)}>
                        <img src={back_icon} alt="" />
                        <p className="noto fs-14 m-0 p-0 ms-2 ">
                          {t("registration.back")}
                        </p>
                      </div>
                    )}
                    <div className="row justify-content-between">
                      <div className="col">
                        <h3>Busines Registration</h3>
                      </div>
                      <div className="col text-end ">
                        <p className="noto fs-14 p-0">
                          Step {step}/2:
                          <span className="d-none  d-md-inline">
                            {step == 1
                              ? "Business Information"
                              : "Payment Method"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <hr />
                    </div>
                    <div className="bg-white card-shadow-page mt-3 rounded-3">
                      {step == 1 ? (
                        <div className="card-body-page">
                          <h5 className="my-3">
                            Account Information
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="row g-3 mb-3">
                            <div className="col fs-8">
                              <div className="form-floating">
                                <input
                                  ref={emailRef}
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.email && "invalid"
                                  }`}
                                  id="emailAddress"
                                  placeholder="Email address"
                                  name="email"
                                  value={profileData.email}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="emailAddress">
                                  <div className="d-flex align-items-center">
                                    <img src={email_icon} alt="" srcSet="" />
                                    &nbsp; {t("registration.email_address")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.email && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.email}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  ref={passwordRef}
                                  type="password"
                                  className={`form-control contact-form ${
                                    errorsProfileData.password && "invalid"
                                  }`}
                                  id="password"
                                  placeholder="Password"
                                  name="password"
                                  value={profileData.password}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="password">
                                  <div className="d-flex align-items-center">
                                    <img src={email_icon} alt="" srcSet="" />
                                    &nbsp; {t("registration.password")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.password && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.password}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  ref={confirmPasswordRef}
                                  type="password"
                                  className={`form-control contact-form ${
                                    errorsProfileData.confirmPassword &&
                                    "invalid"
                                  }`}
                                  id="confirmPassword"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  value={profileData.confirmPassword}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="password">
                                  <div className="d-flex align-items-center">
                                    <img src={lock_icon} alt="" srcSet="" />
                                    &nbsp; {t("registration.password_confirm")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.confirmPassword && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.confirmPassword}
                                </p>
                              )}
                            </div>
                          </div>

                          {/* SECTION 2 */}

                          <h5 className="my-3">
                            Business Information
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="row g-3 mb-3">
                            <div className="col-md-6">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    ref={businessNameRef}
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.businessName &&
                                      "invalid"
                                    }`}
                                    id="businessName"
                                    name="businessName"
                                     placeholder="Business Name"
                                    value={profileData.businessName}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="emailAddress">
                                    <div className="d-flex align-items-center ">
                                       {t("registration.email_address")}
                                    </div>
                                </label>
                                </div>
                                {errorsProfileData.businessName && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.businessName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-group ">
                                <button
                                  className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle border border-gold"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ height: "58px" }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${countryCode1}/flat/24.png`}
                                  />
                                  ({prefixPhoneNumber1})
                                </button>
                                <ul className="dropdown-menu">
                                  <div className="px-1 align-items-center mb-3 dropdown-search">
                                    <input
                                      type="text"
                                      className={`form-control p-3 mb-2`}
                                      defaultValue={searchPhoneCode}
                                      onChange={(e) =>
                                        setSearchPhoneCode(e.target.value)
                                      }
                                      autoComplete={"new-password"}
                                    />
                                  </div>
                                  {countriesData
                                    ?.filter((item) =>
                                      item.name
                                        .toLowerCase()
                                        .includes(
                                          searchPhoneCode !== null
                                            ? searchPhoneCode.toLowerCase()
                                            : null
                                        )
                                    )
                                    ?.map((country, index) => (
                                      <Fragment key={country.code}>
                                        <li
                                          className="dropdown-item m-0"
                                          onClick={() =>
                                            handleLiClickCountryList1(
                                              country.dial_code,
                                              country.code,
                                              country.name
                                            )
                                          }
                                        >
                                          <div className="row px-1 align-items-center">
                                            <div className="col-auto">
                                              <div className="bg-secondary bg-opacity-25 px-1">
                                                <img
                                                  src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                                  alt={country.name}
                                                />
                                              </div>
                                            </div>
                                            <div className="col">
                                              ({country.dial_code})
                                              {country.name}
                                            </div>
                                          </div>
                                        </li>
                                        <hr className="my-1" />
                                      </Fragment>
                                    ))}
                                </ul>
                                <input
                                  ref={businessPhoneNumberRef}
                                  type="number"
                                  className={`form-control contact-form p-3  ${
                                    errorsProfileData.businessPhoneNumber &&
                                    "invalid"
                                  } `}
                                  id="businessPhoneNumber"
                                  placeholder={t("Business Phone Number")}
                                  name="businessPhoneNumber"
                                  value={profileData.businessPhoneNumber}
                                  onChange={handleChangeProfile}
                                />
                              </div>

                              {errorsProfileData.businessPhoneNumber && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.businessPhoneNumber}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  ref={businessAddressRef}
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.businessAddress &&
                                    "invalid"
                                  }`}
                                  id="businessAddress"
                                  placeholder="Confirm Password"
                                  name="businessAddress"
                                  value={profileData.businessAddress}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="businessAddress">
                                  <div className="d-flex align-items-center">
                                   {t("Business Address")}
                                  </div>
                                </label>
                                {errorsProfileData.businessAddress && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.businessAddress}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col ">
                                  {/* <div className="form-floating">
                                    <select
                                      ref={businessIndustryRef}
                                      className={`form-select account-info-select  ${
                                        errorsProfileData.businessIndustry &&
                                        "invalid"
                                      }`}
                                      id="businessIndustry"
                                      placeholder="Confirm Password"
                                      name="businessIndustry"
                                      value={profileData.businessIndustry}
                                      onChange={(e) => {
                                        handleChangeProfile(e);
                                        setSelectedCategory(e.target.value);
                                      }}
                                    >
                                      <option selected>Choose</option>
                                      {businessData.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.category}
                                        >
                                          {item.category}
                                        </option>
                                      ))}
                                    </select>
                                    <label for="businessIndustry">
                                      {t("Business Industries")}
                                    </label>
                                  </div> */}
                                  <Select 
                                    ref={businessIndustryRef}
                                    name={"businessIndustry"}
                                    classNamePrefix ="register-select"
                                    className={`${
                                      errorsProfileData.businessIndustry &&
                                      "invalid"
                                    }`}
                                    isSearchable = {false}
                                    options={businessData}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        businessIndustry: e.value,
                                        businessType : null,
                                      });
                                    }}
                                    placeholder={t("Business Industries")}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dbbe78",
                                        height: "calc(3.5rem + 2px)",
                                        fontSize: "14px",
                                      }),
                                      control: (baseStyles, isActive) => ({
                                        ...baseStyles,
                                        paddingTop: "",
                                        border: "none",
                                        borderBottom: "1px solid #DBBE78",
                                        borderColor: "#dbbe78",
                                        height: "calc(3.5rem + 2px)",
                                        borderRadius: "0.3rem",
                                        background: "rgba(167, 167, 167, 0.1)",
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                  {errorsProfileData.businessIndustry && (
                                    <p className="m-0 text-danger">
                                      {errorsProfileData.businessIndustry}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col ">
                                  {/* <div className="form-floating"> */}
                                    {/* <select
                                      ref={businessTypeRef}
                                      className={`form-select account-info-select  ${
                                        errorsProfileData.businessType &&
                                        "invalid"
                                      }`}
                                      id="businessType"
                                      placeholder="Confirm Password"
                                      name="businessType"
                                      value={profileData.businessType}
                                      onChange={handleChangeProfile}
                                    >
                                      {(
                                        businessData.find(
                                          (item) =>
                                            item.category === selectedCategory
                                        ) || {}
                                      ).types?.map((type, index) => (
                                        <option key={index} value={type}>
                                          {type}
                                        </option>
                                      ))}
                                    </select> */}
                                    
                                    {/* <label for="businessType">
                                      {t("Business Type")}
                                    </label> */}
                                  {/* </div> */}
                                  <Select 
                                    ref={businessTypeRef}
                                    name={"businessType"}
                                    classNamePrefix ="register-select"
                                    className={`${
                                      errorsProfileData.businessType &&
                                      "invalid"
                                    }`}
                                    isSearchable = {false}
                                    options={businessData.find((item) => item.value === profileData.businessIndustry)?.types}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        businessType : e.value,
                                      });
                                    }}
                                    value={ profileData.businessType == null ?null : businessData.find((item) => item.value === profileData.businessIndustry)?.types.find((item) => item.value === profileData.businessType)}
                                    placeholder={t("Business Industries")}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dbbe78",
                                        height: "calc(3.5rem + 2px)",
                                        fontSize: "14px",
                                      }),
                                      control: (baseStyles, isActive) => ({
                                        ...baseStyles,
                                        paddingTop: "",
                                        border: "none",
                                        borderBottom: "1px solid #DBBE78",
                                        borderColor: "#dbbe78",
                                        height: "calc(3.5rem + 2px)",
                                        borderRadius: "0.3rem",
                                        background: "rgba(167, 167, 167, 0.1)",
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                  {errorsProfileData.businessType && (
                                    <p className="m-0 text-danger">
                                      {errorsProfileData.businessType}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col  fs-8">
                                  <div className="form-floating">
                                    <input
                                      ref={businessRegistrationNumberRef}
                                      type="text"
                                      className={`form-control contact-form ${
                                        errorsProfileData.businessRegistrationNumber &&
                                        "invalid"
                                      }`}
                                      id="businessRegistrationNumber"
                                      placeholder="Confirm Password"
                                      name="businessRegistrationNumber"
                                      value={
                                        profileData.businessRegistrationNumber
                                      }
                                      onChange={handleChangeProfile}
                                    />
                                    <label htmlFor="businessRegistrationNumber">
                                      <div className="d-flex align-items-center">
                                        {t("Business Registration Number")}
                                      </div>
                                    </label>
                                  </div>
                                  {errorsProfileData.businessRegistrationNumber && (
                                    <p className="m-0 text-danger">
                                      {
                                        errorsProfileData.businessRegistrationNumber
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col  fs-8">
                                  <div className="form-floating">
                                    <input
                                      ref={contactWebsite}
                                      type="text"
                                      className={`form-control contact-form ${
                                        errorsProfileData.website && "invalid"
                                      }`}
                                      id="website"
                                      placeholder="Website"
                                      name="website"
                                      value={profileData.website}
                                      onChange={handleChangeProfile}
                                    />
                                    <label htmlFor="website">
                                      <div className="d-flex align-items-center">
                                       {t("Website (Optional)")}
                                      </div>
                                    </label>
                                  </div>
                                  {errorsProfileData.website && (
                                    <p className="m-0 text-danger">
                                      {errorsProfileData.website}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* SECTION 3 */}

                          <h5 className="my-3">
                            Authorized Representative Contacts
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="row g-3 ">
                            <div className="col-md-2 mb-3">
                              <Select 
                                classNamePrefix ="register-select"
                                placeholder = {false}
                                isSearchable = {false}
                                defaultValue= {optionsPronounce[0]}
                                options={optionsPronounce}
                                onChange={(e)=>setPronounced(e.value)}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dbbe78",
                                    height: "calc(3.5rem + 2px)",
                                    fontSize: "14px",
                                  }),
                                  control: (baseStyles, isActive) => ({
                                    ...baseStyles,
                                    paddingTop: "",
                                    border: "none",
                                    borderBottom: "1px solid #DBBE78",
                                    borderColor: "#dbbe78",
                                    height: "calc(3.5rem + 2px)",
                                    borderRadius: "0.3rem",
                                    background: "rgba(167, 167, 167, 0.1)",
                                    fontSize: "14px",
                                  }),
                                }}
                              />
                            </div>
                            <div className="col-md-5">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    ref={contactFirstNameRef}
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.firstName && "invalid"
                                    }`}
                                    id="firstName"
                                    placeholder="Name"
                                    name="firstName"
                                    value={profileData.firstName}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="firstName">
                                    <div className="d-flex align-items-center">
                                      {t("registration.first_name")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.firstName && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.firstName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    ref={contactLastNameRef}
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.lastName && "invalid"
                                    }`}
                                    id="lastName"
                                    placeholder="Name"
                                    name="lastName"
                                    value={profileData.lastName}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="lastName">
                                    <div className="d-flex align-items-center">
                                      {t("registration.last_name")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.lastName && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.lastName}
                                  </p>
                                )}
                              </div>
                            </div>
                            
                          </div>
                          <div className="row g-3 mb-3">
                            <div className="col-md-7  fs-8">
                              <div className="form-floating">
                                <input
                                  ref={contactEmailRef}
                                  type="email"
                                  className={`form-control contact-form ${
                                    errorsProfileData.contactEmail && "invalid"
                                  }`}
                                  id="contanctEmailAddress"
                                  placeholder="Email address"
                                  name="contactEmail"
                                  value={profileData.contactEmail}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="contanctEmailAddress">
                                  <div className="d-flex align-items-center">
                                    {t("registration.email_address")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.contactEmail && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.contactEmail}
                                </p>
                              )}
                            </div>
                            <div className="col-md-5">
                              <div className="input-group ">
                                <button
                                  className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle border border-gold"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ height: "58px" }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${countryCode2}/flat/24.png`}
                                  />
                                  ({prefixPhoneNumber2})
                                </button>
                                <ul className="dropdown-menu">
                                  <div className="px-1 align-items-center mb-3 dropdown-search">
                                    <input
                                      type="text"
                                      className={`form-control p-3 mb-2`}
                                      defaultValue={searchPhoneCode}
                                      onChange={(e) =>
                                        setSearchPhoneCode(e.target.value)
                                      }
                                      autoComplete={"new-password"}
                                    />
                                  </div>
                                  {countriesData
                                    ?.filter((item) =>
                                      item.name
                                        .toLowerCase()
                                        .includes(
                                          searchPhoneCode !== null
                                            ? searchPhoneCode.toLowerCase()
                                            : null
                                        )
                                    )
                                    ?.map((country, index) => (
                                      <Fragment key={country.code}>
                                        <li
                                          className="dropdown-item m-0"
                                          onClick={() =>
                                            handleLiClickCountryList2(
                                              country.dial_code,
                                              country.code
                                            )
                                          }
                                        >
                                          <div className="row px-1 align-items-center">
                                            <div className="col-auto">
                                              <div className="bg-secondary bg-opacity-25 px-1">
                                                <img
                                                  src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                                  alt={country.name}
                                                />
                                              </div>
                                            </div>
                                            <div className="col">
                                              ({country.dial_code})
                                              {country.name}
                                            </div>
                                          </div>
                                        </li>
                                        <hr className="my-1" />
                                      </Fragment>
                                    ))}
                                </ul>
                                <input
                                  ref={contactPhoneNumberRef}
                                  type="number"
                                  className={`form-control contact-form p-3  ${
                                    errorsProfileData.contactPhoneNumber &&
                                    "invalid"
                                  } `}
                                  id="contactPhoneNumber"
                                  placeholder={t("Phone Number")}
                                  name="contactPhoneNumber"
                                  value={profileData.contactPhoneNumber}
                                  onChange={handleChangeProfile}
                                />
                              </div>

                              {errorsProfileData.contactPhoneNumber && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.contactPhoneNumber}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="py-3" id="EmailRecipientsSection">
                            <h5 className="my-3">
                              Additional Email Recipients (optional)
                            </h5>
                            <p>
                              ※ For extra CC recipients of generated emails
                              besides the two registered emails, kindly add the
                              email address(es) below:
                            </p>
                            <div className="row">
                              {emailRecipients.map((recipient, index) => (
                                <div className="col-12 input-box" key={index}>
                                  <div className="form-floating mt-3">
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      id={`emailAddress-${index}`}
                                      placeholder="Email address"
                                      value={recipient.email}
                                      onChange={(e) =>
                                        handleRecipientChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                    {emailRecipients.length !== 1 && (
                                      <i
                                        className="fa fa-close icon"
                                        onClick={() => removeRecipient(index)}
                                      ></i>
                                    )}
                                    <label htmlFor={`emailAddress-${index}`}>
                                      <div className="d-flex align-items-center">
                                        <img src={email_icon} alt="" srcSet="" />
                                        &nbsp;
                                        {t("registration.email_address")}
                                      </div>
                                    </label>
                                  </div>
                                  {recipientErrors.some(
                                    (error) => error.index === index
                                  ) && (
                                    <p className="m-0 text-danger">
                                      {
                                        recipientErrors.find(
                                          (error) => error.index === index
                                        ).message
                                      }
                                    </p>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>

                          <button
                            type="button"
                            class="btn bg-gold-light-100"
                            onClick={addRecipient}
                          >
                            <span className="mx-5"> Add Email Address</span>
                          </button>

                          {/* SECTION 4 */}
                          <h5 className="my-3">
                            {t("registration.billing_information")}
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="row g-3 mb-3">
                            <div className="col-md-4">
                              <div className="col fs-8">
                                <div className="form-floating ">
                                  <input
                                    ref={billingNameRef}
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.billingName && "invalid"
                                    }`}
                                    id="billingName"
                                    placeholder="Name"
                                    name="billingName"
                                    value={profileData.billingName}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="billingName">
                                    <div className="d-flex align-items-center">
                                      {t("Contact Name")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.billingName && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.billingName}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="input-group ">
                                <button
                                  className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle border border-gold"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ height: "58px" }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${countryCode3}/flat/24.png`}
                                  />
                                  ({prefixPhoneNumber3})
                                </button>
                                <ul className="dropdown-menu">
                                  <div className="px-1 align-items-center mb-3 dropdown-search">
                                    <input
                                      type="text"
                                      className={`form-control p-3 mb-2`}
                                      defaultValue={searchPhoneCode}
                                      onChange={(e) =>
                                        setSearchPhoneCode(e.target.value)
                                      }
                                      autoComplete={"new-password"}
                                    />
                                  </div>
                                  {countriesData
                                    ?.filter((item) =>
                                      item.name
                                        .toLowerCase()
                                        .includes(
                                          searchPhoneCode !== null
                                            ? searchPhoneCode.toLowerCase()
                                            : null
                                        )
                                    )
                                    ?.map((country, index) => (
                                      <Fragment key={country.code}>
                                        <li
                                          className="dropdown-item m-0"
                                          onClick={() =>
                                            handleLiClickCountryList3(
                                              country.dial_code,
                                              country.code,
                                              country.name,
                                            )
                                          }
                                        >
                                          <div className="row px-1 align-items-center">
                                            <div className="col-auto">
                                              <div className="bg-secondary bg-opacity-25 px-1">
                                                <img
                                                  src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                                  alt={country.name}
                                                />
                                              </div>
                                            </div>
                                            <div className="col">
                                              ({country.dial_code})
                                              {country.name}
                                            </div>
                                          </div>
                                        </li>
                                        <hr className="my-1" />
                                      </Fragment>
                                    ))}
                                </ul>
                                <input
                                  ref={billingPhoneNumberRef}
                                  type="number"
                                  className={`form-control contact-form p-3  ${
                                    errorsProfileData.billingPhoneNumber &&
                                    "invalid"
                                  } `}
                                  id="billingPhoneNumber"
                                  placeholder={t("Phone Number")}
                                  name="billingPhoneNumber"
                                  value={profileData.billingPhoneNumber}
                                  onChange={handleChangeProfile}
                                />
                              </div>

                              {errorsProfileData.billingPhoneNumber && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.billingPhoneNumber}
                                </p>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="row">
                                <div className="col  fs-8">
                                  <div className="form-floating">
                                    <input
                                      ref={billingCountryRef}
                                      type="text"
                                      readOnly={true}
                                      className={`form-control contact-form ${
                                        errorsProfileData.billingCountry &&
                                        "invalid"
                                      }`}
                                      id=""
                                      placeholder="Country"
                                      name="billingCountry"
                                      value={profileData.billingCountry}
                                      onChange={handleChangeProfile}
                                    />
                                    <label htmlFor="">
                                      <div className="d-flex align-items-center">
                                        {t("Country")}
                                      </div>
                                    </label>
                                  </div>
                                  {errorsProfileData.billingCountry && (
                                    <p className="m-0 text-danger">
                                      {errorsProfileData.billingCountry}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-3 g-3">
                            <div className="col-md-2">
                              <div className="row">
                                <div className="col  fs-8">
                                  <div className="form-floating">
                                    <input
                                      ref={billingPostCodeRef}
                                      type="text"
                                      className={`form-control contact-form ${
                                        errorsProfileData.billingPostCode &&
                                        "invalid"
                                      }`}
                                      id="billingPostCode"
                                      placeholder="Post Codea"
                                      name="billingPostCode"
                                      value={profileData.billingPostCode}
                                      onChange={handleChangeProfile}
                                    />
                                    <label htmlFor="billingPostCode">
                                      <div className="d-flex align-items-center">
                                        {t("Post Code")}
                                      </div>
                                    </label>
                                  </div>
                                  {errorsProfileData.billingPostCode && (
                                    <p className="m-0 text-danger">
                                      {errorsProfileData.billingPostCode}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  ref={billingAddressRef}
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.billingAddress &&
                                    "invalid"
                                  }`}
                                  id="billingAddress"
                                  placeholder="billingAddress"
                                  name="billingAddress"
                                  value={profileData.billingAddress}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="billingAddress">
                                  <div className="d-flex align-items-center">
                                    {t("registration.billing_address")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.billingAddress && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.billingAddress}
                                </p>
                              )}
                            </div>
                          </div>
                          {/* SECTION 5 */}

                          {/* SECTION 6 */}
                          <h5 className="my-3">
                            Business License
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="container border-dashsed-gold bg-grey-light mb-3">
                            <div className="my-5">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="m-0">
                                  {businessLicenseFile != undefined
                                    ? businessLicenseFile.name
                                    : "Upload File in PDF or JPEG format"}
                                </p>
                              </div>
                              <div className="d-flex align-items-center justify-content-center">
                                {errorsProfileData.businessLicenseFile && (
                                  <p className="text-danger">
                                    {errorsProfileData.businessLicenseFile}
                                  </p>
                                )}
                              </div>
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary px-5 my-3 rounded"
                                  onClick={handleButtonChooseFile}
                                >
                                  Choose File
                                </button>
                                <input
                                  type="file"
                                  name=""
                                  ref={businessLicenseFileRef}
                                  id=""
                                  className="d-none"
                                  onChange={handleChangeBusinessLicense}
                                  accept="image/jpeg,.pdf"
                                />
                              </div>
                            </div>
                          </div>

                          {/* SECTION 7 */}
                          <h5 className="">
                            {t("Terms & Conditions")}
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <div className="bg-gold-light-50 p-4 mt-3">
                            <div
                              className=" overflow-auto custom-scrollbar"
                              ref={contentRef}
                              style={{ height: "381px" }}
                              dangerouslySetInnerHTML={{
                                __html: `${t("termConditionContent")}`,
                              }}
                            ></div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="row row-cols-auto">
                              <div className="col">
                                <p className="noto">
                                  <input
                                    ref={agreeToTermsRef}
                                    type="checkbox"
                                    id="tnc"
                                    checked={agreeToTerms}
                                    onChange={handleAgreeToTermsChange}
                                    disabled={isScrolledToBottom ? false : true}
                                  />
                                  <label htmlFor="tnc">
                                    <span 
                                    className="ms-2"
                                      dangerouslySetInnerHTML={{
                                        __html: `${t("term.condition")}`,
                                      }}
                                    ></span>
                                  </label>
                                  {errorsProfileData.agreeToTerms && (
                                    <p className="mt-1 text-danger "> 
                                      {errorsProfileData.agreeToTerms}
                                    </p>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card-body-page">
                          <h5 className="mb-3">
                            Payment Method &nbsp;
                           &nbsp; <span className="text-danger">*</span>
                          </h5>
                          <p>※payment terms for B2B</p>
                          <div className="">
                            {paymentMethods.map((item, index) => (
                              <div
                                className="d-flex bg-light my-3  py-3 px-3 justify-content-between payment-method-custom-border-bottom"
                                key={index + 1}
                              >
                                <div className="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="paymentMethod"
                                      checked={item.isActive}
                                      id={`paymentMethod${index}`}
                                      onChange={() =>
                                        handlePaymentMethodActivation(index)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`paymentMethod${index}`}
                                    >
                                      <img
                                        src={getCardTypeByBrand(item.brand)}
                                        alt=""
                                        
                                        className="me-2"
                                      />
                                      <span>
                                        {"••••" +
                                          item.cardNumber +
                                          ", Exp.: " +
                                          item.mmyy}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn-close p-2"
                                    aria-label="Close"
                                    onClick={() => deletePaymentMethod(index)}
                                  ></button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-12 col col-md-auto">
                            <button
                              className="btn bg-gold-light-100"
                              onClick={openModalFlywire}
                            >
                              <div className="d-flex mx-3">
                                <img src={add_icon_gold} alt=""  />
                                <span className="ms-2">Add Payment Method</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-grid gap-2 col-12 col-md-4 mx-auto mt-5">
                      <button
                        type="button"
                        className="btn btn-primary rounded px-4"
                        onClick={handleSubmit}
                      >
                        Register
                        <i className="ps-2">
                         <img src={arrow_right_icon} alt="" srcSet="" />
                        </i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="container pb-5 custom-mt-9 col-lg-12">
                    <h2>Thank you for signing up. </h2>
                    <p className="mt-3">
                      Kindly confirm your account by clicking the link in the
                      mail we sent to your registered email address :
                      <a href="">
                        <b> &nbsp; {profileData.email}</b>
                      </a>
                    </p>
                    <div className="row mt-5 align-items-center">
                      <div className="col-auto">
                        <p className="m-0">
                          We will conduct a thorough verification of your
                          business based on the details you've registered after
                          you have confirmed your email. ※Please note that this
                          business verification procedure may take up to
                          <b className="text-danger fs-14">7 days</b>. As soon
                          as the process is completed, we will promptly notify
                          you via email.
                        </p>
                      </div>

                      <div className="col-auto mt-3">
                        <a
                          name=""
                          id=""
                          className="btn btn-secondary px-5  =rounded"
                          href="#"
                          role="button"
                        >
                          {t("Resend Email")}
                        </a>
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-12 col-md-4">
                        <div className="d-grid">
                          <a
                            name=""
                            id=""
                            className="btn btn-primary rounded"
                            href="/"
                            role="button"
                          >
                            {t("Back to Home")}
                            <i className="ps-2">
                              <img src={arrow_right_icon} alt="" srcSet="" />
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default withAlert()(CustomerRegisterPage);
