import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";
import arrow_down_icon from "../../icons/arrow_down.svg";

const Careers = () => {
  const page_name = "cms-careers";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-careers d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Careers")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2 col col-md-9 col-lg-6"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Careers.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Careers.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- hero end --> */}
      {/* <!-- section 1 --> */}
      <div className="container-fluid bg-grey-2 pt-5 pb-5">
        <div className="container col-md-7 col-lg-6 mx-auto mt-3">
          <div className="row mb-3 mb-lg-5 d-flex justify-content-center">
            <h5
              className="text-center mb-4 col-lg-8"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content4
                    : cmspage?.page_content_jp.content4,
              }}
            >
              {/* {t("Careers.Intro.Two")} */}
            </h5>
            <p
              className="text-dark text-center"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content5
                    : cmspage?.page_content_jp.content5,
              }}
            >
              {/* {t("Careers.Intro.Three")} */}
            </p>
          </div>
        </div>
        {/* <!-- section 1, application btns --> */}
        <div className="container col-md-11 col-lg-10 mx-auto pb-3">
          <div className="row gy-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="card custom-card h-100 shadow-sm-2">
                <div className="card-body d-flex flex-column pt-1">
                  <p
                    className="card-title mt-auto mb-0 pb-0 pt-3 text-center"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content6
                          : cmspage?.page_content_jp.content6,
                    }}
                  >
                    {/* {t("Recruitment.Info")} */}
                  </p>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="d-grid gap-2 d-block">
                        <a
                          className="btn btn-secondary btn-sm px-1"
                          href="mailto:info@theluxuryfleet.com"
                        >
                          <div
                            className="d-inline fs-8"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content7
                                  : cmspage?.page_content_jp.content7,
                            }}
                          ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="card custom-card h-100 shadow-sm-2">
                <div className="card-body d-flex flex-column pt-1">
                  <p
                    className="card-title mt-auto mb-0 pb-0 pt-3 text-center"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content8
                          : cmspage?.page_content_jp.content8,
                    }}
                  >
                    {/* {t("Job.Application.Info")} */}
                  </p>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="d-grid gap-2 d-block">
                        <a
                          className="btn btn-secondary btn-sm px-1"
                          target="_blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSe_MVLMwir3aL3MCnLF4eKzKmYEFi-cpQT8xYQyRTVJFAYYCg/viewform?c=0&flr=0&vc=0&w=1" rel="noreferrer"
                        >
                          <div
                            className="d-inline fs-8"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content9
                                  : cmspage?.page_content_jp.content9,
                            }}
                          >
                            {/* Job application form */}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="card custom-card h-100 shadow-sm-2">
                <div className="card-body d-flex flex-column pt-1">
                  <p
                    className="card-title mt-auto mb-0 pb-0 pt-3 text-center"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content10
                          : cmspage?.page_content_jp.content10,
                    }}
                  >
                    {/* {t("Recruitment.Info")} */}
                  </p>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="d-grid gap-2 d-block">
                        <a
                          className="btn btn-secondary btn-sm px-1"
                          href="mailto:internship@theluxuryfleet.com"
                        >
                          <div
                            className="d-inline fs-8"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content11
                                  : cmspage?.page_content_jp.content11,
                            }}
                          >
                            {/* internship@theluxuryfleet.com */}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- section 1, application btns end --> */}
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2, job postings --> */}
      {/* <!-- chauffeur --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-3 mt-lg-5 pt-4 pt-lg-5 pb-3 px-md-0">
        <h4
          className="col col-lg-10 offset-lg-1 card-title mb-5"
          dangerouslySetInnerHTML={{
            __html:
              lang === "EN"
                ? cmspage?.page_content_en.content12
                : cmspage?.page_content_jp.content12,
          }}
        >
          {/* {t("Chauffeur")} */}
        </h4>
        <div className="row">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 col-md-6 d-flex flex-column">
              <div className="row mb-3">
                <h3
                  className="fs-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content13
                        : cmspage?.page_content_jp.content13,
                  }}
                >
                  {/* {t("Job.Description")} */}
                </h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content14
                      : cmspage?.page_content_jp.content14,
                }}
              ></div>
              {/* <ul>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Description.Point.One")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Description.Point.Two")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Description.Point.Three")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Description.Point.Four")}
                </li>
              </ul> */}
            </div>

            <div className="col-12 col-md-5 d-flex flex-column">
              <div className="row mb-3">
                <h3
                  className="fs-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content15
                        : cmspage?.page_content_jp.content15,
                  }}
                >
                  {/* {t("Job.Requirements")} */}
                </h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content16
                      : cmspage?.page_content_jp.content16,
                }}
              ></div>
              {/* <ul>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Requirements.Point.One")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Requirements.Point.Two")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Requirements.Point.Three")}
                </li>
                <li className="lh-lg">
                  {t("Chauffeur.Job.Requirements.Point.Four")}
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <hr className="col-12 col-lg-10 mx-auto card-line-light-grey mt-5"></hr>
      </div>
      {/* <!-- chauffeur end --> */}
      {/* <!-- administration executive --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-4 pb-3 px-md-0">
        <h4
          className="col col-lg-10 offset-lg-1 card-title mb-5"
          dangerouslySetInnerHTML={{
            __html:
              lang === "EN"
                ? cmspage?.page_content_en.content17
                : cmspage?.page_content_jp.content17,
          }}
        >
          {/* {t("Administration.Executive")} */}
        </h4>
        <div className="row">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 col-md-6 d-flex flex-column">
              <div className="row mb-3">
                <h3
                  className="fs-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content18
                        : cmspage?.page_content_jp.content18,
                  }}
                >
                  {/* {t("Job.Description")} */}
                </h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content19
                      : cmspage?.page_content_jp.content19,
                }}
              >
                {/* <li className="lh-lg">
                  {t("Administration.Executive.Job.Description.Point.One")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Description.Point.Two")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Description.Point.Three")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Description.Point.Four")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Description.Point.Five")}
                </li> */}
              </div>
            </div>

            <div className="col-12 col-md-5 d-flex flex-column">
              <div className="row mb-3">
                <h3
                  className="fs-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content20
                        : cmspage?.page_content_jp.content20,
                  }}
                >
                  {/* {t("Job.Requirements")} */}
                </h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content21
                      : cmspage?.page_content_jp.content21,
                }}
              >
                {/* <li className="lh-lg">
                  {t("Administration.Executive.Job.Requirements.Point.One")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Requirements.Point.Two")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Requirements.Point.Three")}
                </li>
                <li className="lh-lg">
                  {t("Administration.Executive.Job.Requirements.Point.Four")}
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <hr className="col-12 col-lg-10 mx-auto card-line-light-grey mt-5"></hr>
      </div>
      {/* <!-- administration executive end --> */}
      {/* <!-- full time planning specialist --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-4 mb-5 pb-3">
        <h4
          className="col col-lg-10 offset-lg-1 card-title mb-5"
          dangerouslySetInnerHTML={{
            __html:
              lang === "EN"
                ? cmspage?.page_content_en.content22
                : cmspage?.page_content_jp.content22,
          }}
        >
          {/* {t("Full.Time.Planning.Specialist")} */}
        </h4>
        <div className="row mb-5">
          <div className="col-12 col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 d-flex flex-column px-1 px-md-0">
              <div className="row mb-3 ps-0">
                <h3
                  className="fs-6 mb-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content23
                        : cmspage?.page_content_jp.content23,
                  }}
                >
                  {/* {t("Overview")} */}
                </h3>
                <p
                  className=""
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content24
                        : cmspage?.page_content_jp.content24,
                  }}
                >
                  {/* {t("Full.Time.Planning.Specialist.Description")} */}
                </p>
              </div>
            </div>
          </div>

          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 col-md-5 d-flex flex-column">
              <div className="row mb-3 mx-3 mx-sm-0 d-flex">
                <h3 className="fs-6 bg-gold d-flex align-items-center justify-content-center text-center py-2 ms-lg-2">
                  {/* {t("Responsibilities")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content25
                          : cmspage?.page_content_jp.content25,
                    }}
                  ></div>
                  <i className="ps-2">
                    <img src={arrow_down_icon} alt="" srcSet="" />
                  </i>
                </h3>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content26
                      : cmspage?.page_content_jp.content26,
                }}
              ></div>
              {/* <ul className="px-3 ps-md-1">
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.One"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Two"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Three"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Four"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Five"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Six"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Seven"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Eight"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Nine"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Ten"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Eleven"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Twelve"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Thirteen"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Fourteen"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Responsibilities.Point.Fifteen"
                  )}
                </li>
              </ul> */}
            </div>

            <div className="col-12 col-md-5 d-flex flex-column mt-3 mt-md-0">
              <div className="row mb-3 mx-3 mx-sm-0  d-flex">
                <h3 className="fs-6 bg-gold d-flex align-items-center justify-content-center text-center py-2 me-lg-2">
                  {/* {t("Qualifications")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content27
                          : cmspage?.page_content_jp.content27,
                    }}
                  ></div>
                  <i className="ps-2">
                    <img src={arrow_down_icon} alt="" srcSet="" />
                  </i>
                </h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content28
                      : cmspage?.page_content_jp.content28,
                }}
              ></div>
              {/* <ul className="px-3 ps-md-1">
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Qualifications.Point.One"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Qualifications.Point.Two"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Qualifications.Point.Three"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Qualifications.Point.Four"
                  )}
                </li>
                <li className="my-1">
                  {t(
                    "Full.Time.Planning.Specialist.Job.Qualifications.Point.Five"
                  )}
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- full time planning specialist end --> */}
    </FrontLayout>
  );
};

export default Careers;
