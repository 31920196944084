/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import {
  getBooking,
  updateBookingStatus,
  getAvailableVehicles,
  updateDriver,
  addNote,
  getCart,
  getBookingsByCartID,
} from "../../../actions/admin/booking";
import {
  chargeToken,
  chargeTokenByCartId,
  chargeTokenWithRequestedChanges,
} from "../../../actions/admin/flywire";
import Alert from "../../layout/Alert";
import { setAlert } from "../../../actions/alert";
import Select from "react-select";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "../../../utils/customMoment";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { bookingStatusOptions } from "../../../utils/dataLists";
import { getAllDrivers } from "../../../actions/admin/driver";
import {
  getTemplate,
  getTemplates,
  sendMail,
} from "../../../actions/admin/mail";
import { getSetting } from "../../../actions/admin/settings";
import { getBookingCancelEmailTemplate } from "../../../utils/EmailTemplate";
import { useLoading } from "../../../components/front-pages-new-theme/LoadingContext";
import ModalConfirmation from "../../front-pages-new-theme/ModalConfirmation";

const ManageBooking = ({ match }) => {
  const [activeVehicles, setActiveVehicles] = useState([]);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [ddltemplate, setDDlTemplates] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [driverSelectedOption, setDriverSelectedOption] = useState([]);
  const [ddltemplateOption, setddlTemplateOption] = useState(null);
  const { showLoading, closeLoading } = useLoading();

  const dispatch = useDispatch();
  const template_state = useSelector((state) => state.template);
  const { templates } = template_state;
  const bookingState = useSelector((state) => state.booking);
  const { loading, booking } = bookingState;
  const [style, setStyle] = useState("");
  const [note, setNote] = useState("");
  const [bookingStatus, setBookingStatus] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [subject, setSubject] = useState("");
  const [updatedHtmlstate, setUpdatedHtmlstate] = useState(null);

  const [labelData, setLabelData] = useState({});

  const [checkChargeLoading, setcheckChargeLoading] = useState(true);
  const [bookingsWithCartIdLength, setBookingsWithCartIdLength] = useState(1);

  useEffect(() => {
    dispatch(getSetting());
    fetchActiveVehicles(activeVehicleCallBackFunc);
  }, []);

  useEffect(() => {
    fetchActiveDrivers(activeDriversCallBackFunc);
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    if (!templates) return;
    const data = templates.map((row) => {
      return {
        value: row._id,
        label: row.templatename,
        subject: row.subject,
      };
    });
    setDDlTemplates(data);
  }, [templates]);

  const activeDriversCallBackFunc = (e_data) => {
    setActiveDrivers(e_data);
  };

  const fetchActiveDrivers = async (callback) => {
    const vt_data = await getAllDrivers();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.name,
      };
    });
    callback(data);
  };

  const activeVehicleCallBackFunc = (e_data) => {
    setActiveVehicles(e_data);
  };

  const fetchActiveVehicles = async (callback) => {
    const vt_data = await getAllActiveVehicles();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.vehiclename,
      };
    });
    callback(data);
  };

  useEffect(() => {
    showLoading(true);
    dispatch(getBooking(match.params.id));
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setcheckChargeLoading(true);
        const bookingsData = await getBookingsByCartID(booking.cartID);
        console.log(bookingsData);
        setBookingsWithCartIdLength(bookingsData.length);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      } finally {
        setcheckChargeLoading(false);
      }
    };

    if (booking?.cartID) {
      fetchBookings();
    }
  }, [booking]);

  useEffect(() => {
    if (!loading && booking !== null) {
      booking.bookingInfo.map((val, ind) => {
        var newArrayDriver;
        var newArray;
        if (typeof val?.driver !== "undefined" || val?.driver != null) {
          const d_opt = activeDrivers.find(
            (opd) => opd.value === val?.driver?._id
          );
          newArrayDriver = driverSelectedOption;
          newArrayDriver[ind] = d_opt;
          setDriverSelectedOption(newArrayDriver);
        } else {
          newArrayDriver = driverSelectedOption;
          newArrayDriver[ind] = null;
          setDriverSelectedOption(newArrayDriver);
        }

        if (typeof val?.vehicle !== "undefined" || val?.vehicle != null) {
          const opt = activeVehicles.find(
            (op) => op.label === val?.vehicle.vehiclename
          );
          newArray = selectedOption;
          newArray[ind] = opt;
          setSelectedOption(newArray);
        } else {
          newArray = selectedOption;
          newArray[ind] = null;
          setSelectedOption(newArray);
        }
      });

      var bkStatus =
        loading || !booking?.bookingStatus ? "" : booking?.bookingStatus;
      const bk_opt = bookingStatusOptions.find((op) => op.label === bkStatus);
      setBookingStatus(bk_opt);
      switch (bkStatus) {
        case "CONFIRMED":
          setStyle("badge bg-success py-2");
          break;
        case "PAYMENT_PENDING":
          setStyle("badge bg-primary py-2");
          break;
        case "INQUIRY":
          setStyle("badge bg-warning py-2");
          break;
        case "CANCELLED":
          setStyle("badge bg-danger py-2");
          break;
        default:
          setStyle("badge bg-dark py-2");
          break;
      }

      setLabelData({
        _id: loading || !booking?._id ? "" : booking?._id,
        isAbandoned:
          loading || !booking?.isAbandoned ? "" : booking?.isAbandoned,
        cartID: loading || !booking?.cartID ? "" : booking?.cartID,
        stripeRef: loading || !booking?.stripeRef ? "" : booking?.stripeRef,
        byTheHourBook:
          loading || !booking?.byTheHourBook ? false : booking?.byTheHourBook,
        isAirportTransfer:
          loading || !booking?.isAirportTransfer
            ? false
            : booking?.isAirportTransfer,
        paymentStatus:
          loading || !booking?.paymentStatus ? "" : booking?.paymentStatus,
        name: loading || !booking?.customer.name ? "" : booking?.customer.name,
        email:
          loading || !booking?.customer.email ? "" : booking?.customer.email,
        phone:
          loading || !booking?.customer.phone ? "" : booking?.customer.phone,
        package:
          loading || !booking?.activePackage ? "" : booking?.activePackage,
        dropoffFlightNo:
          loading || !booking?.dropoffFlightNo ? "" : booking?.dropoffFlightNo,
        pickupFlightNo:
          loading || !booking?.pickupFlightNo ? "" : booking?.pickupFlightNo,
        isFromAirport:
          loading || !booking?.isFromAirport ? "" : booking?.isFromAirport,
        rate: loading || !booking?.rate ? "" : booking?.rate,
        pickuptime: loading || !booking?.pickuptime ? "" : booking?.pickuptime,
        dropofftime:
          loading || !booking?.dropofftime ? "" : booking?.dropofftime,
        travelDate:
          loading || !booking?.travelDate
            ? ""
            : moment(booking?.travelDate).format("YYYY/MM/DD hh:mm A"),
        cutomerDropOffTimeWithAdditinalTime:
          loading ||
            !booking?.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime
            ? ""
            : moment(
              booking?.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime
            ).format("YYYY/MM/DD hh:mm A"),
        dropoffDatetime:
          loading || !booking?.dropoffDatetime
            ? ""
            : moment(booking?.dropoffDatetime).format("YYYY/MM/DD hh:mm A"),
        userAddedDropoffDatetime:
          loading || !booking?.userAddedDropoffDatetime
            ? ""
            : moment(booking?.userAddedDropoffDatetime).format(
              "YYYY/MM/DD hh: Amm"
            ),
        pickupLocation:
          loading || !booking?.pickupLocation
            ? ""
            : booking?.pickupLocation.label,
        lat:
          loading || !booking?.pickupLocation ? "" : booking?.pickupLocation.lat,
        lon:
          loading || !booking?.pickupLocation ? "" : booking?.pickupLocation.lon,
        destinations:
          loading || !booking?.destinations ? null : booking?.destinations,
        estimate: loading || !booking?.estimate ? "" : booking?.estimate,
        totalDistance:
          loading || !booking?.totalDistance ? "" : booking?.totalDistance,
        bookingStatus:
          loading || !booking?.bookingStatus ? "" : booking?.bookingStatus,

        pax: loading || !booking?.pax ? "" : booking?.pax,
        message: loading || !booking?.message ? "" : booking?.message,
        luggage: loading || !booking?.luggage ? "" : booking?.luggage,
        bookingInfo:
          loading || !booking?.bookingInfo ? "" : booking?.bookingInfo,
        totalPrice: loading || !booking?.totalPrice ? "" : booking?.totalPrice,
        meetAssist:
          loading || !booking?.totalPrice
            ? [
              {
                meetAssistName: "none",
                meetAssistType: "arrival",
                meetAssistPrice: 0,
              },
              {
                meetAssistName: "none",
                meetAssistType: "departure",
                meetAssistPrice: 0,
              },
            ]
            : booking?.meetAssist,

        flywireResponse:
          loading || !booking?.flywireResponse ? "" : booking?.flywireResponse,
        flywireToken:
          loading || !booking?.flywireToken ? "" : booking?.flywireToken,
        flywireChargeResponse:
          loading || !booking?.flywireChargeResponse
            ? ""
            : booking?.flywireChargeResponse,
        flywireChargeReference:
          loading || !booking?.flywireChargeReference
            ? ""
            : booking?.flywireChargeReference,
        flywireChargeWithCart:
          loading || !booking?.flywireChargeWithCart
            ? ""
            : booking?.flywireChargeWithCart,
        requestChangeBookingData:
          loading || !booking?.requestChangeBookingData
            ? ""
            : booking?.requestChangeBookingData,
        passengerName:
          loading || !booking?.passengerName ? "" : booking?.passengerName,
        passengerPronounced:
          loading || !booking?.passenger_pronounced
            ? ""
            : booking?.passenger_pronounced,
        customer: loading || !booking?.customer ? "" : booking?.customer,
      });

      const isVehicleControlerDisabled =
        booking?.bookingStatus === "INQUIRY" ||
          booking?.bookingStatus === "PAYMENT_PENDING" ||
          booking?.bookingStatus === "CANCELLED"
          ? true
          : false;

      if (booking?.cartID !== null) dispatch(getCart(booking?.cartID));

      setIsDisabled(isVehicleControlerDisabled);
      closeLoading(true);
    }
  }, [booking]);

  const addNotes = () => {
    showLoading(true);
    if (note !== "") {
      const note_arr = [{ note: note, isAdmin: true }];

      dispatch(addNote(labelData._id, note_arr));
    }
    closeLoading(true);
    setNote("");
  };

  const updateBookingDriver = async (index = 0) => {
    showLoading(true);
    console.log(driverSelectedOption);
    if (driverSelectedOption[index] !== null)
      dispatch(
        updateDriver(labelData._id, driverSelectedOption[index].value, index)
      );
    closeLoading(true);
  };

  const updateBooking = async () => {
    showLoading(true);
    // get available vehicles

    if (labelData.bookingStatus !== bookingStatus.label) {
      if (bookingStatus.label === "CONFIRMED") {
        const available_vehicles = await getAvailableVehicles(
          moment(labelData.tripStartTime),
          moment(labelData.garageArivalTimeWithAdditionalTime),
          labelData.vehicleTypeName
        );
        if (available_vehicles) {
          const available_vehicle_id = available_vehicles[0]._id;
          dispatch(
            updateBookingStatus(
              labelData._id,
              bookingStatus.label,
              available_vehicle_id
            )
          );
        } else {
          dispatch(
            setAlert("Vehicles are not available for the date.", "danger")
          );
        }
      } else if (
        bookingStatus.label === "CANCELLED" ||
        bookingStatus.label === "INQUIRY" ||
        bookingStatus.label === "PAYMENT_PENDING"
      ) {
        dispatch(updateBookingStatus(labelData._id, bookingStatus.label, null));
      }
    }
    closeLoading(true);
  };

  const sendMailToClient = async () => {
    showLoading(true);

    const mailRes = await sendMail(
      labelData.email,
      "Your booking has been cancelled",
      getBookingCancelEmailTemplate(labelData)
    );

    if (mailRes.msg === "success") {
      dispatch(
        setAlert("Booking cancelation email sent to the customer.", "success")
      );
    } else {
      dispatch(setAlert("email is not sent", "error"));
    }
    closeLoading(true);
  };

  const sendCustomMailToClient = async () => {
    showLoading(true);
    const selectedTemplate = await getTemplate(ddltemplateOption.value);
    var htmltemp = selectedTemplate.htmlemail;
    if (htmltemp.includes("{{pickuplocation}}"))
      htmltemp = htmltemp.replace(
        "{{pickuplocation}}",
        booking.pickupLocation.label
      );
    if (htmltemp.includes("{{dropofflocation}}"))
      htmltemp = htmltemp.replace(
        "{{dropofflocation}}",
        booking.destinations
          .map((d) => {
            return d.label;
          })
          .join(" / ")
      );
    if (htmltemp.includes("{{drivername}}"))
      htmltemp = htmltemp.replace(
        "{{drivername}}",
        booking.driver ? booking.driver?.name : "N/A"
      );
    if (htmltemp.includes("{{vehiclename}}"))
      htmltemp = htmltemp.replace(
        "{{vehiclename}}",
        booking.vehicle.vehiclename
      );
    if (htmltemp.includes("{{vehicletype}}"))
      htmltemp = htmltemp.replace(
        "{{vehicletype}}",
        booking.vehicle.vehicletype
      );
    if (htmltemp.includes("{{pickuptime}}"))
      htmltemp = htmltemp.replace(
        "{{pickuptime}}",
        moment(booking.travelDate).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{dropofftime}}"))
      htmltemp = htmltemp.replace(
        "{{dropofftime}}",
        moment(
          booking.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime
        ).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{customername}}"))
      htmltemp = htmltemp.replace("{{customername}}", booking.customer.name);
    if (htmltemp.includes("{{cart}}"))
      htmltemp = htmltemp.replace("{{cart}}", getAdminCart());
    if (htmltemp.includes("{{packagename}}"))
      htmltemp = htmltemp.replace(
        "{{packagename}}",
        booking.activePackage ? booking.activePackage?.packagename : "N/A"
      );
    if (htmltemp.includes("{{totalcost}}"))
      htmltemp = htmltemp.replace(
        "{{totalcost}}",
        booking.bookingAmountInfo.total
      );

    if (htmltemp.includes("{{dropoffFlightno}}"))
      htmltemp = htmltemp.replace(
        "{{dropoffFlightno}}",
        booking.dropoffFlightNo
      );
    if (htmltemp.includes("{{pickupFlightno}}"))
      htmltemp = htmltemp.replace("{{pickupFlightno}}", booking.pickupFlightNo);
    if (htmltemp.includes("{{vehicleregistration}}"))
      htmltemp = htmltemp.replace(
        "{{vehicleregistration}}",
        booking.vehicle?.registration
      );
    setUpdatedHtmlstate(htmltemp);
    closeLoading(true);
  };

  useEffect(() => {
    if (updatedHtmlstate) sendUpdatedEmailTemplate();
  }, [updatedHtmlstate]);

  const sendUpdatedEmailTemplate = async () => {
    await sendMail(labelData.email, subject, updatedHtmlstate);
    dispatch(setAlert("Email has been sent.", "success"));
  };

  const getAdminCart = () => {
    var crt = "";

    return crt;
  };

  const chargeTokenHandler = async (id, byCartId = false) => {
    console.log(byCartId);
    showLoading(true);
    if (byCartId) {
      const chargeTokenByCartIdRes = await chargeTokenByCartId(id);
      closeLoading(true);

      if (chargeTokenByCartIdRes.data.status === "success") {
        window.location.reload();
      } else {
        console.log(chargeTokenByCartIdRes.data);
      }
    } else {
      const chargeTokenRes = await chargeToken(id);
      closeLoading(true);

      if (chargeTokenRes.data.status === "success") {
        window.location.reload();
      } else {
        console.log(chargeTokenRes.data);
      }
    }
  };

  const chargeTokenWithRequestedChangesHandler = async (id) => {
    showLoading(true);
    const chargeTokenRes = await chargeTokenWithRequestedChanges(id);
    closeLoading(true);

    if (chargeTokenRes.data.status === "success") {
      window.location.reload();
    } else {
      console.log(chargeTokenRes.data);
    }
  };

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [modalConfirmationText, setModalConfirmationText] = useState("");
  const [continueAction, setContinueAction] = useState(() => () => {});


  // Open the confirmation modal and set the message
  const handleOpenModalConfirmation = (text, action) => {
    setModalConfirmationText(text);
    setContinueAction(() => action); // Set the continue action
    setShowModalConfirmation(true);
  };

  // Close the confirmation modal
  const handleCloseModalConfirmation = () => {
    setShowModalConfirmation(false);
  };

  // Function to handle the continuation action
  const handleContinue = () => {
    continueAction(); // Execute the stored continue action
    handleCloseModalConfirmation(); // Close the modal after action
  };


  return (
    <Layout>
      <ModalConfirmation handleClose={handleCloseModalConfirmation} show={showModalConfirmation} text={modalConfirmationText} handleContinue={handleContinue} />
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar
          title={!labelData.isAbandoned ? `Manage Booking` : `Abandoned cart`}
        />
        {!loading && booking != null && (
          <Fragment>
            <div className="container-fluid">
              <div className="row">
                <div className="row mb-3">
                  <div className="col">
                    <Link
                      className="btn btn-secondary"
                      to={
                        booking.isAbandoned
                          ? `/admin/bookinglist`
                          : `/admin/abandentcartlist`
                      }
                    >
                      <i
                        className="fa fa-arrow-left fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Back To List
                    </Link>
                  </div>
                </div>
                {/* <Alert /> */}
                <div className="col">
                  <div className="card mb-3 shadow-sm">
                    <div className="card-header">CUSTOMER INFORMATION</div>
                    <div className="card-body pt-4 px-4">
                      {/* DESKTOP */}
                      <div className="">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col">
                                <p className="fs-14">Passenger Information</p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-md-3">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Prefix
                                </p>
                                <b className="fs-14">
                                  {booking.passenger_pronounced}
                                </b>
                              </div>
                              <hr className="divider mt-2 d-block d-md-none" />

                              <div className="col-6 col-md-3">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Passenger's Name
                                </p>
                                <b className="fs-14">{booking.passengerName}</b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Passengers
                                </p>
                                <b className="fs-14">
                                  Adult: {booking.pax[0]?.paxcount}
                                </b>
                                <br />
                                <b className="fs-14">
                                  Child: {booking.pax[1]?.paxcount} / Toddles:{" "}
                                  {booking.pax[2]?.paxcount} / Infant:{" "}
                                  {booking?.pax[3]?.paxcount}
                                </b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Luggages
                                </p>
                                <b className="fs-14">
                                  Check-in Size :{" "}
                                  {booking?.luggage[0]?.luggagecount}
                                </b>
                                <br />
                                <b className="fs-14">
                                  CABIN Size :{" "}
                                  {booking?.luggage[1]?.luggagecount}
                                </b>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col">
                                <p className="fs-14">Billing Information</p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-md-1">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Prefix
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.pronounced}
                                </b>
                              </div>

                              <div className="col-6 col-md-2">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Reservation Holder
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.name}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Phone Number
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.phone}
                                </b>
                              </div>

                              <div className="col-6 col-md-3 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Email
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.email}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Country
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.country}
                                </b>
                              </div>

                              <div className="col-6 col-md-2 ">
                                <p className="m-0 fw-bold text-grey-200 ">
                                  Postcode
                                </p>
                                <b className="fs-14">
                                  {booking.customer?.postal_code}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      Booking Information{" "}
                      <span
                        style={{
                          border:
                            "1px solid #" + booking.cartID.substring(0, 6),
                          borderRadius: "5px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Cart ID : {booking.cartID} | {booking.paymentStatus}
                      </span>
                    </div>
                    <div className="card-body text-dark">
                      <div className="row">
                        <div className="col mb-2">
                          <h6 className="card-title">Booking Type</h6>
                          <p
                            className={`card-text py-1 ${booking.byTheHourBook
                                ? "badge bg-secondary py-2"
                                : booking.isAirportTransfer
                                  ? "badge bg-light py-2 text-dark"
                                  : "badge bg-dark py-2"
                              }`}
                          >
                            {booking.byTheHourBook
                              ? "By The Hour"
                              : booking.isAirportTransfer
                                ? "Aiport Transfer"
                                : "Specified Destination"}
                          </p>
                          <h6 className="card-title">Current Status</h6>
                          <p className={`card-text py-1 ${style}`}>
                            {booking.bookingStatus}
                          </p>
                          <h6 className="card-title">Total Distance</h6>
                          <p className="card-text py-1 text-start">
                            {booking.totalDistance / 1000} KM
                          </p>
                          <h6 className="card-title">Pickup Time</h6>
                          <p className="card-text py-1 mb-2">
                            {booking.travelDate}
                          </p>
                          <h6 className="card-title">Dropoff Time</h6>
                          <p className="card-text py-1 mb-2">
                            {moment(
                              booking?.bookingInfo[0]
                                ?.cutomerDropOffTimeWithAdditinalTime
                            ).format("YYYY/MM/DD hh:mm A")}
                          </p>
                          <h6 className="card-title">Total Hours Usage</h6>
                          <p className="card-text py-1">
                            {booking.bookingInfo[0].totalhoursUsage}
                          </p>
                        </div>
                        <div className="col">
                          <div className="row">
                            <h6 className="card-title">Update Status</h6>

                            <div className="d-flex justify-content-start">
                              <div className="col">
                                <Select
                                  classNamePrefix="select pick-up location"
                                  autoComplete="off"
                                  value={bookingStatus}
                                  onChange={(e) => {
                                    setBookingStatus(e);
                                  }}
                                  options={bookingStatusOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col ps-2 pt-3">
                              <button
                                // type="submit"
                                disabled={booking.isAbandoned}
                                className="btn btn-secondary ms-1"
                                onClick={updateBooking}
                              >
                                <i
                                  className="fa fa-wrench fa-fw"
                                  aria-hidden="true"
                                ></i>{" "}
                                Update
                              </button>

                              {booking.bookingStatus === "CANCELLED" ? (
                                <button
                                  disabled={booking.isAbandoned}
                                  onClick={sendMailToClient}
                                  className="btn btn-secondary ms-1"
                                >
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Send
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title mt-4">
                                Calculated dispatch from Garage
                              </h6>
                              <p className="card-text py-1">
                                {moment(
                                  booking.bookingInfo[0].tripStartTime
                                ).format("YYYY/MM/DD hh:mm A")}
                              </p>
                              <h6 className="card-title">
                                Calculated arrival at Garage
                              </h6>
                              <p className="card-text py-1">
                                {moment(
                                  booking.bookingInfo[0]
                                    .garageArivalTimeWithAdditionalTime
                                ).format("YYYY/MM/DD hh:mm A")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="card-title">Pickup Location</h6>
                      <p className="card-text py-1">
                        {booking.pickupLocation.label}
                      </p>

                      <h6 className="card-title">Destinations</h6>
                      {booking.destinations ? (
                        booking.destinations.map((d) => (
                          <p key={d._id} className="card-text py-1">
                            {d.label}
                          </p>
                        ))
                      ) : (
                        <h4>No destinations found...</h4>
                      )}

                      {booking.stripeRef && (
                        <>
                          <h6 className="card-title">Stripe Ref</h6>
                          <p>
                            {booking.stripeRef || "No Stripe Reference found"}
                          </p>
                        </>
                      )}

                      {booking.flywireResponse && (
                        <>
                          <h6 className="card-title">Flywire Token</h6>
                          <p>{booking.flywireToken || "No Token found"}</p>
                        </>
                      )}

                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Notes</h6>
                          <p className="card-text py-1">
                            {booking.message ? (
                              booking.message.map((d) => (
                                <p key={d._id} className="card-text py-1">
                                  {moment(d.date).format("YYYY/MM/DD hh:mm A")}
                                  {" (" +
                                    (d.isAdmin ? "admin" : "customer") +
                                    ")"}
                                  {" :  "}
                                  {d.note}
                                </p>
                              ))
                            ) : (
                              <h4>No Luggage found...</h4>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="label mb-2">Note :</label>
                            <div className="col">
                              <textarea
                                className={`form-control form-control-admin`}
                                rows="2"
                                placeholder="Text here..."
                                name="note"
                                value={note}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-secondary ms-1"
                            onClick={addNotes}
                          >
                            <i
                              className="fa fa-wrench fa-fw"
                              aria-hidden="true"
                            ></i>{" "}
                            Add Note
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      {" "}
                      <Alert />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  {booking.requestChangeBookingData && (
                    <div className="card mb-3">
                      <div className="card-header">
                        Request Change Booking Information
                      </div>
                      <div className="card-body text-dark">
                        <div className="row">
                          <div className="col mb-2">
                            <h6 className="card-title">
                              Current Request Status
                            </h6>
                            <p
                              className={`card-text py-1 ${booking.requestChangeBookingData
                                  .approvalStatus === 0
                                  ? "badge bg-warning py-2"
                                  : booking.requestChangeBookingData
                                    .approvalStatus === 1
                                    ? "badge bg-success py-2"
                                    : "badge bg-danger py-2"
                                }`}
                            >
                              {booking.requestChangeBookingData
                                .approvalStatus === 0
                                ? "Waiting for Approval"
                                : booking.requestChangeBookingData
                                  .approvalStatus === 1
                                  ? "Approved"
                                  : "Rejected"}
                            </p>
                            <h6 className="card-title">Pickup Time</h6>
                            <p className="card-text py-1 mb-2">
                              {moment(
                                booking.requestChangeBookingData
                                  .travelDateRequest
                              ).format("YYYY/MM/DD hh:mm A")}
                            </p>
                            <h6 className="card-title">Dropoff Time</h6>
                            <p className="card-text py-1 mb-2">
                              {moment(
                                booking.requestChangeBookingData
                                  .dropoffTimeRequest
                              ).format("YYYY/MM/DD hh:mm A")}
                            </p>
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <h6 className="card-title mt-4">
                                  Calculated dispatch from Garage
                                </h6>
                                <p className="card-text py-1">
                                  {moment(
                                    booking.requestChangeBookingData
                                      .startFromGarage
                                  ).format("YYYY/MM/DD hh:mm A")}
                                </p>
                                <h6 className="card-title">
                                  Calculated arrival at Garage
                                </h6>
                                <p className="card-text py-1">
                                  {moment(
                                    booking.requestChangeBookingData
                                      .arrivalAtGarage
                                  ).format("YYYY/MM/DD hh:mm A")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {booking.requestChangeBookingData
                          .pickupLocationRequest && (
                            <>
                              <h6 className="card-title">Pickup Location</h6>
                              <p className="card-text py-1">
                                {
                                  booking.requestChangeBookingData
                                    .pickupLocationRequest.formatted_address
                                }
                              </p>
                            </>
                          )}

                        <h6 className="card-title">Destinations</h6>
                        {booking.requestChangeBookingData
                          .destinationsRequest ? (
                          booking.requestChangeBookingData.destinationsRequest.map(
                            (d) => (
                              <p key={d._id} className="card-text py-1">
                                {d.label}
                              </p>
                            )
                          )
                        ) : (
                          <h4>No destinations found...</h4>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="card mb-3">
                    <div className="card-header">
                      Price and Route Information
                    </div>
                    <div className="card-body">
                      {booking.bookingInfo.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">Vehicle Type {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.vehicleTypeName}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Base Price {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.baseprice} JPY
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">30 Minutes Rate {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.hourlyprice} JPY
                                </p>
                              </div>
                            </div>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">Total Hours {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.totalhours}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Night Zone Hours {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.zoneHours}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Toll Fare {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.tollFare} JPY
                                </p>
                              </div>
                              {booking.requestChangeBookingData &&
                                booking.requestChangeBookingData
                                  .approvalStatus === 0 && (
                                  <div className="col">
                                    <h6 className="card-title">
                                      Toll Fare {index + 1} After Changes
                                    </h6>
                                    <p className="card-text py-1">
                                      {
                                        booking.requestChangeBookingData
                                          .tollFare
                                      }{" "}
                                      JPY
                                    </p>
                                  </div>
                                )}
                            </div>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">User Added Estimate {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.estimate || "--"} JPY
                                </p>
                              </div>

                              {booking.byTheHourBook && (
                                <>
                                  <div className="col">
                                    <h6 className="card-title">Selected Travel Area {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                    {item.travelArea === "withinCity" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        Within the city
                                      </p>
                                    )}
                                    {item.travelArea === "neighboringPerfecture" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        To the neighboring city
                                      </p>
                                    )}
                                    {item.travelArea === "undecided" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        Undecided
                                      </p>
                                    )}
                                  </div>

                                  <div className="col">
                                    <h6 className="card-title">Travel Area Price {(booking.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                    <p className="card-text py-1">
                                      {item.travelAreaPrice || "--"} JPY
                                    </p>
                                  </div>
                                </>
                              )}

                            </div>
                          </div>
                        );
                      })}
                      <hr className="hr" />
                      <div className="row mt-4">
                        <div className="col">
                          <h6 className="card-title">Activated Package</h6>
                          {booking.package !== null ? (
                            <div className="card-text py-1 py-1 mb-1">
                              <span>{booking.activePackage?.packagename} </span>
                            </div>
                          ) : (
                            <div className="card-text py-1 p-1 mb-1">
                              No package available
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <h6 className="card-title">Dropoff Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {booking.dropoffFlightNo}
                          </p>
                        </div>
                        <div className="col">
                          <h6 className="card-title">Pickup Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {booking.pickupFlightNo}
                          </p>
                        </div>
                        <div className="col">
                          <h6 className="card-title">Package Flat Fee</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark">
                            {booking.package?.vehicles?.find(
                              (v) => v.vtype === booking.vehicleTypeName
                            )?.vehicleCost ?? "--"}{" "}
                            JPY
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Meet Service</h6>
                          {booking?.meetAssist &&
                            booking.meetAssist[0].meetAssistName ===
                            "meetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                Meet & Assist (Arrival)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[0].meetAssistName ===
                            "vipMeetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                VIP Meet & Assist (Arrival)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[1].meetAssistName ===
                            "meetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                Meet & Assist (Departure)
                              </p>
                            )}
                          {booking?.meetAssist &&
                            booking.meetAssist[1].meetAssistName ===
                            "vipMeetAssist" && (
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                VIP Meet & Assist (Departure)
                              </p>
                            )}
                        </div>
                        <div className="col">
                          <h6 className="card-title">Pickup Sign</h6>
                          {booking?.pickupSign != null
                            ? booking?.pickupSign
                            : "-"}
                        </div>
                        <div className="col">
                          <h6 className="card-title">Total booking cost</h6>
                          <p className="card-text py-1 py-1 mb-1 text-success">
                            {booking.totalPrice} JPY
                          </p>
                        </div>
                        {booking.requestChangeBookingData &&
                          booking.requestChangeBookingData.approvalStatus ===
                          0 && (
                            <div className="col">
                              <h6 className="card-title">
                                Total booking cost After Changes
                              </h6>
                              <p className="card-text py-1 py-1 mb-1 text-success">
                                {booking.requestChangeBookingData.totalPrice}{" "}
                                JPY
                              </p>
                            </div>
                          )}
                      </div>
                      <hr className="hr" />
                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">
                            Charge Status -{" "}
                            <p
                              className={`card-text py-1 ${(booking.paymentStatus === "CHARGE_PENDING") &&
                                  !booking.flywireChargeReference
                                  ? "badge bg-warning py-2"
                                  : booking.paymentStatus === "CHARGED" &&
                                    booking.flywireChargeReference
                                    ? "badge bg-success py-2"
                                    : ""
                                }`}
                            >
                              {(booking.paymentStatus === "CHARGE_PENDING") &&
                                !booking.flywireChargeReference
                                ? "CHARGE_PENDING"
                                : booking.paymentStatus === "CHARGED" &&
                                  booking.flywireChargeReference
                                  ? "Success"
                                  : ""}
                            </p>
                          </h6>
                        </div>
                        {booking.flywireChargeResponse && (
                          <div className="col">
                            <h6 className="card-title">Reference</h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {booking.flywireChargeResponse.reference}
                            </p>
                          </div>
                        )}
                      </div>
                      {booking.flywireChargeResponse && (
                        <div className="row">
                          <div className="col">
                            <h6 className="card-title">Payer Amount</h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {`${booking.flywireChargeResponse.payerAmount} ${booking.flywireChargeWithCart
                                  ? "- With the whole cart"
                                  : ""
                                }`}
                            </p>
                          </div>
                          <div className="col">
                            <h6 className="card-title">
                              Payer Amount Currency
                            </h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {
                                booking.flywireChargeResponse
                                  .payerAmountCurrency
                              }
                            </p>
                          </div>
                          <div className="col">
                            <h6 className="card-title">Amount</h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {`${booking.flywireChargeResponse.amount} ${booking.flywireChargeWithCart
                                  ? "- With the whole cart"
                                  : ""
                                }`}
                            </p>
                          </div>
                          <div className="col">
                            <h6 className="card-title">Amount Currency</h6>
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              {booking.flywireChargeResponse.amountCurrency}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="d-flex mt-3">
                        {checkChargeLoading ? (
                          <>
                            <span
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp; Checking charge options...
                          </>
                        ) : (
                          <>
                            <button
                              disabled={
                                booking.isAbandoned ||
                                !booking.flywireToken ||
                                booking.bookingStatus !== "CONFIRMED" ||
                                booking.paymentStatus !== "CHARGE_PENDING"
                              }
                              className="btn btn-secondary ms-1"
                              onClick={() => {
                                // chargeTokenHandler(booking._id);
                                handleOpenModalConfirmation(
                                  "Are you sure want to charge this booking?",
                                  () => chargeTokenHandler(booking._id, false)
                                );
                              }}
                            >
                              <i
                                className="fa fa-money fa-fw"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              {booking.paymentStatus === "CHARGE_PENDING" &&
                                !booking.flywireChargeReference
                                ? "Charge!"
                                : booking.paymentStatus === "CHARGED" &&
                                  booking.flywireChargeReference
                                  ? "Success Charged"
                                  : ""}
                            </button>
                            {bookingsWithCartIdLength > 1 && (
                              booking.paymentStatus !== "CHARGED" && (
                                <button
                                  disabled={
                                    booking.isAbandoned ||
                                    !booking.flywireToken ||
                                    booking.bookingStatus !== "CONFIRMED" ||
                                    booking.paymentStatus !== "CHARGE_PENDING"
                                  }
                                  className="btn btn-secondary ms-1"
                                  onClick={() => {
                                    handleOpenModalConfirmation(
                                      "Are you sure want to charge the whole cart?",
                                      () => chargeTokenHandler(booking.cartID, true)
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-money fa-fw"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;
                                  {booking.paymentStatus === "CHARGE_PENDING" &&
                                    !booking.flywireChargeReference
                                    ? "Charge the Whole Cart!"
                                    : ""}
                                </button>
                              )
                            )}
                          </>
                        )}

                        {booking.paymentStatus === "CHARGE_PENDING" &&
                          booking.requestChangeBookingData &&
                          booking.requestChangeBookingData.currentStatus ===
                          "CHARGE_PENDING" && (
                            <button
                              disabled={
                                booking.isAbandoned ||
                                !booking.flywireToken ||
                                booking.bookingStatus !== "CONFIRMED" ||
                                booking.paymentStatus !== "CHARGE_PENDING"
                              }
                              className="btn btn-secondary ms-1"
                              onClick={() => {
                                chargeTokenWithRequestedChangesHandler(
                                  booking._id
                                );
                              }}
                            >
                              <i
                                className="fa fa-checklist"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Charge Booking and Approve Requested
                              Changes!
                            </button>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    {/* <div className="card-header">Email Template</div> */}
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-4">
                          <h6 className="card-title">Template Name</h6>
                        </div>
                        <div className="col">
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="select pick-up location"
                            value={ddltemplateOption}
                            onChange={(e) => {
                              setSubject(e.subject);
                              setddlTemplateOption(e);
                            }}
                            options={ddltemplate}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <h6 className="card-title">Email Subject</h6>
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col ps-2">
                          <div className="mt-3 ps-0">
                            <button
                              disabled={booking.isAbandoned}
                              onClick={sendCustomMailToClient}
                              className="btn btn-secondary ms-1"
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Send Email
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`card mb-3`}>
                    <div className="card-header">Assigned vehicle</div>
                    {booking.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body" key={index}>
                          <div className="row mb-3">
                            <div className="col-4">
                              <h6 className="card-title">
                                Vehicle Name {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {isDisabled ? (
                                  <span className="badge bg-info">
                                    Vehicle not assigned.
                                  </span>
                                ) : (
                                  item.vehicle.vehiclename
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Vehicle Type {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item.vehicle.vehicletype}
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Registration {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item.vehicle.registration}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Change Vehicle {index + 1}
                              </h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    isDisabled={isDisabled}
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={selectedOption[index]}
                                    onChange={(e) => {
                                      selectedOption[index] = e;
                                      // setSelectedOption(newArr);
                                    }}
                                    options={activeVehicles}
                                  />
                                </div>
                                <button
                                  disabled={isDisabled}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={(e) => {
                                    setSelectedOption((selectedOption) => ({
                                      ...selectedOption,
                                      [index]: e,
                                    }));
                                  }}
                                >
                                  <i
                                    className="fa fa-wrench fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Driver Information</div>
                    {booking.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body" key={index}>
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Driver Name {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.name !== undefined ? (
                                  item?.driver?.name
                                ) : (
                                  <span className="badge bg-info">
                                    Driver not assigned.
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">
                                Driver Email {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.email}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">
                                Driver Phone {index + 1}
                              </h6>
                              <p className="card-text py-1">
                                {item?.driver?.phone}
                              </p>
                            </div>
                            <div className="col-7">
                              <h6 className="card-title">
                                Update Driver {index + 1}
                              </h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={driverSelectedOption[index]}
                                    onChange={(e) => {
                                      setDriverSelectedOption(
                                        (driverSelectedOption) => ({
                                          ...driverSelectedOption,
                                          [index]: e,
                                        })
                                      );
                                    }}
                                    options={activeDrivers}
                                  />
                                </div>
                                <button
                                  disabled={booking.isAbandoned}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={() => updateBookingDriver(index)}
                                >
                                  <i
                                    className="fa fa-plus-circle fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>

          //test
          // <>
          // <p>{loading ? "TRUE" : "FALSE"}</p>
          // <p>{booking?.pax[0]?.paxcount}</p>

          // </>
        )}
      </main>
    </Layout>
  );
};

export default ManageBooking;
