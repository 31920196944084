/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import VehicleTypeCard from "./VehicleTypeCard";
import PassengerCard from "./PassengerCard";
import { FaUsers, FaSuitcaseRolling } from "react-icons/fa";
import pax_icon from "../../../icons/form-icons/pax.svg";
import luggage_icon from "../../../icons/form-icons/luggage.svg";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";

const CarSelection = ({
  //to vehicleTypeCard - start
  vehicleTypeNames,
  applyToTheEquation,
  
  //to vehicleTypeCard - end
  index,
  applyPaxAndLugageToTheEquation,
  idScroll,
  currentMaxPassenger,
  currentMaxLugage,
  clickedManyVehicleType,
  isEditBooking = false,
}) => {
  const { t, } = useTranslation();
  const [maxPassengerInput, ] = useState(0);
  const [lugageVisible, setLugageVisible] = useState(false);
  const wrapperRef = useRef(null);
  const [passengerVisible, setPassangerVisible] = useState(false);
  const [pax, setPax] = useState(null);
  const [lugage, setLugage] = useState(null);
  const [totalPax, setTotalPax] = useState(0);
  const [totalLugage, setTotalLugage] = useState(0);
  const [styleSelectedVehicle, setStyleSelectedVehicle] = useState([]);
  const [clickedVt, setClickedVt] = useState(null);
  const [useModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let resultArray = [];
    for (let i = 0; i < vehicleTypeNames.length; i++) {
      resultArray.push("");
    }

    setStyleSelectedVehicle(resultArray);
  }, []);

  const setStyle = (ind) => {
    let resultArray = [];
    for (let i = 0; i < vehicleTypeNames.length; i++) {
      resultArray.push("");
    }

    resultArray[ind] = "selected-vehicle-card";

    setStyleSelectedVehicle(resultArray);
    setPax(null);
    setLugage(null);
    setTotalLugage(0);
    setTotalPax(0);
  };

  /**
   * * PAX selection popup closing function. Click anywhere outside the popup modal to close it.
   */
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassangerVisible(false);
          setLugageVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * * Show and hide pax panel / modal
   */
  const setPaxAndLuggaePannalVisibility = (status) => {
    setPassangerVisible(status);
  };

  const setLugageFormDataToState = (formData) => {
    var summedLugage = getTotalLuggageCount(formData);
    setTotalLugage(summedLugage);
    setLugage(formData);
  };

  const setPaxFormDataToState = (formData) => {
    var summedPax = getTotalPaxCount(formData);
    setTotalPax(summedPax);
    setPax(formData);
  };

  return (
    <div className="container px-md-3 mt-4 " id={idScroll}>
      <div className="row mb-3 gy-4 d-flex align-items-center justify-content-center">
        <div className="col-auto text-center">
          <h3>
            <b>{t("Discover.SelectVehicle.Multiple.Car")} #{index + 1}</b>
          </h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      <div className="row gy-4 mb-3">
        {vehicleTypeNames.map((vt, i = 0) =>
          clickedManyVehicleType.length === 0 ? (
            <VehicleTypeCard
              key={vt._id}
              index={i}
              onClick={(e) => {
                applyToTheEquation(vt, index);
                setStyle(i);
                setClickedVt(vt);
              }}
              vehicle_type={vt}
              styleSelectedVehicle={styleSelectedVehicle[i]}
              handleShowModal={handleShowModal}
              isEditBooking={isEditBooking}
              price={vt.price}
            />
          ) : (
            <VehicleTypeCard
              key={vt._id}
              onClick={(e) => {
                applyToTheEquation(vt, index);
                setStyle(i);
                setClickedVt(vt);
              }}
              vehicle_type={vt}
              styleSelectedVehicle={styleSelectedVehicle[i]}
              handleShowModal={handleShowModal}
              disabledCard={
                clickedManyVehicleType.filter((item) => item === vt.typename)
                  .length >= vt.count
              }
              isEditBooking={isEditBooking}
              price={vt.price}
            />
          )
        )}
      </div>

      <div className="row gy-4 mb-5">
        <div className="col-12">
          <div className="card h-100 custom-card-passenger d-none d-lg-block">
            <div className="card-body">
              <div className="container">
              <div ref={wrapperRef} className="row d-flex align-items-center justify-content-center">
                  <div className="col">
                    <b className="noto" style={{ fontWeight: 900 }}>
                      Please specify the number of passengers on this vehicle:
                    </b>
                  </div>
                  <div className="col-auto">
                    <div className="form-floating position-relative">
                      <input
                        name="pax"
                        type="number"
                        id="passengers"
                        placeholder="Passengers"
                        aria-label="Passengers"
                        max={maxPassengerInput}
                        value={totalPax === 0 ? "" : totalPax}
                        onClick={() => {
                          setPassangerVisible(!passengerVisible);
                          setLugageVisible(false);
                        }}
                        readOnly={true}
                        className={ "form-control form-control-icon"}
                      />
                      {passengerVisible && (
                        <div
                          className="position-absolute passanger-card-position"
                          // style={{ right: "30%" }}
                        >
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setPaxFormDataToState}
                            passengerFormData={pax}
                            passangerForm={true}
                            luggageForm={false}
                            passangerMax={currentMaxPassenger}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}

                      <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                        <div className="d-flex justify-content-center">
                          <img  alt="img" src={pax_icon}/>
                        </div>
                      </span>
                      <label for="floatingInput" className="label-icon">
                        {t("Passengers")}
                      </label>
                    </div>
                  </div>
                  
                  <div className="col-auto">
                    <div className="form-floating position-relative">
                      <input
                      max={maxPassengerInput}
                      value={totalLugage === 0 ? "" : totalLugage}
                      onClick={() => {
                        setPassangerVisible(false);
                        setLugageVisible(!lugageVisible);
                      }}
                      readOnly={true}
                        className={"form-control form-control-icon"}
                      />

                      <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                        <div className="d-flex justify-content-center">
                          <img  alt="img" src={luggage_icon}/>
                        </div>
                      </span>
                      {lugageVisible && (
                      <div className="position-absolute passanger-card-position">
                        <PassengerCard
                          setPaxPanel={setPaxAndLuggaePannalVisibility}
                          setFormDataToState={setLugageFormDataToState}
                          passangerForm={false}
                          luggageForm={true}
                          passengerFormData={lugage}
                          lugageMax={currentMaxLugage}
                          clickedVt={clickedVt}
                        />
                      </div>
                    )}
                      <label className="label-icon">{t("Luggage")}</label>
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="d-grid gap-2 d-md-block mt-auto">
                      <button
                        type="button"
                        onClick={(e) => {
                          applyPaxAndLugageToTheEquation(pax, lugage, index);
                        }}
                        className="btn btn-primary btn-sm rounded-3 px-4 d-flex align-items-center justify-content-center px-md-5 py-2"
                        style={{ height: 50 }}
                      >
                        <div className="d-flex align-items-center px-2 d-inline">
                          {t("Discover.Proceed.Multiple.Car")}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {clickedVt !== null ? (
                <div className="row d-flex align-items-center gap-4 justify-content-center mt-3">
                  <div className="container">
                    <div className="col py-2 bg-gold-light-100 rounded-3 justify-content-center text-center">
                      Maximum Luggage Capacity available for{" "}
                      {clickedVt.maxpassenger ?? "-"} passengers:{" "}
                      {clickedVt.maxluggages ?? "-"} suitcases
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {useModal ? (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block px-3"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg "
              role="document"
            >
              <div className="modal-content border-top-gold">
                <div className="modal-body px-4">
                  <div className="row d-flex justify-content-end">
                    <button
                      onClick={handleCloseModal}
                      type="button"
                      className="btn-close p-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <b className="noto" style={{ fontWeight: 900 }}>
                    Please specify the number of passengers on this vehicle:
                  </b>
                  <div className="row gap-3 mt-3">
                    <div className="col-12 form-floating">
                      <input
                        type="number"
                        name="paxPerCar"
                        className={"form-control passenger-select"}
                        placeholder="Pick-up time"
                        aria-label="Pick-up time"
                        max={maxPassengerInput}
                        value={totalPax === 0 ? "" : totalPax}
                        onClick={() => {
                          setPassangerVisible(!passengerVisible);
                          setLugageVisible(false);
                        }}
                        readOnly={true}
                      />
                      {passengerVisible && (
                        <div className="position-absolute passanger-card-position">
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setPaxFormDataToState}
                            passengerFormData={pax}
                            passangerForm={true}
                            luggageForm={false}
                            passangerMax={currentMaxPassenger}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}
                      <label htmlFor="paxPerCar">
                        <div className="d-flex align-items-center gap-2">
                          <FaSuitcaseRolling />
                          Number Of Passengers
                        </div>
                      </label>
                    </div>

                    <div className="col-12 form-floating">
                      <input
                        type="number"
                        name="paxPerCar"
                        className={"form-control passenger-select"}
                        placeholder="Pick-up time"
                        aria-label="Pick-up time"
                        max={maxPassengerInput}
                        value={totalLugage === 0 ? "" : totalLugage}
                        onClick={() => {
                          setPassangerVisible(false);
                          setLugageVisible(!lugageVisible);
                        }}
                        readOnly={true}
                      />
                      {lugageVisible && (
                        <div className="position-absolute passanger-card-position">
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setLugageFormDataToState}
                            passangerForm={false}
                            luggageForm={true}
                            passengerFormData={lugage}
                            lugageMax={currentMaxLugage}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}
                      <label htmlFor="paxPerCar">
                        <div className="d-flex align-items-center gap-2">
                          <FaUsers />
                          Number Of Lugages
                        </div>
                      </label>
                    </div>

                    <div className="col-12">
                      <div className="d-grid gap-2 d-md-block mt-auto">
                        <button
                          type="button"
                          onClick={(e) => {
                            handleCloseModal();
                            applyPaxAndLugageToTheEquation(pax, lugage, index);
                          }}
                          className="btn btn-primary btn-sm rounded-3 px-4 d-flex align-items-center justify-content-center px-md-5 py-2"
                          style={{ height: 50 }}
                        >
                          <div className="d-flex align-items-center px-2 d-inline">
                            Proceed
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CarSelection;
