
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";


export const flywireSuccessPayment = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/success-payment",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireUpdatePayment = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/update-payment",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

// charge token
export const chargeToken = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token",
            {bookingId: id},
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token eddited fee
export const chargeTokenEditedFee = async (id, editedFee) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-with-edited-fee",
            {
                bookingId: id,
                editedFee: editedFee
            },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token additional fee
export const chargeTokenAdditionalFee = async (id, additionalFee) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-additional-fee",
            {
                bookingId: id,
                additionalFee: additionalFee
            },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

export const chargeTokenByCartId = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-by-cart-id",
            {cartID: id},
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token with requested changes
export const chargeTokenWithRequestedChanges = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-with-changes",
            {bookingId: id},
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

export const chargeTokenReschedule = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-reschedule",
            {requestId: id},
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

