/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "../../../utils/customMoment";
import EditBooking from "../../front-pages-new-theme/EditBooking";
import calendar_icon from "../../../images/calendar-icon.svg";
import plus_icon from "../../../icons/plus.svg";
import {
  addToCartReduxStore,
  addBooking,
  addSpecifiedDestinationFormToState,
  addByTheHourFormToState,
} from "../../../actions/admin/booking";
import ReactTooltip from "react-tooltip";
import jwtDecode from "jwt-decode";
import NewEditBooking from "../../front-pages-new-theme/NewEditBooking";

const NewCartItemCard = ({ cart, removeItemFromCart }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [meetAssist, setMeetAssist] = useState("");
  const [allChildSeatSize, setAllChildSeatSize] = useState(0);
  const [allInfantSeatSize, setAllInfantSeatSize] = useState(0);
  const [allToddlerSeatSize, setAllToddlerSeatSize] = useState(0);
  const [editBookingForm, setEditBookingForm] = useState({});
  const [keyBook, setKeyBook] = useState(null);
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  

  //edit booking
  const [useModalEditBooking, setUseModalEditBooking] = useState(false);
  const handleCloseModalEditBooking = () => {
    setUseModalEditBooking(false);
  };
  const handleOpenModalEditBooking = () => {
    if(cart.byTheHourBook){
      dispatch(addByTheHourFormToState(editBookingForm));
      dispatch(addSpecifiedDestinationFormToState(null));
    }else{
      dispatch(addSpecifiedDestinationFormToState(editBookingForm));
      dispatch(addByTheHourFormToState(null));
    }
    setUseModalEditBooking(true);
  };

  const [inputFields, setInputFields] = useState([""]);
  const [useModalDuplicateBooking, setUseModalDuplicateBooking] =
    useState(false);
  const currentDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    if (
      cart.meetAssist[0].meetAssistName === "none" &&
      cart.meetAssist[1].meetAssistName !== "none"
    ) {
      setMeetAssist("departure");
    } else if (
      cart.meetAssist[0].meetAssistName !== "none" &&
      cart.meetAssist[1].meetAssistName === "none"
    ) {
      setMeetAssist("arrival");
    } else if (
      cart.meetAssist[0].meetAssistName !== "none" &&
      cart.meetAssist[1].meetAssistName !== "none"
    ) {
      setMeetAssist("both");
    } else if (
      cart.meetAssist[0].meetAssistName === "none" &&
      cart.meetAssist[1].meetAssistName === "none"
    ) {
      setMeetAssist("");
    }


    setKeyBook(cart.key);
    console.log("AKU INGIN LIHAT", JSON.stringify(cart));
    if (cart.byTheHourBook) {
      setEditBookingForm({
        pickuplocation: cart.pickupLocation,
        dropofflocations: cart.destinations,
        traveldate: moment(cart.travelDateWithPickupTime).format("YYYY-MM-DD"),
        pickuptime: moment(cart.travelDateWithPickupTime).format("HH:mm"),
        dropofftime: "",
        pax: {
          adult: cart.paxList[0].paxcount,
          child: cart.paxList[1].paxcount,
          toddler: cart.paxList[2].paxcount,
          baby: cart.paxList[3].paxcount,
        },
        lugage: {
          cabinSize: cart.luggagelist[0].luggagecount,
          checkinSize: cart.luggagelist[1].luggagecount,
        },
        duration: cart.duration,
        byTheHourBook: true,
        isFromAirport: false,
        isToAirport: false,
        travelArea: cart.bookingInfo[0].travelArea,
        travelAreaPrice: cart.bookingInfo[0].travelAreaPrice,
      });
    } else {
      setEditBookingForm({
        pickuplocation: cart.pickuplocation,
        dropofflocations: cart.destinations,
        traveldate: moment(cart.travelDateWithPickupTime).format("YYYY-MM-DD"),
        pickuptime: moment(cart.travelDateWithPickupTime).format("HH:mm"),
        pax: {
          adult: cart.paxList[0].paxcount,
          child: cart.paxList[1].paxcount,
          toddler: cart.paxList[2].paxcount,
          baby: cart.paxList[3].paxcount,
        },
        lugage: {
          cabinSize: cart.luggagelist[0].luggagecount,
          checkinSize: cart.luggagelist[1].luggagecount,
        },
        isAirportTransfer: cart.isAirportTransfer,
      });
    }

    cart?.bookingInfo?.map((i) => {
      setAllInfantSeatSize(allInfantSeatSize + i.childSeat[0].childSeatCount);
      setAllToddlerSeatSize(allToddlerSeatSize + i.childSeat[1].childSeatCount);
      setAllChildSeatSize(allChildSeatSize + i.childSeat[2].childSeatCount);
    });


  }, []);

  const getCountChildSeat = () => {
    const totalCount = cart?.bookingInfo?.reduce((acc, booking) => {
      return booking.childSeat.reduce((acc, seat) => {
        return acc + seat.childSeatCount;
      }, acc);
    }, 0);

    return totalCount
  }

  const getChildSeatView = () => {
    var infant = 0;
    var toddler = 0;
    var child = 0;
    cart?.bookingInfo.forEach(booking => {
      // Iterate over childSeat array within each booking
      infant += booking.childSeat[0].childSeatCount
      toddler += booking.childSeat[1].childSeatCount
      child += booking.childSeat[2].childSeatCount
    });

    return `Child Seats (${(infant > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Infant")} x${infant}` : "")} ${(toddler > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Toddler")} x${toddler}` : "")} ${(child > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Child")} x${child}` : "")})`;
  }



  const onChangeDuplicateDate = (e, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index] = e.target.value;
    setInputFields(newInputFields);
  };

  const removeDateByIndex = (indexToRemove) => {
    setInputFields(prevArray => {
      // Create a copy of the array
      const newArray = [...prevArray];
      // Remove the element at the specified index
      newArray.splice(indexToRemove, 1);
      // Update the state with the new array

      return newArray;
    });
  };

  const handleAddDate = () => {
    // if (inputFields[inputFields.length - 1] != "") {
      const newInputFields = [...inputFields, ""];
      setInputFields(newInputFields);
    // }
  };

  // Fungsi untuk rekursif menelusuri JSON dan mengubah tanggal
  const recursiveChangeDate = async (obj, newDate) => {
    // Date strings
    const dateString1 = obj.pickupDateTime;
    const dateString2 = newDate;

    // Parse the date strings using Moment.js
    const date1 = moment(dateString1).format("YYYY-MM-DD");
    const date2 = moment(dateString2).format("YYYY-MM-DD");

    // Calculate the difference in days
    const daysDifference = moment(date2).diff(date1, "days");

    const updatedCart = await changeDate(cart, obj, daysDifference);

    return updatedCart;
  };

  // Fungsi untuk mengubah hanya bagian tanggal pada objek
  const changeDate = async (cart, duplicateCart, diffDays) => {
    duplicateCart.key = Math.floor(Math.random() * 10000000000 + 1);

    const pickupDateTime = moment(cart.pickupDateTime);
    duplicateCart.pickupDateTime = pickupDateTime
      .add(diffDays, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]").toString();

    const travelDate = moment(cart.travelDate);
      duplicateCart.travelDate = travelDate
        .add(diffDays, "days")
        .format("YYYY-MM-DD").toString();

    const customerDropoffDateTime = moment(cart.customerDropoffDateTime);
    duplicateCart.customerDropoffDateTime = customerDropoffDateTime
      .add(diffDays, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]").toString();

    const startFromGarage = moment(cart.startFromGarage);
    duplicateCart.startFromGarage =
      startFromGarage
        .add(diffDays, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]").toString();;

    const garageArrivalTime = moment(cart.garageArrivalTime);
    duplicateCart.garageArrivalTime = garageArrivalTime
      .add(diffDays, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]").toString();

    
    const flightTracking = cart.flightTracking;
    duplicateCart.flightTracking = flightTracking;

    return duplicateCart;
  };

  const duplicateBooking = async () => {
    let cartArray = [];

    await Promise.all(
      inputFields.map(async (i, ind) => {
        if (i !== "") {
          // Mendefinisikan tanggal yang spesifik (25 Desember 2023)
          const specificDate = new Date(i);

          // Duplikat JSON
          const duplicatedJson = JSON.parse(JSON.stringify(cart));

          // Mengubah hanya bagian tanggal pada duplicatedJson dengan tanggal yang spesifik
          const updatedDuplicateCart = await recursiveChangeDate(
            duplicatedJson,
            specificDate
          );

          cartArray.push(updatedDuplicateCart);
        }
      })
    );

    await Promise.all(
      cartArray.map((i) => {
        addItemCartToStore(JSON.parse(JSON.stringify(cart)), i);
      })
    );

    setUseModalDuplicateBooking(false);
    setInputFields([""]);

    // const nowDateTime = moment();
    // localStorage.setItem("lastAddedBookToCart", nowDateTime);

    // window.location.reload();
  };

  /**
   * * manage cart storage
   * * local store,redux state,DB
   */
  const addItemCartToStore = async (duplicateFrom, item, storeLocalStorage = true) => {
    if (storeLocalStorage) {
      const localStoteItem = JSON.parse(localStorage.getItem("cart"));
      if (localStoteItem !== null && localStoteItem.length > 0) {
        var store_cart = JSON.parse(localStorage.getItem("cart"));
        if (store_cart[0].randomId !== item.randomId) {
          item.randomId = store_cart[0].randomId;
        }
        store_cart.push(item);
        localStorage.setItem("cart", JSON.stringify(store_cart));
        dispatch(addToCartReduxStore(store_cart));
      } else {
        var cart_array_store = [];
        cart_array_store.push(item);
        localStorage.setItem("cart", JSON.stringify(cart_array_store));
        dispatch(addToCartReduxStore(cart_array_store));
      }

      const nowDateTime = moment();
      localStorage.setItem("lastAddedBookToCart", nowDateTime);
    }
    addCartToDB(item, duplicateFrom);
  };

  const checkTokenUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedJwt = jwtDecode(token);
      if ((decodedJwt.user.id.role === "individual" || decodedJwt.user.id.role === "business") && decodedJwt.user.id.verifiedStatus === true) {
        return decodedJwt
      }
      return null
    }
    return null
  }

  /**
   * * Insert data to the database
   */
  const addCartToDB = async (item, duplicateFrom) => {
    const decodedJwtToken = await checkTokenUser();
    const cartUser = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.name : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.contactName : "cart user";
    const cartEmail = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.email : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.email : "cart@email.com";
    const cartPhone = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.phoneNumber : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.phoneNumber : "0000000000";
    const cartAddress = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.address : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.businessInformation.businessAddress : "cart address";
    const cartCountry = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.country : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.country : "Japan";
    const cartPrefixNumber = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.prefix_number : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.prefix_number : "+81";
    const cartCountryCode = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.country_code : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.country_code : "JP";
    const cartPostCode = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.postCode : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.postCode : "0000";
    const userId = decodedJwtToken !== null ? decodedJwtToken.user.id._id : "";

    dispatch(
      addBooking({
        key: item.key,
        randomId: item.randomId,
        travelDate: item.travelDate,
        pickupTime: item.pickupTime,
        pickupDateTime: item.pickupDateTime,
        customerDropoffDateTime: item.customerDropoffDateTime,
        startFromGarage: item.startFromGarage,
        garageArrivalTime: item.garageArrivalTime,
        pickupLocation: item.pickupLocation,
        destinations: item.destinations,
        totalDistance: item.totalDistance,
        garage: item.garage._id,
        pax: item.paxList,
        luggage: item.luggagelist,
        bookingType: item.bookingType,
        additionalTime: item.additionalTime,
        breakTime: item.breakTime,
        flightTrackingTime: item.flightTrackingTime,
        usageDuration: item.usageDuration,
        billedDuration: item.billedDuration,
        duration: item.bookingType === "byTheHour" ? item.duration : null,
        isAirportTransfer: item.isAirportTransfer,
        isFromAirport: item.isFromAirport,
        isToAirport: item.isToAirport,
        meetAssist: item.meetAssist,
        isManyPeople: item.isManyPeople,
        package: item.package,
        bookingInfo: item.bookingInfo,
        date: item.date,
        passengerName: item.passengerName === undefined ? "(your name)" : item.passengerName,
        passengerPronounced: item.passengerPronounced,
        noteList: item.note_arr,
        totalPrice: item.totalPrice,
        pickupFlightNo: item.pickupFlightNo,
        dropoffFlightNo: item.dropoffFlightNo,
        bookingStatus: "ADDED_TO_CART",
        userId: userId,
        name: cartUser,
        email: cartEmail,
        phone: cartPhone,
        address: cartAddress,
        country: cartCountry,
        countryCodeCust: cartCountryCode,
        prefixNumber: cartPrefixNumber,
        postCode: cartPostCode,
      })
    );
  };

  const resetDuplicateBookingForm = () => {
    setInputFields([""]);
  };

  const discardDuplicateBooking = () => {
    setInputFields([""]);
    setUseModalDuplicateBooking(false);
  };

  return (
    <>
      <div className="card shadow-sm border-top-gold">
        <div className="card-body pt-4 px-4">
          {/* DESKTOP */}
          <div className="">
            <div className="row">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-6  col-md-4">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.PassengerName")}
                    </p>
                    <b className="fs-14">{cart?.pronounced} {cart?.passengerName}</b>
                  </div>

                  <div className={cart.byTheHourBook ? 'col-5 col-md-2' : 'col-5 col-md-7' } >
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.TypeOfTransfer")}
                    </p>
                    <b className="fs-14">
                      {cart?.isAirportTransfer
                        ? t("TypeOfTransfer.AirportTransfer")
                        : cart.byTheHourBook
                          ? t("TypeOfTransfer.ByTheHour")
                          : t("TypeOfTransfer.SpecifiedDestination")}
                    </b>
                  </div>
                  {cart.byTheHourBook && <div className='col-5 col-md-2' >
                    <p className="m-0 fw-bold text-grey-200 ">
                                    {t("BookingConfirmation.Car.UsageDuration")}
                    </p>
                    <b className="fs-14">
                    {lang === "EN" ? cart?.usageDuration + " Hours" : cart?.usageDuration + "時間"} &nbsp;
                    <i
                      data-tip
                      data-for="usageDuration"
                      className="fas fa-info-circle text-primary"
                    ></i>
                    <ReactTooltip
                      id="usageDuration"
                      backgroundColor="#F6EEDC"
                      textColor="#4E4E4E"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("usageDurationTooltip"),
                        }}
                      ></span>
                    </ReactTooltip>
                    </b>
                  </div>}
                  {cart.byTheHourBook && <div className='col-5 col-md-2' >
                    <p className="m-0 fw-bold text-grey-200 ">
                                    {t("BookingConfirmation.Car.BilledDuration")}
                    </p>
                    <b className="fs-14">
                    {lang === "EN" ? cart?.billedDuration + " Hours" : cart?.billedDuration + "時間"} &nbsp;
                    <i
                      data-tip
                      data-for="billedDuration"
                      className="fas fa-info-circle text-primary"
                    ></i>
                    <ReactTooltip
                      id="billedDuration"
                      backgroundColor="#F6EEDC"
                      textColor="#4E4E4E"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("billedDurationTooltip"),
                        }}
                      ></span>
                    </ReactTooltip>
                    </b>
                  </div>}
                  <div className={cart.byTheHourBook ? 'col-2 text-end' : 'col-1 text-end' } >
                    <div className="dropdown dropdown-lg d-inline-block ms-auto">
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenu1"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="text-dark"
                      >
                        <i className="fas fa-ellipsis-h"></i>
                      </a>

                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenu1" style={{padding : "0"}}
                      >
                        {process.env.REACT_APP_EDIT_BOOKING === "true" ? (
                          <>
                            <li>
                              <a
                                className="dropdown-item text-dark py-2"
                                onClick={handleOpenModalEditBooking}
                              >
                                Edit Booking
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider p-0 m-0" />
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        <li>
                          <a
                            onClick={() => setUseModalDuplicateBooking(true)}
                            className="dropdown-item text-dark py-2"
                          >
                            {t("NewCart.DuplicateBooking.Title")}
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider p-0 m-0" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-dark py-2"
                            onClick={() => {
                              removeItemFromCart(cart);
                            }}
                          >
                            {t("NewCart.RemoveBooking.Title")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr className="divider" />
                <div className="row">
                  <div className="col-6 col-md-4  order-1 order-lg-1 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">{t("NewCart.Card.PickupTime")}</p>
                    <b className="fs-14">
                      {moment(cart?.pickupDateTime).format(
                        "MMM. Do, YYYY (ddd)"
                      )}
                      , {cart.pickupTime}
                    </b>
                  </div>
                  <div className="col-12 col-md-8 order-3 order-lg-2 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.PickupLocation")}
                    </p>
                    <b className="fs-14">{cart?.pickupLocation?.label}</b>
                  </div>
                  <div className="col-6 col-md-4 order-2 order-lg-3 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.DropoffTime")}
                    </p>

                    <b className="fs-14">
                      {moment(cart.customerDropoffDateTime, 'YYYY-MM-DD HH:mm').format('MMM. Do, YYYY (ddd), HH:mm')}
                    </b>
                  </div>
                  <div className="col-md-8 order-4 order-lg-4 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.DropoffLocation")}
                    </p>
                    {cart?.destinations.map((item, index) => {
                      return (
                        <div>
                          <b className="fs-14">{item.label}</b>
                          {index === cart.destinations.length - 1 ? "" : <br />}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr className="divider" />
                {cart.bookingInfo && cart?.bookingInfo?.length > 0 && cart?.bookingInfo?.map((item, index) => {
                  return (
                    <div className="row">
                      <div className="col-md-4 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Vehicle") + " " + (index + 1)}
                        </p>

                        <b className="fs-14">{item.vehicleTypeName}</b>
                      </div>
                      <div className="col-md-3 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Passenger")}
                        </p>
                        <b className="fs-14">
                          {item.pax
                            .filter((passenger) => passenger.paxcount > 0)
                            .map((passenger, index, array) => (
                              <span key={passenger.paxtype}>
                                {passenger.paxtype}: {passenger.paxcount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                      <div className="col-md-3 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Luggage")}
                        </p>
                        <b className="fs-14">
                          {item.luggage
                            .filter((luggage) => luggage.luggagecount > 0)
                            .map((luggage, index, array) => (
                              <span key={luggage.luggagetype}>
                                {luggage.luggagetype === "CHECK-IN SIZE"
                                  ? t("Check-in")
                                  : t("Cabin")}
                                : {luggage.luggagecount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                    </div>
                  );
                })}

                <hr className="divider" />
                <div className="row">
                  <div className="col-md-4 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.AddOnServices")}
                    </p>
                    {meetAssist !== "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.meetAssist")} (${t("NewCart.AddOnServices." + meetAssist)})`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart.travelArea && cart.byTheHourBook && cart.travelArea !== "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.TravelArea.Title")} ${t("NewCart.AddOnServices.TravelArea."+cart.travelArea)}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {getCountChildSeat() > 0 && (
                      <div>
                        <b className="fs-14">
                          {getChildSeatView()}
                        </b>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-8 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.AdditionalInformation")}
                    </p>
                    {cart?.pickupFlightNo && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.ArrivalFlightNumber")} ${cart?.pickupFlightNo}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart?.dropoffFlightNo && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.DepartureFlightNumber")} ${cart?.dropoffFlightNo}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart?.note_arr[0]?.note !== "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.SpecialRequest")} ${cart?.note_arr[0].note}`}
                        </b>
                        <br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer py-3 ">
          <div className="row  align-items-center  justify-content-between">
            <div className="col-auto">
              <p className="m-0 fs-14">{t("NewCart.Card.Price")}</p>
            </div>
            <div className="col-auto">
              <h5>{`${t("JPY")} ${cart.totalPrice.toLocaleString()}`}{t("JPY.JP")}</h5>
            </div>
          </div>
        </div>
      </div>
      <NewEditBooking
        useModal = {useModalEditBooking} 
        handleCloseModal = {handleCloseModalEditBooking}
        typeOfTransfer={
          cart.byTheHourBook ? "byTheHour" : "specifiedDestination"
        }
        keyBook={keyBook}
        bookingFormData = {editBookingForm}
      />
      {/* <EditBooking
        useModal={useModalEditBooking}
        handleCloseModal={handleCloseModalEditBooking}
        typeOfTransfer={
          cart.byTheHourBook ? "byTheHour" : "specifiedDestination"
        }
        editBookingForm={editBookingForm}
      /> */}

      {/* DUPLICATE BOOKING MODAL */}
      {useModalDuplicateBooking && (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xxl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="d-flex mt-2 justify-content-between">
                    <h5 className="d-none d-lg-block">{t("NewCart.DuplicateBooking.Title")}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setUseModalDuplicateBooking(false);
                        resetDuplicateBookingForm();
                      }}
                    ></button>
                  </div>
                  {/* DESKTOP */}
                  <div className="card border-gold">
                    <div className="card-body">
                      <b>
                      {t("BookingConfirmation.DuplicateBooking.Description")}
                      </b>
                      <div className="row mt-3 ">
                        {inputFields.map((item, index) => (
                          <div className="col-12 col-lg-3 mb-3 input-box" key={index}>
                            <div className="form-floating">
                            <input
                              type="date"
                              id="date"
                              name="traveldate"
                              min={currentDate}
                              className={"form-control"}
                              placeholder="Travel date"
                              aria-label="Travel date"
                              onChange={(e) => {
                                onChangeDuplicateDate(e, index);
                              }}
                              value={inputFields[index]}
                            />
                          <i className="fa fa-close icon"   onClick={() => removeDateByIndex(index)}></i> 
                            <label htmlFor="travelDate">
                              <div className="d-flex align-items-center">
                                <i className="pe-2 d-flex align-self-center">
                                  <img src={calendar_icon} alt=""/>
                                </i> 
                                {t("Travel.Date")}
                              </div>
                            </label>
                            </div>
                            
                          </div>
                        ))}
                        <div className="col-lg-3 pb-3">
                          <button
                            type="button"
                            className="btn bg-gold-light-100" 
                            style={{height : "58px"}}
                            onClick={handleAddDate}
                          >
                           <img src={plus_icon} alt=""/>
                            &nbsp; {t("BookingConfirmation.DuplicateBooking.Adddate")}
                          </button>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <p className="mt-3 ">
                        {lang === "EN" ? <> Duplicate booking for another {" "}
                          <b>{inputFields.length} days</b></> : <>さらに{inputFields.length}日同じ予約を作成</> }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-lg-3 d-grid">
                      <button
                        type="button"
                        className="btn btn-secondary rounded"
                        onClick={() => {
                          discardDuplicateBooking();
                        }}
                      >
                        {t("NewCart.DuplicateBooking.DiscardButton")}
                      </button>
                    </div>
                    <div className="col-lg-3 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary rounded"
                        data-bs-dismiss="modal"
                        disabled={inputFields[0] === "" || inputFields.length < 1 ? true : false}
                        onClick={() => {
                          duplicateBooking();
                        }}
                      >
                        {t("NewCart.DuplicateBooking.DuplicateButton")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) }
    </>
  );
};

export default NewCartItemCard;
