/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import moment from "moment";
import {
  addToCartReduxStore,
  deleteCartItem,
  removeFromCartReduxStore,
} from "../../actions/admin/booking";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withAlert, types } from "react-alert";
import Clock from "../front-pages/Clock";
import { getPageContents } from "../../actions/admin/cmspage";
import clock_icon from "../../images/clock-icon.svg";
import NewCartItemCard from "../layout-new-theme/cards/NewCartItemCard";
import "moment/locale/ja";

import plus_circle_gold_icon from "../../icons/plus_circle_gold.svg";
import visa_icon from "../../images/visa-icon.svg";
import jcb_icon from "../../images/jcb-icon.svg";
import diners_club_icon from "../../images/diners-club-icon.svg";
import american_express_icon from "../../images/amex-icon.svg";
import discover_icon from "../../images/discover-icon.svg";
import maestro_icon from "../../images/maestro-icon.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";

const NewCart = ({ alert }) => {
  const history = useHistory();
  const { cart } = useSelector((state) => state.booking);
  const dispatch = useDispatch();
  const page_name = "cms-cart";
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  const [clockRef, setClockRef] = useState(null);
  const lastAddedBookToCart = localStorage.getItem("lastAddedBookToCart")

  if (lang === "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  useEffect(() => {
    if (cart) {
      cart.map((val) => {
        const items = [];
        let totalPrice = 0;

        val.bookingInfo.map((bi, ind) => {
          const item = {
            index: ind,
            item_id: bi.vehicleTypeID,
            item_name: bi.vehicleTypeName,
            item_brand: "Toyota",
            affiliation: "Stripe",
            coupon: "",
            discount: 0,
            item_category: val.isAirportTransfer ? 'Airport Transfer' : val.byTheHourBook ? 'By The Hour' : 'Specified Destination',
            item_category2: val.destinations.length === 1 ? val.destinations[0].place_id : val.destinations[val.destinations.length - 1].place_id,
            item_category3: (bi.childSeat[0].childSeatCount + bi.childSeat[1].childSeatCount + bi.childSeat[2].childSeatCount) > 0 ? "Child Seats" : "",
            location_id: "",
            price: bi.total,
            quantity: 1,
          };

          items.push(item);
        })

        totalPrice = val.totalPrice

        const ecommerceData = {
          currency: "JPY",
          value: totalPrice,
          items: items
        }

        const dataToPush = {
          event: 'view_cart',
          ecommerce: ecommerceData
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
      })
    }
  }, [cart])

  // Delete a single item/ booking from the cart when the delete button is clicked
  const clickRemoveItemFromcart = async (item) => {
    const items = [];
    let totalPrice = 0;

    item.bookingInfo.map((bi, ind) => {
      const itemArr = {
        index: ind,
        item_id: bi.vehicleTypeID,
        item_name: bi.vehicleTypeName,
        item_brand: "Toyota",
        affiliation: "Stripe",
        coupon: "",
        discount: 0,
        item_category: item.isAirportTransfer ? 'Airport Transfer' : item.byTheHourBook ? 'By The Hour' : 'Specified Destination',
        item_category2: item.destinations.length === 1 ? item.destinations[0].place_id : item.destinations[item.destinations.length - 1].place_id,
        item_category3: (bi.childSeat[0].childSeatCount + bi.childSeat[1].childSeatCount + bi.childSeat[2].childSeatCount) > 0 ? "Child Seats" : "",
        location_id: "",
        price: bi.total,
        quantity: 1,
      };

      items.push(itemArr);
    })

    totalPrice = item.totalPrice

    const ecommerceData = {
      currency: "JPY",
      value: totalPrice,
      items: items
    }

    const dataToPush = {
      event: 'remove_from_cart',
      ecommerce: ecommerceData
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataToPush);

    if (localStorage.getItem("cart") !== null) {
      const curCart = cart.filter((i) => i.key !== item.key);
      dispatch(addToCartReduxStore(curCart));
      localStorage.setItem("cart", JSON.stringify(curCart));
      dispatch(removeFromCartReduxStore(curCart));
      // Remove the relevant item/ booking from the database
      await deleteCartItem(item.key);
      if (curCart.length === 0) {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  };


  const getTotalAmount = () => {
    var tot = 0;
    if (cart === null) return 0;

    cart.map((i) => {
      tot += i.totalPrice;
    });

    return tot;
  };

  const submit = () => {
    if (cart) {
      cart.map((val) => {
        const items = [];
        let totalPrice = 0;

        val.bookingInfo.map((bi, ind) => {
          const item = {
            index: ind,
            item_id: bi.vehicleTypeID,
            item_name: bi.vehicleTypeName,
            item_brand: "Toyota",
            affiliation: "Stripe",
            coupon: "",
            discount: 0,
            item_category: val.isAirportTransfer ? 'Airport Transfer' : val.byTheHourBook ? 'By The Hour' : 'Specified Destination',
            item_category2: val.destinations.length === 1 ? val.destinations[0].place_id : val.destinations[val.destinations.length - 1].place_id,
            item_category3: (bi.childSeat[0].childSeatCount + bi.childSeat[1].childSeatCount + bi.childSeat[2].childSeatCount) > 0 ? "Child Seats" : "",
            location_id: "",
            price: bi.total,
            quantity: 1,
          };

          items.push(item);
        })

        totalPrice = val.totalPrice

        const ecommerceData = {
          currency: "JPY",
          value: totalPrice,
          items: items
        }

        const dataToPush = {
          event: 'begin_checkout',
          ecommerce: ecommerceData
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
      })
    }
    history.push("/checkout", {
      items_to_checkout: JSON.stringify(cart),
      fromCart: true,
    });
  };

  const SettingClockRef = (ref) => {
    if (ref !== null) setClockRef(ref);
  };

  const onCompleteTimer = () => {
    if (cart.length > 0) {
      emptyCart();
      alert.show(
        t("Your cart has expired. Please add all items to your cart again."),
        { type: types.INFO }
      );
    }
  };

  const start = () => {
    try {
      if (clockRef === null) return;
      clockRef.start();
    } catch (e) {
      console.error(e);
    }
  };


  const stop = () => {
    try {
      clockRef.stop();
    } catch { }
  };

  const emptyCart = () => {
    dispatch(addToCartReduxStore([]));
    localStorage.setItem("cart", JSON.stringify([]));
    dispatch(removeFromCartReduxStore([]));
  };

  return (
    <FrontLayout>
      {/* <!-- section 1 --> */}
      <div className="container-fluid bg-page-cart mt-1 pt-1">
        <div className="custom-mx">
          <div className="container pb-5 custom-mt-8 col-lg-12">
            {/* <!-- column for CART ITEMS--> */}
            <div className="col">
              <div className="row  gx-0 d-flex justify-content-between">
                <div className="col-md-8 pb-3 pb-lg-0 pb-lg-0 my-md-0 ">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-md-7">
                      <h1 className="fw-bold text-start text-dark mt-5 mt-lg-0">
                        {t("NewCart.Title")} {cart === null ? '' : `(${cart.length})`}
                      </h1>

                      <div className="d-block d-lg-none ">
                        <hr />
                      </div>
                    </div>
                    <div className="col-md-5 col-12 mt-lg-0 text-end">
                     {cart.length > 0 &&  <div className="bg-gold-light py-3 px-3 rounded">
                        <div className="d-flex justify-content-between align-items-center">
                          <p
                            className="p-0 m-0"
                            style={{ fontFamily: "Noto Sans JP" }}
                          >
                            {t("NewCart.Countdown")}
                          </p>
                          <div className="d-flex justify-content-end">
                            <Clock
                              start={start}
                              stop={stop}
                              // setting={setting}
                              cart={cart}
                              refCallback={SettingClockRef}
                              onCompleteTimer={onCompleteTimer}
                              lastAddedBookToCart={lastAddedBookToCart}
                            />

                            <img
                              src={clock_icon}
                              className="ps-2"
                              alt="Clock icon"
                            />
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- item card 1 --> */}
            <div className="row gap-3 gap-lg-0 mt-0 mt-lg-4 ">
              <div className="col-md-8 p-0">
                {cart.length > 0
                  ? cart.map((c, i = 0) => (
                    <div className="mb-3">
                      <NewCartItemCard
                        cart={c}
                        key={i}
                        removeItemFromCart={clickRemoveItemFromcart}
                      />
                    </div>
                  ))
                  : <div className="bg-white mb-3 d-flex justify-content-center align-items-center" style={{height : "155px"}}>
                    <p className="noto-san p-0 m-0">{t("NewCart.NoBooking")}</p>
                  </div> }

                <div className="d-grid ">
                  <button
                    type="button"
                    className="btn border-dashsed rounded"
                    onClick={() => {
                      history.push("/book");
                    }}
                  >
                    <div className="d-flex gap-2 py-2 justify-content-center align-items-center">
                      <img  alt="img" src={plus_circle_gold_icon} />

                      <span>{t("Add Another Booking")}</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h3 className="fw-bold text-start text-dark my-1 px-3">
                      {t("NewCart.Total")}
                    </h3>
                  </div>
                  <div className="card-body bg-medium-grey">
                    <div className="px-3">
                      {/* BASIC FARE */}
                      
                      {cart.length > 0 ?
                        cart.map((item, index) => {
                          return (
                            <div>
                              <div className="row">
                                <div className="col">
                                  <p className="fw-bold text-dark">
                                    {moment(item.travelDateWithPickupTime).format("MMM D, yyyy (ddd)")
                                      + " - "
                                      + (item.isAirportTransfer
                                        ? t("TypeOfTransfer.AirportTransfer")
                                        : item.byTheHourBook
                                          ? t("TypeOfTransfer.ByTheHour") + ` (${lang === "EN" ? item.bookingInfo[0].totalHoursUsage + " Hours" : item.bookingInfo[0].totalHoursUsage + "時間"})`
                                          : t("TypeOfTransfer.SpecifiedDestination")) +
                                      (item.activePackage
                                        ? " - " + item.activePackage.packagename
                                        : " ")}
                                  </p>
                                </div>
                              </div>
                              {item.bookingInfo && item?.bookingInfo?.length > 0 && item.bookingInfo.map((itm, ind) => {
                                return (
                                  <div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="ms-3 text-dark">
                                          {(item?.bookingInfo?.length > 1 ? (ind + 1 + ". ") : "") + t(itm.vehicleTypeName.replace(/ /g, ".")) + " - " + t("Vehicle.Usage.Fee")}
                                        </p>
                                      </div>
                                      <div className="col-auto align-items-end justify-content-end">
                                        <p className="text-end noto">
                                          {t("JPY")}
                                          {item.activePackage ? itm.total.toLocaleString() : (itm.total - itm.longDistanceSurcharge - itm.travelAreaPrice).toLocaleString()}
                                          {t("JPY.JP")}
                                        </p>
                                      </div>
                                    </div>
                                    {
                                      (item.byTheHourBook && itm.travelAreaPrice > 0) ? (
                                        <div className="row">
                                          <div
                                            className="col"
                                            style={{ paddingLeft: "30px" }}
                                          >
                                            <p className="text-dark">
                                              {t("BookingConfirmation.HighwayFee")}
                                            </p>
                                          </div>
                                          <div className="col-auto align-items-end justify-content-end">
                                            <p className="text-end noto">
                                              {t("JPY")}
                                              {(
                                                itm.travelAreaPrice
                                              ).toLocaleString()}
                                              {t("JPY.JP")}
                                            </p>
                                          </div>
                                        </div>
                                      ) : ""
                                    }
                                    {
                                      (!item.activePackage && itm.longDistanceSurcharge > 0) ? (
                                        <div className="row">
                                          <div
                                            className="col"
                                            style={{ paddingLeft: "30px" }}
                                          >
                                            <p className="text-dark">
                                              {t("NewCheckout.LongDistanceSurcharge")}
                                            </p>
                                          </div>
                                          <div className="col-auto align-items-end justify-content-end">
                                            <p className="text-end noto">
                                              {t("JPY")}
                                              {(
                                                itm.longDistanceSurcharge
                                              ).toLocaleString()}
                                              {t("JPY.JP")}
                                            </p>
                                          </div>
                                        </div>
                                      ) : ""
                                    }
                                    {itm.childSeat.map((i) => {
                                      if (i.childSeatCount > 0) {
                                        return (
                                          <div className="row">
                                            <div
                                              className="col"
                                              style={{ paddingLeft: "30px" }}
                                            >
                                              <p className="text-dark">
                                                {`Child Seats (${t("NewCart.AddOnServices.ChildSeat." + i.childSeatType)} x${i.childSeatCount})`}
                                              </p>
                                            </div>
                                            <div className="col-auto align-items-end justify-content-end">
                                              <p className="text-end noto">
                                                {t("JPY")}
                                                {(
                                                  i.childSeatCount * 1100
                                                ).toLocaleString()}
                                                {t("JPY.JP")}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                );
                              })}
                              {(item.meetAssist[0].meetAssistName !== "none" ||
                                item.meetAssist[1].meetAssistName !== "none") && (
                                  item.meetAssist.map((m) => {
                                    if (m.meetAssistName !== "none") {
                                      return (
                                        <div className="row">
                                          <div className="col">
                                            <p className="fw-bold text-dark">
                                              {`${t("NewCart.AddOnServices." + m.meetAssistName)} (${t("NewCart.AddOnServices." + m.meetAssistType)})`}
                                            </p>
                                          </div>
                                          <div className="col-auto align-items-end justify-content-end">
                                            <p className="text-end noto">
                                              {
                                                t("JPY") + m.meetAssistPrice.toLocaleString() + t("JPY.JP")
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                )}
                              {index < cart.length - 1 && <hr className="grey" />}
                            </div>
                          );
                        }) : (<div style={{height : "110px"}}></div>)  }
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row px-3 align-items-center ">
                      <div className="col">
                        <h5 className="fw-bold text-dark" >{t("Booking.Confirmation.Total.Price")}</h5>
                      </div>
                      <div className="col-auto align-items-end justify-content-end">
                        <h3 className="text-end fw-bold">
                          {t("JPY")} {getTotalAmount().toLocaleString()}{t("JPY.JP")}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 ">
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3 px-3">
                    <p className="text-center">{t("Footer.Payment.Methods")}</p>
                  </div>
                  <div className="col-12 px-3 d-md-flex justify-content-md-center d-lg-block mb-3">
                    <div className="border-0 mb-3 d-flex flex-row align-items-center gap-2">
                      <img  alt="img" src={visa_icon} className="img-fluid"/>
                      <img  alt="img" src={jcb_icon} className="img-fluid"/>
                      <img  alt="img" src={diners_club_icon} className="img-fluid"/>
                      <img  alt="img" src={american_express_icon} className="img-fluid"/>
                      <img  alt="img" src={discover_icon} className="img-fluid"/>
                      <img  alt="img" src={maestro_icon} className="img-fluid"/>
                    </div>
                  </div>
                </div>
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-primary rounded py-2"
                    disabled={!cart || cart.length === 0}
                    onClick={submit}
                  >
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <span>{t("NewCart.ProceedToPayment")}</span>
                     <img  alt="img" src={arrow_right_icon} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default withAlert()(NewCart);
