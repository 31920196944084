/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import {
  getImage,
} from "../../../utils/Calculator";
import { useTranslation } from "react-i18next";
import depature_flight_gold_icon from "../../../icons/form-icons/depature_flight.svg";
import info_gold_icon from "../../../icons/form-icons/info_gold.svg";
import x_circle_gold_icon from "../../../icons/x_circle_gold.svg";

const CartItemInfoCard = ({
  cart,
  index,
  removeItemFromCart,
  updateCartItem,
}) => {
  const [infantS, setInfantS] = useState([]);
  const [toddlerS, setToddlerS] = useState([]);
  const [childS, setChildS] = useState([]);

  const [isUsingChildSeat, setChildSeat] = useState(false);
  const [isUsingMeetAssistForm, setMeetAssistForm] = useState(false);
  const [pronounced, setPronounced] = useState("Mr.");
  const [meetAssistS, setMeetAssistS] = useState(null);
  const { t } = useTranslation();

  function sum(prev, next) {
    return prev + next;
  }

  useEffect(() => {
    if (cart) {
      const toddlerSizeSeatArray = cart.bookingInfo.map(item => item.toddlerSizeSeat !== null ? item.toddlerSizeSeat : 0);
      const infantSizeSeatArray = cart.bookingInfo.map(item => item.infantSizeSeat !== null ? item.infantSizeSeat : 0);
      const childSizeSeatArray = cart.bookingInfo.map(item => item.childSizeSeat !== null ? item.childSizeSeat : 0);

      setInfantS(infantSizeSeatArray);
      setToddlerS(toddlerSizeSeatArray);
      setChildS(childSizeSeatArray);

      // Check if there is any value not equal to 0 in each array
      const hasNonZeroValueToddler = toddlerSizeSeatArray.some(value => value > 0);
      const hasNonZeroValueInfant = infantSizeSeatArray.some(value => value > 0);
      const hasNonZeroValueChild = childSizeSeatArray.some(value => value > 0);

      if (hasNonZeroValueToddler || hasNonZeroValueInfant || hasNonZeroValueChild) setChildSeat(true);
      if (cart.meetAssist !== null && cart.meetAssist !== undefined) {
        setMeetAssistForm(true);
        setMeetAssistS(cart.meetAssist);
      } else {
        setMeetAssistForm(false);
        setMeetAssistS(null);
      }

      if (cart && cart.bookingInfo.length > 0) {
        cart.bookingInfo.map((i, ind) => {
          if (i.infantSizeSeat == null) cart.bookingInfo[ind].infantSizeSeat = 0;
          if (i.toddlerSizeSeat == null) cart.bookingInfo[ind].toddlerSizeSeat = 0;
          if (i.childSizeSeat == null) cart.bookingInfo[ind].childSizeSeat = 0;
        })
        if (cart.meetAssist === undefined) cart.meetAssist = null;
      }

      updateCartItem(cart);
    }
  }, [])

  const onChangeRequestForm = (e) => {
    cart.specialRequest = e.target.value;
    updateCartItem(cart);
  };

  const onChangeCustomerNameForm = (e) => {
    cart.passenger_name = pronounced + " " + e.target.value;
    updateCartItem(cart);
  };

  const onChangePickupFlightNumberForm = (e) => {
    cart.pickupFlightNo = e.target.value;
    updateCartItem(cart);
  };

  const onChangeDropoffFlightNumberForm = (e) => {
    cart.dropoffFlightNo = e.target.value;
    updateCartItem(cart);
  };

  const handleRadioChange = (event) => {
    setMeetAssistS(event.target.value);
    cart.meetAssist = event.target.value;
    updateCartItem(cart);
  };

  // Function to handle pronounced list clicked
  const handleLiClickPronouncedList = (pronounced) => {
    setPronounced(pronounced);
    if (cart.passenger_name !== "" || cart.passenger_name != null) {
      const containsMr = /Mr\./.test(cart.passenger_name);
      const containsMrs = /Mrs\./.test(cart.passenger_name);

      if (containsMr) {
        cart.passenger_name = cart.passenger_name.substring(4);
        // Perform actions for 'Mr.'
      }

      if (containsMrs) {
        cart.passenger_name = cart.passenger_name.substring(5);
        // Perform actions for 'Mrs.'
      }
      cart.passenger_name = pronounced + " " + cart.passenger_name;
      updateCartItem(cart);
    }
  };

  const clickMeetAssist = (v) => {
    if (isUsingMeetAssistForm) {
      //remove child seat
      setMeetAssistForm(false);
      setMeetAssistS(null);
      cart.meetAssist = null;
      updateCartItem(cart);
    } else {
      //add child seat
      setMeetAssistS(null);
      cart.meetAssist = null;
      setMeetAssistForm(true);
    }
  };

  const clickChildSeat = () => {
    if (isUsingChildSeat) {
      //remove child seat
      const newArray = new Array(cart.bookingInfo.length).fill(0);

      setInfantS(newArray);
      setToddlerS(newArray);
      setChildS(newArray);

      if (cart && cart.bookingInfo.length > 0) {
        cart.bookingInfo.map((i, ind) => {
          cart.bookingInfo[ind].infantSizeSeat = 0;
          cart.bookingInfo[ind].toddlerSizeSeat = 0;
          cart.bookingInfo[ind].childSizeSeat = 0;
        })
      }

      setChildSeat(false);
      updateCartItem(cart);
    } else {
      //add child seat
      setChildSeat(true);
    }
  };

  const incrementChildSeat = (type, ind) => {
    switch (type) {
      case "infant":
        const arr = [...infantS];
        if (arr[ind] === undefined) {
          arr[ind] = 0;
        }
        cart.bookingInfo[ind].infantSizeSeat = arr[ind] + 1;
        arr[ind] = arr[ind] + 1;
        setInfantS(arr);
        break;
      case "toddler":
        const arrToddler = [...toddlerS];
        if (arrToddler[ind] === undefined) {
          arrToddler[ind] = 0;
        }
        cart.bookingInfo[ind].toddlerSizeSeat = arrToddler[ind] + 1;
        arrToddler[ind] = arrToddler[ind] + 1;
        setToddlerS(arrToddler);
        break;
      case "child":
        const arrChild = [...childS];
        if (arrChild[ind] === undefined) {
          arrChild[ind] = 0;
        }
        cart.bookingInfo[ind].childSizeSeat = arrChild[ind] + 1;
        arrChild[ind] = arrChild[ind] + 1;
        setChildS(arrChild);
        break;
      default:
        break;
    }
    updateCartItem(cart);
  };

  const decrementChildSeat = (type, ind) => {
    switch (type) {
      case "infant":
        const arr = [...infantS];
        if (arr[ind] === undefined) {
          arr[ind] = 0;
        }
        if (arr[ind] > 0) {
          cart.bookingInfo[ind].infantSizeSeat = arr[ind] - 1;
          arr[ind] = arr[ind] - 1;
          setInfantS(arr);
        }
        break;
      case "toddler":
        const arrToddler = [...toddlerS];
        if (arrToddler[ind] === undefined) {
          arrToddler[ind] = 0;
        }
        if (arrToddler[ind] > 0) {
          cart.bookingInfo[ind].toddlerSizeSeat = arrToddler[ind] - 1;
          arrToddler[ind] = arrToddler[ind] - 1;
          setToddlerS(arrToddler);
        }
        break;
      case "child":
        const arrChild = [...childS];
        if (arrChild[ind] === undefined) {
          arrChild[ind] = 0;
        }
        if (arrChild[ind] > 0) {
          cart.bookingInfo[ind].childSizeSeat = arrChild[ind] - 1;
          arrChild[ind] = arrChild[ind] - 1;
          setChildS(arrChild);
        }
        break;
      default:
        break;
    }

    updateCartItem(cart);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Handle the case where the date couldn't be parsed
      return "Invalid Date";
    }

    const months = [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "May",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sep.",
      "Oct.",
      "Nov.",
      "Dec.",
    ];

    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    function getOrdinalSuffix(number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const lastDigit = number % 10;
      const suffix = number >= 11 && number <= 13 ? "th" : suffixes[lastDigit];
      return `${number}${suffix}`;
    }

    return `${month} ${getOrdinalSuffix(day)}, ${year} (${dayOfWeek})`;
  };

  return (
    <div className="row justify-content-between"
      style={{
        backgroundColor: "#F9EDD3"
      }}
    >
      <div className="col-12 col-lg-8 me-2 me-lg-0 order-2 order-lg-1 order-md-1 order-sm-2 order-xs-2"
        style={{
          padding: "25px 25px 25px 25px"
        }}>
        <div className="card shadow-sm">
          <div className="card-body">
            {cart.isFromAirport && cart.isToAirport ? (
              <div className={`mb-4`}>
                <h5 className="card-title mb-3">{t("Flight.Info")}</h5>
                <div className="row g-3 mb-3">
                  {/* FLIGHT NUMBER */}
                  <div className="col-6">
                    <div className="col form-floating">
                      <input
                        type="text"
                        id="flight-number"
                        className={
                          "form-control"
                        }
                        placeholder="Pickup Flight-number"
                        name="flightNo"
                        value={cart.pickupFlightNo !== undefined ? cart.pickupFlightNo : ""}
                        onChange={(e) => onChangePickupFlightNumberForm(e)}
                      />
                      <label htmlFor="dropOffAddress">
                        <div className="d-flex align-items-center">
                          <i className="pe-2 d-flex align-self-center">
                           <img  alt="img" src={depature_flight_gold_icon} />
                          </i>

                          {t("Pickup")} {t("Flight.Number")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col form-floating">
                      <input
                        type="text"
                        id="flight-number"
                        className={
                          "form-control"
                        }
                        placeholder="Dropoff Flight-number"
                        name="flightNo"
                        value={cart.dropoffFlightNo !== undefined ? cart.dropoffFlightNo : ""}
                        onChange={(e) => onChangeDropoffFlightNumberForm(e)}
                      />
                      <label htmlFor="dropOffAddress">
                        <div className="d-flex align-items-center">
                          <i className="pe-2 d-flex align-self-center">
                           <img  alt="img" src={depature_flight_gold_icon} />
                          </i>

                          {t("Dropoff")} {t("Flight.Number")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <i className="pe-2">
                      <img  alt="img" src={info_gold_icon} />
                    </i>
                    <p className="text-info-card-cart">
                      {t("Flight.Info.Desc")}
                    </p>
                  </div>

                </div>
              </div>
            ) :
              cart.isFromAirport || cart.isToAirport ? (
                <div className={`mb-4`}>
                  <h5 className="card-title mb-3">{t("Flight.Info")}</h5>
                  <div className="row g-3 mb-3">
                    {/* FLIGHT NUMBER */}
                    <div className="col form-floating">
                      <input
                        type="text"
                        id="flight-number"
                        className={
                          "form-control"
                        }
                        placeholder="Flight-number"
                        name="flightNo"
                        value={cart.isFromAirport ? (cart.pickupFlightNo !== undefined ? cart.pickupFlightNo : "") : (cart.dropoffFlightNo !== undefined ? cart.dropoffFlightNo : "")}
                        onChange={(e) => {
                          cart.isFromAirport ? onChangePickupFlightNumberForm(e) : onChangeDropoffFlightNumberForm(e)
                        }}
                      />
                      <label htmlFor="dropOffAddress">
                        <div className="d-flex align-items-center">
                          <i className="pe-2 d-flex align-self-center">
                            <img  alt="img" src={depature_flight_gold_icon}/>
                          </i>

                          {cart.isFromAirport ? t("Pickup") + " " + t("Flight.Number") : t("Dropoff") + " " + t("Flight.Number")}
                        </div>
                      </label>
                    </div>
                    <div className="d-flex">
                      <i className="pe-2">
                      <img  alt="img" src={info_gold_icon}/>
                      </i>
                      <p className="text-info-card-cart">
                        {t("Flight.Info.Desc")}
                      </p>
                    </div>
                  </div>
                </div>
              ) :
                (
                  ""
                )}

            <div className="mb-4">
              <h5 className="card-title mb-3">
                {t("Additional Services (optional)")}
              </h5>

              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column flex-sm-row justify-content-between justify-content-sm-center">
                    <div className="me-3">
                      <h5 className="card-title mb-3">{t("Meet & Assist Service")}</h5>
                      <p className="text-warning-card-cart">
                        {t("MNS.DESC")}
                      </p>
                    </div>
                    <div className="d-flex row">
                      <h5 className="mt-3 text-center">{t("JPY")} 15,500 {t("JPY.JP")}</h5>
                      <div className="d-grid col-12">
                        <button
                          onClick={clickMeetAssist}
                          type="button"
                          className={`btn ${isUsingMeetAssistForm
                            ? `btn-primary`
                            : `btn-secondary`
                            } btn-sm rounded-3 text-dark`}
                        >
                          <div className="d-inline">{t("Add")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isUsingMeetAssistForm && (
                <div className={`card mt-2 border-gold`}>
                  <div className="card-body">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="meetAssist"
                        id="inlineCheckbox1"
                        value="arrival"
                        checked={meetAssistS === "arrival"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="inlineCheckbox1">
                        <p className="noto m-0">{t("Arrival")}</p>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="meetAssist"
                        id="inlineCheckbox2"
                        value="departure"
                        checked={meetAssistS === "departure"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="inlineCheckbox2">
                        <p className="noto m-0">{t("Depature")}</p>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="meetAssist"
                        id="inlineCheckbox3"
                        value="both"
                        checked={meetAssistS === "both"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="inlineCheckbox3">
                        <p className="noto m-0">{t("Both")}</p>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div className="card mt-2">
                <div className="card-body">
                  <div className="d-flex flex-column flex-sm-row justify-content-between justify-content-sm-center">
                    <div className="me-3">
                      <h5 className="card-title mb-3">{t("Child Seats")}</h5>
                      <p className="text-warning-card-cart">
                        {t(
                          "Available for children aged 0-11 years old"
                        )}
                      </p>
                    </div>
                    <div className="d-flex row">
                      <h5 className="mt-3 text-center">{t("JPY")} 1,100 {t("JPY.JP")}</h5>
                      <div className="d-grid col-12">
                        <button
                          onClick={clickChildSeat}
                          type="button"
                          className={`btn ${isUsingChildSeat ? `btn-primary` : `btn-secondary`
                            } btn-sm rounded-3 text-dark`}
                        >
                          <div className="d-inline">{t("Add")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                cart && cart.bookingInfo.map((item, index) => {
                  return (
                    <div className={`card mt-2 border-gold  ${isUsingChildSeat ? `` : `d-none`} `}>
                      <div className="card-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <h4><b> {"Vehicle #" + (index + 1) + ": " + item.vehicleTypeName}</b></h4>
                              <hr />
                            </div>
                          </div>
                          <div className="row ">
                            <div className=" col-md-4 py-4">
                              <h5 className="text-center">{t("Infant Size (0-2)")}</h5>
                              {/* <p>{t("Detailed dimensions:")} </p> */}
                              {/* <img
                                className="img-fluid"
                                src="https://placehold.co/240x178"
                                alt=""
                                
                              /> */}
                              <div className="d-flex justify-content-center gap-3 mt-3">
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => decrementChildSeat("infant", index)}
                                >
                                  -
                                </button>
                                <div className="d-flex align-items-center">
                                  {infantS[index]}
                                </div>
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => incrementChildSeat("infant", index)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="col-md-4 py-4">
                              <h5 className="text-center">{t("Toddler Size (1-3)")}</h5>
                              {/* <p className="mt-2">{t("Detailed dimensions:")} </p> */}

                              {/* <img
                                className="img-fluid"
                                src="https://placehold.co/240x178"
                                alt=""
                                
                              /> */}
                              <div className="d-flex justify-content-center gap-3 mt-3">
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => decrementChildSeat("toddler", index)}
                                >
                                  -
                                </button>
                                <div className="d-flex align-items-center">
                                  {toddlerS[index]}
                                </div>
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => incrementChildSeat("toddler", index)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className=" col-md-4 py-4">
                              <h5 className="text-center">{t("Child Size (4-6)")}</h5>
                              {/* <p>{t("Detailed dimensions:")} </p> */}

                              {/* <img
                                className="img-fluid"
                                src="https://placehold.co/240x178"
                                alt=""
                                
                              /> */}
                              <div className="d-flex justify-content-center gap-3 mt-3">
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => decrementChildSeat("child", index)}
                                >
                                  -
                                </button>
                                <div className="d-flex align-items-center">
                                  {childS[index]}
                                </div>
                                <button
                                  type="button"
                                  className="btn border-gold rounded-circle"
                                  onClick={() => incrementChildSeat("child", index)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              <div className="card mt-2">
                <div className="card-body bg-gold-light-100 border border-0">
                  <p className="card-text font-weight-light">
                    <i className="fa fa-warning text-gold"></i>
                    &nbsp; {t("Warning.Child.Seat")}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h5 className="card-title mb-3">{t("Note")}</h5>
              <div className="card">
                <div className="card-body note-card-cart rounded-3">
                  <p className="text-warning-card-cart text-white">
                    {t("Note Desc")}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="mb-3">
                <h5 className="card-title">
                  {t("Passenger’s Name (optional)")}
                </h5>
                <p className="text-info-card-cart">
                  {t(
                    "If the passenger differs from the booking person, kindly specify the passenger’s full name and honorific prefixes"
                  )}
                </p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 input-group">
                <button
                  className="btn bg-gold-light-100 fw-100 rounded dropdown-toggle p"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {pronounced}
                </button>
                <ul className="dropdown-menu">
                  <li
                    className="dropdown-item "
                    onClick={() => handleLiClickPronouncedList("Mr.")}
                  >
                    Mr.
                  </li>
                  <li
                    className="dropdown-item "
                    onClick={() => handleLiClickPronouncedList("Ms.")}
                  >
                    Ms.
                  </li>
                  <li
                    className="dropdown-item "
                    onClick={() => handleLiClickPronouncedList("Mrs.")}
                  >
                    Mrs.
                  </li>
                </ul>
                <input
                  type="text"
                  name="passengersName"
                  className="form-control form-insert"
                  placeholder={t("Passenger Name")}
                  aria-label={t("Passenger Name")}
                  onChange={(e) => onChangeCustomerNameForm(e)}
                  value={
                    cart?.passenger_name?.match(/(?:Mr\.|Mrs\.)\s+(.+)/)[1]
                  }
                />
              </div>
            </div>

            <div>
              <h5 className="card-title mb-3">{t("Special Request")}</h5>
              <textarea
                onChange={(e) => onChangeRequestForm(e)}
                className="form-control form-insert"
                placeholder={t(
                  "Please write here if you have any special requests or additional notes"
                )}
              >{cart?.specialRequest}</textarea>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-4 position-relative order-1 order-lg-2 order-md-2 order-sm-1  mt-4 mt-sm-0"
        style={{
          padding: "25px 25px 25px 25px"
        }}>
        {
          cart.bookingInfo.map((item, index) => {
            return (
              <div className="card mb-2 card-shadow-page-cart">
                <div className="card-body">
                  <div className="row">
                    {/* <!-- remove btn--> */}
                    <div className="d-flex justify-content-between px-0">
                      <div className="col d-flex align-items-center pe-0">
                        <div>
                          <div className="badge rounded-pill text-bg-primary">
                            {cart.activePackage ? "Airport transfer" : ""}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto d-flex flex-row align-content-center ps-0">
                        <button
                          className="btn btn-add text-dark px-0"
                          type="button"
                          onClick={() => {
                            removeItemFromCart(cart);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Remove.From.Cart")}
                            <i className="ps-1">
                             <img  alt="img" src={x_circle_gold_icon}/>
                            </i>
                          </div>
                        </button>
                      </div>
                    </div>

                    {/* <!-- remove btn end --> */}
                    <div className="d-flex flex-column">
                      {/* <!-- vehicle type and cost --> */}
                      <div className="col">
                        <img
                          src={getImage(item.vehicleTypeName)}
                          className="card-img-top px-4 h-100"
                          alt="..."
                        />
                      </div>
                      <div className="col col-lg-12 d-flex flex-column">
                        <div className="card-header pt-3 pb-2 px-0">
                          {t("Toyota.Granace.Tag")}
                        </div>

                        <div className="row d-flex align-items-center justify-content-between">
                          <div className="col">
                            <h5 className="card-title d-inline">
                              {item.vehicleTypeName}
                            </h5>
                          </div>
                          <div className="col mt-3 mt-md-0">
                            <h5 className="d-inline">
                              {t("JPY")}
                              {item.total.toLocaleString()}
                              {t("JPY.JP")}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* <!-- vehicle type and cost, end --> */}
                      <hr></hr>
                      {/* <!-- booking details --> */}
                      <div className="col">
                        <ul className="list-group list-group-flush border-0">
                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 px-0 mt-0">
                                <p className="fw-400 text-dark m-0">
                                  {/* {t("Discover.No.Of.Passengers")} */}
                                  {t("NOP")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">
                                  {item.pax.map((p) => p.paxcount).reduce(sum)}
                                </p>
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">{t("NOL")}</p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">
                                  {item.luggage.map((p) => p.luggagecount).reduce(sum)}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">
                                  {t("Travel Date:")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">
                                  {formatDate(cart.travelDateWithPickupTime)}
                                </p>
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">
                                  {t("Discover.Pick.Up.Time")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">{cart.pickuptime}</p>
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">
                                  {t("Discover.Drop.Off.Time")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">
                                  {cart.cutomerChangedDropOffTimeOnly}
                                </p>
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">
                                  {t("Discover.Pick.Up.Location")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <p className="m-0">{cart.pickuplocation.label}</p>
                                {/* <p className="m-0">{getCartPickupLocation(cart)}</p> */}
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item border-0 text-dark">
                            <div className="row">
                              <div className="col-6 col-md-6 mt-0 px-0">
                                <p className="fw-400 text-dark m-0">
                                  {t("Discover.Drop.Off.Location")}
                                </p>
                              </div>
                              <div className="col-6 col-md px-0">
                                <div>
                                  {/* {getCartDropOffLocations(cart)} */}
                                  {cart.destinations.map((d, j = 0) => (
                                    <div key={"key" + j}>
                                      {"(" + (j + 1) + ") " + d.label} <br />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>)
          })
        }
        <span className="position-absolute top-0 mt-3 mt-lg-0 translate-middle badge rounded-pill border border-warning text-dark">
          {index + 1}
        </span>
      </div>
    </div>
  );
};

export default CartItemInfoCard;