import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./i18n";
import HttpsRedirect from "react-https-redirect";

// if(process.env.REACT_APP_ENVIRONMENT === "production"){
//   if (!window.location.host.startsWith("www")){
//     window.location = window.location.protocol + "//www." + window.location.host + window.location.pathname;
//   }
// }


// import ReactGA from "react-ga4";
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-MMKDDMVW'
// }
// TagManager.initialize(tagManagerArgs)

// ReactGA.initialize("G-HJCLF6XVFR");
// ReactGA.initialize("GTM-MMKDDMVW");

// var hours = 1;
// var now = moment()
// var setupTime = localStorage.getItem('lastAddedBookToCart');

// if (setupTime != null) {
//   var timeDifference = now.diff(setupTime, 'milliseconds');
//   if (timeDifference > hours * 60 * 60 * 1000) {
//     localStorage.removeItem("cart");
//   }
// }

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 10000,
  offset: "4px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  // type: types.SUCCESS,
  containerStyle: {
    zIndex: 10000,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </HttpsRedirect>
);

// ReactDOM.render(
//   <HttpsRedirect>
//     <AlertProvider template={AlertTemplate} {...options}>
//       <App />
//     </AlertProvider>
//   </HttpsRedirect>,
//   document.getElementById("root")
// );
