/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../../images/tlf-logo-cropped.png";
import logoMobile from "../../images/tlf-logo-cropped-mobile.png";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../actions/admin/settings";
import LanguageCard from "./LanguageCard";
import CustomerLogin from "../auth/CustomerLogin";
import CustomerRegister from "../auth/CustomerRegister";
import { isTokenValid } from "../../utils/authUtils";
import jwtDecode from "jwt-decode";

const NavigationBar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const search = useLocation();
  const history = useHistory();
  const booking = useSelector((state) => state.booking);
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { cart } = booking;
  const [small, setSmall] = useState(false);

  function checkTokenUser() {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);
      return (
        decodedJwt.user.role !== "admin" || decodedJwt.user.role === undefined
      );
    }
  }

  const isAuthenticated = isTokenValid() && checkTokenUser();

  const langChange = (lng) => {
    if (lng === null) return;

    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lang", lng);
    dispatch(setLanguage(lng.toUpperCase()));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 50)
      );
    }
    if (history.location.search.includes("lang")) {
      const lng = history.location.search.split("=");
      langChange(lng[1]);
    } else {
      langChange(lang);
    }
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top  ${
        search.pathname === "/book" ||
        search.pathname === "/Cart" ||
        search.pathname === "/login" ||
        search.pathname === "/register" ||
        search.pathname === "/business-register" ||
        search.pathname === "/checkout" ||
        search.pathname.includes("/my-page") ||
        search.pathname === "/booking-confirmation" ||
        search.pathname.includes("/emailverification/") ||
        search.pathname.includes("/EmailVerification/") ||
        search.pathname.includes("/booking-detail/") ||
        search.pathname.includes("/notifications") ||
        search.pathname.includes("/forgot-password") ||
        search.pathname.includes("/reset-password") ||
        search.pathname
          .toLocaleLowerCase()
          .includes("PaymentSuccess".toLocaleLowerCase())
          ? "bg-grey"
          : "" || small
          ? "bg-grey"
          : ""
      }`}
      style={{paddingTop : "14px", paddingBottom : "14px"}}
    >
      <div className="container">
        <button
          className="navbar-toggler collapsed custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </button>
        <Link className="navbar-brand mx-0" to={"../"}>
          <img
            className="image-fluid d-none d-md-block"
            src={logo}
            alt="logo"
          />
          <img
            className={`image-fluid d-block d-md-none ${isAuthenticated ? `ps-5` : `ps-0` }  ps-md-0`}
            src={logoMobile}
            alt="logo"
          />
        </Link>

        <div
          className="collapse navbar-collapse justify-content-lg-end"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav d-flex mb-2 mb-lg-0">
            <li className="nav-item mx-lg-3">
              <Link
                className={`nav-link nav-link-header  ${
                  search.pathname === "/" ? "active" : ""
                }`}
                aria-current="page"
                to="/"
              >
                {t("Home")}
              </Link>
            </li>
            <li className="nav-item mx-lg-3">
              <Link
                className={`nav-link nav-link-header  ${
                  search.pathname === "/book" ? "active" : ""
                }`}
                to="/book"
              >
                {t("Book.Now")}
              </Link>
            </li>
            <li className="nav-item mx-lg-3">
              <Link
                className={`nav-link nav-link-header  ${
                  search.pathname === "/airport-transfer" ? "active" : ""
                }`}
                to="/airport-transfer"
              >
                {t("Services")}
              </Link>
            </li>
            <li className="nav-item mx-lg-3">
              <Link
                className={`nav-link nav-link-header  ${
                  search.pathname === "/fleet" ? "active" : ""
                }`}
                to="../fleet"
              >
                {t("Fleet")}
              </Link>
            </li>
            {/* <!-- removed --> */}
            <li className="nav-item mx-lg-3">
              <Link
                className={`nav-link nav-link-header  ${
                  search.pathname === "/pricing" ? "active" : ""
                }`}
                to="/pricing"
              >
                {t("Pricing")}
              </Link>
            </li>
            <li className="d-lg-block d-none">
              <LanguageCard />
            </li>
            <li>
              {/* LOGIN WITH MODAL */}
              {isAuthenticated ? (
                <button
                  type="button"
                  onClick={() => history.push("/my-page/bookings?content=current")}
                  className="btn btn-outline-dark btn-lg btn-login rounded-3 position-relative ms-lg-3 me-2 me-lg-0 py-lg-2 d-none d-md-block "
                >
                  <div className="d-flex flex-row justify-content-center">
                    <div className="pe-2 d-inline nav-link-header">
                      <i className="bi bi-person-circle f-xl"></i>
                    </div>
                    <div className="d-none d-lg-block nav-link-header">{t("My Page")} </div>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-dark btn-lg btn-login rounded-3 position-relative ms-lg-3 me-2 me-lg-0 py-lg-2 d-none d-md-block "
                  data-bs-toggle="modal"
                  data-bs-target="#login"
                >
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="pe-2 d-inline nav-link-header">
                      <i className="bi bi-person-circle f-xl"></i>
                    </div>
                    <div className="d-none d-lg-block nav-link-header">{t("Login")} </div>
                  </div>
                </button>
              )}

              {isAuthenticated ? (
                <button
                  type="button"
                  onClick={() => history.push("/my-page/bookings?content=current")}
                  className="btn btn-outline-dark btn-lg btn-login rounded-3 position-relative ms-lg-3 me-2 me-lg-0 py-lg-2 d-md-none "
                >
                  <div className="d-flex flex-row justify-content-center">
                    <div className="pe-2 d-inline">
                      <i className="bi bi-person-circle f-xl"></i>
                    </div>
                    <div className="d-none d-lg-block"></div>
                    {t("My Page")}
                  </div>
                </button>
              ) : (
                <>
                  <CustomerRegister useModal={true} modalId={"register"} />
                  <CustomerLogin useModal={true} modalId={"login"} />
                </>
              )}

              {/* LOGIN WITH MODAL */}
              {/* LOGIN FROM PAGE */}
              {isAuthenticated ? (
                <></>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-dark btn-lg btn-login rounded-3 position-relative ms-lg-3 me-2 me-lg-0 py-lg-2  d-md-none"
                  onClick={() =>
                    isAuthenticated
                      ? history.push("my-page/bookings?content=current")
                      : history.push("login")
                  }
                >
                  <div className="d-flex flex-row justify-content-center">
                    <div className="pe-2 d-inline">
                      <i className="bi bi-person-circle f-xl"></i>
                    </div>
                    <div className="">
                      {isAuthenticated ? t("My Page") : t("Log In")}
                    </div>
                  </div>
                </button>
              )}
              {/* LOGIN FROM PAGE */}
            </li>
          </ul>
        </div>

        <button
          type="button"
          className="btn btn-primary btn-lg btn-cart position-relative me-2 ms-lg-3 d-none d-md-block btn-outline-dark rounded-3"
          onClick={() => history.push("../Cart")}
        >
          <div className="d-flex flex-row justify-content-center">
            <div className="pe-2 nav-link-header">
              <i className="bi bi-cart"></i>
            </div>
            <div className="d-none d-lg-block nav-link-header">{t("Cart")}</div>
          </div>

          <span className="position-absolute top-0 mt-3 mt-lg-0 start-100 translate-middle ">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 25,
                height: 25,
                borderRadius: "100%",
                fontSize: "12px",
                backgroundColor: "white",
              }}
            >
              {cart !== null ? cart?.length : "0"}
            </div>
          </span>
        </button>
        <div className="d-flex gap-2 d-block d-md-none">
          
        {isAuthenticated && (<div className="pe-2">
            <div
              href="#"
              className="notification "
              onClick={() => history.push("../notifications")}
            
            >
              <span>
                <i className="bi bi-bell-fill"></i>
              </span>
              <span className="badge bg-red text-white" >0</span>
            </div>
          </div> )}
          <div className="pe-2">
            <div
              href="#"
              className="notification "
              onClick={() => history.push("../Cart")}
            >
              <span>
                <i className="bi bi-cart"></i>
              </span>
              <span className="badge bg-white text-dark">
              {cart !== null ? cart?.length : "0"}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
