import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import tlf_logo_2 from "../../images/tlf_logo_2.svg";
import jpt_logo from "../../images/jpt_logo.svg";
import vehicle_in_city from "../../images/vehicle_in_city.png";
import jpt_corporate from "../../images/jpt_corporate.png";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";

const Corporate = () => {
  const page_name = "cms-corporate";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-corporate d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("About.Us")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Corporate.Profile")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Corporate.Profile.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      <section className="custom-mt-7">
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-0">
              <div class="bg-burgundy h-100 d-flex  align-items-center p-5">
                <div className="col-3 col-md-2">
                  <img className="" src={tlf_logo_2} alt="logo" />
                </div>
                <div className="col-9 col-md-10">
                  <div className="ms-5">
                  <p className="noto card-header ps-0 text-gold">CORPORATE PROFILE</p>
                  <h2 className="text-gold">THE LUXURY FLEET</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 p-0">
              <div class="menu-image h-100 d-flex align-items-start">
              <img className="w-100" src={vehicle_in_city} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-5">
              <h5>About</h5>
              <p className="mt-3">At <b>THE LUXURY FLEET</b>, we are dedicated to delivering a premium car service experience in Tokyo and the wider Kanto area of Japan. Whether you are in need of a personal transfer, a family outing, or a way to impress important guests, we have the perfect solution for you. <br/><br/>
              Our knowledgeable and experienced chauffeurs are committed to providing a swift and effortless commuting experience, ensuring that you arrive at your destination in comfort and style. <br/><br/>
              Our modern and luxurious vehicles are designed to accommodate all of your needs, from spacious interiors and plush seating, to state-of-the-art amenities.</p>
            </div>
            <div class="col-12 col-lg-6 p-5">
            <h5>About</h5>
              <p className="mt-3">THE LUXURY FLEET Co.,Ltd. <br/><br/> General Passenger Vehicle Transportation Business Reg No. 6488 <br/><br/>
              Address: Dai 2 Hayashi Building 1F/2F,  1-8-20 Kitashinagawa, Shinagawa-ku, Tokyo 140-0001 <br/><br/>
              Established：September 14, 2017 <br/><br/>
              Capital：JPY 69,750,000</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6  p-0">
              <div class=" bg-black h-100 d-flex  align-items-center p-5">
                <div className="col-3 col-md-2">
                  <img className="" src={jpt_logo} alt="logo" />
                </div>
                <div className="col-9 col-md-10">
                  <div className="ms-5">
                  <p className="noto card-header ps-0 text-white">CORPORATE PROFILE</p>
                  <h2 className="text-white">JAPAN PRIVATE TOUR</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 p-0">
              <div class="menu-image h-100 d-flex align-items-start">
              <img className="w-100" src={jpt_corporate} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-5">
              <h5>About</h5>
              <p className="mt-3"><b>JAPAN PRIVATE TOUR</b> - THE LUXURY FLEET’s sister company - is the premium tour agency for trips inside Japan and our trusted partner in delivering private transportation for non-Tokyo routes. <br/><br/>
From our Tokyo headquarter, they put together unforgettable journeys, whether it be a family trip, retreat, romantic honeymoon, culinary tour, or numerous other adventures in modern, traditional, or hidden Japan.  <br/><br/>
The team offers the highest quality of service building personalized itineraries designed to ensure your time in Japan is truly memorable.</p>
            </div>
            <div class="col-12 col-lg-6 p-5">
            <h5>Corporate Information</h5>
              <p className="mt-3">JAPAN PRIVATE TOUR Co., Ltd. Reg No.: ANTA 2-7356 <br/><br/>
Services: Private Transportation, Private Bespoke & Package Tours, Events (Weddings, Golf etc.), Accommodation & Restaurant Reservation  <br/><br/>
Address: Dai 2 Hayashi Building 1F/2F,  1-8-20 Kitashinagawa, Shinagawa-ku, Tokyo 140-0001  <br/><br/>
Capital: JPY 16,990,000</p>
            </div>
          </div>
        </div>
      </section>


    </FrontLayout>
  );
};

export default Corporate;
