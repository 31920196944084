import moment from "moment";

export const getPaymentSuccessEmailTemplate = (bookings) => {
  var totalPrice = 0;
  var totalBooking = bookings.length;

  bookings.map((i) => {
    totalPrice += i.totalPrice;
  })

  var template = `
      <!DOCTYPE html>
      <html>
      <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <title>Email Verification</title>
          <style>
              .bg-page {
                  position: relative;
                  width: 100%;
                  /* height: 300px; */
                  /* Set the height */
              }
              .bg-image {
                  object-fit: cover;
                  width: 100%;
                  height: 70%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 0;
              }
              .row {
                  margin-bottom: 15px;
              }
              .card {
                  background-color: #ffffff;
                  padding: 20px;
              }
              .card-title {
                  font-size: 22px;
                  font-weight: 900;
              }
              .line-gold {
                  background-color: #DBBE78;
                  height: 7px;
                  border-radius: 5px 5px 0 0;
                  width: 100%;
              }
              .btn {
                  font-weight: 500;
                  text-align: center;
                  text-decoration: none;
                  display: inline-block;
                  cursor: pointer;
                  border: 1px solid transparent;
                  padding: 5px 20px;
                  line-height: 1.5;
                  border-radius: 0.25rem;
              }
              .btn-warning {
                  color: #4A4A4A !important;
                  background-color: #DBBE78;
                  border-color: #DBBE78;
              }
              .btn-warning:hover {
                  background-color: #b9995e;
                  border-color: #b9995e;
              }
              .b {
                  font-family: 'Noto Serif JP';
                  color: #282828;
              }
              h1 {
                  color: #282828;
                  font-family: 'Noto Serif JP';
                  font-size: 22px;
              }
              h2 {
                  color: #4E4E4E;
                  font-family: 'Noto Serif JP';
                  font-size: 14px;
                  font-weight: 600;
              }
              span {
                  font-family: 'Noto Sans JP';
                  color: #282828;
              }
              p,
              b {
                  font-family: 'Noto Serif JP';
                  color: #282828;
              }
              a,
              a:hover,
              a:visited,
              a:active {
                  color: inherit;
                  text-decoration: none;
              }
              .thin-line {
                  margin: 2px 0;
                  border: 0;
                  border-top: 1px solid #ccc;
              }
              ul{
                  margin-left: 20px;
                  padding: 0;
                  color: #282828;
              }
              li {
                  color: #282828;
              }
              /* Mobile View */
              @media screen and (max-width: 767px) {
                  img.tlf-logo {
                      width: 100px;
                      height: 63px;
                      display: block;
                      margin: 0 auto;
                  }
                  .col {
                      width: 100%;
                      max-width: 350px;
                      /* Set your desired maximum width */
                      word-wrap: break-word;
                      /* For older browsers */
                  }
              }
              /* Desktop View */
              @media screen and (min-width: 768px) {
                  img.tlf-logo {
                      width: 105px;
                      height: 65px;
                      display: block;
                      margin: 0 auto;
                  }
                  .col {
                      width: 100%;
                      max-width: 500px;
                      /* Set your desired maximum width */
                      word-wrap: break-word;
                      /* For older browsers */
                  }
              }
          </style>
      </head>
      <body
          style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0; background-color: #F6F6F6;">
          <table style=" border: 0; margin: 0 auto; justify-content: center; ">
              <tr class="bg-page">
                  <td style="display: inline-table; font-family:sans-serif; font-size: 14px; margin: 0 auto; padding:10px;"
                      valign="top">
                      <div style="box-sizing: border-box; display: block;  margin: 0 auto; padding-top: 10px; ">
                          <table style="border-collapse: collapse; width: 100%; border-radius: 1px;" bgcolor="transparent">
                              <tr class="row" style="height: 120px; width: 100%; background-color: #571B35;">
                                  <td class="col">
                                      <img class="tlf-logo" src="${process.env.REACT_APP_URL}/tlf-logo.png"
                                          alt="THE LUXURY FLEET Logo" title="TLF logo">
                                  </td>
                              </tr>
                              <tr
                                  style="background-color: white; margin-top: 1px; padding: 1px; height: 5px; width: 50px;  margin: 0; padding: 0; margin-top: 0px;">
                                  <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; padding: 20px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;"
                                      valign="top">
                                      <table style="border-collapse: separate; width: 100%; border: 0;">
                                          <tr>
                                              <td style="font-family: sans-serif; font-size: 14px;" valign="top">
                                                  <div class="card" style="padding-top: 0px;">
                                                      <div class="card-body">
                                                          <table style="margin: auto;">
                                                              <tr class="row mb-4" style="height: 80px; margin-bottom: 0;">
                                                                  <td class="col" style="text-align: center;">
                                                                      <h1 class="card-title" style="margin-bottom: 0px;">予約を受け取りました</h1>
                                                                      <h1 class="card-title" style="margin-top: 0px;">We
                                                                          have received your booking</h1>
                                                                      <h2 style="margin-bottom: 0px;">
                                                                          24時間以内に確認、請求を行います</h2>
                                                                      <h2 style="margin-top: 0px;">
                                                                          We will
                                                                          review, confirm and charge you for it
                                                                          within 24
                                                                          hours</h2>
                                                                  </td>
                                                              </tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row mb-4">
                                                                  <td class="col">
                                                                      <p style="margin-bottom: 0px;">
                                                                          ${bookings[0].customer.name} 様,
                                                                      </p>
                                                                      <p style="margin-bottom: 0px; margin-top: 0;">
                                                                          この度はご予約いただき誠にありがとうございます。
                                                                          以下の内容で予約を承りました</p>
                                                                      <p style="margin-top: 0;">
                                                                          なお、本メールは、お客様確認用のお知らせであり、<b>予約はまだ確定
                                                                              しておりません。</b></p>
                                                                  </td>
                                                              </tr>
                                                              <tr class="row mb-4">
                                                                  <td class="col">
                                                                      <p style="margin-bottom: 0px;">Hi
                                                                          ${bookings[0].customer.name},</p>
                                                                      <p style="margin-bottom: 0px; margin-top: 0;">
                                                                          We have
                                                                          received your booking. </p>
                                                                      <p style="margin-bottom: 0px; margin-top: 0;">
                                                                          We have
                                                                          authorized and reserved the total amount
                                                                          of the
                                                                          booking from your card — but your card
                                                                          has
                                                                          <b>not</b> been charged yet.
                                                                      </p>
                                                                      <p style="margin-bottom: 0px; margin-top: 0;">
                                                                          Our staff
                                                                          will accept or reject your booking in
                                                                          the next 24
                                                                          hours.</p>
                                                                      <p style="margin-bottom: 0px; margin-top: 0;">
                                                                          If your
                                                                          booking is confirmed, your card will be
                                                                          charged for
                                                                          the full booking cost stated below, and
                                                                          you will
                                                                          receive an official confirmation email.
                                                                      </p>
                                                                  </td>
                                                              </tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row mb-4">
                                                                  <td class="col"
                                                                      style="background-color: #FCF8FA; border-color: #571B35; justify-content: center; border: 1px solid #571B35;">
                                                                      <p
                                                                          style="margin-bottom: 1px; font-weight: bolder; text-align: center; color: #571B35;">
                                                                          合計/Total Price (${totalBooking} booking)</p>
                                                                      <p
                                                                          style="margin-top:0px; color: #282828; font-size: 19px; font-family: Noto Sans JP; font-weight: 700; text-align:center;">
                                                                          JPY
                                                                          ${totalPrice.toLocaleString()}
                                                                      </p>
                                                                  </td>
                                                              </tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
  `;

  bookings.map((item, index) => {
    template += `
          <tr class="row mb-4" style="background-color: #571B35;">
              <td class="col">
                  <p
                      style="font-weight: bolder; text-align: center; color: #E2C47C;">
                      予約の詳細/Booking Details</p>
              </td>
          </tr>
          <tr class="row mb-4" style="background-color: #FCF8FA;">
              <td class="col">
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      予約者/Reservation Holder:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.cartName}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      予約タイプ/Transfer Type:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.isAirportTransfer ? 'Airport Transfer' : item.byTheHourBook ? 'By The Hour' : 'Specified Destination'}</p>`;

    if (item.isAirportTransfer) {
      template += `<p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        フライトナンバー/Flight No.:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${'Pickup : ' + item.pickupFlightNo ?? '-' + " " + 'Dropoff : ' + item.dropoffFlightNo ?? '-'}</p>`;
    }
    if(item.pickupSign != null){
        template += `
        <p
            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
            Pickup Sign : ${item.pickupSign} </p>
        `;
    }

    template += `<p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                      日時/Date & Time:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                      Pick-up:
                      ${moment(item.travelDate).format("YYYY/MM/DD HH:mm")}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      Drop-off:
                      ${moment(item.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD HH:mm")}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                      配車先/Pick-up Location:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.pickupLocation.label}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                      目的地/Drop-off Location:</p>
      `;
      item.destinations.map((itm, ind) => {
        template += `<p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                          ${itm.label}</p>`;
      })
    item.bookingInfo.map((val, ind) => {
      template += `
      <p style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              選択された車両/Car Type:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
              ${val.vehicle.vehicletype}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              乗客/Passengers:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              大人/Adult: ${val.pax[0].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              お子様/Child: ${val.pax[1].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              幼児/Toddler: ${val.pax[2].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              ベビー/Baby: ${val.pax[3].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              荷物/Luggage:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              機内持ち込み手荷物/Cabin Size: ${val.luggage[1].luggagecount}
          </p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              預け入れ手荷物/Check-in Size: ${val.luggage[0].luggagecount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              Child Seats:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Infant: ${val.childSeat[0].childSeatCount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Toddler: ${val.childSeat[1].childSeatCount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Child: ${val.childSeat[2].childSeatCount}</p>
      `
    })
    template += `
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              合計/Total Price:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
              ${item.totalPrice.toLocaleString()}円
          </p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              メッセージ・お問い合わせ/Message・Inquiry:</p>
      `;

    if (item.message.length > 0) {
      item.message.map((itm, ind) => {
        template += `<p
                                  style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                  ${itm.note == "" ? '-' : itm.note}
                              </p>`;
      })
    } else {
      template += `<p
                                  style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                  -
                              </p>`;
    }
    template += `
              </td>
          </tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row"></tr>
          <tr class="row mb-4" style="background-color: #571B35;">
              <td class="col">
                  <p
                      style="font-weight: bolder; text-align: center; color: #E2C47C;">
                      お客様情報/Customer Information</p>
              </td>
          </tr>
          <tr class="row mb-4 mt-4" style="background-color: #FCF8FA;">
              <td class="col">
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      予約者/Reservation Holder:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.name}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      メール/Email:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.email}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      電話番号/Phone No.:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.phone}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      国/Country:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.country ?? '-'}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      郵便番号/Postal Code:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.postcode ?? '-'}</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                      住所/Address:</p>
                  <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                      ${item.customer.address}</p>
              </td>
          </tr>`;
  })

  template += `
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                              <tr class="row"></tr>
                                                          </table>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                              <tr class="row mb-4 mt-4" style="background-color: #FCFBFB;">
                                  <td class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <table style="margin: auto;">
                                                  <tr class="row mb-4 mt-4">
                                                      <td class="col">
                                                          <h1 style="font-weight: bolder; text-align: left; color: #282828;">
                                                              要確認/Important Notice</h1>
                                                          <hr style="color: #A7A7A7;">
                                                          <ul>
                                                              <li>高速道路使用料・駐車場使用料は別途でご請求申し上げます
                                                                  (空港送迎を除く)。</li>
                                                              <li>22:00〜翌6:00の時間帯のご利用には深夜割増料金が適用され、記載料金の1.25倍をご請求申し上げます。
                                                              </li>
                                                              <li>乗務員の宿泊が必要な旅程又は時間帯である場合は、乗務員の宿泊費を別途ご請求申し上げます。
                                                              </li>
                                                              <li>ご請求金額は、お客様が実際にご乗車される時間ではなく、車両の出庫と帰庫の時間に基づき計算されます。
                                                              </li>
                                                              <li>事故・渋滞で遅延が生じた場合、追加料金が加算されます。</li>
                                                              <li>喫煙されたり車の内装や座席を汚された場合に別途料金申し受けます。
                                                              </li>
                                                              <li>料金は所有時間によって変動する場合がございます。最新の料金表は
                                                                  <u><b><a
                                                                              href="www.theluxuryfleet.com/pricing">www.theluxuryfleet.com/pricing</a></b></u>
                                                                  にてご確認ください。
                                                              </li>
                                                          </ul>
                                                          <ul>
                                                              <li>All highway tolls and parking fees
                                                                  will be
                                                                  billed on top of the charter price
                                                                  (except
                                                                  airport transfers).</li>
                                                              <li>There will be an extra fee of 1.25
                                                                  times the
                                                                  prices for any usage between
                                                                  22:00-06:00.</li>
                                                              <li>Overnight trips and late night
                                                                  transfers
                                                                  (22:00-06:00) will require
                                                                  accommodation for the
                                                                  driver that will be billed together
                                                                  with the
                                                                  transfer fee.</li>
                                                              <li>The price will be calculated based
                                                                  on the time
                                                                  the vehicle departs and returns to
                                                                  the garage,
                                                                  not by the time the passenger
                                                                  remains on board.
                                                              </li>
                                                              <li>In the event of delays, including
                                                                  accidents or
                                                                  traffic jams, additional charges
                                                                  will apply.
                                                              </li>
                                                              <li>Clients will be charged with an
                                                                  additional fee
                                                                  for any smoking inside the car or
                                                                  any damage
                                                                  caused to the seats.</li>
                                                              <li>Prices will vary depending on the
                                                                  overall time
                                                                  of transfer. Please refer to
                                                                  <u><b><a
                                                                              href="www.theluxuryfleet.com/pricing">www.theluxuryfleet.com/pricing</a></b></u>
                                                                  for the latest version.
                                                              </li>
                                                          </ul>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                              <tr class="row mb-4 mt-4" style="background-color: #FCFBFB;">
                                  <td class="col">
                                      <div class="card">
                                          <div class="card-body">
                                              <table style="margin: auto;">
                                                  <tr class="row mb-4 mt-4">
                                                      <td class="col">
                                                          <h1 style="font-weight: bolder; text-align: left; color: #282828;">
                                                              キャンセルポリシー/Cancellation Policy</h1>
                                                          <hr style="color: #A7A7A7;">
                                                          <p style="font-weight: bolder; color: #282828;">
                                                              <u><b
                                                                      style="color: #282828;">https://www.theluxuryfleet.com/cancellation-policy</b></u>
                                                          </p>
                                                          <p style="margin-top: 0px; color: #282828;">
                                                              Cancellation fees vary depending on the
                                                              time of day
                                                              and before or after the reserved date.
                                                              Please refer
                                                              to the link above for cancellation
                                                              procedures.</p>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                              <tr class="row mb-4 mt-4">
                                  <td class="col">
                                      <div class="card" style="background-color: #F1F1F1;">
                                          <div class="card-body">
                                              <table style="margin: auto;">
                                                  <tr class="row mb-4 mt-4">
                                                      <td class="col">
                                                          <p style="margin-bottom: 0px; color: #282828;">
                                                              ※このメールアドレスは送信専用となっております。ご返信いただいても対応はいたしかねますので、ご了承ください。</p>
                                                          <p style="margin-top: 0px; color: #282828;">
                                                              ※
                                                              This email is meant for only the intended recipient of the
                                                              transmission. It is a
                                                              confidential communication.</p>
                                                          <p style="margin-bottom: 0px; color: #282828;">
                                                              ※このメールにお心当たりの無い場合は、メールを破棄してください。</p>
                                                          <p style="margin-top: 0px; color: #282828;">
                                                              ※ If
                                                              you received this email in error, please kindly ignore it and
                                                              delete this message
                                                              from your system. </p>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                              <tr class="row mb-4 mt-4">
                                  <td class="col">
                                      <div class="card" style="background-color: #571B35;">
                                          <div class="card-body">
                                              <table style="margin: auto;">
                                                  <tr class="row mb-4 mt-4">
                                                      <td class="col">
                                                          <br><b style="color: #DBBE78;">株式会社 THE LUXURY FLEET | THE LUXURY
                                                              FLEET Co.,
                                                              Ltd.</b><br><br>
                                                          <span
                                                              style="font-size: 90%; text-align: left; color: #DBBE78">〒140-0001
                                                              東京都品川区北品川1-8-20
                                                              第2林ビル 1階/2階</span><br>
                                                          <span style="font-size: 90%; text-align: left; color: #DBBE78">Dai 2
                                                              Hayashi Building
                                                              1F/2F, Kitashinagawa 1-8-20, Shinagawa-ku, Tokyo 140-0001
                                                          </span><br><br>
                                                          <span style="font-size: 90%; text-align: left; color: #DBBE78">Tel.
                                                              (+81)
                                                              3-6822-8772</span><br><br>
                                                          <span
                                                              style="font-size: 90%; text-align: left; color: #DBBE78">Copyright
                                                              © 2023 THE
                                                              LUXURY FLEET A member of MAHA Group</span><br><br>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </table>
                      </div>
                  </td>
              </tr>
          </table>
      </body>
      </html>
`;

  return template;
}

export const getBookingCancelEmailTemplate = (labelData) => {
  if (labelData.pax) {
    var passen = "";

    labelData.pax.map((d) => {
      passen += `<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;"><em>${d.paxtype}:</em> ${d.paxcount}</span></p>`;
    });
  }

  if (labelData.destinations) {
    var des = "";

    labelData.destinations.map((d) => {
      des += `<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;">${d.label}</span></p>`;
    });
  }

  var template = `<!DOCTYPE html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="color-scheme" content="light only">
        <title>Your booking is awaiting confirmation </title>
    </head>
    <body style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0;" bgcolor="#f6f6f6">
    <table style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; role: presentation; cellpadding: 0; cellspacing: 0; border: 0;" bgcolor="#f6f6f6">
      <tr>
        <td style="font-family: sans-serif; font-size: 14px;" valign="top"> </td>
        <td style="font-family: sans-serif; font-size: 14px; display: block; max-width: 580px; width: 580px; margin: 0 auto; padding: 10px;" valign="top">
          <div style="box-sizing: border-box; display: block; max-width: 580px; margin: 0 auto; padding: 10px;">

            
            <table style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; border-radius: 3px;" bgcolor="#ffffff">

              

              <tr>
                <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; padding: 20px;" valign="top">
                  <table style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; role: presentation; cellpadding: 0; cellspacing: 0; border: 0;">
                     <tr>
                <td style="font-family: sans-serif; font-size: 14px; color: #d3ac50; margin-top: 20px; margin-bottom: 20px; padding: 10px;" bgcolor="#461e2b" valign="top"> 
                  <img src="https://booking.theluxuryfleet.com/tlf-logo.png" alt="THE LUXURY FLEET Logo" title="TLF logo" style="outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; clear: both; display: inline-block !important; height: auto; max-width: 139.2px; width: 139.2px; border: none;" align="none">
                </td>
              </tr>
                        <tr>
                          <td style="font-family: sans-serif; font-size: 14px;" valign="top">
                            <div style="background-color: #d3ac50; color: #461e2b; margin-top: 20px; margin-bottom: 20px; padding: 10px;"> <b>日本語 (English below) </b>
    </div>
    

    <h2 style="color: #000000; font-family: sans-serif; font-weight: 400; line-height: 1.4; margin: 0 0 30px;">ご予約をキャンセルいしました。ご請求はございません。 <br>
    </h2>
                           <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; color: #464c4f; margin: 0 0 1px;">${labelData.name
    } 様,</p>
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; color: #464c4f; margin: 0 0 1px;">予約をキャンセルし、クレジットカードの事前承認を取り消しいたしました。<br><br>
    
                            こちらが間違いである場合は、<a href="mailto:info@theluxuryfleet.com">info@theluxuryfleet.com</a>、または <a href="tel:03-6822-8772">03-6822-8772</a>に連絡してください。<br><br>
                            キャンセルされた予約の詳細は以下をご覧ください。 またのご予約をお待ちしております。<br><br>
    </p>
                            <div> <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"> <b>Total booking cost:</b></p> <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"> ${labelData.total.toLocaleString()} 円</p> </div>
    <div style="background-color: #461e2b; color: #d3ac50; margin-top: 20px; margin-bottom: 20px; padding: 10px;"> <b>Booking details </b>
    </div>
                            <div>
        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>配車先</b></span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;">${labelData.pickupLocation
    }</span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>目的地:</b></span></p>
    ${des}
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>選択された車両:</b></span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span>${labelData.vehicleTypeName
    }</span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>乗客:</b></span></p>
    ${passen}
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>日時:</b></span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;"><em>Pick-up: </em>${moment(
      labelData.travelDate
    ).format("yyyy/MM/DD hh:mm A")}</span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;"><em>Drop-off:</em> ${moment(
      labelData.userAddedDropoffDatetime
    ).format("yyyy/MM/DD hh:mm A")}</span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>料金：</b></span></p>
    <p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;">${labelData.total.toLocaleString()} 円</span></p>
        <hr style="border-top-color: #bbb; border-top-style: solid; margin: 20px 0; border-width: 3px 0 0;">
    </div>
      
                            
    
      <div style="background-color: #d3ac50; color: #461e2b; margin-top: 20px; margin-bottom: 20px; padding: 10px;"> <b>In English </b>
    </div>
                            
                          </td>
                       
                        </tr>
                        <tr>
                        <td style="font-family: sans-serif; font-size: 14px;" valign="top">
                       
                          <h2 style="color: #000000; font-family: sans-serif; font-weight: 400; line-height: 1.4; margin: 0 0 30px;"> We have cancelled your booking — you have not been charged.</h2>

                          <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; color: #464c4f; margin: 0 0 1px;">Hi ${labelData.name
    },<br><br></p>
                          <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; color: #464c4f; margin: 0 0 1px;">We have cancelled your booking and released the pre-authorization from your credit card.
  <br><br>
  If this is a mistake, please contact our team at: <a href="mailto:info@theluxuryfleet.com" style="color: #3498db; text-decoration: underline;">info@theluxuryfleet.com</a>, or on <a href="tel:0081368228772" style="color: #3498db; text-decoration: underline;"> (+81) 3-6822-8772</a>.
  <br><br>
  You can find the details of the cancelled booking below. We hope to serve you again in the future.
  <br><br>
  </p>
  <div> <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"> <b>Total booking cost:</b></p> <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"> ${labelData.total.toLocaleString()} 円</p> </div>
  
    <div style="background-color: #461e2b; color: #d3ac50; margin-top: 20px; margin-bottom: 20px; padding: 10px;"> <b>Booking details </b>
    </div>
    <div>
    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Pick-up point:</b></span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;">${labelData.pickupLocation
    }</span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Destinations:</b></span></p>
${des}
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Selected vehicle:</b></span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span>${labelData.vehicleTypeName
    }</span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Passengers:</b></span></p>
${passen}
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Dates & times:</b></span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;"><em>Pick-up: </em>${labelData.travelDate
    }</span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #464c4f;"><em>Drop-off:</em> ${labelData.userAddedDropoffDatetime
    }</span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;"><b>Price :</b></span></p>
<p style="font-size: 14px; line-height: 140%; font-family: sans-serif; font-weight: normal; margin: 0 0 1px;"><span style="font-size: 14px; line-height: 22.4px; color: #d3ac50;">${labelData.total.toLocaleString()} 円</span></p>
    <hr style="border-top-color: #bbb; border-top-style: solid; margin: 20px 0; border-width: 3px 0 0;">
</div>
      
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;"><br></p>
                          </td>
                        </tr>
    
                      </table>
                    </td>
                  </tr>
    
                
                </table>
                
    
                
                <div style="clear: both; margin-top: 10px; width: 100%;" align="center">
                  <table style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; role: presentation; cellpadding: 0; cellspacing: 0; border: 0;">
                    <tr>
                      <td style="font-family: sans-serif; font-size: 12px; padding-bottom: 10px; padding-top: 10px; color: #999999;" align="center" valign="top">
                        <span style="color: #999999; font-size: 12px; text-align: center;">株式会社THE LUXURY FLEET, 〒140-0001 東京都品川区北品川1-8-20 第2林ビル 1階/2階</span>
                        <br> Tel. (+81) 3-6822-8772.
                      </td>
                    </tr>
                    <tr>
                    
                    </tr>
                  </table>
                </div>
                
    
              </div>
            </td>
            <td style="font-family: sans-serif; font-size: 14px;" valign="top"> </td>
          </tr>
        </table>
      </body>
    </html>
    `;
  return template;
};

export const getVerficationAccoutnEmailTemplate = (customer_name, verification_link) => {
  var template = `<!DOCTYPE html>
              <html>
                <head>

                    <meta name="viewport" content="width=device-width, initial-scale=1.0">

                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <title>Email Verification</title>

                </head>


                <body style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4;
                    -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0;" bgcolor="#f6f6f6">
                    <table
                        style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; role: presentation; cellpadding: 0; cellspacing: 0; border: 0;"
                        bgcolor="#f6f6f6">
                        <tr>
                            <td style="font-family: sans-serif; font-size: 14px;" valign="top"> </td>
                            <td style="font-family:
                                sans-serif; font-size: 14px; display: block; max-width: 580px; width: 580px; margin: 0 auto; padding:
                                10px;" valign="top">
                                <div style="box-sizing: border-box; display: block; max-width: 580px; margin:
                                    0 auto; padding: 10px;">
                                    <table style="border-collapse: separate; mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt; width: 100%; border-radius: 3px;" bgcolor="#ffffff">
                                        <tr>
                                            <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; padding:
                                                20px;" valign="top">
                                                <table style="border-collapse: separate; mso-table-lspace:
                                                    0pt; mso-table-rspace: 0pt; width: 100%; role: presentation; cellpadding: 0;
                                                    cellspacing: 0; border: 0;">
                                                    <tr>
                                                        <td style="font-family: sans-serif;
                                                            font-size: 14px; color: #d3ac50; margin-top: 20px; margin-bottom: 20px;
                                                            padding: 10px; display: flex; justify-content: center;" bgcolor="#461e2b" valign="top">
                                                            <img src="${process.env.REACT_APP_URL}/tlf-logo.png" alt="THE LUXURY
                                                                FLEET Logo" title="TLF logo" style="outline: none; text-decoration:
                                                                none; -ms-interpolation-mode: bicubic; clear: both; display:
                                                                inline-block !important; height: auto; max-width: 139.2px; width:
                                                                139.2px; border: none;" align="none">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-family: sans-serif; font-size: 14px;" valign="top">
                                                            <div style="background-color: #d3ac50; color: #461e2b; margin-top: 20px;
                                                                margin-bottom: 20px; padding: 10px;"> <b>日本語で (English below) </b>

                                                            </div>

                                                            <h2 style="color: #000000; font-family: sans-serif;
                                                                font-weight: 400; line-height: 1.4; margin: 0 0 30px;">アカウントの保護にご協力ください <br>
                                                            </h2>
                                                            <p style="font-family:
                                                                sans-serif; font-size: 14px; font-weight: normal; color: #464c4f;
                                                                margin: 0 0 1px;">
                                                                `+ customer_name + `  様,<br><br>
                                                                サインインする前に、本人確認を行う必要があります。 下のリンクをクリックしてメールを確認してください:<br><br>
                                                            </p>
                                                            <p style="font-family: sans-serif; font-size: 14px; font-weight:
                                                                normal; color: #464c4f; margin: 0 0 1px;"><b></b><a
                                                                    href="`+ verification_link + `">` + verification_link + `</a><br><br>このリンクは 24 時間後に期限切れになります。 The Luxury Fleet アカウントにサインアップしていない場合は、このメールを無視しても問題ありません。<br><br>
                                                            </p>
                                                            <div style="background-color: #d3ac50; color: #461e2b;
                                                                margin-top: 20px; margin-bottom: 20px; padding: 10px;"> <b>In English
                                                                </b>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-family: sans-serif; font-size:
                                                            14px;" valign="top">
                                                            <h2 style="color: #000000; font-family:
                                                                sans-serif; font-weight: 400; line-height: 1.4; margin: 0 0 30px;"> Help us protect your account</h2>
                                                            <p style="font-family: sans-serif;
                                                                font-size: 14px; font-weight: normal; color: #464c4f; margin: 0 0
                                                                1px;">Hi  `+ customer_name + ` ,<br><br></p>
                                                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal;
                                                                color: #464c4f; margin: 0 0 1px;">Before you sign in, we need to verify your identity. Click on the link below to verify your email:
                                                                <br><br><a
                                                                    href="`+ verification_link + `">` + verification_link + `</a><br><br>
                                                                    This link will expire in 24 hours. If you did not sign up for a The Luxury Fleet account,
                                                                    you can safely ignore this email.
                                                            </p>
                                                            <p style="font-family:
                                                                sans-serif; font-size: 14px; font-weight: normal; margin: 0 0 1px;">
                                                                <br>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <div style="clear: both; margin-top: 10px; width: 100%;" align="center">
                                        <table style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width:
                                            100%; role: presentation; cellpadding: 0; cellspacing: 0; border: 0;">
                                            <tr>
                                                <td style="font-family: sans-serif; font-size: 12px; padding-bottom: 10px; padding-top:
                                                    10px; color: #999999;" align="center" valign="top">
                                                    <span style="color:
                                                        #999999; font-size: 12px; text-align: center;">株式会社THE LUXURY FLEET, 〒140-0001
                                                        東京都品川区北品川1-8-20 第2林ビル 1階/2階</span>
                                                    <br> Tel. (+81) 3-6822-8772.
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                            <td style="font-family: sans-serif; font-size: 14px;" valign="top"> </td>
                        </tr>
                    </table>
                  </body>
              </html>
    `;

  return template;
}

export const getPaymentSuccessAdminEmailTemplate = (bookings) => {
  var template = `
  <!DOCTYPE html>
  <html>
  
  
  <head>
  
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
  
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Email Verification</title>
      <style>
          .bg-page {
              position: relative;
              width: 100%;
              /* height: 300px; */
              /* Set the height */
          }
  
          .bg-image {
              object-fit: cover;
              width: 100%;
              height: 70%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
          }
  
          .row {
              margin-bottom: 15px;
          }
  
  
          .card {
              background-color: #ffffff;
              padding: 20px;
          }
  
          .card-title {
              font-size: 22px;
              font-weight: 900;
          }
  
          .line-gold {
              background-color: #DBBE78;
              height: 7px;
              border-radius: 5px 5px 0 0;
              width: 100%;
          }
  
          .btn {
              font-weight: 500;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              cursor: pointer;
              border: 1px solid transparent;
              padding: 5px 20px;
              line-height: 1.5;
              border-radius: 0.25rem;
          }
  
          .btn-warning {
              color: #4A4A4A !important;
              background-color: #DBBE78;
              border-color: #DBBE78;
          }
  
          .btn-warning:hover {
              background-color: #b9995e;
              border-color: #b9995e;
          }
  
          .b {
              font-family: 'Noto Serif JP';
              color: #282828;
          }
  
          h1 {
              color: #282828;
              font-family: 'Noto Serif JP';
              font-size: 22px;
          }
  
          h2 {
              color: #4E4E4E;
              font-family: 'Noto Serif JP';
              font-size: 14px;
              font-weight: 600;
          }
  
          span {
              font-family: 'Noto Sans JP';
              color: #282828;
          }
  
          p,
          b {
              font-family: 'Noto Serif JP';
              color: #282828;
          }
  
          a,
          a:hover,
          a:visited,
          a:active {
              color: inherit;
              text-decoration: none;
          }
  
          .thin-line {
              margin: 2px 0;
              border: 0;
              border-top: 1px solid #ccc;
          }
  
          ul {
              margin-left: 20px;
              padding: 0;
          }
  
          /* Mobile View */
          @media screen and (max-width: 767px) {
              img.tlf-logo {
                  width: 100px;
                  height: 63px;
                  display: block;
                  margin: 0 auto;
              }
  
              .col {
                  width: 100%;
                  max-width: 350px;
                  /* Set your desired maximum width */
                  word-wrap: break-word;
                  /* For older browsers */
              }
          }
  
          /* Desktop View */
          @media screen and (min-width: 768px) {
              img.tlf-logo {
                  width: 105px;
                  height: 65px;
                  display: block;
                  margin: 0 auto;
              }
  
              .col {
                  width: 100%;
                  max-width: 500px;
                  /* Set your desired maximum width */
                  word-wrap: break-word;
                  /* For older browsers */
              }
          }
      </style>
  
  </head>
  
  
  <body
      style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0; background-color: #F6F6F6;">
      <table style=" border: 0; margin: 0 auto; justify-content: center; ">
          <tr class="bg-page">
              <td style="display: inline-table; font-family:sans-serif; font-size: 14px; margin: 0 auto; padding:10px;"
                  valign="top">
                  <div style="box-sizing: border-box; display: block;  margin: 0 auto; padding-top: 10px; ">
                      <table style="border-collapse: collapse; width: 100%; border-radius: 1px;" bgcolor="transparent">
                          <tr class="row" style="height: 100px; width: 100%; background-color: #282828;">
                              <td class="col">
                                  <img class="tlf-logo" src="${process.env.REACT_APP_URL}/tlf-logo.png"
                                      alt="THE LUXURY FLEET Logo" title="TLF logo"
                                      style="display: block; margin: 0 auto;">
                              </td>
                          </tr>
                          <tr
                              style="background-color: white; margin-top: 1px; padding: 1px; height: 5px; width: 50px;  margin: 0; padding: 0;">
                              <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;"
                                  valign="top">
                                  <table style="border-collapse: separate; width: 100%; border: 0;">
                                      <tr>
                                          <td style="font-family: sans-serif; font-size:14px;" valign="top">
                                              <div class="card" style="padding-top: 0px; margin-top: 0px;">
                                                  <div class="card-body">
                                                      <table style="margin: auto;">
                                                          <tr class="row mb-4 mt-4" style="height: 80px; ">
                                                              <td class="col" style="text-align: center;">
                                                                  <h1 class="card-title">新規予約を受注しました</h1>
                                                                  <h1 class="card-title" style="margin-top: -15px;">New
                                                                      Booking Received</h1>
                                                              </td>
                                                          </tr>
                                                          <tr class="row mb-4">
                                                              <td class="col" style="text-align: center;">
                                                                  <p style="margin-bottom: 1px; max-width: 400px; overflow-wrap: break-word; margin: auto;">
                                                                      ご担当者様、theluxuryfleet.com
                                                                      から、新規予約を受注しました。
                                                                      Hello Operation Team, we have received a
                                                                      new booking
                                                                      from theluxuryfleet.com</p><br>
                                                                  <p style="margin-bottom: 1px; max-width: 400px; overflow-wrap: break-word; margin: auto;">
                                                                      以下のボタンをクリックして、予約の詳細を確認してください。
                                                                      Kindly click on the button below to
                                                                      confirm booking
                                                                      details</p><br>
                                                              </td>
                                                          </tr>
`;
  bookings.map((item, index) => {
    var dashboardBookingLink = "${process.env.REACT_APP_URL}/admin/bookinglist/edit/" + item._id;
    template += `
              <tr class="row mb-4" style="background-color: #FFF8E8;">
                  <td class="col">
                      <p
                          style="margin-bottom: 0px; font-weight: bolder; text-align: center;">
                          予約の詳細/Booking Details</p>
                      <hr
                          style="background-color: #DBBE78; height:2px; border: 0;">
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          予約者/Reservation Holder:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.cartName}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          予約タイプ/Transfer Type:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.isAirportTransfer ? 'Airport Transfer' : item.byTheHourBook ? 'By The Hour' : 'Specified Destination'}</p>`;
    if (item.isAirportTransfer) {
      template += `<p
                                              style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                              フライトナンバー/Flight No.:</p>
                                          <p
                                              style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                              ${'Pickup : ' + item.pickupFlightNo ?? '-' + " " + 'Dropoff : ' + item.dropoffFlightNo ?? '-'}</p>`;
    }
    if(item.pickupSign != null){
        template += `
        <p
            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
            Pickup Sign : ${item.pickupSign} </p>
        `;
    }
    template += `
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                          日時/Date & Time:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                          Pick-up:
                          ${moment(item.travelDate).format("YYYY/MM/DD HH:mm")}</p>
                      
                      <p
                      style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                      Drop-off:
                      ${moment(item.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD HH:mm")}</p>
                      
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                          配車先/Pick-up Location:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.pickupLocation.label}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                          目的地/Drop-off Location:</p>
    `;
    item.destinations.map((itm, ind) => {
      template += `<p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                        ${itm.label}</p>`;
    })
    item.bookingInfo.map((val, ind) => {
      template += `
      <p style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              選択された車両/Car Type:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
              ${val.vehicle.vehicletype}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              乗客/Passengers:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              大人/Adult: ${val.pax[0].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              お子様/Child: ${val.pax[1].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              幼児/Toddler: ${val.pax[2].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              ベビー/Baby: ${val.pax[3].paxcount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              荷物/Luggage:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              機内持ち込み手荷物/Cabin Size: ${val.luggage[1].luggagecount}
          </p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              預け入れ手荷物/Check-in Size: ${val.luggage[0].luggagecount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
              Child Seats:</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Infant: ${val.childSeat[0].childSeatCount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Toddler: ${val.childSeat[1].childSeatCount}</p>
          <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              Child: ${val.childSeat[2].childSeatCount}</p>
      `
    })
    template += `
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                          合計/Total Price:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.totalPrice.toLocaleString()}円
                      </p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                          メッセージ・お問い合わせ/Message・Inquiry:</p>
    `;
    if (item.message.length > 0) {
      item.message.map((itm, ind) => {
        template += `<p
                                  style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                  ${itm.note == "" ? '-' : itm.note}
                              </p>`;
      })
    } else {
      template += `<p
                                  style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                  -
                              </p>`;
    }
    template += `
  <p style = "margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;"> お支払い参照コード / Payment Reference: </p>
  <p style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
    ${item.stripeRef}
  </p>
</td >
</tr >
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row mb-4 mt-4" style="background-color: #FFF8E8;">
                  <td class="col">
                      <p
                          style="margin-bottom: 0px; font-weight: bolder; text-align: center;">
                          お客様情報/Customer Information</p>
                      <hr
                          style="background-color: #DBBE78; height:2px; border: 0;">
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          予約者/Reservation Holder:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.name}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          メール/Email:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.email}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          電話番号/Phone No.:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.phone}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          国/Country:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.country ?? '-'}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          郵便番号/Postal Code:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.postcode ?? '-'}</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          住所/Address:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${item.customer.address}</p>
                  </td>
              </tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row"></tr>
              <tr class="row mb-4 mt-4">
                  <td class="col">
                      <a href="`+ dashboardBookingLink + `" class="btn btn-warning"
                          style="margin-top: 0px; text-align: center; background-color: #DBBE78; font-family: 'Noto Sans JP'; width: -webkit-fill-available;">予約詳細へ
                          (Proceed to Booking List)</a><br><br>
                  </td>
              </tr>
      `;
  })

  template += `
                                                  </table>
                                              </div>
                                          </div >
                                      </td >
                                  </tr >
                              </table >
                          </td >
                      </tr >
  <tr class="row mb-4 mt-4">
    <td class="col">
      <div class="card" style="background-color: #282828;">
        <div class="card-body">
          <table style="margin: auto;">
            <tr class="row mb-4 mt-4">
              <td class="col">
                <br><b style="color: #DBBE78;">株式会社 THE LUXURY FLEET | THE LUXURY
                  FLEET Co.,
                  Ltd.</b><br><br>
                    <span
                      style="font-size: 90%; text-align: left; color: #DBBE78">〒140-0001
                      東京都品川区北品川1-8-20
                      第2林ビル 1階/2階</span><br>
                      <span style="font-size: 90%; text-align: left; color: #DBBE78">Dai 2
                        Hayashi Building
                        1F/2F, Kitashinagawa 1-8-20, Shinagawa-ku, Tokyo 140-0001
                      </span><br><br>
                        <span style="font-size: 90%; text-align: left; color: #DBBE78">Tel.
                          (+81)
                          3-6822-8772</span><br><br>
                              <span
                                style="font-size: 90%; text-align: left; color: #DBBE78">Copyright
                                © 2023 THE
                                LUXURY FLEET A member of MAHA Group</span><br><br>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      </tr>
                    </table>
                  </div>
                  </td>
                </tr>
              </table>
            </body>
          </html>
          `;

  return template
};

export const getEditBookingAdminEmailTemplate = (requestChange, bookings) => {
    var template = `
    <!DOCTYPE html>
    <html>
    
    
    <head>
    
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <title>Email Verification</title>
        <style>
            .bg-page {
                position: relative;
                width: 100%;
                /* height: 300px; */
                /* Set the height */
            }
    
            .bg-image {
                object-fit: cover;
                width: 100%;
                height: 70%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
    
            .row {
                margin-bottom: 15px;
            }
    
    
            .card {
                background-color: #ffffff;
                padding: 20px;
            }
    
            .card-title {
                font-size: 22px;
                font-weight: 900;
            }
    
            .line-gold {
                background-color: #DBBE78;
                height: 7px;
                border-radius: 5px 5px 0 0;
                width: 100%;
            }
    
            .btn {
                font-weight: 500;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                cursor: pointer;
                border: 1px solid transparent;
                padding: 5px 20px;
                line-height: 1.5;
                border-radius: 0.25rem;
            }
    
            .btn-warning {
                color: #4A4A4A !important;
                background-color: #DBBE78;
                border-color: #DBBE78;
            }
    
            .btn-warning:hover {
                background-color: #b9995e;
                border-color: #b9995e;
            }
    
            .b {
                font-family: 'Noto Serif JP';
                color: #282828;
            }
    
            h1 {
                color: #282828;
                font-family: 'Noto Serif JP';
                font-size: 22px;
            }
    
            h2 {
                color: #4E4E4E;
                font-family: 'Noto Serif JP';
                font-size: 14px;
                font-weight: 600;
            }
    
            span {
                font-family: 'Noto Sans JP';
                color: #282828;
            }
    
            p,
            b {
                font-family: 'Noto Serif JP';
                color: #282828;
            }
    
            a,
            a:hover,
            a:visited,
            a:active {
                color: inherit;
                text-decoration: none;
            }
    
            .thin-line {
                margin: 2px 0;
                border: 0;
                border-top: 1px solid #ccc;
            }
    
            ul {
                margin-left: 20px;
                padding: 0;
            }
    
            /* Mobile View */
            @media screen and (max-width: 767px) {
                img.tlf-logo {
                    width: 100px;
                    height: 63px;
                    display: block;
                    margin: 0 auto;
                }
    
                .col {
                    width: 100%;
                    max-width: 350px;
                    /* Set your desired maximum width */
                    word-wrap: break-word;
                    /* For older browsers */
                }
            }
    
            /* Desktop View */
            @media screen and (min-width: 768px) {
                img.tlf-logo {
                    width: 105px;
                    height: 65px;
                    display: block;
                    margin: 0 auto;
                }
    
                .col {
                    width: 100%;
                    max-width: 500px;
                    /* Set your desired maximum width */
                    word-wrap: break-word;
                    /* For older browsers */
                }
            }
        </style>
    
    </head>
    
    
    <body
        style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0; background-color: #F6F6F6;">
        <table style=" border: 0; margin: 0 auto; justify-content: center; ">
            <tr class="bg-page">
                <td style="display: inline-table; font-family:sans-serif; font-size: 14px; margin: 0 auto; padding:10px;"
                    valign="top">
                    <div style="box-sizing: border-box; display: block;  margin: 0 auto; padding-top: 10px; ">
                        <table style="border-collapse: collapse; width: 100%; border-radius: 1px;" bgcolor="transparent">
                            <tr class="row" style="height: 100px; width: 100%; background-color: #282828;">
                                <td class="col">
                                    <img class="tlf-logo" src="${process.env.REACT_APP_URL}/tlf-logo.png"
                                        alt="THE LUXURY FLEET Logo" title="TLF logo"
                                        style="display: block; margin: 0 auto;">
                                </td>
                            </tr>
                            <tr
                                style="background-color: white; margin-top: 1px; padding: 1px; height: 5px; width: 50px;  margin: 0; padding: 0;">
                                <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;"
                                    valign="top">
                                    <table style="border-collapse: separate; width: 100%; border: 0;">
                                        <tr>
                                            <td style="font-family: sans-serif; font-size:14px;" valign="top">
                                                <div class="card" style="padding-top: 0px; margin-top: 0px;">
                                                    <div class="card-body">
                                                        <table style="margin: auto;">
                                                            <tr class="row mb-4 mt-4" style="height: 80px; ">
                                                                <td class="col" style="text-align: center;">
                                                                    <h1 class="card-title">予約内容変更のリクエストを受け取りました</h1>
                                                                    <h1 class="card-title" style="margin-top: -15px;">Booking Modification Request Received</h1>
                                                                </td>
                                                            </tr>
                                                            <tr class="row mb-4">
                                                                <td class="col" style="text-align: center;">
                                                                    <p style="margin-bottom: 1px; max-width: 400px; overflow-wrap: break-word; margin: auto;">
                                                                    ご担当者様、theluxuryfleet.com から、予約内容変更のリクエストを受け取りました。</p><br>
                                                                    <p style="margin-bottom: 1px; max-width: 400px; overflow-wrap: break-word; margin: auto;">
                                                                    Hello Operation Team,  we have received a booking modification request from theluxuryfleet.com</p><br>
                                                                </td>
                                                            </tr>
                                                            <tr class="row mb-4" style="background-color: #FFF8E8;">
                                                                <td class="col">
                                                                    <p
                                                                        style="margin-bottom: 0px; font-weight: bolder; text-align: center;">
                                                                        変更後の予約内容/Requested Booking Details</p>
                                                                    <hr
                                                                        style="background-color: #DBBE78; height:2px; border: 0;">
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                                        日時/Date & Time:</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                                                                        Pick-up:
                                                                        ${moment(requestChange.travelDateRequest).format("YYYY/MM/DD HH:mm")}</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                                                                        Drop-off:
                                                                        ${moment(requestChange.dropoffTimeRequest).format("YYYY/MM/DD HH:mm")}</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                                        ご乗車場所/Pick-up Location:</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                                                        ${requestChange.pickupLocationRequest.label}</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                                                                        ご降車場所/Drop-off Location:</p>`;
                                                                    requestChange.destinationsRequest.map((itm, ind) => {
                                                                        template += `<p
                                                                                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                                                                                        ${itm.label}</p>`;
                                                                    });
                                                            template += `<p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                                        メッセージ・お問い合わせ/Message・Inquiry:</p>
                                                                    <p
                                                                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                                                        None</p>`;
                                                            if(requestChange.stripeRef !== undefined){
                                                                template += `<p
                                                                                style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                                                追加料金/Additional Payment:</p>
                                                                            <p
                                                                                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                                                                ${requestChange.totalPrice - bookings[0].totalPrice}</p>
                                                                            <p
                                                                                style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                                                お支払い参照コード/Payment Reference:</p>
                                                                            <p
                                                                                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                                                                ${requestChange.stripeRef}</p>`;
                                                            }
                                                    template += `</td>
                                                            </tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
                                                            <tr class="row"></tr>
  `;
    bookings.map((item, index) => {
      var dashboardBookingLink = "http://localhost:3000/admin/cancel-reschedule-request/manage/" + requestChange._id;
      template += `
                <tr class="row mb-4 mt-4" style="background-color: #FFF8E8;">
                    <td class="col">
                        <p
                            style="margin-bottom: 0px; font-weight: bolder; text-align: center;">
                            変更前の予約内容/Previous Booking Details</p>
                        <hr
                            style="background-color: #DBBE78; height:2px; border: 0;">
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            予約者/Reservation Holder:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.cartName}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            予約タイプ/Transfer Type:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.isAirportTransfer ? 'Airport Transfer' : item.byTheHourBook ? 'By The Hour' : 'Specified Destination'}</p>`;
      if (item.isAirportTransfer) {
        template += `<p
                                                style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                                                フライトナンバー/Flight No.:</p>
                                            <p
                                                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                                ${'Pickup : ' + item.pickupFlightNo ?? '-' + " " + 'Dropoff : ' + item.dropoffFlightNo ?? '-'}</p>`;
      }
      if(item.pickupSign != null){
        template += `
        <p
            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
            Pickup Sign : ${item.pickupSign} </p>
        `;
    }
      template += `
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                            日時/Date & Time:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                            Pick-up:
                            ${moment(item.travelDate).format("YYYY/MM/DD HH:mm")}</p>
                        
                        <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                        Drop-off:
                        ${moment(item.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD HH:mm")}</p>
                        
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                            配車先/Pick-up Location:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.pickupLocation.label}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                            目的地/Drop-off Location:</p>
      `;
      item.destinations.map((itm, ind) => {
        template += `<p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                          ${itm.label}</p>`;
      })
      item.bookingInfo.map((val, ind) => {
        template += `
        <p style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                選択された車両/Car Type:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                ${val.vehicle.vehicletype}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                乗客/Passengers:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                大人/Adult: ${val.pax[0].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                お子様/Child: ${val.pax[1].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                幼児/Toddler: ${val.pax[2].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                ベビー/Baby: ${val.pax[3].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                荷物/Luggage:</p>
            <p
              style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
              機内持ち込み手荷物/Cabin Size: ${val.luggage[1].luggagecount}
            </p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                預け入れ手荷物/Check-in Size: ${val.luggage[0].luggagecount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                Child Seats:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Infant: ${val.childSeat[0].childSeatCount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Toddler: ${val.childSeat[1].childSeatCount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Child: ${val.childSeat[2].childSeatCount}</p>
        `
      })
      template += `
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                            合計/Total Price:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.totalPrice.toLocaleString()}円
                        </p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                            メッセージ・お問い合わせ/Message・Inquiry:</p>
      `;
      if (item.message.length > 0) {
        item.message.map((itm, ind) => {
          template += `<p
                                    style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                    ${itm.note == "" ? '-' : itm.note}
                                </p>`;
        })
      } else {
        template += `<p
                                    style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                    -
                                </p>`;
      }
      template += `
    <p style = "margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;"> お支払い参照コード / Payment Reference: </p>
    <p style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
      ${item.stripeRef}
    </p>
  </td >
  </tr >
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row mb-4 mt-4" style="background-color: #FFF8E8;">
                    <td class="col">
                        <p
                            style="margin-bottom: 0px; font-weight: bolder; text-align: center;">
                            お客様情報/Customer Information</p>
                        <hr
                            style="background-color: #DBBE78; height:2px; border: 0;">
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            予約者/Reservation Holder:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.name}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            メール/Email:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.email}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            電話番号/Phone No.:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.phone}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            国/Country:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.country ?? '-'}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            郵便番号/Postal Code:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.postcode ?? '-'}</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                            住所/Address:</p>
                        <p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                            ${item.customer.address}</p>
                    </td>
                </tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row"></tr>
                <tr class="row mb-4 mt-4">
                    <td class="col">
                        <a href="`+ dashboardBookingLink + `" class="btn btn-warning"
                            style="margin-top: 0px; text-align: center; background-color: #DBBE78; font-family: 'Noto Sans JP'; width: -webkit-fill-available;">予約詳細へ (Proceed to Booking List)</a><br><br>
                    </td>
                </tr>
        `;
    })
  
    template += `
                                                    </table>
                                                </div>
                                            </div >
                                        </td >
                                    </tr >
                                </table >
                            </td >
                        </tr >
    <tr class="row mb-4 mt-4">
      <td class="col">
        <div class="card" style="background-color: #282828;">
          <div class="card-body">
            <table style="margin: auto;">
              <tr class="row mb-4 mt-4">
                <td class="col">
                  <br><b style="color: #DBBE78;">株式会社 THE LUXURY FLEET | THE LUXURY
                    FLEET Co.,
                    Ltd.</b><br><br>
                      <span
                        style="font-size: 90%; text-align: left; color: #DBBE78">〒140-0001
                        東京都品川区北品川1-8-20
                        第2林ビル 1階/2階</span><br>
                        <span style="font-size: 90%; text-align: left; color: #DBBE78">Dai 2
                          Hayashi Building
                          1F/2F, Kitashinagawa 1-8-20, Shinagawa-ku, Tokyo 140-0001
                        </span><br><br>
                          <span style="font-size: 90%; text-align: left; color: #DBBE78">Tel.
                            (+81)
                            3-6822-8772</span><br><br>
                                <span
                                  style="font-size: 90%; text-align: left; color: #DBBE78">Copyright
                                  © 2023 THE
                                  LUXURY FLEET A member of MAHA Group</span><br><br>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                        </tr>
                      </table>
                    </div>
                    </td>
                  </tr>
                </table>
              </body>
            </html>
            `;
  
    return template
  };

  export const getEditBookingEmailTemplate = (requestChange, bookings) => {
    var totalPrice = 0;
    var totalBooking = bookings.length;
  
    bookings.map((i) => {
      totalPrice += i.totalPrice;
    })
  
    var template = `
        <!DOCTYPE html>
        <html>
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Email Verification</title>
            <style>
                .bg-page {
                    position: relative;
                    width: 100%;
                    /* height: 300px; */
                    /* Set the height */
                }
                .bg-image {
                    object-fit: cover;
                    width: 100%;
                    height: 70%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
                .row {
                    margin-bottom: 15px;
                }
                .card {
                    background-color: #ffffff;
                    padding: 20px;
                }
                .card-title {
                    font-size: 22px;
                    font-weight: 900;
                }
                .line-gold {
                    background-color: #DBBE78;
                    height: 7px;
                    border-radius: 5px 5px 0 0;
                    width: 100%;
                }
                .btn {
                    font-weight: 500;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid transparent;
                    padding: 5px 20px;
                    line-height: 1.5;
                    border-radius: 0.25rem;
                }
                .btn-warning {
                    color: #4A4A4A !important;
                    background-color: #DBBE78;
                    border-color: #DBBE78;
                }
                .btn-warning:hover {
                    background-color: #b9995e;
                    border-color: #b9995e;
                }
                .b {
                    font-family: 'Noto Serif JP';
                    color: #282828;
                }
                h1 {
                    color: #282828;
                    font-family: 'Noto Serif JP';
                    font-size: 22px;
                }
                h2 {
                    color: #4E4E4E;
                    font-family: 'Noto Serif JP';
                    font-size: 14px;
                    font-weight: 600;
                }
                span {
                    font-family: 'Noto Sans JP';
                    color: #282828;
                }
                p,
                b {
                    font-family: 'Noto Serif JP';
                    color: #282828;
                }
                a,
                a:hover,
                a:visited,
                a:active {
                    color: inherit;
                    text-decoration: none;
                }
                .thin-line {
                    margin: 2px 0;
                    border: 0;
                    border-top: 1px solid #ccc;
                }
                ul{
                    margin-left: 20px;
                    padding: 0;
                    color: #282828;
                }
                li {
                    color: #282828;
                }
                /* Mobile View */
                @media screen and (max-width: 767px) {
                    img.tlf-logo {
                        width: 100px;
                        height: 63px;
                        display: block;
                        margin: 0 auto;
                    }
                    .col {
                        width: 100%;
                        max-width: 350px;
                        /* Set your desired maximum width */
                        word-wrap: break-word;
                        /* For older browsers */
                    }
                }
                /* Desktop View */
                @media screen and (min-width: 768px) {
                    img.tlf-logo {
                        width: 105px;
                        height: 65px;
                        display: block;
                        margin: 0 auto;
                    }
                    .col {
                        width: 100%;
                        max-width: 500px;
                        /* Set your desired maximum width */
                        word-wrap: break-word;
                        /* For older browsers */
                    }
                }
            </style>
        </head>
        <body
            style="font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0; padding: 0; background-color: #F6F6F6;">
            <table style=" border: 0; margin: 0 auto; justify-content: center; ">
                <tr class="bg-page">
                    <td style="display: inline-table; font-family:sans-serif; font-size: 14px; margin: 0 auto; padding:10px;"
                        valign="top">
                        <div style="box-sizing: border-box; display: block;  margin: 0 auto; padding-top: 10px; ">
                            <table style="border-collapse: collapse; width: 100%; border-radius: 1px;" bgcolor="transparent">
                                <tr class="row" style="height: 120px; width: 100%; background-color: #571B35;">
                                    <td class="col">
                                        <img class="tlf-logo" src="${process.env.REACT_APP_URL}/tlf-logo.png"
                                            alt="THE LUXURY FLEET Logo" title="TLF logo">
                                    </td>
                                </tr>
                                <tr
                                    style="background-color: white; margin-top: 1px; padding: 1px; height: 5px; width: 50px;  margin: 0; padding: 0; margin-top: 0px;">
                                    <td style="font-family: sans-serif; font-size: 14px; box-sizing: border-box; padding: 20px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;"
                                        valign="top">
                                        <table style="border-collapse: separate; width: 100%; border: 0;">
                                            <tr>
                                                <td style="font-family: sans-serif; font-size: 14px;" valign="top">
                                                    <div class="card" style="padding-top: 0px;">
                                                        <div class="card-body">
                                                            <table style="margin: auto;">
                                                                <tr class="row mb-4" style="height: 80px; margin-bottom: 0;">
                                                                    <td class="col" style="text-align: center;">
                                                                        <h1 class="card-title" style="margin-bottom: 0px;">予約を受け取りました</h1>
                                                                        <h1 class="card-title" style="margin-top: 0px;">We
                                                                            have received your booking</h1>
                                                                        <h2 style="margin-bottom: 0px;">
                                                                            24時間以内に確認、請求を行います</h2>
                                                                        <h2 style="margin-top: 0px;">
                                                                            We will
                                                                            review, confirm and charge you for it
                                                                            within 24
                                                                            hours</h2>
                                                                    </td>
                                                                </tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row mb-4">
                                                                    <td class="col">
                                                                        <p style="margin-bottom: 0px;">
                                                                            ${bookings[0].customer.name} 様,
                                                                        </p>
                                                                        <p style="margin-bottom: 0px; margin-top: 0;">
                                                                            この度はご予約いただき誠にありがとうございます。
                                                                            以下の内容で予約を承りました</p>
                                                                        <p style="margin-top: 0;">
                                                                            なお、本メールは、お客様確認用のお知らせであり、<b>予約はまだ確定
                                                                                しておりません。</b></p>
                                                                    </td>
                                                                </tr>
                                                                <tr class="row mb-4">
                                                                    <td class="col">
                                                                        <p style="margin-bottom: 0px;">Hi
                                                                            ${bookings[0].customer.name},</p>
                                                                        <p style="margin-bottom: 0px; margin-top: 0;">
                                                                            We have
                                                                            received your booking. </p>
                                                                        <p style="margin-bottom: 0px; margin-top: 0;">
                                                                            We have
                                                                            authorized and reserved the total amount
                                                                            of the
                                                                            booking from your card — but your card
                                                                            has
                                                                            <b>not</b> been charged yet.
                                                                        </p>
                                                                        <p style="margin-bottom: 0px; margin-top: 0;">
                                                                            Our staff
                                                                            will accept or reject your booking in
                                                                            the next 24
                                                                            hours.</p>
                                                                        <p style="margin-bottom: 0px; margin-top: 0;">
                                                                            If your
                                                                            booking is confirmed, your card will be
                                                                            charged for
                                                                            the full booking cost stated below, and
                                                                            you will
                                                                            receive an official confirmation email.
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row mb-4">
                                                                    <td class="col"
                                                                        style="background-color: #FCF8FA; border-color: #571B35; justify-content: center; border: 1px solid #571B35;">
                                                                        <p
                                                                            style="margin-bottom: 1px; font-weight: bolder; text-align: center; color: #571B35;">
                                                                            合計/Total Price (${totalBooking} booking)</p>
                                                                        <p
                                                                            style="margin-top:0px; color: #282828; font-size: 19px; font-family: Noto Sans JP; font-weight: 700; text-align:center;">
                                                                            JPY
                                                                            ${totalPrice.toLocaleString()}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
    `;
  
    bookings.map((item, index) => {
      template += `
            <tr class="row mb-4" style="background-color: #571B35;">
                <td class="col">
                    <p
                        style="font-weight: bolder; text-align: center; color: #E2C47C;">
                        予約の詳細/Booking Details</p>
                </td>
            </tr>
            <tr class="row mb-4" style="background-color: #FCF8FA;">
                <td class="col">
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        予約者/Reservation Holder:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.cartName}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        予約タイプ/Transfer Type:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.isAirportTransfer ? 'Airport Transfer' : item.byTheHourBook ? 'By The Hour' : 'Specified Destination'}</p>`;
  
      if (item.isAirportTransfer) {
        template += `<p
                          style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                          フライトナンバー/Flight No.:</p>
                      <p
                          style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                          ${'Pickup : ' + item.pickupFlightNo ?? '-' + " " + 'Dropoff : ' + item.dropoffFlightNo ?? '-'}</p>`;
      }
      if(item.pickupSign != null){
        template += `
        <p
            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
            Pickup Sign : ${item.pickupSign} </p>
        `;
    }
  
      template += `<p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                        日時/Date & Time:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                        Pick-up:
                        ${moment(item.travelDate).format("YYYY/MM/DD HH:mm")}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        Drop-off:
                        ${moment(item.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD HH:mm")}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                        配車先/Pick-up Location:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.pickupLocation.label}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                        目的地/Drop-off Location:</p>
        `;
        item.destinations.map((itm, ind) => {
          template += `<p
                            style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                            ${itm.label}</p>`;
        })
      item.bookingInfo.map((val, ind) => {
        template += `
        <p style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                選択された車両/Car Type:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                ${val.vehicle.vehicletype}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                乗客/Passengers:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                大人/Adult: ${val.pax[0].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                お子様/Child: ${val.pax[1].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                幼児/Toddler: ${val.pax[2].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                ベビー/Baby: ${val.pax[3].paxcount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                荷物/Luggage:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                機内持ち込み手荷物/Cabin Size: ${val.luggage[1].luggagecount}
            </p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                預け入れ手荷物/Check-in Size: ${val.luggage[0].luggagecount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                Child Seats:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Infant: ${val.childSeat[0].childSeatCount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Toddler: ${val.childSeat[1].childSeatCount}</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px; margin-bottom: 1px;">
                Child: ${val.childSeat[2].childSeatCount}</p>
        `
      })
      template += `
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                合計/Total Price:</p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                ${item.totalPrice.toLocaleString()}円
            </p>
            <p
                style="margin-left: 27px; margin-right: 27px; margin-bottom: 1px; font-weight: bolder;">
                メッセージ・お問い合わせ/Message・Inquiry:</p>
        `;
  
      if (item.message.length > 0) {
        item.message.map((itm, ind) => {
          template += `<p
                                    style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                    ${itm.note == "" ? '-' : itm.note}
                                </p>`;
        })
      } else {
        template += `<p
                                    style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                                    -
                                </p>`;
      }
      template += `
                </td>
            </tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row"></tr>
            <tr class="row mb-4" style="background-color: #571B35;">
                <td class="col">
                    <p
                        style="font-weight: bolder; text-align: center; color: #E2C47C;">
                        お客様情報/Customer Information</p>
                </td>
            </tr>
            <tr class="row mb-4 mt-4" style="background-color: #FCF8FA;">
                <td class="col">
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        予約者/Reservation Holder:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.name}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        メール/Email:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.email}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        電話番号/Phone No.:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.phone}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        国/Country:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.country ?? '-'}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        郵便番号/Postal Code:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.postcode ?? '-'}</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-bottom: 0px; font-weight: bolder;">
                        住所/Address:</p>
                    <p
                        style="margin-left: 27px; margin-right: 27px; margin-top: 0px;">
                        ${item.customer.address}</p>
                </td>
            </tr>`;
    })
  
    template += `
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                                <tr class="row"></tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="row mb-4 mt-4" style="background-color: #FCFBFB;">
                                    <td class="col">
                                        <div class="card">
                                            <div class="card-body">
                                                <table style="margin: auto;">
                                                    <tr class="row mb-4 mt-4">
                                                        <td class="col">
                                                            <h1 style="font-weight: bolder; text-align: left; color: #282828;">
                                                                要確認/Important Notice</h1>
                                                            <hr style="color: #A7A7A7;">
                                                            <ul>
                                                                <li>高速道路使用料・駐車場使用料は別途でご請求申し上げます
                                                                    (空港送迎を除く)。</li>
                                                                <li>22:00〜翌6:00の時間帯のご利用には深夜割増料金が適用され、記載料金の1.25倍をご請求申し上げます。
                                                                </li>
                                                                <li>乗務員の宿泊が必要な旅程又は時間帯である場合は、乗務員の宿泊費を別途ご請求申し上げます。
                                                                </li>
                                                                <li>ご請求金額は、お客様が実際にご乗車される時間ではなく、車両の出庫と帰庫の時間に基づき計算されます。
                                                                </li>
                                                                <li>事故・渋滞で遅延が生じた場合、追加料金が加算されます。</li>
                                                                <li>喫煙されたり車の内装や座席を汚された場合に別途料金申し受けます。
                                                                </li>
                                                                <li>料金は所有時間によって変動する場合がございます。最新の料金表は
                                                                    <u><b><a
                                                                                href="www.theluxuryfleet.com/pricing">www.theluxuryfleet.com/pricing</a></b></u>
                                                                    にてご確認ください。
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>All highway tolls and parking fees
                                                                    will be
                                                                    billed on top of the charter price
                                                                    (except
                                                                    airport transfers).</li>
                                                                <li>There will be an extra fee of 1.25
                                                                    times the
                                                                    prices for any usage between
                                                                    22:00-06:00.</li>
                                                                <li>Overnight trips and late night
                                                                    transfers
                                                                    (22:00-06:00) will require
                                                                    accommodation for the
                                                                    driver that will be billed together
                                                                    with the
                                                                    transfer fee.</li>
                                                                <li>The price will be calculated based
                                                                    on the time
                                                                    the vehicle departs and returns to
                                                                    the garage,
                                                                    not by the time the passenger
                                                                    remains on board.
                                                                </li>
                                                                <li>In the event of delays, including
                                                                    accidents or
                                                                    traffic jams, additional charges
                                                                    will apply.
                                                                </li>
                                                                <li>Clients will be charged with an
                                                                    additional fee
                                                                    for any smoking inside the car or
                                                                    any damage
                                                                    caused to the seats.</li>
                                                                <li>Prices will vary depending on the
                                                                    overall time
                                                                    of transfer. Please refer to
                                                                    <u><b><a
                                                                                href="www.theluxuryfleet.com/pricing">www.theluxuryfleet.com/pricing</a></b></u>
                                                                    for the latest version.
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="row mb-4 mt-4" style="background-color: #FCFBFB;">
                                    <td class="col">
                                        <div class="card">
                                            <div class="card-body">
                                                <table style="margin: auto;">
                                                    <tr class="row mb-4 mt-4">
                                                        <td class="col">
                                                            <h1 style="font-weight: bolder; text-align: left; color: #282828;">
                                                                キャンセルポリシー/Cancellation Policy</h1>
                                                            <hr style="color: #A7A7A7;">
                                                            <p style="font-weight: bolder; color: #282828;">
                                                                <u><b
                                                                        style="color: #282828;">https://www.theluxuryfleet.com/cancellation-policy</b></u>
                                                            </p>
                                                            <p style="margin-top: 0px; color: #282828;">
                                                                Cancellation fees vary depending on the
                                                                time of day
                                                                and before or after the reserved date.
                                                                Please refer
                                                                to the link above for cancellation
                                                                procedures.</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="row mb-4 mt-4" style="background-color: #FCFBFB;">
                                    <td class="col">
                                        <div class="card">
                                            <div class="card-body">
                                                <table style="margin: auto;">
                                                    <tr class="row mb-4 mt-4">
                                                        <td class="col">
                                                            <h1 style="font-weight: bolder; text-align: left; color: #282828;">
                                                                連絡先/Contact Us</h1>
                                                            <hr style="color: #A7A7A7;">
                                                            <p style="font-weight: bolder; color: #571B35;">
                                                                <b style="color: #571B35;">株式会社 THE LUXURY FLEET | THE LUXURY
                                                                    FLEET Co.,
                                                                    Ltd.</b>
                                                            </p>
                                                            <p style="margin-top: 0px; color: #571B35;">
                                                                〒140-0001 東京都品川区北品川1-8-20 第2林ビル
                                                                1階/2階Dai 2 Hayashi
                                                                Building 1F/2F, Kitashinagawa 1-8-20,
                                                                Shinagawa-ku,
                                                                Tokyo 140-0001 </p>
                                                            <p style="margin-bottom: 0px; color: #571B35;">
                                                                <b style="color: #571B35;">Tel.</b> (+81) 3-6822-8772
                                                            </p>
                                                            <p style="margin-bottom: 0px; color: #571B35;">
                                                                <b style="color: #571B35;">※営業時間 10:00 - 19:00 平日対応
                                                                    (土・日・祝日を除く)</b>
                                                            </p>
                                                            <p style="margin-top: 0px; color: #571B35;">
                                                                <b style="color: #571B35;">Business hours:</b> 10:00 - 19:00
                                                                weekdays
                                                                (except Saturdays, Sundays and national
                                                                holidays)
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="row mb-4 mt-4">
                                    <td class="col">
                                        <div class="card" style="background-color: #F1F1F1;">
                                            <div class="card-body">
                                                <table style="margin: auto;">
                                                    <tr class="row mb-4 mt-4">
                                                        <td class="col">
                                                            <p style="margin-bottom: 0px; color: #282828;">
                                                                ※このメールアドレスは送信専用となっております。ご返信いただいても対応はいたしかねますので、ご了承ください。</p>
                                                            <p style="margin-top: 0px; color: #282828;">
                                                                ※
                                                                This email is meant for only the intended recipient of the
                                                                transmission. It is a
                                                                confidential communication.</p>
                                                            <p style="margin-bottom: 0px; color: #282828;">
                                                                ※このメールにお心当たりの無い場合は、メールを破棄してください。</p>
                                                            <p style="margin-top: 0px; color: #282828;">
                                                                ※ If
                                                                you received this email in error, please kindly ignore it and
                                                                delete this message
                                                                from your system. </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="row mb-4 mt-4">
                                    <td class="col">
                                        <div class="card" style="background-color: #571B35;">
                                            <div class="card-body">
                                                <table style="margin: auto;">
                                                    <tr class="row mb-4 mt-4">
                                                        <td class="col">
                                                            <br><b style="color: #DBBE78;">株式会社 THE LUXURY FLEET | THE LUXURY
                                                                FLEET Co.,
                                                                Ltd.</b><br><br>
                                                            <span
                                                                style="font-size: 90%; text-align: left; color: #DBBE78">〒140-0001
                                                                東京都品川区北品川1-8-20
                                                                第2林ビル 1階/2階</span><br>
                                                            <span style="font-size: 90%; text-align: left; color: #DBBE78">Dai 2
                                                                Hayashi Building
                                                                1F/2F, Kitashinagawa 1-8-20, Shinagawa-ku, Tokyo 140-0001
                                                            </span><br><br>
                                                            <span style="font-size: 90%; text-align: left; color: #DBBE78">Tel.
                                                                (+81)
                                                                3-6822-8772</span><br><br>
                                                            <span
                                                                style="font-size: 90%; text-align: left; color: #DBBE78">Copyright
                                                                © 2023 THE
                                                                LUXURY FLEET A member of MAHA Group</span><br><br>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
        </body>
        </html>
  `;
  
    return template;
  }