/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, } from "react";
import { useTranslation } from "react-i18next";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";

const PassengerCard = ({
  setFormDataToState,
  passengerFormData,
  setPaxPanel,
  passangerForm = true,
  luggageForm = true,
  passangerMax = null,
  lugageMax = null,
  clickedVt = null,
}) => {
  const { t } = useTranslation();
  const [lugageFormData, setLugageFormData] = useState({
    cabinSize: 0,
    checkinSize: 0,
  });
  const [paxFormData, setPaxFormData] = useState({
    adult: 0,
    child: 0,
    toddler: 0,
    baby: 0,
  });
  const [validationStyle, setValidationStyle] = useState({
    adult: false,
    child: false,
    toddler: false,
    baby: false,
  });
  const [validationStyleLugage, setValidationStyleLugage] = useState({
    cabinSize: false,
    checkinSize: false,
  });

  useEffect(() => {
    if (passangerForm) {
      if (passengerFormData === null || passengerFormData === undefined) return;
      setPaxFormData({
        adult: passengerFormData.adult,
        child: passengerFormData.child,
        toddler: passengerFormData.toddler,
        baby: passengerFormData.baby,
      });
    } else if (luggageForm) {
      if (passengerFormData === null || passengerFormData === undefined) return;
      setLugageFormData({
        cabinSize: passengerFormData.cabinSize,
        checkinSize: passengerFormData.checkinSize,
      });
    }
  }, []);
  useEffect(() => {
    if (passangerForm) {
      setFormDataToState(paxFormData);
    } else if (luggageForm) {
      setFormDataToState(lugageFormData);
    }

  }, [paxFormData, lugageFormData]);

  const onChange = (e) => {
    // setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) });
  };

  const checkMaxPax = (type) => {
    var maxPax;
    var selectedPax;

    switch (type) {
      case "adult":
        maxPax = passangerMax.adult
        selectedPax = paxFormData.adult
        break;
      case "child":
        maxPax = passangerMax.child
        selectedPax = paxFormData.child
        break;
      case "toddler":
        maxPax = passangerMax.toddler
        selectedPax = paxFormData.toddler
        break;
      case "baby":
        maxPax = passangerMax.baby
        selectedPax = paxFormData.baby
        break;
      default:
        break;
    }

    if (getTotalPaxCount(passengerFormData) >= clickedVt.maxpassenger) {
      setValidationStyle({
        adult: true,
        child: true,
        toddler: true,
        baby: true,
      })

      return false;
    } else {
      if (selectedPax >= maxPax) {
        setValidationStyle({ ...validationStyle, [type]: true })

        return false;
      } else {
        setValidationStyle({ ...validationStyle, [type]: false })

        return true;
      }
    }
  }

  const checkMaxLugage = (type) => {
    var maxLugage;
    var selectedLugage;

    switch (type) {
      case "cabinSize":
        maxLugage = lugageMax.cabinSize
        selectedLugage = lugageFormData.cabinSize
        break;
      case "checkinSize":
        maxLugage = lugageMax.checkinSize
        selectedLugage = lugageFormData.checkinSize
        break;
      default:
        break;
    }

    if (getTotalLuggageCount(lugageFormData) >= clickedVt.maxluggages) {
      setValidationStyleLugage({
        cabinSize: true,
        checkinSize: true,
      })

      return false;
    } else {
      if (selectedLugage >= maxLugage) {
        setValidationStyleLugage({ ...validationStyleLugage, [type]: true })

        return false;
      } else {
        setValidationStyleLugage({ ...validationStyleLugage, [type]: false })

        return true;
      }
    }
  }

  useEffect(() => {
    if (lugageMax !== null) {
      for (const key in lugageFormData) {
        if (lugageFormData.hasOwnProperty(key)) {
          setValidationStyleLugage(validationStyleLugage => ({
            ...validationStyleLugage,
            [key]: lugageFormData[key] >= lugageMax[key]
          }));
        }
      }
    }
    return
  }, [lugageFormData])

  useEffect(() => {
    if (passangerMax !== null) {
      for (const key in paxFormData) {
        if (paxFormData.hasOwnProperty(key)) {
          setValidationStyle(validationStyle => ({
            ...validationStyle,
            [key]: paxFormData[key] >= passangerMax[key]
          }));
        }
      }
    }
  }, [paxFormData])

  return (
    <div
      className="passengers-card position-absolute start-0 translate-middle-x modal-fullscreen-sm-down"
      style={{ zIndex: "3" }}
    >
      <div className="card mb-2 px-1 py-1">
        <div className="card-body pb-0">
          <div className="row">
            {passangerForm ? (
              <div className="col-7 d-flex flex-row align-items-center">
                <p className="text-dark fs-6 fw-bold mb-1">{t("Passengers")}</p>
              </div>
            ) : (
              <></>
            )}

            {passangerForm ? (
              <div className="col-12 px-0">
                <ul className="list-group list-group-flush border-0">
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0">
                        <p className="text-dark my-0">{t("Adults")}</p>
                      </div>
                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (paxFormData.adult === 0) return;
                              setPaxFormData({
                                ...paxFormData,
                                adult: paxFormData.adult - 1,
                              });
                              if (passangerMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>

                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            aria-label="Number of adults"
                            name="adult"
                            value={paxFormData?.adult}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyle.adult ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyle.adult}
                            onClick={() => {
                              if (passangerMax != null) {
                                if (checkMaxPax("adult")) {
                                  setPaxFormData({
                                    ...paxFormData,
                                    adult: paxFormData.adult + 1,
                                  });
                                }
                              } else {
                                setPaxFormData({
                                  ...paxFormData,
                                  adult: paxFormData.adult + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0">
                        <p className="text-dark my-0">{t("Child")}</p>
                      </div>

                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (paxFormData.child === 0) return;
                              setPaxFormData({
                                ...paxFormData,
                                child: paxFormData.child - 1,
                              });
                              if (passangerMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            name="child"
                            value={paxFormData?.child}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyle.child ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyle.child}
                            onClick={() => {
                              if (passangerMax != null) {
                                if (checkMaxPax("child")) {
                                  setPaxFormData({
                                    ...paxFormData,
                                    child: paxFormData.child + 1,
                                  });
                                }
                              } else {
                                setPaxFormData({
                                  ...paxFormData,
                                  child: paxFormData.child + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0">
                        <p className="text-dark my-0">{t("Toddler")}</p>
                      </div>

                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (paxFormData.toddler === 0) return;
                              setPaxFormData({
                                ...paxFormData,
                                toddler: paxFormData.toddler - 1,
                              });
                              if (passangerMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            name="toddler"
                            value={paxFormData?.toddler}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyle.toddler ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyle.toddler}
                            onClick={() => {
                              if (passangerMax != null) {
                                if (checkMaxPax("toddler")) {
                                  setPaxFormData({
                                    ...paxFormData,
                                    toddler: paxFormData.toddler + 1,
                                  });
                                }
                              } else {
                                setPaxFormData({
                                  ...paxFormData,
                                  toddler: paxFormData.toddler + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0">
                        <p className="text-dark my-0">{t("Baby")}</p>
                      </div>

                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (paxFormData.baby === 0) return;
                              setPaxFormData({
                                ...paxFormData,
                                baby: paxFormData.baby - 1,
                              });
                              if (passangerMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            name="baby"
                            value={paxFormData?.baby}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyle.baby ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyle.baby}
                            onClick={() => {
                              if (passangerMax != null) {
                                if (checkMaxPax("baby")) {
                                  setPaxFormData({
                                    ...paxFormData,
                                    baby: paxFormData.baby + 1,
                                  });
                                }
                              } else {
                                setPaxFormData({
                                  ...paxFormData,
                                  baby: paxFormData.baby + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            {luggageForm ? (
              <div className="col-12 d-flex flex-row align-items-center">
                <p className="text-dark fs-6 fw-bold mb-1">{t("Luggage")}</p>
              </div>
            ) : (
              <></>
            )}
            {luggageForm ? (
              <div className="col-12 px-0">
                <ul className="list-group list-group-flush border-0">
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0">
                        <p className="text-dark my-0">
                          {t("Cabin.Size")}
                          <br />
                          <span className="fs-6">
                            {t("Cabin.Size.Measurements")}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (lugageFormData.cabinSize === 0) return;
                              setLugageFormData({
                                ...lugageFormData,
                                cabinSize: lugageFormData.cabinSize - 1,
                              });
                              if (lugageMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            name="cabinSize"
                            value={lugageFormData?.cabinSize}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyleLugage.cabinSize ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyleLugage.cabinSize}
                            onClick={() => {
                              if (lugageMax != null) {
                                if (checkMaxLugage("cabinSize")) {
                                  setLugageFormData({
                                    ...lugageFormData,
                                    cabinSize: lugageFormData.cabinSize + 1,
                                  });
                                }
                              } else {
                                setLugageFormData({
                                  ...lugageFormData,
                                  cabinSize: lugageFormData.cabinSize + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item border-0 text-dark">
                    <div className="row d-flex align-items-center">
                      <div className="col-6 mt-0 pe-0">
                        <p className="text-dark my-0">
                          {t("Checkin.Size")}
                          <br />
                          <span className="fs-6">
                            {t("Checkin.Size.Measurements")}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <div
                          className="d-flex"
                          role="group"
                          aria-label="Default button group"
                        >
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            onClick={() => {
                              if (lugageFormData?.checkinSize === 0) return;
                              setLugageFormData({
                                ...lugageFormData,
                                checkinSize: lugageFormData.checkinSize - 1,
                              });
                              if (lugageMax != null) {

                              }
                            }}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            type="text"
                            className="form-control passenger-card border-0 text-center mx-sm-2 d-flex align-items-end"
                            placeholder="0"
                            name="checkinSize"
                            value={lugageFormData?.checkinSize}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-circle btn-outline-dark"
                            style={validationStyleLugage.checkinSize ? {
                              borderColor: "red"
                            } : {}}
                            disabled={validationStyleLugage.checkinSize}
                            onClick={() => {
                              if (lugageMax != null) {
                                if (checkMaxLugage("checkinSize")) {
                                  setLugageFormData({
                                    ...lugageFormData,
                                    checkinSize: lugageFormData.checkinSize + 1,
                                  });
                                }
                              } else {
                                setLugageFormData({
                                  ...lugageFormData,
                                  checkinSize: lugageFormData.checkinSize + 1,
                                });
                              }
                            }}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <div className="d-grid gap-2 d-sm-none mt-5 mb-1 mx-2">
          <button
            type="button"
            className="btn btn-primary btn-lg px-1"
            onClick={() => {
              setPaxPanel(false);
            }}
          >
            <div className="px-2 d-inline">{t("Done")}</div>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default PassengerCard;
