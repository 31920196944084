/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, } from "react-redux";
import { setLanguage } from "../../actions/admin/settings";
/**This is the hovering language card used to switch the language.
 */
const LanguageCard = () => {
  const dispatch = useDispatch();

  const {  i18n } = useTranslation();
  // const [language, setLanguage] = useState("JP");
  const langChange = (lng) => {
    console.log(lng)
    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lang", lng);
    dispatch(setLanguage(lng));
  };

  useEffect(() => {
    try {
      if (localStorage.getItem("lang") === undefined || localStorage.getItem("lang") === null) {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang === "ja") {
          langChange("JP");
        } else {
          langChange("EN");
        }
      }
    } catch { }
  }, []);

  return (
    <div className="btn-group dropdown">
      <button
        type="button"
        className="nav-link-header bottom-sm-0 end-sm-0 btn btn-dark btn-lg btn-language rounded-3 dropdown-toggle ms-lg-3 me-2 me-lg-0 py-lg-2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className=" d-inline ">
          <i className="bi bi-globe"></i>{" "}
        </div>
        {/* <span className="ps-1 pe-2">{t("Language")} </span> {" "}  */}
        <span className="ps-1 pe-2">{localStorage.getItem("lang")} </span>{" "}
      </button>

      <ul className="dropdown-menu">
        <li>
          <button
            className="dropdown-item"
            onClick={() => {
              langChange("EN");
            }}
          >
            {" "}
            English
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            onClick={() => {
              langChange("JP");
            }}
          >
            {" "}
            Japanese
          </button>
        </li>
      </ul>
    </div>
  );
};

export default LanguageCard;
