import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { login, checkEmailBeforeRegister } from "../../actions/auth";
import { useHistory, useLocation } from "react-router-dom";
import googleIcon from "../../images/google-icon.svg";
import CustomerRegister from "./CustomerRegister";
import { useTranslation } from "react-i18next";
import { useLoading } from "../front-pages-new-theme/LoadingContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import email_icon from "../../icons/email.svg";
import lock_icon from "../../icons/lock.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";
import profile_gold_icon from "../../icons/profile_gold.svg";

const CustomerLogin = ({
  login,
  isAuthenticated,
  useModal,
  isRegister,
  setIsRegister,
  modalId,
  isBooking,
  book,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [loginFailed, setLoginFailed] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  let registerFormQuery = queryParams.get("is_register");


  const settings = useSelector((state) => state.setting);
  const { lang } = settings;


  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formDataRegister, setFormDataRegister] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    sign_up_with_google: false,
    access_token_google: null,
    avatar: null,
  });
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const { email, password } = formData;
  const { showLoading, closeLoading } = useLoading();
  const buttonCLoseModalLogin = document.getElementById("closeModalLogin");
  const [loginErrorMsg, setLoginErrorMsg] = useState("")

  useEffect(() => {
    window.gapi.load("client:auth2", () => {
      window.gapi.client.init({
        client_id:
          "341935228570-l3uovc1akibqn63tjf2lfhiaamvnuk61.apps.googleusercontent.com",
        scope: "email",
        plugin_name: "chat",
        cookie_policy: "single_host_origin",
      });
    });
  }, []);

  const resetState = () => {
    setFormDataRegister({
      email: "",
      password: "",
      password_confirmation: "",
      sign_up_with_google: false,
      access_token_google: null,
      avatar: null,
    });
    setFormData({
      email: "",
      password: "",
    });
    setIsGoogleSignUp(false);
    setLoginFailed(false);
  };

  const handleSignInGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();

      // Handle the signed-in user
      const user = await auth2.signIn({ prompt: "consent" });
      const profile = user.getBasicProfile();
      const authResponse = user.getAuthResponse();
      const accessToken = authResponse.access_token;

      const userId = profile.getId();
      const userName = profile.getName();
      const userEmail = profile.getEmail();
      const userImageURL = profile.getImageUrl();
      showLoading(true);
      const checkLoginWithGoogle = await login(
        userEmail,
        accessToken,
        "google"
      );
      // console.log(checkLoginWithGoogle);

      // console.log("RESPONSE LOGIN GOOGLE");
      // console.log(checkLoginWithGoogle);

      // Log user details
      // console.log("User ID: ", userId);
      // console.log("User Name: ", userName);
      // console.log("User Email: ", userEmail);
      // console.log("User Image URL: ", userImageURL);
      // console.log("Access Token: ", accessToken);

      if (!checkLoginWithGoogle) {
        const checkEmailStatus = await checkEmailBeforeRegister({
          ...formDataRegister,
          sign_up_with_google: true,
          email: userEmail,
          access_token_google: accessToken,
          avatar: userImageURL,
          role: "individual"
        })

        if (checkEmailStatus.data.code == 200) {
          setFormDataRegister({
            ...formDataRegister,
            sign_up_with_google: true,
            email: userEmail,
            access_token_google: accessToken,
            avatar: userImageURL,
          });
          setIsGoogleSignUp(true);
        } else {
          setLoginErrorMsg(checkEmailStatus.data.desc);
          setLoginFailed(true);
          closeLoading(true);
        }
      }else{
      buttonCLoseModalLogin.click();
      closeLoading(true);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
    }
  };

  useEffect(() => {
    if (isGoogleSignUp) {
      closeLoading(true);
      buttonCLoseModalLogin.click();
      
      history.push({
        pathname: "/register",
        state: { formData: formDataRegister },
      });
    }
  }, [isGoogleSignUp, formDataRegister]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    showLoading(true);
    e.preventDefault();
    const res = await login(email, password);
    
    if (!res) {
      setLoginErrorMsg("login.error");
      setLoginFailed(true);
      closeLoading(true);
    } else {
      buttonCLoseModalLogin.click();
      closeLoading(true);
    }
  };
  if (isAuthenticated) {
    if (isBooking) {
      resetState();
      book();
    } else {
      resetState();
      closeLoading(true);
      buttonCLoseModalLogin.click();
      history.push("/");
    }
  }

  return (
    <Fragment>
      
      {!useModal && <h2 className="d-block d-md-none">{(isRegister == true || registerFormQuery == "true") ? t("sign_up.sign_up") : t("login.login") }</h2>}
            <hr className="d-block d-md-none" />
      {useModal ? (
        <div
          className={`modal fade`}
          id={modalId}
          tabIndex="0"
          aria-labelledby="loginLabel"
          aria-hidden="true"
        >
          <div className=" modal-dialog modal-login modal-dialog-centered ">
            <div className="modal-content  border-top-gold">
              <div className="modal-body px-4">
                <div className="row d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close p-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeModalLogin"
                  ></button>
                </div>
                <div className="row d-flex align-items-center mb-3">
                  <div className="col-auto">
                    <h3 className="fw-900 text-secondary">
                      {t("login.login")}
                    </h3>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                {/* START FORM */}
                {loginFailed && (
                  <>
                    <div className="alert alert-danger">{t(loginErrorMsg)}</div>
                  </>
                )}
                <form onSubmit={(e) => onSubmit(e)} action="#">
                  <div className="row g-3 mb-3">
                    <div className="col form-floating fs-8">
                      <input
                        type="text"
                        className={`form-control contact-form `}
                        id="emailAddress1"
                        placeholder="Email address"
                        name="email"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="emailAddress1">
                        <div className="d-flex align-items-center">
                          <img src={email_icon} alt=""  />
                          &nbsp; {t("login.email_address")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col form-floating fs-8">
                      <input
                        type="password"
                        className={`form-control contact-form `}
                        id="password1"
                        placeholder="Password"
                        name="password"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="password1">
                        <div className="d-flex align-items-center">
                          <img src={lock_icon} alt=""  />
                          &nbsp; {t("login.password")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <a href="/forgot-password" className="text-muted noto-sans" style={{fontSize : "14px"}}>

                    {t("login.forgot_password")}
                  </a>
                  <div className="row mt-4 justify-content-center">
                    <div className="col-auto d-grid text-center">
                      <button
                        name=""
                        id=""
                        type="submit"
                        className="btn btn-primary rounded py-2 px-4"
                        href="#"
                        role="button"
                      >
                        {t("login.login")}
                        <i className="ps-2">
                          <img src={arrow_right_icon} alt=""  />
                        </i>
                      </button>
                      <a
                        href="#"
                        className="text-muted mt-3 noto-sans"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#register"
                        style={{fontSize : "14px"}}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("login.sign_up_link"),
                          }}
                        ></span>
                      </a>
                    </div>
                  </div>
                </form>

                <div className="row align-items-center mt-4">
                  <div className="col">
                    <hr />
                  </div>
                  <div className="col-auto">
                    <p className="p-0 m-0">{t("login.or")}</p>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                <div className="row my-4 justify-content-center ">
                  <div className="col-auto d-grid text-center ">
                    <a
                      name=""
                      id=""
                      className="btn btn-secondary rounded py-2 px-4 m-0"
                      role="button"
                      onClick={handleSignInGoogle}
                    >
                      <img
                        src={googleIcon}
                        className="img-fluid"
                        alt="Efficiency"
                      />
                      &nbsp; {t("login.continue_w_google")}
                    </a>
                  </div>
                  {isBooking ? (
                    <div className="col-6 d-grid text-center ">
                      <button
                        type="button"
                        name=""
                        id=""
                        className="btn btn-secondary rounded py-2 px-4 m-0"
                        href="#"
                        role="button"
                        onClick={book}
                        data-bs-dismiss="modal"
                      >
                        <img src={profile_gold_icon} alt=""  />
                        &nbsp; {t("login.continue_as_guest")}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* END FORM */}
              </div>
            </div>
          </div>
        </div>
      ) : (isRegister == true || registerFormQuery == "true") ? (
        <CustomerRegister setIsRegister={setIsRegister} />
      ) : (
        <>
          {loginFailed && (
            <>
              <div className="alert alert-danger">{t(loginErrorMsg)}</div>
            </>
          )}
          <form onSubmit={(e) => onSubmit(e)} action="#">
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form `}
                  id="emailAddress2"
                  placeholder="Email address"
                  name="email"
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="emailAddress2">
                  <div className="d-flex align-items-center">
                    <img src={email_icon} alt=""  />
                    &nbsp; {t("login.email_address")}
                  </div>
                </label>
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="password"
                  className={`form-control contact-form `}
                  id="password2"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="password2">
                  <div className="d-flex align-items-center">
                    <img src={lock_icon} alt=""  />
                    &nbsp; {t("login.password")}
                  </div>
                </label>
              </div>
            </div>
            <a href="/forgot-password" className="text-muted noto-sans">
              {t("login.forgot_password")}
            </a>
            <div className="row mt-4 justify-content-center">
              <div className="col-md-6 d-grid text-center">
                <button
                  name=""
                  id=""
                  className="btn btn-primary rounded py-2 px-4"
                  href="#"
                  role="button"
                  type="submit"
                >
                  {t("login.login")}
                  <i className="ps-2">
                    <img src={arrow_right_icon} alt=""  />
                  </i>
                </button>
                <Link className="text-muted mt-3 noto-sans" to="/login?is_register=true"> <span
                    dangerouslySetInnerHTML={{ __html: t("login.sign_up_link") }}
                  ></span>
                </Link>
                {/* <a
                  href="#"
                  // onClick={() => setIsRegister(true)}
                  className="text-muted mt-3 noto-sans"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: t("login.sign_up_link") }}
                  ></span>
                </a> */}
              </div>
            </div>
            <div className="row align-items-center mt-4">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto">
                <p className="p-0 m-0">{t("login.or")}</p>
              </div>
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row my-4 justify-content-center ">
              <div className="col-md-7 d-grid text-center">
                <a
                  name=""
                  id=""
                  className="btn btn-secondary rounded py-2 px-4 m-0"
                  role="button"
                  onClick={handleSignInGoogle}
                >
                  <img src={googleIcon} className="img-fluid" alt="Efficiency" />
                  &nbsp; {t("login.continue_w_google")}
                </a>
              </div>
            </div>
          </form>
        </>
      )}
    </Fragment>
  );
};

CustomerLogin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(CustomerLogin);
