import eye_icon from "../../../../icons/eye.svg";
import download_white_icon from "../../../../icons/download_white.svg";
const Quotation = () => {
  return (
    <>
      <div className="mx-md-3 mt-2">
        {/* DESKTOP VIEW */}
        <div className="table-responsive d-none d-md-block mt-4">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Quotation ID</th>
                <th scope="col">Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-table-myPage">2023/07/31</td>
                <td className="td-table-myPage">24F3BF790</td>
                <td className="td-table-myPage">
                  Receipt for TLF June Invoice
                </td>
                <td className="td-table-myPage">
                  <div className="row justify-content-center">
                    <div className="col-5 d-grid">
                      <button
                        type="button"
                        className="btn bg-gold-light-100 rounded"
                      >
                        <img  alt="img" src={eye_icon}/>
                        &nbsp; View
                      </button>
                    </div>
                    <div className="col-7 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary rounded "
                      >
                        <img src={download_white_icon} alt="" srcset="" />
                        &nbsp; <span className="white-text">Download</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* MOBILE VIEW */}
        <div className="card mb-3 d-block d-md-none">
          <div className="card-body">
            <div className="row justify-content-between align-items-center mb-3">
              <div className="col fs-14">2023/07/31</div>
            </div>
            <p className="fs-16 noto">TLF July Invoice</p>
            <p>
              <b>Quotation ID : </b>24F3BF790
            </p>
            <div className="row p-0">
              <div className="col-auto">
                <button type="button" className="btn bg-gold-light-100 rounded">
                  
                <img  alt="img" src={eye_icon}/>
                  &nbsp; View
                </button>
              </div>
              <div className="col-auto p-0">
                <button type="button" className="btn btn-primary rounded ">
                 
                <img  alt="img" src={download_white_icon}/>
                  &nbsp; <span className="white-text">Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotation;
