import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import Alert from "../../layout/Alert";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../layout/Spinner";
import { getCancelRescheduleRequestById, addResponseRequest } from "../../../actions/admin/cancelreschedulerequest";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import Moment from "react-moment";
import { useLoading } from "../../../components/front-pages-new-theme/LoadingContext";
import { chargeTokenReschedule } from "../../../actions/admin/flywire"

const ManageCancelRescheduleRequest = ({ match, history }) => {
  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);
  const { showLoading, closeLoading } = useLoading();
  const cancelreschedule = useSelector((state) => state.cancelreschedule);
  const { loading, cancelreschedulerequest } = cancelreschedule;
  const [formData, setFormData] = useState({
    requestId: "",
    status: "",
    note: "",
  });
  const [formDataValidation, setFormDataValidation] = useState({
    requestId: true,
    status: true
  });
  const [style, setStyle] = useState("")
  const [styleBookingStatus, setStyleBookingStatus] = useState("")
  const [requestStatusStyle, setRequestStatusStyle] = useState("")

  useEffect(() => {
    showLoading(true);
    dispatch(getCancelRescheduleRequestById(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (!loading && cancelreschedulerequest !== null) {
      setFormData({ ...formData, requestId: cancelreschedulerequest._id })
      switch (cancelreschedulerequest.bookingId.bookingStatus) {
        case "CONFIRMED":
          setStyleBookingStatus("badge bg-success py-2");
          break;
        case "CHARGE_PENDING":
          setStyleBookingStatus("badge bg-primary py-2");
          break;
        case "INQUIRY":
          setStyleBookingStatus("badge bg-warning py-2");
          break;
        case "CANCELLED":
          setStyleBookingStatus("badge bg-danger py-2");
          break;
        default:
          setStyleBookingStatus("badge bg-dark py-2");
          break;
      }

      switch (cancelreschedulerequest.currentStatus) {
        case "APPROVED_BY_ADMIN":
          setStyle("badge bg-success py-2");
          break;
        case "WAITING_CONFIRMATION":
          setStyle("badge bg-primary py-2");
          break;
        case "AUTO_APPROVED":
          setStyle("badge bg-warning py-2");
          break;
        default:
          setStyle("badge bg-dark py-2");
          break;
      }

      switch (cancelreschedulerequest.approvalStatus) {
        case 0:
          setRequestStatusStyle("badge bg-grey-2 py-2");
          break;
        case 1:
          setRequestStatusStyle("badge bg-success py-2");
          break;
        case 2:
          setRequestStatusStyle("badge bg-danger py-2");
          break;
        default:
          setRequestStatusStyle("badge bg-dark py-2");
          break;
      }
      closeLoading(true);
    }
  }, [cancelreschedulerequest])

  console.log(cancelreschedulerequest)

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onChangeStatus = (e) => {
    setFormData({ ...formData, status: e.value });
    setFormDataValidation({
      ...formDataValidation,
      status: true,
    })
  }

  const chargeTokenHandler = async (id) => {
    showLoading(true);
    const chargeTokenRes = await chargeTokenReschedule(id);
    closeLoading(true);

    if (chargeTokenRes.data.status == "success") {
      window.location.reload();
    } else {
      console.log(chargeTokenRes.data)
    }

  }

  const validationRequestResponse = () => {
    if (formData.requestId == "" || formData.status == "") {
      setFormDataValidation({
        ...formDataValidation,
        status: formData.status == "" ? false : true,
        requestId: formData.requestId == "" ? false : true,
      })
      return false;
    } else {
      return true;
    }
  }

  const onFormSubmit = () => {
    if (validationRequestResponse()) {
      // e.preventDefault();

      console.log(formData)
      dispatch(addResponseRequest(formData));
    }
  };
  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Manage Request Cancellation or Reschedule" />
          {!loading && cancelreschedulerequest !== null && (
            <Fragment>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="col-sm-offset-2 col-sm-10">
                        <Link
                          className="btn btn-secondary"
                          to="/admin/cancel-reschedule-request"
                        >
                          <i
                            className="fa fa-arrow-left fa-fw"
                            aria-hidden="true"
                          ></i>{" "}
                          Back To List
                        </Link>
                      </div>
                    </div>
                    {/* Customer Information */}
                    <div className="card text-dark mb-3">
                      <div className="card-header">Customer's Billing Information</div>
                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col">
                            <h6 className="card-title">Name</h6>
                            <p className="card-text py-1">{cancelreschedulerequest.userId.individualDetail.billingInformation.fullName}</p>
                          </div>
                          <div className="col">
                            <h6 className="card-title">Phone</h6>
                            <p className="card-text py-1">{cancelreschedulerequest.userId.individualDetail.billingInformation.phoneNumber}</p>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <h6 className="card-title">Email</h6>
                            <p className="card-text py-1">{cancelreschedulerequest.userId.email}</p>
                          </div>
                          <div className="col">
                            <h6 className="card-title">Type</h6>
                            <p className="card-text py-1">{cancelreschedulerequest.userId.role}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <div className="card-header">
                        Booking Information{" "}
                        <span
                          style={{
                            border:
                              "1px solid #AAAAA",
                            borderRadius: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Cart ID : {cancelreschedulerequest.bookingId.cartID} | {cancelreschedulerequest.bookingId.paymentStatus}
                        </span>
                      </div>
                      <div className="card-body text-dark">
                        <div className="row">
                          <div className="col mb-2">
                            <div className="row mb-2">
                              <div className="col">
                                <h6 className="card-title">Current Status</h6>
                                <p className={`card-text py-1 ${style}`}>
                                  {cancelreschedulerequest.currentStatus}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Booking Status</h6>
                                <p className={`card-text py-1 ${style}`}>
                                  {cancelreschedulerequest.bookingId.bookingStatus}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Booking Type</h6>
                                <p className={`card-text py-1`}>
                                  {cancelreschedulerequest.bookingId.bookingType === "byTheHour" ? "By The Hour" : "Specified Destination"}
                                </p>
                              </div>
                            </div>

                            <h6 className="card-title text-center">Pickup Time </h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1 mb-2">
                                  <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.travelDate : cancelreschedulerequest.bookingId.pickupDateTime}</Moment>
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2">
                                      <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.travelDateRequest}</Moment>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">Duration </h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1 mb-2">
                                  {cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.duration : cancelreschedulerequest.bookingId.duration}
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2">
                                      {cancelreschedulerequest.duration}
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">Dropoff Time</h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1 mb-2">
                                  <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.dropoffTime : cancelreschedulerequest.bookingId.customerDropoffDateTime}</Moment>
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2">
                                      <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.dropoffTimeRequest}</Moment>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">Calculated dispatch from Garage</h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1 mb-2">
                                  <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.startFromGarage : cancelreschedulerequest.bookingId.startFromGarage}</Moment>
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2">
                                      <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.startFromGarage}</Moment>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">
                              Calculated arrival at Garage
                            </h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1 mb-2">
                                  <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.arrivalAtGarage : cancelreschedulerequest.bookingId.garageArivalTime}</Moment>
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2">
                                      <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.arrivalAtGarage}</Moment>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">Pickup Location</h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1" style={{
                                  textAlign: 'center'
                                }}>
                                  {cancelreschedulerequest?.historyId ? cancelreschedulerequest.historyId.previousBooking.pickupLocation.label : cancelreschedulerequest.bookingId.pickupLocation.label}
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1 mb-2" style={{
                                      textAlign: 'center'
                                    }}>
                                      {cancelreschedulerequest.pickupLocationRequest.label}
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>

                            <h6 className="card-title text-center">Destinations</h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1" style={{
                                  textAlign: 'center'
                                }}>
                                  {cancelreschedulerequest?.historyId ?
                                    cancelreschedulerequest.historyId.previousBooking.destinations.map((d) => (
                                      <p key={d._id} className="card-text py-1">
                                        {d.label}
                                      </p>
                                    )) :
                                    cancelreschedulerequest.bookingId.destinations.map((d) => (
                                      <p key={d._id} className="card-text py-1">
                                        {d.label}
                                      </p>
                                    ))}
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    {cancelreschedulerequest.destinationsRequest ? (
                                      cancelreschedulerequest.destinationsRequest.map((d, i) => (
                                        <p key={i} className="card-text py-1" style={{
                                          textAlign: 'center'
                                        }}>
                                          {d.label}
                                        </p>
                                      ))
                                    ) : (
                                      <h4>No destinations found...</h4>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <h6 className="card-title text-center">Total Price</h6>
                            <div className="row mb-3">
                              <div className="col d-flex align-items-center justify-content-center">
                                <p className="card-text py-1">
                                  {"JPY " + (cancelreschedulerequest?.historyId ? cancelreschedulerequest?.historyId?.previousBooking?.totalPrice.toLocaleString() : cancelreschedulerequest.bookingId.totalPrice.toLocaleString())}
                                </p>
                              </div>
                              {cancelreschedulerequest.requestType == "reschedule" && (
                                <>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <h6 className="card-title text-center">
                                      <i
                                        className="fa fa-arrow-right fa-fw"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </h6>
                                  </div>
                                  <div className="col d-flex align-items-center justify-content-center">
                                    <p className="card-text py-1">
                                      {"JPY " + cancelreschedulerequest.totalPrice.toLocaleString()}
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />

                        {cancelreschedulerequest.bookingId.bookingInfo.map((val, index) => {
                          return (
                            <div key={index}>
                              <div className="row">
                                <div className="col">
                                  <h6 className="card-title">Vehicle Type {cancelreschedulerequest.bookingId.bookingInfo.length > 1 ? (index + 1) : ''}</h6>
                                  <p className="card-text py-1">
                                    {val?.vehicleTypeID?.typename}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">Vehicle Name {cancelreschedulerequest.bookingId.bookingInfo.length > 1 ? (index + 1) : ''}</h6>
                                  <p className="card-text py-1">
                                    {val?.vehicle?.vehiclename}
                                  </p>
                                </div>
                                <div className="col">
                                  <h6 className="card-title">Driver Name {cancelreschedulerequest.bookingId.bookingInfo.length > 1 ? (index + 1) : ''}</h6>
                                  <p className="card-text py-1">
                                    {val?.driver?.drivername}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        <Link
                          className="btn btn-secondary"
                          to={"/admin/bookinglist/edit/" + cancelreschedulerequest.bookingId._id}
                          target="_blank"
                        >
                          Booking Detail Page
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <div className="card-header">Request details | created at : <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.requestCreatedAt}</Moment></div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col mb-2">
                            <div className="row">
                              <div className="col">
                                <h6 className="card-title">Request Status</h6>
                                <p className={`card-text py-1 ${requestStatusStyle}`}>
                                  {cancelreschedulerequest.approvalStatus == 0 ? "Need Approval" : cancelreschedulerequest.approvalStatus == 1 ? "Approved" : cancelreschedulerequest.approvalStatus == 2 ? "Rejected" : cancelreschedulerequest.approvalStatus == 3 ? "Auto Approved" : "-"}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Request Type</h6>
                                {cancelreschedulerequest.requestType == "reschedule" ? (
                                  <span className="badge bg-info py-2 mb-2">Reschedule</span>
                                ) : (
                                  <span className="badge bg-primary py-2 mb-2">Cancellation</span>
                                )}
                              </div>
                            </div>
                            {cancelreschedulerequest.requestType == "reschedule" && (
                              <>
                                {cancelreschedulerequest.note !== "" && (
                                  <>
                                    <h6 className="card-title">Notes</h6>
                                    <p className="card-text py-1 mb-2">{cancelreschedulerequest.note}</p>
                                  </>
                                )}
                                <h6 className="card-title">Total Price Requested Booking</h6>
                                <p className="card-text py-1">
                                  {"JPY " + cancelreschedulerequest.totalPrice.toLocaleString()}
                                </p>

                                {cancelreschedulerequest.approvalStatus === 0 && (
                                  <>
                                    <h6 className="card-title">Total Price Previous Booking</h6>
                                    <p className="card-text py-1">
                                      {"JPY " + cancelreschedulerequest.bookingId.totalPrice.toLocaleString()}
                                    </p>
                                  </>
                                )}

                                <h6 className="card-title">Payment Status</h6>
                                {cancelreschedulerequest.paymentStatus == "CHARGED" ? (
                                  <span className="badge bg-success py-2">Paid - JPY {(cancelreschedulerequest?.amountToPay ? cancelreschedulerequest?.amountToPay : 0).toLocaleString()}</span>
                                ) : cancelreschedulerequest.paymentStatus == "CHARGE_PENDING" ? (
                                  <span className="badge bg-warning py-2">Need to Charge</span>
                                ) : cancelreschedulerequest.paymentStatus == "AUTO_APPROVED" ? (
                                  <span className="badge bg-success py-2">Need to Charge With Booking</span>
                                ) : ""}

                                <h6 className="card-title mt-2">Flywire Charge Ref</h6>
                                <p className="card-text py-1">
                                  {cancelreschedulerequest?.flywireChargeReference ? cancelreschedulerequest?.flywireChargeReference : "Need to Charge"}
                                </p>

                                <h6 className="card-title mt-2">Charged at</h6>
                                <p className="card-text py-1">
                                  {cancelreschedulerequest.paidAt ? (<Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.paidAt}</Moment>) : '-'}
                                </p>

                                <button
                                  disabled={cancelreschedulerequest.paymentStatus === "CHARGED"}
                                  className="btn btn-secondary ms-1"
                                  onClick={() => { chargeTokenHandler(cancelreschedulerequest._id) }}
                                >
                                  <i
                                    className="fa fa-money fa-fw"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;{
                                    cancelreschedulerequest.paymentStatus === "CHARGED" && cancelreschedulerequest.flywireChargeReference ? 'Success Charged'
                                      : cancelreschedulerequest.paymentStatus === "CHARGE_PENDING" && !cancelreschedulerequest.flywireChargeReference ? 'Charge! - JPY' + cancelreschedulerequest.amountToPay.toLocaleString()
                                        : cancelreschedulerequest.paymentStatus === "AUTO_APPROVED" && !cancelreschedulerequest.flywireChargeReference ? 'Charge! - JPY' + cancelreschedulerequest.totalPrice.toLocaleString()
                                          : ''}
                                </button>
                              </>
                            )}
                            {cancelreschedulerequest.requestType == "cancel" && (
                              <>
                                <div className="row">
                                  <div className="col">
                                    <h6 className="card-title">Cancellation Fee</h6>
                                    <p className={`card-text py-1`}>
                                      JPY {(cancelreschedulerequest?.partialCancellationFee ? cancelreschedulerequest?.partialCancellationFee : 0).toLocaleString()}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <h6 className="card-title">Total Refund Amount</h6>
                                    <p className={`card-text py-1`}>
                                      JPY {(cancelreschedulerequest?.totalRefundAmount ? cancelreschedulerequest?.totalRefundAmount : 0).toLocaleString()}
                                    </p>
                                  </div>
                                </div>

                                <h6 className="card-title">Payment Status</h6>
                                {cancelreschedulerequest.paymentStatus == "CHARGED" && cancelreschedulerequest.currentStatus == "APPROVED_BY_ADMIN" ? (
                                  <span className="badge bg-success py-2">Paid - JPY {(cancelreschedulerequest?.totalRefundAmount ? cancelreschedulerequest?.totalRefundAmount : 0).toLocaleString()}</span>
                                ) : cancelreschedulerequest.paymentStatus == "CHARGE_PENDING" && cancelreschedulerequest.currentStatus == "WAITING_CONFIRMATION" ? (
                                  <span className="badge bg-warning py-2">Need to Charge</span>
                                ) : ""}

                                <h6 className="card-title mt-2">Flywire Charge Ref</h6>
                                <p className="card-text py-1">
                                  {cancelreschedulerequest?.flywireChargeReference ? cancelreschedulerequest?.flywireChargeReference : "Need to Charge"}
                                </p>

                                <h6 className="card-title mt-2">Charged at</h6>
                                <p className="card-text py-1">
                                  {cancelreschedulerequest.paidAt ? (<Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.paidAt}</Moment>) : '-'}
                                </p>

                                <button
                                  disabled={cancelreschedulerequest.paymentStatus === "CHARGED"}
                                  className="btn btn-secondary ms-1"
                                  onClick={() => { chargeTokenHandler(cancelreschedulerequest._id) }}
                                >
                                  <i
                                    className="fa fa-money fa-fw"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;{
                                    cancelreschedulerequest.paymentStatus === "CHARGED" || cancelreschedulerequest.paymentStatus === "AUTO_APPROVED" ? 'Success Charged'
                                      : cancelreschedulerequest.paymentStatus === "CHARGE_PENDING" && cancelreschedulerequest.bookingId.paymentStatus === "CHARGE_PENDING" ? 'Charge! - JPY' + cancelreschedulerequest.partialCancellationFee.toLocaleString()
                                      : cancelreschedulerequest.paymentStatus === "CHARGE_PENDING" && cancelreschedulerequest.bookingId.paymentStatus === "CHARGED" ? 'Refund! - JPY' + cancelreschedulerequest.totalRefundAmount.toLocaleString()
                                      : ''}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {cancelreschedulerequest.approvalStatus == 0 && (
                      <div className="card mb-3">
                        <div className="card-header">Approval Response</div>
                        <div className="card-body">
                          <div className="mb-3">
                            <label className="label mb-2">Response:</label>
                            <div className="col-sm-12">
                              <Select
                                className="basic-multi-select"
                                classNamePrefix="select pick-up location"
                                value={formData.status.value}
                                name={"status"}
                                onChange={(e) => {
                                  onChangeStatus(e)
                                }}
                                options={[
                                  { label: 'Approve', value: 'approved' },
                                  { label: 'Reject', value: 'rejected' },
                                ]}
                              />
                              {!formDataValidation.status && (
                                <p className="text-danger">This form is required</p>
                              )}
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className="label mb-2">
                              Notes:
                            </label>
                            <div className="col-sm-12">
                              <textarea
                                className="form-control form-control-admin"
                                id="reg"
                                placeholder="Enter notes for this request"
                                name="note"
                                value={formData.note}
                                onChange={(e) => onChange(e)}
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="col-sm-offset-2 col-sm-10">
                              <button type="submit" className="btn btn-primary" onClick={() => onFormSubmit()}>
                                <i
                                  className="fa fa-plus-circle fa-fw"
                                  aria-hidden="true"
                                ></i>{" "}
                                Send
                              </button>{" "}

                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default withRouter(ManageCancelRescheduleRequest);
