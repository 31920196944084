import { useState } from "react";
import Invoices from "./Invoices";
import Receipts from "./Receipts";
import Quotation from "./Quotation";
import arrow_triangle_up_icon from "../../../../icons/download_white.svg";
const InvoiceAndBIlling = () => {
  const [page, setpage] = useState("Invoices");
  return (
    <>
      <div className="card card-myPage mt-3">
        <div className="card-body">
          {/* DESKTOP VIEW */}
          <div className="d-none d-md-block">
            <ul className="nav">
              <li className="nav-item">
                <a
                  className="nav-link my-page-booking-nl text-black-30 active bg-transparent"
                  id="invoices-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#invoices"
                  type="button"
                  role="tab"
                  aria-controls="invoices"
                  aria-selected="true"
                  aria-current="page"
                  href="#"
                >
                  Invoices
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link my-page-booking-nl text-black-30 bg-transparent"
                  id="receipts-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#receipts"
                  type="button"
                  role="tab"
                  aria-controls="receipts"
                  aria-selected="false"
                  href="#"
                >
                  Receipts
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link my-page-booking-nl text-black-30 bg-transparent"
                  id="quotations-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#quotations"
                  type="button"
                  role="tab"
                  aria-controls="quotations"
                  aria-selected="false"
                  href="#"
                >
                  Quotations
                </a>
              </li>
            </ul>
          </div>

          {/* MOBILE VIEW */}
          <div className="d-block d-md-none">
            <div className="d-grid gap-2 ">
              <button
                className="btn bg-gold-light-100 py-3 rounded"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#booking-menu"
                aria-expanded="false"
                aria-controls="booking-menu"
              >
                <div className="row align-items-center">
                  <div className="col text-start ">{page}</div>
                  <div className="col-auto">
                   <img  alt="img" src={arrow_triangle_up_icon} />
                  </div>
                </div>
              </button>
            </div>
            <div className="collapse" id="booking-menu">
              <div
                className="card card-body border-gold"
                style={{
                  marginTop: "-3px ",
                  fontSize: "0.875rem",
                }}
              >
                <ul className="nav flex-column">
                  <li className="nav-item active noto" role="presentation">
                    <span
                      data-bs-toggle="tab"
                      data-bs-target="#invoices"
                      type="button"
                      role="tab"
                      aria-controls="invoices"
                      aria-selected="true"
                      aria-current="page"
                      onClick={() => setpage("Invoices")}
                    >
                      Invoices
                    </span>
                  </li>
                  <hr />
                  <li className="nav-item noto" role="presentation">
                    <span
                      data-bs-toggle="tab"
                      data-bs-target="#receipts"
                      type="button"
                      role="tab"
                      aria-controls="receipts"
                      aria-selected="false"
                      onClick={() => setpage("Receipts")}
                    >
                      Receipts
                    </span>
                  </li>
                  <hr />
                  <li className="nav-item noto" role="presentation">
                    <span
                      data-bs-toggle="tab"
                      data-bs-target="#quotations"
                      type="button"
                      role="tab"
                      aria-controls="quotations"
                      aria-selected="false"
                      onClick={() => setpage("Quotations")}
                    >
                      Quotations
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* TAB CONTENT */}
          <div className="tab-content" id="invoicesTabContent">
            <div
              className="tab-pane fade show active"
              id="invoices"
              role="tabpanel"
              aria-labelledby="invoices-tab"
            >
              <Invoices />
            </div>
            <div
              className="tab-pane fade"
              id="receipts"
              role="tabpanel"
              aria-labelledby="receipts-tab"
            >
              {/* <Notifications /> */}
              <Receipts />
            </div>
            <div
              className="tab-pane fade"
              id="quotations"
              role="tabpanel"
              aria-labelledby="quotations-tab"
            >
              <Quotation />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceAndBIlling;
